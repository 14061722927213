import React from 'react';
import { Divider, Modal, Button } from 'antd';

import MessageTime from '../Chat/TextMessage/MessageTime/MessageTime';
import './NotesModal.scss';
import "../ReviewComments/ReviewComments.scss"
import { ZERO } from '../../constants';
import { getString } from '../../utils/localization';

export class NotesModal extends React.Component<any, any> {
    messagesEnd:any = React.createRef<HTMLElement | null>();

    constructor(props) {
        super(props);
        const { linkCode, eformVersionId } = props;
        this.state = {
            comment: '',
            fieldName: `${linkCode}_${eformVersionId}`,
        };
        this.handleOk = this.handleOk.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    public handleOk() {
        const { saveCommentsHandler, linkCode, eformVersionId } = this.props;
        const { comment:_comment, fieldName } = this.state;
        const comment = _comment.replace(/\s+/g,' ').trim();
        if (saveCommentsHandler && comment.length !== ZERO) {
            saveCommentsHandler(fieldName, linkCode, eformVersionId, comment);
            this.setState({ comment: '' })
            this.scrollToBottom();
        }
    }

    public keyDownHandler = (e) => {
        if (e.key === 'Enter') {
            this.handleOk();
        }
    };

    public onCancel() {
        const { linkCode, eformVersionId, notesModalCloseHandler } = this.props;

        this.setState({
            comment: '',
            fieldName: `${linkCode}_${eformVersionId}`,
        });

        if (notesModalCloseHandler) {
            notesModalCloseHandler();
        }
    }

    scrollToBottom = () => {
        if(this.messagesEnd && this.messagesEnd.current){
            this.messagesEnd.current.scrollIntoView({ behavior: "auto" });
        }
    }

    componentDidMount() {
        this.scrollToBottom();
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    public render(): React.ReactNode {
        const { reviewComments, showNotesModal } = this.props;

        const { comment, fieldName } = this.state;

        const threads = (reviewComments && reviewComments[fieldName]) || [];
        const comments: any = [];
        threads.forEach(t => {
            t.comments.forEach(c => {
                comments.push({
                    ...c,
                    resolved: t.resolved
                })
            })
        });

        return (
            <React.Fragment>
                <Modal
                  title="Notes"
                  visible={showNotesModal}
                  className="review-comments notes-modal-container"
                  centered
                  onCancel={this.onCancel}
                  footer={[
                        <Button key="back" onClick={this.onCancel}>{getString("Cancel")}</Button>,
                        <Button
                          key="submit"
                          type="primary"
                          disabled={comment.length === 0}
                          onClick={this.handleOk}
                        >Save
                        </Button>
                    ]}
                >
                    <div>
                        <div className={comments.length !== ZERO ? "comments" : ""}>
                            {comments.length !== ZERO &&
                                comments.map((c, index) => {
                                    return (
                                        <div className={`${c.resolved ? "resolved" : ""}`}>
                                            <div
                                              data-letters={(
                                                    (c.userName || "").match(/\b(\w)/g) || [""]
                                                ).join("")}
                                              className="user-details"
                                            >
                                                <span className="user-name">{c.userName}</span>
                                                <MessageTime timestamp={c.createdAt} moveToNewLine={false} />
                                            </div>
                                            <div className="comment">{c.comment}</div>
                                            {(index !== (comments.length-1)) && <Divider className="divider" />}
                                            <div
                                              style={{ clear: "both", float: "left" }}
                                              ref={this.messagesEnd}
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                    <textarea
                      className="notes-input"
                      placeholder="Type notes here"
                      onChange={(e) => {
                            this.setState({ comment: e.target.value });
                        }}
                      value={comment}
                      onKeyPress={this.keyDownHandler}
                      maxLength={255}
                    />
                </Modal>
            </React.Fragment>
        )
    }
}
