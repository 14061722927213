import React, { ReactNode } from 'react';
import { Layout } from 'antd';
import { Route, Switch } from 'react-router-dom';
import { SCBreadCrumb } from '@saleskey/frontend-shared';
import { AdminGroup } from '../AdminGroup/AdminGroup';
import { ThirdParty } from '../Thirdparty/Thirdparty';
import { ListEFormsContainer } from '../ListEForms/ListEForms';
import { DocumentsContainer } from '../Documents/Documents';
import { InternalUsers } from '../InternalUsers/InternalUsers';
import Error from '../../components/Error/Error';
import { SidebarContainer } from '../Sidebar/Sidebar';
import { HeaderComponent } from '../../components/Header/Header';
import { LandingPageComponent } from '../../components/LandingPage/LandingPage';
import { IDashboardProp, IDashboardState } from '../../interfaces/IDashboard';
import { SubmittedEFormsContainer } from '../SubmittedEForms/SubmittedEForms';
import { CreateEForm } from '../CreateEForm/CreateEForm';
import './Dashboard.scss';
import { Geolocation } from '../Geolocation/geolocation';
import { UploadDocumentCategoryTypeAndValue } from '../UploadDocumentCategoryTypeAndValue/UploadDocumentCategoryTypeAndValue';

const { Content } = Layout;
/**
 * Dashboard Container
 * @remarks
 * Landing page for the admin app, contains all the nested routes for admin
 */
class DashboardBaseContainer extends React.Component<IDashboardProp, IDashboardState> {
    /**
     *
     * @param props - type of `{IDashboardProp}`
     */
    constructor(props: IDashboardProp) {
        super(props);
        const { location } = this.props;
        const { pathname } = location;
        const breadCrumbsArray = pathname.split('/');
        this.state = {
            breadCrumbsArray,
        };
    }

    /**
     * @remarks
     * set the breadcrumbs array, if new state is rendered\
     * @returns void
     */
    public componentDidUpdate(): void {
        const { breadCrumbsArray } = this.state;
        const { location } = this.props;
        const { pathname } = location;
        const breadCrumbsArr = pathname.split('/');
        if (breadCrumbsArr.join() !== breadCrumbsArray.join()) {
            this.setState({ breadCrumbsArray: breadCrumbsArr });
        }
    }

    /**
     * @returns ReactNode - parsed as HTML DOM
     */
    public render(): ReactNode {
        const { breadCrumbsArray } = this.state;
        return (
            <Layout className="dashboardContainer">
                <HeaderComponent />
                <Layout className="dashboardSiderLayout">
                    <SidebarContainer />
                    <Layout className="dashboardLayout">
                        <SCBreadCrumb crumbsArray={breadCrumbsArray} />
                        <Content className="dashboardContent">
                            <Switch>
                                <Route path="/dashboard/upload/groupadmin" exact component={AdminGroup} />
                                <Route path="/dashboard/upload/users" exact component={InternalUsers} />
                                <Route path="/dashboard/upload/thirdparty" exact component={ThirdParty} />
                                <Route path="/dashboard/eform/list" exact component={ListEFormsContainer} />
                                <Route path="/dashboard/geolocation" exact component={Geolocation} />
                                <Route
                                    path="/dashboard/eform/list/:eformId/submissions"
                                    exact
                                    component={SubmittedEFormsContainer}
                                />
                                <Route path="/dashboard/eform/create" exact component={CreateEForm} />
                                <Route
                                    path="/dashboard/documents/upload"
                                    component={UploadDocumentCategoryTypeAndValue}
                                />
                                <Route path="/dashboard/documents" exact component={DocumentsContainer} />
                                <Route path="/dashboard" exact component={LandingPageComponent} />
                                <Route component={Error} />
                            </Switch>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        );
    }
}

export { DashboardBaseContainer };
