import React from 'react';
import { Button as AntButton } from 'antd';
import IButton from '../../interfaces/IButton';
import './Button.scss';

const Button = (props: IButton) => {
    const { text, type, className, fontWeight, onClick, size, disabled, loading, hidden } = props;
    return (
        <React.Fragment>
            {!hidden && (
                <AntButton
                  type={type}
                  className={className}
                  style={{ fontWeight }}
                  onClick={onClick}
                  size={size}
                  disabled={disabled}
                  loading={loading}
                >
                    {text}
                </AntButton>
            )}
        </React.Fragment>
    );
};

export default Button;
