import React from 'react'
import {ISearchProp} from "../../interfaces/ISearchComponent";
import searchIcon from '../../images/search.svg';
import crossIcon from '../../images/cross.svg';
import Input from '../Input/Input'
import './SearchComponent.scss'

export const SearchComponent = (props: ISearchProp) => {
    const { searchHandler, placeholder, value, searchClearHandler, keyDownHandler } = props;
    return (
        <div className="searchComponent">
            <div className="searchComponentWrapper">
            <img src={searchIcon} alt="search icon" />
            <Input placeholder={placeholder} value={value} onChange={searchHandler} onKeyPress={(e) => keyDownHandler(e,value)} />
            {value && <img src={crossIcon} className="crossIcon" alt="cross icon" onClick={searchClearHandler} />}
            </div>
        </div>
    )
}