import React, { Component } from 'react';
import { connect } from 'react-redux';
import { message, Layout, Row, Col, Divider, Typography, Alert } from 'antd';
import { SCButton, SCEmailInput, SCInput, SCLogo, SCPassword } from '@saleskey/frontend-shared';
import { ILoginProp, ILoginState } from '../../interfaces/ILogin';
import { loginAction } from '../../store/Authentication/Authentication.action';
import { IWindow } from '../../interfaces/IWindow';
import './Login.scss';

const customWindow: IWindow = window;

/**
 * LoginContainer
 * @remarks
 * Login page for the admin
 * @param props : type of `{ILoginProp}`
 * @param state : type of `{ILoginState}`
 */
class LoginBaseContainer extends Component<ILoginProp, ILoginState> {
    private fontWeight: number = 600;

    constructor(props: ILoginProp) {
        super(props);
        const { history } = this.props;
        let pathname
        if(history.location.state){
            const {from} = history.location.state as any
            pathname = from && from.pathname
        }   

        this.state = {
            disableSubmit: true,
            email: undefined,
            footerText: customWindow.config ? customWindow.config.footerText : null,
            isEmailValid: false,
            isPasswordValid: false,
            nextLocation: pathname,
            password: undefined,
        };
        this.handlePassword = this.handlePassword.bind(this);
        this.submitPassword = this.submitPassword.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
    }

    componentDidUpdate() {
        const { authenticated, history, loginLoading } = this.props;
        const { nextLocation } = this.state;
        if (authenticated && !loginLoading) {
            message.success('Login Successfully');
            if (nextLocation) {
                history.push(nextLocation);
            } else {
                history.push('/dashboard');
            }
        }
    }

    submitPassword() {
        const { email, password } = this.state;
        const { loginDispatcher } = this.props;
        if (email && password) {
            loginDispatcher(email, password);
        }
    }

    handlePassword(e: React.FormEvent<HTMLInputElement>) {
        const { value } = e.currentTarget;
        if (value.length > 3) {
            const { isEmailValid } = this.state;
            if (isEmailValid) {
                this.setState({ disableSubmit: false, isPasswordValid: true, password: value });
            } else {
                this.setState({ disableSubmit: true, isPasswordValid: true, password: value });
            }
        } else {
            this.setState({ disableSubmit: true, isPasswordValid: false, password: value });
        }
    }

    handleEmail = e => {
        const { value } = e.target;
        const { isPasswordValid } = this.state;
        
        if (value && value.length) {
            if (isPasswordValid) {
                this.setState({ disableSubmit: false, email: value, isEmailValid: true });
            } else {
                this.setState({ disableSubmit: true, email: value, isEmailValid: true });
            }
        } else {
            this.setState({ disableSubmit: true, email: value, isEmailValid: true })
        }
    };

    // handleEmail(value, isValid) {
    //     if (isValid) {
    //         const { isPasswordValid } = this.state;
    //         if (isPasswordValid) {
    //             this.setState({ disableSubmit: false, email: value, isEmailValid: true });
    //         } else {
    //             this.setState({ disableSubmit: true, email: value, isEmailValid: true });
    //         }
    //     } else {
    //         this.setState({ disableSubmit: false, email: value, isEmailValid: true });
    //     }
    // }

    public render() {
        const { Content } = Layout;
        const { disableSubmit, email, password, footerText } = this.state;
        const { loginLoading, authErrorMessage } = this.props;
        const { Title, Text } = Typography;
        return (
            <React.Fragment>
                <Layout className="Login">
                    <Content className="fullHeight">
                        <Row type="flex" justify="start" align="middle" className="fullHeight">
                            <Col xs={24} sm={24} md={12} lg={8}>
                                <Content className="login-left-content">
                                    <div className="login-wrapper">
                                        {!loginLoading && authErrorMessage && (
                                            <Alert message={authErrorMessage} type="error" showIcon banner closable />
                                        )}
                                        <SCLogo />
                                        <Title level={2} className="headerText">
                                            Log In
                                        </Title>

                                        <SCInput
                                            placeholder="Email or Username"
                                            value={email}
                                            onChange={this.handleEmail}
                                            size="large"
                                        />

                                        {/* <SCEmailInput
                                            value={email}
                                            placeholder="Email"
                                            emailHandler={this.handleEmail}
                                            size="large"
                                        /> */}
                                        <Divider className="noBorder" />
                                        <SCPassword
                                          value={password}
                                          placeholder="Password"
                                          onChange={this.handlePassword}
                                          size="large"
                                        />
                                        <Divider className="noBorder" />
                                        <SCButton
                                          text="Login"
                                          type="primary"
                                          className="primary loginButton"
                                          fontWeight={this.fontWeight}
                                          onClick={this.submitPassword}
                                          size="large"
                                          loading={loginLoading}
                                          disabled={loginLoading || disableSubmit}
                                        />
                                    </div>
                                    <div className="footerText">
                                        <Text type="secondary">{footerText}</Text>
                                    </div>
                                </Content>
                            </Col>
                            <Col md={12} lg={16}>
                                <Content className="login-right-content fullHeight">
                                    <div className="center-text-wrapper">
                                        {/* TODO: real text will be added later */}
                                        {/* <h2> Today&apos;s news</h2>
                                        <p>
                                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ea sint eum vitae
                                            nesciunt, neque consequuntur nostrum facere excepturi unde, minima autem
                                            laudantium, id eaque reiciendis voluptas dolorum aut tenetur enim!{' '}
                                        </p> */}
                                    </div>
                                </Content>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </React.Fragment>
        );
    }
}

const stateToProps = state => {
    return {
        authErrorMessage: state.auth.authErrorMessage,
        authenticated: state.auth.authenticated,
        loginLoading: state.auth.loginLoading,
    };
};

const dispatchers = dispatch => {
    return {
        loginDispatcher: (email, password) => dispatch(loginAction(email, password)),
    };
};

const LoginContainer = connect(stateToProps, dispatchers)(LoginBaseContainer);
export { LoginBaseContainer, LoginContainer };
