import React from 'react';
import './ListDocumentWrapper.scss';
import { Layout, Row, Col } from 'antd';
import { DocThumbnailCard } from '../DocThumbnailCard/DocThumbnailCard';
import { IListDocument } from '../../interfaces/IDocument';
import { getString } from '../../utils/localization';

const { Content } = Layout;
/**
 * @remarks
 * Act as wrapper for the document thumbnails, takes documents as input
 * @param props : type of `{ IListDocument } `
 * @return List Documents with Document Thumbnail card
 */
export const ListDocumentWrapper = (props: IListDocument) => {
    const { documents, downloadDocument, dmsToken } = props;
    const minDocLength = 0;

    // specific for this page, need to define variables because of codacy.
    const xsScreen = 24;
    const smScreen = 12;
    const mdScreen = 8;
    const lgScreen = 6;
    const xlScreen = 4;
    const downloadDocumentHandler = (documentId, source) => {
        downloadDocument(documentId, source);
    };
    /**
     * @return React Node for Document Thumbnail Card
     */
    return (
        <Layout>
            <Content className="listDocumentWrapper">
                <Row type="flex" justify="start">
                    {documents.length > minDocLength &&
                        documents.map(document => (
                            <Col
                              xs={xsScreen}
                              sm={smScreen}
                              md={mdScreen}
                              lg={lgScreen}
                              xl={xlScreen}
                              key={document.documentId}
                            >
                                <DocThumbnailCard {...document} downloadDocument={downloadDocumentHandler} dmsToken={dmsToken} />
                            </Col>
                        ))}
                    {!documents.length && (
                        <Col
                          xs={xsScreen}
                          sm={smScreen}
                          md={mdScreen}
                          lg={lgScreen}
                          xl={xlScreen}
                          className="noContentCol"
                        >
                            <span> {getString("No document found")} </span>
                        </Col>
                    )}
                </Row>
            </Content>
        </Layout>
    );
};
