export enum TimerTypes{
    FORWARD_TICKING,
    REVERSE_TICKING
}
export default interface Timer {
    sec: number;
    timerStarEvent?: () => void;
    timerStopEvent: () => void;
    type: TimerTypes
}
