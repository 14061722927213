import React from 'react';
import { connect } from 'react-redux';
import { ListEFormsInternal } from '../ListEFormsInternal/ListEFormsInternal';
import { EFormDrawer } from '../EFormDrawer/EFormDrawer';
import { showAddNewEFormButton } from '../../store/rootActions';
import { IListEFormProp, enumEFormModes } from '../../interfaces/IEForm';

interface IListEFormsState {}
class ListEFormsBaseContainer extends React.Component<IListEFormProp, IListEFormsState> {
    constructor(props: IListEFormProp) {
        super(props);
        this.viewSubmissionsHandler = this.viewSubmissionsHandler.bind(this);
    }

    /**
     *When component mounts
     *call the addNewEFormSidebarButton dispatcher
     *which will make the EFormDrawer visible
     */
    componentDidMount() {
        const { addNewEFormSidebarButton: addNewEFormSidebarButtonProp } = this.props;
        addNewEFormSidebarButtonProp(true);
    }

    /**
     *When component closes
     *call the addNewEFormSidebarButton dispatcher
     *which will make the EFormDrawer hide
     */
    componentWillUnmount() {
        const { addNewEFormSidebarButton: addNewEFormSidebarButtonProp } = this.props;
        addNewEFormSidebarButtonProp(false);
    }

    // navigate to the submissions page
    viewSubmissionsHandler = eformId => {
        const { history } = this.props;
        history.push(`/dashboard/eform/list/${eformId}/submissions`);
    };

    render() {
        return (
            <div className="listEFormsContainer">
                <ListEFormsInternal viewSubmissions={this.viewSubmissionsHandler} />
                <EFormDrawer title="New E-Form" mode={enumEFormModes.CREATE} />
            </div>
        );
    }
}

const dispatchers = dispatch => {
    return {
        addNewEFormSidebarButton: (isVisible: boolean) => dispatch(showAddNewEFormButton(isVisible)),
    };
};

const ListEFormsContainer = connect(
    null,
    dispatchers,
)(ListEFormsBaseContainer);
export { ListEFormsContainer, ListEFormsBaseContainer };
