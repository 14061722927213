import React from 'react';
import Messages from './Messages/Messages';
import Sender from './Sender/Sender';
import { IChat } from '../../interfaces/IChat';
import './Chat.scss';
import { ChatViewMode } from '../../interfaces/IConversation';

/**
 * Base component for Messages list and input wrapper
 * @param props : IChat
 */
const Chat = (props: IChat) => {
    const {
        messages,
        historyMessages,
        sendMessage,
        placeholder,
        onTextInputChange,
        attachmentCallback1,
        attachmentCallback2,
        agentLogin,
        typingStatus,
        linkShareHandler,
        viewMode,
        noInputView,
        fullChatHeight,
    } = props;
    return (
        <div
          className={`chat-holder ${viewMode === ChatViewMode.BOT ? 'chatBotView' : ''} ${
                viewMode === ChatViewMode.BOT && fullChatHeight ? 'fullChatHeight' : ''
            } ${noInputView ? 'noInputView' : ''}`}
        >
            <Messages data={noInputView ? historyMessages : messages} linkShareHandler={linkShareHandler} />
            {!noInputView && (
                <Sender
                  sendMessage={sendMessage}
                  placeholder={placeholder}
                  onTextInputChange={onTextInputChange}
                  attachmentCallback1={attachmentCallback1}
                  attachmentCallback2={attachmentCallback2}
                  agentLogin={agentLogin}
                  typingStatus={typingStatus}
                  viewMode={viewMode}
                />
            )}
        </div>
    );
};

export default Chat;
