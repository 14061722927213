import React from 'react';
import { Card, Icon } from 'antd';
import { ISubmittedEForm, IViewSubmission } from '../../interfaces/IEForm';
import './SubmittedEFormItem.scss';
import { ZERO } from '../../constants/numberConstants';
import { getString } from '../../utils/localization';
/**
 * @remarks
 * Document thumbnail component which can generate certain actions
 * @param props : type of `{ISubmittedEForm}`
 * @return Document thumbnail card dom
 */
const SubmittedEFormItem = (props: ISubmittedEForm & IViewSubmission) => {
    const { id, user, submittedFields, isComplete, viewSubmission, docsUploadedCount, docsRequiredCount, header } = props;
    const { name } = user;

    const viewSubmissionsHandler = (): void => {
        viewSubmission(id, `${header}:${user.name}`);
    };
    /**
     * @return HTML Dom
     */
    return (
        <Card className="submittedEFormItem">
            <div className="topRow">
                <div className="leftRow">
                    <h5>#{id}</h5>
                    <p className="customerName">
                        {getString("User Name")}: <span>{name}</span>
                    </p>
                    {isComplete && (
                        <Icon type="check-circle" theme="filled" viewBox="0 0 24 24" className="completedSubmission" />
                    )}
                    {!isComplete && <Icon type="check-circle" viewBox="0 0 24 24" className="notCompletedSubmission" />}
                </div>
                {/* <div className="rightRow">
                <img alt="example" src={thumbnailUrl} />
            </div> */}
            </div>
            <div className="bottomRow">
                <div className="submissionRow">
                    {getString("Filled")} : <span className="submissionView"> {submittedFields.length}</span>
                </div>
                <div
                  className="submissionRow viewSubmissions"
                  role="button"
                  tabIndex={ZERO}
                  onClick={viewSubmissionsHandler}
                  onKeyUp={viewSubmissionsHandler}
                >
                    {getString("View")}
                </div>
            </div>
            {docsRequiredCount && (
                <div className="bottomRow">
                    <div className="submissionRow documentRow">
                        {getString("Attachments")}:{' '}
                        <span className="submissionView">
                            {' '}
                            {docsUploadedCount} / {docsRequiredCount}
                        </span>
                    </div>
                </div>
            )}
        </Card>
    );
};

export { SubmittedEFormItem };
