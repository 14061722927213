import { initLocalization } from '@saleskey/frontend-shared';
import { axiosLocalizationInstance } from './axios';
import fallbackLiterals from '../constants/Literals';

const SCOPE = 'sk_fe';

function detectLanguage() {
    return (navigator.languages && navigator.languages[0]) || navigator.language;
}

export function init(): Promise<Object> {
    //selected language can be in local storage or part of url. localstorage is opted here 
    const locale = localStorage.getItem('locale') || detectLanguage() || 'en-US';
    return new Promise(async resolve => {
        try {
            const result = await axiosLocalizationInstance.get(`/translation/all/locale/${locale}/scope/${SCOPE}`, {});
            if (result.data.status === 'success') {
                initLocalization(result.data.translations);
                resolve(result.data.translations);
            } else {
                initLocalization(fallbackLiterals);
                resolve();
            }
        } catch (err) {
            initLocalization(fallbackLiterals);
            console.warn('Exception in fetching the content', err);
            resolve();
        }
    });
}
