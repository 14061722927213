/**
 * @class Header
 */
import React from 'react';
import './Header.scss';
import IHeader from '../../interfaces/IHeader';
import { getString } from '../../utils/localization';

const Header = (props: IHeader) => {
    const { text } = props;
    return (
        <div>
            <h1 className="Header"> {getString("Header")}: {text}</h1>
        </div>
    );
};

export default Header;
