import React from 'react';

import ILogo from '../../interfaces/ILogo';
import IWindow from '../../interfaces/IWindow';
import './Logo.scss';

const LogoText = require('./../../images/only-text.png');
const LogoFull = require('./../../images/gc_logo.png');

const customWindow: IWindow = window;
const gcLogoClass = 'gc-logo';

const Logo: React.FunctionComponent<ILogo> & { defaultProps: Partial<ILogo> } = props => {
    const { size, altText } = props;
    let { logoClass } = customWindow.config;
    if (!logoClass) {
        logoClass = gcLogoClass;
    }
    const returnLogoType = logoType => {
        if (logoClass !== gcLogoClass) {
            return <img alt={altText} className={`${size} ${logoClass}`} />;
        }
        if (logoType.onlyText) {
            return <img src={LogoText} alt={altText} className={size} />;
        }
        if (logoType.onlyLogo) {
            return <img alt={altText} className={`${size} ${logoClass}`} />;
        }
        if (logoType.fullLogo) {
            return <img alt={altText} className={`${size} ${logoClass}`} />;
        }
        return <img alt={altText} className={`${size} ${logoClass}`} />;
    };
    return returnLogoType(props);
};
Logo.defaultProps = {
    altText: 'Game Change Solutions',
    size: 'medium',
};
export default Logo;
