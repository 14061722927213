import React from 'react';
import { Radio, Button, Modal } from 'antd';
import documentshare from '../../images/documentshare.svg';
import userSvg from '../../images/user.svg';
import rightArrow from '../../images/rightArrow.svg';
import backButton from '../../images/back_button.svg';
import interactionCall from '../../images/interaction_call.svg';
import interactionMeeting from '../../images/interaction_meeting.svg';
import interactionMissed from '../../images/interaction_missed.svg';
import imageThumbnail from '../../images/Image_thumbnail.png';
import { SearchComponent } from '../SearchComponent/SearchComponent';
import './ConversationContent.scss';
import { __ContentTypes } from '../../constants/documentTabType';
import crossIcon from '../../images/cross.svg';
import { IConversationContent } from '../../interfaces/IConversationContent';
import { SharedContentItem } from '../SharedContentItem/SharedContentItem';
import { ESharedContentDirection } from '../../interfaces/ISharedContentItem';
import { getString } from '../../utils/localization';
import Loader from '../Loader/Loader';

export enum viewPanes {
    CONTACT_INFO = 'contact_info',
    OPPORTUNITY_INFO = 'opportunity_info',
    BROCHURE_INFO = 'brochure_info',
}

export class ConversationContent extends React.Component<IConversationContent, any> {
    constructor(props) {
        super(props);
        this.state = {
            isInteractionModal: false,
            isSetNotesModal: false,
            isSetStatusModal: false,
            searchKey: '',
            tabSelected: 'documents',
            viewPane: viewPanes.CONTACT_INFO,
        };
        this.handleModeChange = this.handleModeChange.bind(this);
        this.searchClearHandler = this.searchClearHandler.bind(this);
        this.searchHandler = this.searchHandler.bind(this);
        this.hideInteractionModal = this.hideInteractionModal.bind(this);
        this.hideSetStatusModal = this.hideSetStatusModal.bind(this);
        this.hideSetNotesModal = this.hideSetNotesModal.bind(this);

        this.setInteraction = this.setInteraction.bind(this);
        this.setStatus = this.setStatus.bind(this);
        this.setNotes = this.setNotes.bind(this);

        this.changeViewPane = this.changeViewPane.bind(this);
    }

    public handleModeChange = e => {
        const { contentTypeChangeHandler } = this.props;
        const { value } = e.target;
        contentTypeChangeHandler(value);
        this.setState({ tabSelected: value });
    };

    public searchHandler = e => {
        const { value } = e.target;
        this.setState({ searchKey: value });
    };

    public searchClearHandler = () => {
        this.setState({ searchKey: '' });
        const { tabSelected } = this.state;
        const { searchValueHandler } = this.props;
        searchValueHandler('', tabSelected);
    };

    public keyDownHandler = (e, value) => {
        if (e.key === 'Enter') {
            this.searchContent(value);
        }
    };

    public searchContent = (value: string) => {
        const { searchValueHandler } = this.props;
        const { tabSelected } = this.state;
        if ((value && value.length > 3) || !value) {
            searchValueHandler(value, tabSelected);
        }
    };

    public changeViewPane = (vPane: viewPanes) => {
        this.setState({ viewPane: vPane });
    };

    public hideInteractionModal = () => {
        this.setState({ isInteractionModal: false, isSetNotesModal: false, isSetStatusModal: false });
    };

    public hideSetStatusModal = () => {
        this.setState({ isInteractionModal: false, isSetNotesModal: false, isSetStatusModal: false });
    };

    public hideSetNotesModal = () => {
        this.setState({ isInteractionModal: false, isSetNotesModal: false, isSetStatusModal: false });
    };

    public showInteractionModal = () => {
        this.setState({ isInteractionModal: true, isSetNotesModal: false, isSetStatusModal: false });
    };

    public showSetStatusModal = () => {
        this.setState({ isInteractionModal: false, isSetNotesModal: false, isSetStatusModal: true });
    };

    public showSetNotesModal = () => {
        this.setState({ isInteractionModal: false, isSetNotesModal: true, isSetStatusModal: false });
    };

    /**
     * interaction handler method
     */

    public setInteraction = () => {
        const { setInteractionHandler } = this.props;
    };

    /**
     * lead status handler method
     */
    public setStatus = () => {
        const { setStatusHandler } = this.props;
    };

    /**
     * add notes handler method
     */
    public setNotes = () => {
        const { setNotesHandler } = this.props;
    };

    render() {
        const { tabSelected, searchKey, isInteractionModal, isSetStatusModal, isSetNotesModal, viewPane } = this.state;
        const { show, closeDrawerHandler, linkShareHandler, sharedContentList, fetchingContent } = this.props;
        return (
            <div className={`chatDocumentSharedContainer ${!show ? 'hideMe' : ''}`}>
                {/* <div className="chatDocumentSharedHeader">
                    <div className="documentSpan">
                        <img src={documentshare} alt="document" />
                        <span>{getString("Documents")} & {getString("Links")}</span>
                        <img
                          src={crossIcon}
                          className="crossIcon"
                          alt="search icon"
                          onClick={closeDrawerHandler}
                          onKeyDown={closeDrawerHandler}
                        />
                    </div>
                </div>
                <div className="chatDocumentSharedListContainer">
                    <SearchComponent
                      value={searchKey}
                      placeholder="Search here"
                      searchHandler={this.searchHandler}
                      searchClearHandler={this.searchClearHandler}
                      keyDownHandler={this.keyDownHandler}
                    />
                    <div className="chatSearchComponentTabs">
                        <Radio.Group onChange={this.handleModeChange} value={tabSelected} style={{ marginBottom: 8 }}>
                            <Radio.Button value={__ContentTypes.DOCUMENT}>{getString("Documents")}</Radio.Button>
                            <Radio.Button value={__ContentTypes.MEDIA}>{getString("Media")}</Radio.Button>
                            <Radio.Button value={__ContentTypes.EFORM}>{getString("Links")}</Radio.Button>
                        </Radio.Group>
                        <div className="chatDocumentList">
                            {!fetchingContent && sharedContentList.length > 0 && (
                                <div className="chatContentWrapper">
                                    {tabSelected === __ContentTypes.DOCUMENT &&
                                        sharedContentList.map(item => (
                                          <SharedContentItem key={item.contentId} {...item} direction={ESharedContentDirection.LeftDocumentView} addBottomBorder={false} />
                                        ))}
                                    {tabSelected === __ContentTypes.MEDIA && <div>{getString("Media")}</div>}
                                    {tabSelected === __ContentTypes.EFORM &&
                                        sharedContentList.map(item => (
                                            <SharedContentItem key={item.contentId} {...item} linkShareHandler={linkShareHandler} direction={ESharedContentDirection.RightDocumentView} addBottomBorder={false} />
                                        ))}
                                </div>
                            )}
                            {!fetchingContent && !sharedContentList.length && (
                                <div className="noContentText">{getString("No")} {tabSelected} {getString("found")}.</div>
                            )}
                            {fetchingContent && (
                                <div className="loadingContent">
                                    <Loader fontSize={24} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>  */}
                {viewPane === viewPanes.CONTACT_INFO && (
                    <div className="sectionRHS leadSectionRHS">
                        <div className="sectionHeader ">
                            <div className="leftSection">
                                <img src={userSvg} alt="" />
                                <span>Lead Information</span>
                            </div>
                            <div className="rightSection">
                                <img src={crossIcon} alt="" />
                            </div>
                        </div>
                        <div className="sectionContent">
                            <div className="topContainer">
                                <div className="profileAvatar">
                                    <img src={userSvg} alt="" />
                                </div>
                                <div className="profileBasicInfo">
                                    <div className="level">Agent Vinod</div>
                                    <div className="level">+91-9876543210</div>
                                    <div className="level">example@email.com</div>
                                </div>
                            </div>
                            <div className="bottomSectionContainer">
                                <div className="navigationLevel">
                                    <div className="textContent">
                                        <div className="boldHeader">Opportunity</div>
                                    </div>
                                    <div className="numberContent">
                                        <div className="numberCount">2</div>
                                        <div
                                          className="navigationIcon"
                                          onClick={() => this.changeViewPane(viewPanes.OPPORTUNITY_INFO)}
                                        >
                                            <img src={rightArrow} />
                                        </div>
                                    </div>
                                </div>
                                <div className="navigationLevel">
                                    <div className="textContent">
                                        <div className="boldHeader">Service Request</div>
                                    </div>
                                    <div className="numberContent">
                                        <div className="numberCount">3</div>
                                        <div className="navigationIcon">
                                            <img src={rightArrow} />
                                        </div>
                                    </div>
                                </div>
                                <div className="navigationLevel">
                                    <div className="textContent">
                                        <div className="boldHeader">WIP Forms</div>
                                    </div>
                                    <div className="numberContent">
                                        <div className="numberCount">1</div>
                                        <div className="navigationIcon" onClick={()=>this.changeViewPane(viewPanes.BROCHURE_INFO)}>
                                            <img src={rightArrow} />
                                        </div>
                                    </div>
                                </div>
                                <div className="navigationLevel">
                                    <div className="textContent">
                                        <div className="boldHeader">Brochure Shared</div>
                                    </div>
                                    <div className="numberContent">
                                        <div className="numberCount">2</div>
                                        <div className="navigationIcon" onClick={()=>this.changeViewPane(viewPanes.BROCHURE_INFO)}>
                                            <img src={rightArrow} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {viewPane === viewPanes.OPPORTUNITY_INFO && (
                    <div className="sectionRHS opportunitySectionRHS">
                        <div className="sectionHeader ">
                            <div className="leftSection">
                                <img
                                  src={backButton}
                                  alt=""
                                  onClick={() => this.changeViewPane(viewPanes.CONTACT_INFO)}
                                />
                                <span>Opportunity</span>
                            </div>
                            <div className="rightSection">
                                <img src={crossIcon} alt="" />
                            </div>
                        </div>
                        <div className="sectionContent">
                            <div className="topContainer">
                                <div className="productPills">
                                    <div className="product active">Product 1</div>
                                    <div className="product">Product 2</div>
                                </div>
                            </div>
                            <div className="leadListInfoWrapper">
                                <div className="bottomSectionContainer">
                                    <div className="navigationLevel">
                                        <div className="textContent">
                                            <div className="mainHeader">Product</div>
                                            <div className="subHeader">
                                                <strong>Short Term Loan</strong>
                                            </div>
                                        </div>
                                        <div className="numberContent">
                                            <div className="navigationIcon" />
                                        </div>
                                    </div>
                                    <div className="navigationLevel">
                                        <div className="textContent">
                                            <div className="mainHeader">Schedule Interaction</div>
                                            <div className="subHeader">
                                                <strong>6-Aug-20, 9:30PM</strong>{' '}
                                                <span className="dotContent">
                                                    <img src={interactionMeeting} alt="" />
                                                </span>{' '}
                                                <span className="status">Meeting</span>
                                            </div>
                                        </div>
                                        <div className="buttonContent">
                                            <Button> Change </Button>
                                        </div>
                                    </div>
                                    <div className="navigationLevel">
                                        <div className="textContent">
                                            <div className="mainHeader">Last Interaction</div>
                                            <div className="subHeader">
                                                <strong>6-Aug-20, 9:30PM</strong>{' '}
                                                <span className="dotContent">
                                                    <img src={interactionCall} />
                                                </span>{' '}
                                                <span className="status">Missed</span>{' '}
                                                <span className="statusIcon">
                                                    <img src={interactionMissed} alt="" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="navigationLevel">
                                        <div className="textContent">
                                            <div className="mainHeader">Lead Status</div>
                                            <div className="subHeader">
                                                <strong>Interested</strong>
                                            </div>
                                        </div>
                                        <div className="buttonContent">
                                            <Button> Update </Button>
                                        </div>
                                    </div>
                                    <div className="navigationLevel">
                                        <div className="textContent">
                                            <div className="mainHeader">Lead Notes</div>
                                            <div className="subHeader">Last call was productive</div>
                                        </div>
                                        <div className="buttonContent">
                                            <Button> Add Note </Button>
                                        </div>
                                    </div>
                                    <div className="navigationLevel">
                                        <div className="textContent">
                                            <div className="mainHeader">Handler</div>
                                            <div className="subHeader">
                                                <strong>Akhmad</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {viewPane === viewPanes.BROCHURE_INFO && (
                    <div className="sectionRHS wipSectionRHS">
                         <div className="sectionHeader ">
                            <div className="leftSection">
                            <img
                              src={backButton}
                              alt=""
                              onClick={() => this.changeViewPane(viewPanes.CONTACT_INFO)}
                            />
                                <span>WIP Forms/Applications</span>
                            </div>
                            <div className="rightSection">
                                <img src={crossIcon} alt="" />
                            </div>
                         </div>
                        <div className="sectionContent">
                        <div className="topContainer">
                                <div className="productPills">
                                    <div className="product active">Opportunity</div>
                                    <div className="product">S.R</div>
                                </div>
                        </div>
                            <div className="opportunityInfo">
                                <div className="contentThumbnailContainer">
                                    <div className="contentInfo">
                                        <span>Short Term Loan</span>
                                    </div>
                                    <div className="contentThumbnail">
                                        <img src={imageThumbnail} alt="" />
                                    </div>
                                </div>
                                <div className="bottomSectionContainer">
                                    <div className="navigationLevel">
                                    <div className="textContent">
                                            <div className="mainHeader">Product</div>
                                            <div className="subHeader">
                                                <strong>Short Term Loan</strong>
                                            </div>
                                    </div>
                                    </div>
                                    <div className="navigationLevel">
                                        <div className="textContent">
                                            <div className="mainHeader">Shared On</div>
                                            <div className="subHeader">
                                            <strong>27-Oct-20, 2:30PM</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="navigationLevel">
                                        <div className="textContent">
                                            <div className="mainHeader">Opened On</div>
                                            <div className="subHeader">
                                            <strong>27-Oct-20, 2:31PM</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="navigationLevel">
                                        <Button className="shareButton">Share</Button>
                                        <Button className="coBrowsebutton">Co-Browse</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="setInteractionModal">
                    <Modal
                      visible={isInteractionModal}
                      title=""
                      onOk={() => this.setInteraction()}
                      onCancel={this.hideInteractionModal}
                    >
                        <div className="interactionContaier" />
                    </Modal>
                </div>
                <div className="setStatusModal">
                    <Modal
                      visible={isSetStatusModal}
                      title=""
                      onOk={() => this.setStatus()}
                      onCancel={this.hideSetStatusModal}
                    >
                        <div className="setStatusContaier" />
                    </Modal>
                </div>
                <div className="setNotesModal">
                    <Modal
                      visible={isSetNotesModal}
                      title=""
                      onOk={() => this.setNotes()}
                      onCancel={this.hideSetNotesModal}
                    >
                        <div className="setNotesContaier" />
                    </Modal>
                </div>
            </div>
        );
    }
}
