import React from 'react';
import { Divider, Modal, Button, Typography } from 'antd';
const { Text } = Typography;
import { getString } from '../../utils/localization';
import OtpInput from "../OtpInput/otpInput";

export default class CashOnDeliveryModal extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            otpValue: '',
            disableOtpNext: true
        }
    }

    handleOtp = (otp: number) => {
        const otpLength = 6;
        this.setState({ otpValue: otp });
        if (otp && otp.toString().length === otpLength) {
            this.setState({ disableOtpNext: false });
        } else {
            this.setState({ disableOtpNext: true });
        }
    };

    public render(): React.ReactNode {
        return (
            <React.Fragment>
                <Modal
                  title="Verify Contact"
                  visible={true}
                  className="cash-on-delivery"
                  centered
                  onCancel={this.props.onCancel}
                  footer={[
                        <Button key="back" onClick={this.props.onCancel}>{getString("Cancel")}</Button>,
                        <Button
                          key="submit"
                          type="primary"
                          disabled= {this.state.disableOtpNext}
                          onClick={() => {
                            this.props.verifyContactNumberAction(this.props.code, this.state.otpValue, this.props.phoneNumber);
                          }}
                          loading={this.props.submitLoading}
                        >Place Order
                        </Button>
                    ]}
                >
            <>
            <div className="otp-product">
            <div
                        className='section-header section-product'
                    >
                        <Text strong>Verification Code</Text>
                        <Text className="subText">A text message with 6-digit verification code was just sent to **********{this.props.phoneNumber.slice(this.props.phoneNumber.length -2)}</Text>
                    </div>
                <OtpInput count={6} callbackOtpEvent={this.handleOtp} errorMessage="" className="otp-product"/>
            </div>
            </>
                </Modal>
            </React.Fragment>
        )
    }
}
