import moment from 'moment';

export function formatAMPM(dateString: string) {
    if (!dateString) {
        return '';
    }

    const date: Date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const localDateString = date.toLocaleDateString();
    const todayDateString = new Date().toLocaleDateString();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    if(hours === 0){
        hours = 12
    }
    const hoursString = hours.toString(); // the hour '0' should be '12'
    const minuteString = minutes < 10 ? `0${minutes.toString()}` : minutes.toString();
    let strTime = `${hoursString}:${minuteString} ${ampm}`;

    if(localDateString !== todayDateString){
        const ddmm = moment(date).format('MMM D')
        strTime = `${ddmm} ${strTime}`
    }

    return strTime;
}
