import * as React from 'react';
import { Avatar, Modal, Button } from 'antd';
import mime from 'mime';
import { getFileType } from '../../utils/fileConstants';
import './ThumbnailByType.scss';
import { ONE } from '../../constants/numberConstants';
import PDFViewer from '../../containers/PDFViewer/PDFViewerContainer';
import { ISingleDocumentSourceType } from '../../interfaces/IDocumentViewer';

export interface IThumbnailByTypeProp {
    fileType: string | undefined;
    fileExtension?: string | undefined;
    fileContent?: string | undefined;
    fileUrl?: string | undefined;
    mimeType?: string;
    className?: string;
    isUrlInvalid?: boolean;
    showPreviewButton?: boolean;
    isReviewable?: boolean;
}

/**
 * @remarks
 * shows the thumbnail for excel, pdf, image type doc only as per the current scope
 * @param props type of `IThumbnailByTypeProp`
 */
export class ThumbnailByType extends React.Component<IThumbnailByTypeProp, any> {
    constructor(props) {
        super(props);
        this.state = {
            showPreviewModal: false,
        };
        this.returnThumbnail = this.returnThumbnail.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.showModal = this.showModal.bind(this);
    }

    closeModal() {
        this.setState({ showPreviewModal: false });
    }

    showModal() {
        this.setState({ showPreviewModal: true });
    }

    returnThumbnail() {
        const { fileExtension, fileUrl, mimeType, isUrlInvalid, showPreviewButton, isReviewable } = this.props;
        const { showPreviewModal } = this.state;
        let fileExtensionResult;
        if (mimeType) {
            fileExtensionResult = mime.getExtension(mimeType);
            if (!fileExtensionResult) {
                const tempTypes = mimeType.split('.');
                fileExtensionResult = tempTypes[tempTypes.length - ONE];
            }
        } else {
            fileExtensionResult = fileExtension;
        }
        const fileTypeResult = getFileType(fileExtensionResult);
        switch (fileTypeResult) {
            case 'image':
                return (
                    <React.Fragment>
                        {isUrlInvalid && (
                            <div className="thumbnailOnlyContainer">
                                <Avatar
                                    size={64}
                                    shape="square"
                                    className="default-avatar image-avatar"
                                    icon="file-image"
                                />
                            </div>
                        )}
                        {!isUrlInvalid && (
                            <div className="thumbnailOnlyContainer">
                                <img src={fileUrl} alt="thumbnail" />
                                {showPreviewButton && (
                                    <Button
                                        role="button"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        className={`preview ${isReviewable ? 'isReviewable' : ''}`}
                                        onClick={this.showModal}
                                    >
                                        Preview
                                    </Button>
                                )}
                            </div>
                        )}

                        {!isUrlInvalid && (
                            <Modal
                                visible={showPreviewModal}
                                title=""
                                onCancel={this.closeModal}
                                className="previewModal"
                                footer={[
                                    <Button key="back" onClick={this.closeModal}>
                                        Close
                                    </Button>,
                                ]}
                            >
                                <img src={fileUrl} alt="thumbnail" className="previewImage" />
                            </Modal>
                        )}
                    </React.Fragment>
                );
            case 'excel':
                return (
                    <React.Fragment>
                        {isUrlInvalid && (
                            <div className="thumbnailOnlyContainer">
                                <Avatar
                                    size={64}
                                    shape="square"
                                    className="default-avatar excel-avatar"
                                    icon="file-excel"
                                />
                            </div>
                        )}
                        {!isUrlInvalid && (
                            <div className="thumbnailOnlyContainer">
                                <img src={fileUrl} alt="thumbnail" />
                            </div>
                        )}
                    </React.Fragment>
                );
            case 'pdf':
                return (
                    <React.Fragment>
                        <div className="thumbnailOnlyContainer">
                            <Avatar size={64} shape="square" icon="file-pdf" className="default-avatar pdf-avatar" />
                            {showPreviewButton && (
                                <Button
                                    role="button"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className={`preview ${isReviewable ? 'isReviewable' : ''}`}
                                    onClick={this.showModal}
                                >
                                    Preview
                                </Button>
                            )}
                        </div>

                        {/* commented the condition as fileUrl is invalid from backend. Needs to be fixed at backend. */}
                        {/* {isUrlInvalid && (
                            <div className="thumbnailOnlyContainer">
                                <Avatar size={64} shape="square" icon="file-pdf" className="default-avatar pdf-avatar" />
                            </div>
                        )}
                        {!isUrlInvalid && (
                            <div className="thumbnailOnlyContainer">
                                <img src={fileUrl} alt="thumbnail" />
                            </div>
                        )} */}

                        <Modal
                            visible={showPreviewModal}
                            title=""
                            className="previewModal"
                            onCancel={this.closeModal}
                            footer={[
                                <Button key="back" onClick={this.closeModal}>
                                    Close
                                </Button>,
                            ]}
                        >
                            <PDFViewer
                                document={{
                                    content: {
                                        link: '',
                                        source: 'managed' as ISingleDocumentSourceType,
                                    },
                                    contentUrl: fileUrl,
                                    documentId: '',
                                    expiryDate: '',
                                    fileType: '',
                                    isActive: true,
                                    name: '',
                                    source: '',
                                    status: '',
                                    unifiedDocId: 1,
                                    userId: '',
                                    userType: '',
                                    visibility: '',
                                }}
                                documentCloseEvent={() => {}}
                                documentOpenEvent={() => {}}
                                pageTurnEvent={() => {}}
                                intervalInSeconds={0}
                                invokeDocumentCloseApi
                            />
                        </Modal>
                    </React.Fragment>
                );
            default:
                return (
                    <div className="thumbnailOnlyContainer">
                        <Avatar size="large" className="file-avatar" icon="file" />
                    </div>
                );
        }
    }

    render() {
        return this.returnThumbnail();
    }
}
