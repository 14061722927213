const uploadFormatHashMaps = {
    admingroups: {
        displayName: 'Display Name',
        isActive: 'Active',
        name: 'Name',
    },
    adminusers: {
        email: 'Email',
        group: 'Group',
        isActive: 'Active',
        name: 'Name',
        password: process.env.REACT_APP_FORMAT_PASSWORD,
    },
    documentcategorytype: {
        color: 'Color',
        name: 'Category Name',
    },
    documentcategoryvalue: {
        categoryName: 'Category Name',
        name: 'Category Value',
    },
    internalusers: {
        alias: 'Alias',
        email: 'Email',
        isActive: 'Active',
        name: 'Name',
    },
    thirdparty: {
        email: 'Email',
        isActive: 'Active',
        name: 'Name',
        phoneNumber: 'Phone Number',
        reportingTo: 'Reporting To',
    },
    thirdpartyorg: {
        code: 'Code',
        emailDomains: 'Email Domains',
        isActive: 'Active',
        name: 'Name',
        website: 'Website',
    },
};

export { uploadFormatHashMaps };
