import XLSX from 'xlsx';

interface IWorkBook {
    status: boolean;
    excelJson: object;
}
/**
 * @remarks
 * utility function which reads blob and converts it to json
 * ex: excel to json parser
 * @param blob : File | Blob
 * @return json
 */
const readExcelJson = (blob: File) => {
    return new Promise<IWorkBook>(resolve => {
        const reader = new FileReader();

        const rABS = !!reader.readAsBinaryString;

        reader.onload = () => {
            const response = {
                excelJson: {},
                status: false,
            };
            /* Parse data */
            const bstr = reader.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });

            /* Get first worksheet */
            wb.SheetNames.forEach(sheet => {
                response.status = true;
                const ws = wb.Sheets[sheet];

                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
                // excelJson[sheet] = data;
                response.excelJson[sheet] = data;
            });
            resolve(response);
        };

        if (rABS) reader.readAsBinaryString(blob);
        else reader.readAsArrayBuffer(blob);
    });
};

// export { readExcelJson };
const excelReader = {
    readExcelJson,
};
export default excelReader;
