import React from 'react';
import { Modal, Button } from 'antd';

import './RejectionReasonModal.scss';
import { ZERO } from '../../constants';
import { getString } from '../../utils/localization';
import { IRejectionReasonProps, IRejectionReasonState } from '../../interfaces/IRejectionReason';


export class RejectionReasonModal extends React.Component<IRejectionReasonProps, IRejectionReasonState> {

    constructor(props) {
        super(props);
        this.state = {
            reason: '',
        };
        this.handleOk = this.handleOk.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    public handleOk() {
        const { rejectionReasonSaveHandler, rejectionReasonCloseHandler } = this.props;
        const { reason:_reason } = this.state;
        const reason = _reason.replace(/\s+/g,' ').trim();
        if (rejectionReasonSaveHandler && reason.length !== ZERO) {
            rejectionReasonSaveHandler(reason);
            this.setState({ reason: '' })
        }

        if(rejectionReasonCloseHandler){
            rejectionReasonCloseHandler()
        }
        
    }

    public keyDownHandler = (e) => {
        if (e.key === 'Enter') {
            this.handleOk();
        }
    };

    public onCancel() {
        this.setState({reason: ''});
        const { rejectionReasonCloseHandler } = this.props;
        rejectionReasonCloseHandler();
    }

    public render(): React.ReactNode {
        const { showModel } = this.props;

        const { reason } = this.state;

        return (
            <React.Fragment>
                <Modal
                  title="Rejection Reason"
                  visible={showModel}
                  className="rejection-reason-modal-container"
                  centered
                  onCancel={this.onCancel}
                  footer={[
                        <Button key="back" onClick={this.onCancel}>{getString("Cancel")}</Button>,
                        <Button
                          key="submit"
                          type="primary"
                          disabled={reason.length === 0}
                          onClick={this.handleOk}
                        >Save
                        </Button>
                    ]}
                >
                    <textarea
                      className="notes-input"
                      placeholder="Type reason here"
                      onChange={(e) => {
                            this.setState({ reason: e.target.value });
                        }}
                      value={reason}
                      onKeyPress={this.keyDownHandler}
                      maxLength={255}
                    />
                </Modal>
            </React.Fragment>
        )
    }
}
