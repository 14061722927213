import React from 'react';
import { Input as AntInput } from 'antd';

import './PasswordInput.scss';

import IInput from '../../interfaces/IInput';

const PasswordInput: React.FunctionComponent<IInput> & { defaultProps: Partial<IInput> } = (props: IInput) => {
    const { width, disabled, onChange, value, placeholder, size, label, error, hidden } = props;
    return (
        <React.Fragment>
            {!hidden && (
                <div className="inputComponent">
                    <span>{label}</span>
                    <AntInput.Password
                        className="passwordInput"
                        style={{ width }}
                        onChange={onChange}
                        disabled={disabled}
                        placeholder={placeholder}
                        value={value}
                        size={size}
                    />
                    <div className="errorText">{error}</div>
                </div>
            )}
        </React.Fragment>
    );
};
PasswordInput.defaultProps = {
    disabled: false,
    width: '100%',
};
export default PasswordInput;
