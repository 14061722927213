import React from 'react';
import { Divider, Badge, Popover } from 'antd';

import MessageTime from '../Chat/TextMessage/MessageTime/MessageTime';
import SentIcon from '../../images/comment-send.svg'
import './ReviewComments.scss';
import reviewCommentIcon from '../../images/review-comment.svg';
import { ZERO } from '../../constants';
import { IReviewCommentsProps, IReviewCommentsState } from '../../interfaces/IReveiwComments';
import { getString } from '../../utils/localization';

export class ReviewComments extends React.Component<IReviewCommentsProps, IReviewCommentsState> {
    inputComment = React.createRef<HTMLTextAreaElement>();

    constructor(props) {
        super(props);
        this.state = {
            comment: '',
            resolvedComments: false,
            seeAll: false,
            showCommentBox: false,
            showPopOver: false
        };
        this.saveComment = this.saveComment.bind(this);
        this.seeAllComments = this.seeAllComments.bind(this);
        this.resolveComments = this.resolveComments.bind(this);
        this.loadComments = this.loadComments.bind(this);
        this.onVisibleChange = this.onVisibleChange.bind(this);
        this.keyDownHandler = this.keyDownHandler.bind(this);
        this.getInputStyle = this.getInputStyle.bind(this)
    }

    public componentDidUpdate() {
        if (this.inputComment && this.inputComment.current) {
            this.inputComment.current.focus();
        }
    }

    public onVisibleChange(visible: boolean) {
        if (!visible) {
            this.setState({
                comment: '',
                resolvedComments: false,
                seeAll: false,
                showCommentBox: false,
                showPopOver: false
            })
        }
    }

    public loadComments() {
        const { fetchReviewCommentsHandler, fieldName, linkCode, eformVersionId } = this.props;
        if (fetchReviewCommentsHandler) {
            fetchReviewCommentsHandler(fieldName, linkCode, eformVersionId)
        }
        this.setState({ showPopOver: true })
    }

    public resolveComments() {
        const { resolveCommentsHandler, linkCode, eformVersionId, fieldName } = this.props;
        resolveCommentsHandler(fieldName, linkCode, eformVersionId)
        this.setState({ resolvedComments: true })
    }

    public saveComment() {
        const { saveCommentsHandler, linkCode, eformVersionId, fieldName } = this.props;
        const { comment:_comment } = this.state;
        const comment = _comment.replace(/\s+/g,' ').trim();
        if (comment.length !== 0) {
            saveCommentsHandler(fieldName, linkCode, eformVersionId, comment)
            this.setState({ comment: '' })
        }
    }

    public keyDownHandler = (e) => {
        if (e.key === 'Enter') {
            this.saveComment();
        }
    };

    public seeAllComments() {
        this.setState({ seeAll: true });
    }

    public simulateClick(e) {
        if (e) {
            e.click()
        }
    }

    public getInputStyle (
        value,
        lineHight = 20
      ){
        const lines = (value.length * 14) / 520;
        return {
          height: lineHight * Math.min(3, Math.ceil(lines))
        };
      };

    public render(): React.ReactNode {
        let { reviewComments: threads } = this.props;
        const { threadIds } = this.props;
        if (!threads) {
            threads = []
        }
        const { showCommentBox, seeAll, showPopOver, resolvedComments, comment } = this.state;
        let content: React.ReactNode | undefined;
        let activeThreadsCount = (threadIds && threadIds.length) || 0;
        if (showPopOver) {
            const allComments: any = [];
            threads.forEach(t => {
                t.comments.forEach(c => {
                    allComments.push({
                        ...c,
                        resolved: t.resolved
                    })
                })
            });
            const activeThread = threads.find(t => !t.resolved);
            activeThreadsCount = activeThread ? 1 : 0;
            const showCommentBoxProp = showCommentBox || allComments.length === 0;
            let comments = allComments;
            if (!seeAll) {
                if (activeThread) {
                    const oldComments = activeThread.comments.filter(c => c.id);
                    const liveComments = activeThread.comments.filter(c => !c.id)
                    comments = oldComments.slice(oldComments.length - 2);
                    if(liveComments.length){
                        comments = [...comments, ...liveComments]
                    }
                } else if (allComments.length > 2) {
                    comments = allComments.slice(allComments.length - 2);
                } else {
                    comments = allComments;
                }
            }
            comments = comments.sort((a,b)=> a.createdAt - b.createdAt)
            content = (
                <div className="review-comments">
                    {comments.length !== ZERO && (
                        <div className="top-action-btns">
                            <div className="actions">
                                {!resolvedComments && activeThread && <span onClick={this.resolveComments} className="resolve">Resolve</span>}
                                {!seeAll && allComments.length > 2 && (
                                    <span onClick={this.seeAllComments} className="see-all">{getString("See All")}</span>
                                )}
                            </div>
                        </div>
                    )}
                    <div className={comments.length !== ZERO ? "comments" : ""}>
                        {comments.length !== ZERO && comments.map((c) => {
                            return (
                                <div className={`${(c.resolved || resolvedComments) ? 'resolved' : ''}`}>
                                    <div
                                      data-letters={((c.userName || "").match(/\b(\w)/g) || ['']).join("")}
                                      className="user-details"
                                    >
                                        <span className="user-name">{c.userName}</span>
                                        <MessageTime timestamp={c.createdAt} moveToNewLine={false} />
                                    </div>
                                    <div className="comment">{c.comment}</div>
                                    <Divider />
                                </div>
                            );
                        })}
                    </div>
                    {!resolvedComments && showCommentBoxProp && (
                        <div className="comment-box">
                            {/* <input type="text" 
                            placeholder="Type comment here"
                            ref={this.inputComment} 
                            className="input-comment" 
                            value={comment} 
                            onChange={(e) => { this.setState({ comment: e.target.value }) }} 
                            onKeyPress={this.keyDownHandler}/> */}

                            <textarea
                              style={this.getInputStyle(comment)}
                              ref={this.inputComment}
                              className="input-comment"
                              placeholder="Type comment here"
                              value={comment}
                              onChange={(e) => { this.setState({ comment: e.target.value }) }} 
                              onKeyPress={this.keyDownHandler}
                              maxLength={255}
                            />
                            <img
                              src={SentIcon}
                              onClick={this.saveComment}
                              className="send-icon"
                              alt="send"
                              role="button"
                            />
                        </div>
                    )}
                    {!resolvedComments && !showCommentBoxProp && (
                        <div
                          className="reply-text"
                          onClick={() => this.setState({ showCommentBox: true })}
                        >
                            <span>{activeThread ? 'Reply' : 'Add Comment'}</span>
                        </div>
                    )}
                </div>
            )
        }

        return (
            <React.Fragment>
                {!showPopOver && (
                    <Badge showZero={false} count={activeThreadsCount} dot>
                        <img className="review-icon" src={reviewCommentIcon} onClick={this.loadComments} role="article" alt="review" />
                    </Badge>
                )}

                {showPopOver && (
                    <Popover placement="bottom" content={content} trigger="click" destroyTooltipOnHide onVisibleChange={this.onVisibleChange}>
                        <Badge showZero={false} count={activeThreadsCount} dot>
                            <img className="review-icon" src={reviewCommentIcon} ref={this.simulateClick} alt="review" />
                        </Badge>
                    </Popover>
                )}
            </React.Fragment>
        )
    }
}