import React from 'react';
import { Layout } from 'antd';
import IFooter from '../../interfaces/IFooter';

const Footer = (props: IFooter) => {
    const { text, textAlign, position, height, width } = props;
    const { Footer: AntFooter } = Layout;
    return (
        <div className="Footer">
            <AntFooter style={{ height, position, textAlign, width }}>{text}</AntFooter>
        </div>
    );
};
export default Footer;
