import React from 'react';
import { Layout, Row, Col, message, Alert } from 'antd';
import { connect } from 'react-redux';
import { getString } from '@saleskey/frontend-shared';
import { UploadCard } from '../../components/UploadCard/UploadCard';
import { AclPermission } from '../../components/AclPermission/AclPermission';
import * as uploadTypes from '../../utils/uploadTypeUrl';
import './InternalUsers.scss';
import { IUploadApiActionProp, UploadCommonProps } from '../../interfaces/IUploadApiAction';
import { uploadApiAction, uploadApiResetAction } from '../../store/rootActions';
import { ZERO, TWENTY_FOUR, EIGHT, SIX } from '../../constants/Common';

const { Content } = Layout;
const InternalUsersBase = (props: UploadCommonProps) => {
    const { uploading, uploadErrorMessage, remarks, uploadedSuccess, summary, uploadApiResetDispatcher } = props;
    const { created, updated } = summary || { created: ZERO, updated: ZERO };

    /**
     * React Hook way of calling componentWillUmount in Functional Component
     */
    React.useEffect(() => {
        return function cleanup() {
            uploadApiResetDispatcher();
        };
    }, [uploadApiResetDispatcher]);
    /**
     * @remarks
     * callback when file uploading starts
     */
    /* eslint class-methods-use-this: 0 */
    const uploadStarted = () => {};

    /**
     * @remarks
     * callback when file uploading fails
     */
    /* eslint class-methods-use-this: 0 */
    const uploadFailed = () => {
        const errmsg = 'Error in Uploading files';
        message.error(errmsg);
    };
    /**
     * @remarks
     * callback when file uploading successful
     * call the `{uploadApiDispatcher}` with upload type and required params
     */

    const uploadSuccessful = (files, uploadType) => {
        const { uploadApiDispatcher } = props;
        const params: IUploadApiActionProp = {
            files,
            uploadType,
        };
        uploadApiDispatcher(params);
        /**
         * Only single file upload for the admin uploads
         */
    };
    return (
        <Layout className="UserLayout">
            <Content className="userContent">
                {!uploading && !uploadedSuccess && uploadErrorMessage && (
                    <React.Fragment>
                        <Alert message={uploadErrorMessage} type="error" showIcon banner closable />
                        <Row type="flex" justify="center" align="middle" gutter={24} className="errorContainer">
                            <Col xs={TWENTY_FOUR}>
                                {!uploading &&
                                    remarks instanceof Array &&
                                    remarks.length > ZERO &&
                                    remarks.map(remark => (
                                        <h4 className="errorText" key={remark}>
                                            {remark}
                                        </h4>
                                    ))}
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
                {!uploading && uploadedSuccess && (
                    <Alert
                        message={`File uploaded successfully - ${created} created, ${updated} updated`}
                        type="success"
                        showIcon
                        banner
                        closable
                    />
                )}
                <Row type="flex" justify="start" align="middle" className="fullHeight">
                    <Col xs={TWENTY_FOUR}>
                        <Row type="flex" justify="center" align="middle">
                            <Col xs={TWENTY_FOUR} sm={TWENTY_FOUR} md={TWENTY_FOUR} lg={EIGHT} xl={SIX}>
                                <AclPermission feature="internalUser" action="view" propertytype="element">
                                    <UploadCard
                                        title={getString('Upload internal users')}
                                        subtitle={getString('This will upload the internal users')}
                                        uploadType={uploadTypes.UPLOAD_INTERNAL_USERS}
                                        uploadStarted={uploadStarted}
                                        uploadSuccessful={uploadSuccessful}
                                        uploadFailed={uploadFailed}
                                    />
                                </AclPermission>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
};
export const stateToProps = state => {
    return {
        remarks: state.uploadApi.remarks,
        summary: state.uploadApi.summary,
        uploadErrorMessage: state.uploadApi.uploadErrorMessage,
        uploadedSuccess: state.uploadApi.uploadedSuccess,
        uploading: state.uploadApi.uploading,
    };
};

const dispatchers = dispatch => {
    return {
        uploadApiDispatcher: (params: IUploadApiActionProp) => dispatch(uploadApiAction(params)),
        uploadApiResetDispatcher: () => dispatch(uploadApiResetAction()),
    };
};

const InternalUsers = connect(stateToProps, dispatchers)(InternalUsersBase);

export { InternalUsers, InternalUsersBase };
