import React from 'react';
import { Card } from 'antd';
import { SCUploader } from '@saleskey/frontend-shared';
import { IUploadCardProp } from '../../interfaces/IUploadCard';
import { IWindow } from '../../interfaces/IWindow';
import './UploadCard.scss';

const customWindow: IWindow = window;

const { Meta } = Card;
/**
 * @remarks
 * Upload card ui with upload button which has inherited functionality
 * from @link `{@saleskey-admin/UploadRender}`
 * Method `{handleUpload}` is inherited method.
 * @param props : type of `{IUploadCardProp}`
 */
export const UploadCard = (props: IUploadCardProp) => {
    const { title, subtitle, uploadType, uploadSuccessful, uploadFailed, uploadStarted } = props;
    const uploadSuccessfulHandler = files => {
        uploadSuccessful(files, uploadType);
    };
    return (
        <Card title={title} bordered hoverable className="uploadCard">
            <div className="cardMiddleContent">
                <Meta description={subtitle} />
                <SCUploader
                    companionUrl={`${customWindow.config.baseUrl}/api/storage/companion-upload`}
                    id={`test-upload-div-button${uploadType}`}
                    modeOptions={{
                        buttonText: 'Upload',
                        mode: 'button',
                    }}
                    restrictions={{
                        allowedFileTypes: ['application/*'],
                        maxFileSize: null,
                        maxNumberOfFiles: null,
                        minNumberOfFiles: null,
                    }}
                    uploadFailed={uploadFailed}
                    uploadStarted={uploadStarted}
                    uploadSuccessful={uploadSuccessfulHandler}
                    xhrUrl={`${customWindow.config.baseUrl}/api/storage/xhr-upload`}
                />
            </div>
        </Card>
    );
};
