import { IApplicationReducer } from '../../interfaces/IApplicationReducer';
import { IAuthenticateAction } from '../../interfaces/IAuthenticateReducer';
import * as actions from '../../utils/actions';

/**
 * Application reducer
 * @remarks
 * Handles all the application reducers and set the store
 */
const initialState: IApplicationReducer = {
    sidebarCollapsed: false,
};

/**
 * @remarks
 * Handle the `{TOGGLE_SIDEBAR}` action.
 * @param state - current state of the store
 * @param action - action type and payload from dispatcher
 * @returns updated state of the store
 */
const AuthenticateReducer = (state = initialState, action: IAuthenticateAction) => {
    switch (action.type) {
        case actions.TOGGLE_SIDEBAR:
            return { ...state, sidebarCollapsed: action.payload.toggleState };
        case actions.SHOW_ADDNEW_DOCUMENT:
            return { ...state, showAddNewDocumentButton: action.payload.showState };
        case actions.SHOW_ADDNEW_EFORM_BUTTON:
            return { ...state, showAddNewEFormButton: action.payload.showState };
        case actions.GET_URLCONTENT:
            if (action.payload.loading) {
                return { ...state, loadingUrlType: action.payload.loading };
            }
            if (action.payload.status) {
                return {
                    ...state,
                    loadingUrlType: action.payload.loading,
                    mimeType: action.payload.mimeType,
                    urlError: undefined,
                    urlExtension: action.payload.urlExtension,
                    urlType: action.payload.urlType,
                };
            }
            return {
                ...state,
                loadingUrlType: action.payload.loading,
                urlError: action.payload.errors,
            };
        default:
            return state;
    }
};

export default AuthenticateReducer;
