import React from 'react';
import { connect } from 'react-redux';
import { ListDocumentsContainer } from '../ListDocuments/ListDocuments';
import { DocumentDrawerContainer } from '../DocumentDrawer/DocumentDrawer';
import { showAddNewDocument } from '../../store/rootActions';
import './Documents.scss';

interface IDocumentProp {
    showAddNewDocument: (boolean) => void;
}

interface IDocumentState {}
class DocumentsBaseContainer extends React.Component<IDocumentProp, IDocumentState> {
    /**
     *When component mounts
     *call the showAddNewDocument dispatcher
     *which will make the addNewDocument visible
     */
    componentDidMount() {
        const { showAddNewDocument: showAddNewDocumentProp } = this.props;
        showAddNewDocumentProp(true);
    }

    /**
     *When component closes
     *call the showAddNewDocument dispatcher
     *which will make the addNewDocument hide
     */
    componentWillUnmount() {
        const { showAddNewDocument: showAddNewDocumentProp } = this.props;
        showAddNewDocumentProp(false);
    }

    render() {
        return (
            <div className="documentsContainer">
                <ListDocumentsContainer />
                <DocumentDrawerContainer />
            </div>
        );
    }
}

const dispatchers = dispatch => {
    return {
        showAddNewDocument: (show: boolean) => dispatch(showAddNewDocument(show)),
    };
};

const DocumentsContainer = connect(
    null,
    dispatchers,
)(DocumentsBaseContainer);
export { DocumentsBaseContainer, DocumentsContainer };
