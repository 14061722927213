import fileType from 'file-type';
import * as actions from '../../utils/actions';
import { axiosInstance } from '../../utils/axios';
import { statusCodes } from '../../utils/statusCodes';
import { loadingState, successState, failedState } from '../../utils/responseStates';
/**
 * Application action dispatchers
 * @remarks
 * dispatch all the actions related to application type
 */
const defaultError = { message: 'Something went wrong. Please check the internet connection or use a valid link..' };
const { TOGGLE_SIDEBAR, GET_URLCONTENT, SHOW_ADDNEW_DOCUMENT, SHOW_ADDNEW_EFORM_BUTTON } = actions;

/**
 * @remarks
 * Use to toggle the application left sidebar
 * @param toggleState - type of `{boolean}`
 */
export const toggleSidebarAction = (toggleState: boolean | undefined) => {
    return async dispatch => {
        dispatch({ payload: { toggleState }, type: TOGGLE_SIDEBAR });
    };
};

/**
 * @remarks
 * Use to toggle the application left sidebar Add New Document Button
 * @param toggleState - type of `{boolean}`
 */
export const showAddNewDocument = (showState: boolean | undefined) => {
    return async dispatch => {
        dispatch({ payload: { showState }, type: SHOW_ADDNEW_DOCUMENT });
    };
};

/**
 * @remarks
 * Use to toggle the application left sidebar Add New EForm Button
 * @param toggleState - type of `{boolean}`
 */
export const showAddNewEFormButton = (showState: boolean | undefined) => {
    return async dispatch => {
        dispatch({ payload: { showState }, type: SHOW_ADDNEW_EFORM_BUTTON });
    };
};

/**
 * @remarks
 * Dispatcher action to make the request for fetching the content from url
 * `fileType` is being used to get the extenstion and mime type from the response received
 * @param url string
 */
export const getUrlContentType = (url: string) => {
    return async dispatch => {
        dispatch({ payload: { ...loadingState }, type: GET_URLCONTENT });
        try {
            const response = await axiosInstance.get(url, { responseType: 'arraybuffer' });
            const urlTypeResponse = fileType(response.data);
            if (!urlTypeResponse) {
                throw new Error('Not a valid document type url');
            }

            const { ext: urlExtension, mime } = urlTypeResponse;
            const urlType = mime.split('/')[0];
            return dispatch({
                payload: {
                    ...successState,
                    mimeType: mime,
                    urlExtension,
                    urlType,
                },
                type: GET_URLCONTENT,
            });
        } catch (err) {
            let error = defaultError;
            if (err.response) {
                const { status } = err.response;
                if (status === statusCodes.NOTFOUND) {
                    error = { message: 'Content not found' };
                } else if (status === statusCodes.SERVER_ERROR) {
                    error = { message: 'Server error' };
                } else if (status === statusCodes.NETWORK_UNREACHABLE) {
                    error = { message: 'Network Unreachable' };
                } else {
                    const { data } = err.response;
                    error = { message: data.message };
                }
            } else if (err.request) {
                error = { message: 'Url not found' };
            } else if (err.message) {
                error = { message: err.message };
            }
            return dispatch({ payload: { ...failedState, errors: error.message }, type: GET_URLCONTENT });
        }
    };
};
