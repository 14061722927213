/**
 * Function which returns if the email is valid or not
 *
 * @remarks
 * This method is part of the {@link @saleskey/frontend-shared | Frontend Shared Components}.
 *
 * @param email - email string
 * @returns boolean value, true if email is valid else false
 *
 */
const emailValidator = (email: string | undefined) => {
    if (!email) {
        return false;
    }
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
    return emailRegex.test(email);
};
export default emailValidator;
