import React from 'react';
import { Layout, Row, Col, Typography } from 'antd';
import { connect } from 'react-redux';
import './LandingPage.scss';
import { getString } from '@saleskey/frontend-shared';
import { IUser } from '../../interfaces/IAuthenticateReducer';

interface ILandingProp {
    user: IUser;
}

const { Content } = Layout;
const { Title } = Typography;

/**
 * @remarks
 * Landing page after the login for admin users
 * @param props : type of `{ILandingProp}`
 */
const LandingPageBaseComponent = (props: ILandingProp) => {
    const { user } = props;
    const { name, group: groupType } = user;
    const title = `Hi, ${name}`;

    /**
     * @returns HTML Dom
     */
    return (
        <Layout className="landingLayout">
            <Content>
                <Row type="flex" justify="start" align="middle" className="fullHeight">
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Row type="flex" justify="center" align="middle" gutter={24}>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Content className="landingContent">
                                    <Title className="adminGreeting">{title}</Title>
                                    <Title className="secondaryText" level={4} type="secondary">
                                        { groupType && <div>{getString('Your are in')}<strong> {groupType} </strong> {getString('group')}.{' '}</div>}
                                        {getString('Please use navigation menu on left to nagivate.')}
                                    </Title>
                                </Content>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
};

export const stateToProps = state => {
    return {
        user: state.auth.user,
    };
};

const LandingPageComponent = connect(stateToProps, null)(LandingPageBaseComponent);
export { LandingPageComponent, LandingPageBaseComponent };
