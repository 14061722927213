import React from 'react';
import { connect } from 'react-redux';
import { Layout, Menu, Icon } from 'antd';
import { getString } from '@saleskey/frontend-shared';
import { toggleSidebarAction } from '../../store/Application/Application.action';
import { logoutAction } from '../../store/Authentication/Authentication.action';
import { IHeader } from '../../interfaces/IHeader';
import './Header.scss';
import { Link } from 'react-router-dom';

const { Header } = Layout;
const { SubMenu } = Menu;

/**
 * Header navigation component
 * @remarks
 * Name, icon, logout component on top right of the dashboard
 * @param props : type of `{IHeader}`
 */
const HeaderBaseComponent = (props: IHeader) => {
    const { authenticated, sidebarCollapsed, toggleSidebar, user, logoutDispatcher, userType } = props;
    let username;
    if (user) {
        const { name } = user;
        username = name;
    }

    /**
     * @remarks
     * Toggle handler for the `{Sidebar}` component
     * trigger toggle state and save to store
     */
    const toggleSidebarHandler = () => {
        toggleSidebar(!sidebarCollapsed);
    };

    /**
     * @remarks
     * logout handler for the `{Sidebar}` component
     */
    const logoutHandler = () => {
        logoutDispatcher();
    };

    /**
     * @returns HTML Dom
     */
    return (
        <React.Fragment>
            <Header className="header">
                <Icon
                    className="trigger"
                    type={sidebarCollapsed ? 'menu-unfold' : 'menu-fold'}
                    onClick={toggleSidebarHandler}
                />
                <div className="userProfile">
                    <div className="userProfilePicture" />
                    <Menu mode="inline" theme="dark" defaultSelectedKeys={['1']} className="userProfileNav">
                        <SubMenu key="sub1" title={<span>{authenticated && username}</span>}>
                            {
                                userType === "admin" && 
                                <Menu.Item key="2">
                                    <Link to="/changepassoword">
                                        <span className='drop-down-option'>
                                            Change Password
                                        </span>
                                    </Link>
                                </Menu.Item>
                            }
                            <Menu.Item key="1">
                                <span
                                    className="logOutSpan"
                                    onClick={logoutHandler}
                                    tabIndex={0}
                                    role="button"
                                    onKeyUp={logoutHandler}
                                >
                                    {getString('Logout')}
                                </span>
                            </Menu.Item>
                        </SubMenu>
                    </Menu>
                </div>
            </Header>
        </React.Fragment>
    );
};

export const stateToProps = state => {
    return {
        authenticated: state.auth.authenticated,
        sidebarCollapsed: state.application.sidebarCollapsed,
        user: state.auth.user,
        userType: state.auth.userType,
    };
};

const dispatchers = dispatch => {
    return {
        logoutDispatcher: () => dispatch(logoutAction()),
        toggleSidebar: toggleState => dispatch(toggleSidebarAction(toggleState)),
    };
};
const HeaderComponent = connect(stateToProps, dispatchers)(HeaderBaseComponent);
export { HeaderBaseComponent, HeaderComponent };
