import * as actions from '../../utils/actions';
import { axiosInstance } from '../../utils/axios';
import { loadingState, successState, failedState } from '../../utils/responseStates';
import { transformToEForm, extendedEFormProperties } from '../../utils/eFormTransform';
import {
    IEFormApiParam,
    IPagination,
    enumFormActionType,
    INewEForm,
    ISingleDetailEForm,
    ISubmittedEForm,
    ISingleEFormSubmitted,
} from '../../interfaces/IEForm';
// import { listEForms } from '../../fixtures/formData';
// import { submittedEFormsList } from "../../fixtures/formData"

const defaultError = { message: 'Something went wrong. Please check the internet connection or use a valid link..' };
const {
    LIST_EFORMS,
    CREATE_OR_UDPATE_EFORM,
    ARCHIVE_EFORM,
    SUBMITTED_EFORMS,
    FETCH_EFORM,
    EFORM_DRAWER,
    RESET_EFORM_STORE,
} = actions;
const RADIX = 10;
/**
 * @remarks
 * handles all the form api actions
 *
 */
/**
 * @param params : `{IEFormApiParam}`
 * @return asynchronus dispatchers to reducer
 */
export const listEFormsAction = (params: IEFormApiParam) => {
    return async dispatch => {
        dispatch({ payload: { ...loadingState }, type: LIST_EFORMS });
        try {
            const result = await axiosInstance.get('/api/eform/', {
                params,
            });
            const {
                data: { data, pagination: paginationResponse },
            } = result.data;
            const filteredEforms = data.filter(d => d.isActive);
            const pagination: IPagination = paginationResponse;
            return dispatch({
                payload: {
                    ...successState,
                    data: filteredEforms,
                    pagination,
                },
                type: LIST_EFORMS,
            });
        } catch (err) {
            let error = defaultError;
            if (err.response) {
                const { data } = err.response;
                error = { message: data.message };
            } else if (err.request) {
                error = { message: 'Network Unreachable' };
            }
            return dispatch({ payload: { ...failedState, errors: error.message }, type: LIST_EFORMS });
        }
    };
};

/**
 * @remarks
 * handles the add new or update eform api
 * @param eform : `INewEForm`
 * @param actionType : `enumFormActionType` i.e create | update
 * @return asynchronus dispatchers to reducer
 */
export const eFormCreateOrUpdateAction = (eform: INewEForm, actionType: enumFormActionType) => {
    return async dispatch => {
        dispatch({ payload: { ...loadingState }, type: CREATE_OR_UDPATE_EFORM });
        try {
            /**
             * add other properties to the request like inputsAvailable, inputsRequired
             */
            const modifiedEform = extendedEFormProperties(eform);
            const result = await axiosInstance.post('/api/eform/config', {
                actionType,
                data: modifiedEform,
            });
            const { data } = result.data;
            return dispatch({
                payload: {
                    ...successState,
                    createdEForm: data,
                },
                type: CREATE_OR_UDPATE_EFORM,
            });
        } catch (err) {
            let error = defaultError;
            if (err.response) {
                const { data } = err.response;
                let errors = data.errors ? data.errors[0] : undefined;
                if (errors) {
                    errors = errors.remarks;
                    error = { message: errors };
                } else {
                    error = { message: data.message };
                }
            } else if (err.request) {
                error = { message: 'Network Unreachable' };
            }
            return dispatch({ payload: { ...failedState, errors: error.message }, type: CREATE_OR_UDPATE_EFORM });
        }
    };
};

/**
 * @remarks
 * handles the api to fetch the eform with its metadata
 * @param id : string | number
 * @return asynchronus dispatchers to reducer
 */
export const fetchEFormAction = (id: string | number, versionId?: string | number) => {
    return async dispatch => {
        dispatch({ payload: { ...loadingState }, type: FETCH_EFORM });
        try {
            const result = await axiosInstance.get(`/api/eform/eformId/${id}`, {
                params: {
                    versionId,
                },
            });
            const { data } = result.data;
            const successFetchEForm: ISingleDetailEForm = { ...data };
            return dispatch({
                payload: {
                    ...successState,
                    successFetchEForm,
                },
                type: FETCH_EFORM,
            });
        } catch (err) {
            let error = defaultError;
            if (err.response) {
                const { data } = err.response;
                error = { message: data.message };
            } else if (err.request) {
                error = { message: 'Network Unreachable' };
            }
            return dispatch({ payload: { ...failedState, errors: error.message }, type: FETCH_EFORM });
        }
    };
};

/**
 * @remarks
 * handles the api action to show all the sumitted forms list
 * @param params : `IEFormApiParam`
 * @param eformId : string | number
 * @return asynchronus dispatchers to reducer
 */
export const submittedEFormsAction = (params: IEFormApiParam, eformId: string | number) => {
    return async dispatch => {
        dispatch({ payload: { ...loadingState }, type: SUBMITTED_EFORMS });
        try {
            const paramQuery = params;
            // const eformTypecasted: number = parseInt(eformId.toString())
            if (eformId) {
                paramQuery.eformId = parseInt(eformId.toString(), RADIX);
            }
            const result = await axiosInstance.get('/api/eform/submissions', {
                params: {
                    ...paramQuery,
                },
            });
            const {
                data: { data, pagination: paginationResponse },
            } = result.data;
            const submittedFormsList: Array<ISubmittedEForm> = data;
            const pagination: IPagination = paginationResponse;
            return dispatch({
                payload: {
                    ...successState,
                    pagination,
                    submittedFormsList,
                },
                type: SUBMITTED_EFORMS,
            });
        } catch (err) {
            let error = defaultError;
            if (err.response) {
                const { data } = err.response;
                error = { message: data.message };
            } else if (err.request) {
                error = { message: 'Network Unreachable' };
            }
            return dispatch({ payload: { ...failedState, errors: error.message }, type: SUBMITTED_EFORMS });
        }
    };
};

/**
 * @remarks
 * archive the eform based on the id of eform
 * @param id : string| number
 */
export const archiveEFormAction = (id: string | number) => {
    return async dispatch => {
        dispatch({ payload: { ...loadingState }, type: ARCHIVE_EFORM });
        try {
            await axiosInstance.delete('/api/eform/config', { data: { id } });
            // TODO: will be remove once integration is complete
            // const result = {
            //     data: {}
            // }
            return dispatch({
                payload: {
                    ...successState,
                    successArchiveEForm: true,
                },
                type: ARCHIVE_EFORM,
            });
        } catch (err) {
            let error = defaultError;
            if (err.response) {
                const { data } = err.response;
                error = { message: data.message };
            } else if (err.request) {
                error = { message: 'Network Unreachable' };
            }
            return dispatch({ payload: { ...failedState, errors: error.message }, type: ARCHIVE_EFORM });
        }
    };
};

/**
 * @remarks
 * toggle the state of EForm drawer
 * @param isOpen : boolean
 */
export const eFormDrawerAction = (isOpen: boolean) => {
    return async dispatch => {
        dispatch({ payload: { isOpen }, type: EFORM_DRAWER });
    };
};

/**
 * @remarks
 * handles the api to fetch the eform with its metadata
 * @param id : string | number
 * @return asynchronus dispatchers to reducer
 */
export const viewSubmissionAction = (id: string | number) => {
    return async dispatch => {
        dispatch({ payload: { ...loadingState }, type: FETCH_EFORM });
        try {
            const result = await axiosInstance.get(`/api/efSubmissions/id/${id}`);

            const { data } = result.data;
            const successFetchSubmission: ISingleEFormSubmitted = { ...data };
            const { formJson, formValues } = transformToEForm(successFetchSubmission);
            return dispatch({
                payload: {
                    ...successState,
                    successFetchEForm: formJson,
                    successFetchEFormValues: formValues,
                },
                type: FETCH_EFORM,
            });
        } catch (err) {
            let error = defaultError;
            if (err.response) {
                const { data } = err.response;
                error = { message: data.message };
            } else if (err.request) {
                error = { message: 'Network Unreachable' };
            }
            return dispatch({ payload: { ...failedState, errors: error.message }, type: FETCH_EFORM });
        }
    };
};

/**
 * @remarks
 * function use to clear the store for eform
 */
export const resetEFormStoreAction = () => {
    return async dispatch => {
        dispatch({ payload: { ...successState }, type: RESET_EFORM_STORE });
    };
};
