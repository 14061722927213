import React from 'react';
import { Pagination as AntPagination } from 'antd';
import { IPagination } from '../../interfaces/IPagination';
/**
 * @remarks
 * Wrapper for pagination component provided by AntDesign
 * @param props : type of `{IPagination}`
 * @return Pagination React Node with pagination events
 */
const Pagination = (props: IPagination) => {
    const {
        onPageChange,
        onSizeChange,
        defaultPageSize,
        defaultPageCurrent,
        total,
        simple,
        pageSize,
        currentPage,
        paginationSize,
    } = props;
    /**
     * @return HTML DOM containing pagination
     */
    return (
        <AntPagination
          defaultCurrent={defaultPageCurrent}
          defaultPageSize={defaultPageSize}
          total={total}
          onChange={onPageChange}
          onShowSizeChange={onSizeChange}
          size={paginationSize}
          pageSize={pageSize}
          simple={simple}
          current={currentPage}
        />
    );
};
/**
 * setting up the default values for props;
 */
Pagination.defaultProps = {
    defaultPageCurrent: 1,
    defaultPageSize: 20,
    pageSize: 20,
    paginationSize: 'large',
    total: 0,
};
export { Pagination };
