export enum enumDocumentStatus {
    ACTIVE = 'active',
    EXPIRED = 'expired',
    ARCHIVED = 'archived',
    REJECTED = 'rejected',
    UPLOADED = 'uploaded',
    APPROVED = 'approved',
}
export interface IDocumentThumbnail {
    name: string | undefined;
    downloadLink: string;
    thumbnailUrl: string | undefined;
    fileType?: string;
    source?: string;
    documentId: string | number;
    status?: enumDocumentStatus;
    rejectionReason?: string;
}

export interface IDocThumbnailCard extends IDocumentThumbnail {
    hidden?: boolean;
    isHorizontalView?: boolean;
    downloadDocument: (documentId: string | number, source: string) => void;
    viewDetailDocument?: (documentId: string | number, source: string) => void;
    dmsToken:string
}

export interface IListDocument {
    documents: Array<IDocumentThumbnail>;
    downloadDocument: (documentId: string | number, source: string) => void;
    dmsToken:string;
}
