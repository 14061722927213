import React from 'react';
import IIFrame from '../../interfaces/IIFrame';

const IFrame = (props: IIFrame) => {
    const { url, title } = props;

    return <iframe src={url} title={title} allowFullScreen allow="camera;microphone" scrolling="auto" />;
};

export { IFrame };
