const ZERO = 0;
const ONE = 1;
const TWO = 2;
const EIGHT = 8;
const FOUR = 4;
const SIX = 6;
const SIXTEEN = 16;
const TWELVE = 12;
const TWENTY = 20;
const TWENTY_FOUR = 24;

export { ZERO, ONE, TWO, TWENTY_FOUR, TWENTY, SIXTEEN, TWELVE, EIGHT, FOUR, SIX };
