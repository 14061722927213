import React from 'react';
import MessageContent from './MessageBox/MessageBox';
import { ITextMessage, IMessage } from '../../../interfaces/IChat';
import './TextMessage.scss';
/**
 * Wrapper component to hold the MessageContent which has time and text view
 * Based on the sender or received, the alignment changes from left to right.
 * @param props : ITextMessage
 */
const TextMessage = (props: ITextMessage) => {
    const { data, linkShareHandler, addAnchor } = props;
    const { messageExchangeType, message, timestamp }: IMessage = data;
    if (data && messageExchangeType === 'sent') {
        return (
            <div className="message-holder sent-message-holder">
                <MessageContent type={messageExchangeType} message={message} linkShareHandler={linkShareHandler} addAnchor={addAnchor} timestamp={timestamp} />
               
            </div>
        );
    }
    return (
        <div className="message-holder received-message-holder">
            <MessageContent type={messageExchangeType} message={message} linkShareHandler={linkShareHandler} addAnchor={addAnchor} timestamp={timestamp} />
        </div>
    );
};

export default TextMessage;
