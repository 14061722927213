import { ISuccessfulUploadedFile } from '@saleskey/frontend-shared/src/interfaces/IUploader';
import { IUploadApiActionProp } from '../../interfaces/IUploadApiAction';
import { UPLOAD_DOCUMENT_CATEGORY_TYPE_OR_VALUE_API } from '../../utils/actions';
import { axiosInstance } from '../../utils/axios';
import excelReader from '../../utils/excelReader';
import { headerValidation } from '../../utils/jsonValidations';
import { loadingState, successState, failedState } from '../../utils/responseStates';
import * as uploadUtil from '../../utils/uploadTypeUrl';

const defaultError = { message: 'Something went wrong. Please check the internet connection or use a valid link..' };

interface IUploadBodyParams {
    data?: Array<object>;
    uploadReferenceIds?: Array<number>;
}

export const documentCategoryTypeOrValueAction = (params: IUploadApiActionProp) => {
    return async dispatch => {
        dispatch({ payload: { ...loadingState }, type: UPLOAD_DOCUMENT_CATEGORY_TYPE_OR_VALUE_API });
        try {
            let bodyParams: IUploadBodyParams = {};
            const { files, uploadType } = params;
            const file: ISuccessfulUploadedFile = files[0];
            const errors: Array<string> = [];
            const { fileStream, fileId } = file;
            const response = await excelReader.readExcelJson(fileStream);
            if (response.status) {
                const { excelJson } = response;
                Object.keys(excelJson).forEach(key => {
                    const result = headerValidation(excelJson[key], uploadType);
                    if (result.status) {
                        bodyParams = {
                            data: result.data,
                            uploadReferenceIds: [fileId],
                        };
                    } else if (result.errors && result.errors.length) {
                        result.errors.map(err => errors.push(err));
                    }
                });
            }
            const url = uploadUtil.getUploadUrl(uploadType);
            if (!url) {
                throw new Error('Upload Url type not found');
            }
            const body = {
                ...bodyParams,
            };
            if (errors.length) {
                return dispatch({
                    payload: {
                        ...failedState,
                        errors: 'Error in excel uploaded',
                        remarks: errors,
                        uploadedSuccess: false,
                    },
                    type: UPLOAD_DOCUMENT_CATEGORY_TYPE_OR_VALUE_API,
                });
            }
            const result = await axiosInstance.post(url, body);
            const { data } = result.data;
            return dispatch({
                payload: {
                    ...successState,
                    summary: data.summary,
                    uploadedSuccess: true,
                },
                type: UPLOAD_DOCUMENT_CATEGORY_TYPE_OR_VALUE_API,
            });
        } catch (err) {
            let error = defaultError;
            let remarks = [];
            if (err.response) {
                const { data } = err.response;
                const { errors } = data;
                errors.forEach(item => {
                    remarks = remarks.concat(item.remarks);
                });
                error = { message: data.message };
            } else if (err.request) {
                error = { message: 'Network Unreachable' };
            } else if (err.message) {
                error = { message: err.message };
            }
            return dispatch({
                payload: { ...failedState, errors: error.message, remarks, uploadedSuccess: false },
                type: UPLOAD_DOCUMENT_CATEGORY_TYPE_OR_VALUE_API,
            });
        }
    };
};
