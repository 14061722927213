import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Row, Col, Input, Select, DatePicker, Button, Layout, message, Icon, Radio, Tooltip } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import './AddDocument.scss';
import { SCInput, SCThumbnailByType, SCLoader, SCUploader, SCTags, getString } from '@saleskey/frontend-shared';
import TextArea from 'antd/lib/input/TextArea';
import { RadioChangeEvent } from 'antd/lib/radio';
import { constants } from '../../constants/AddDocument';
import { IAddNewDocument, IAddDocumentProps, IAddDocumentState } from '../../interfaces/IDocument';
import { isUrlValid } from '../../utils/urlValidator';
import {
    addNewDocumentDrawer,
    addNewDocument,
    getUrlContentType,
    getDocumentCategories,
} from '../../store/rootActions';
import { IWindow } from '../../interfaces/IWindow';
import { ZERO } from '../../constants/Common';
import { AddQuestion } from './AddQuestion/AddQuestion';
import { ISingleQuestion } from '../../interfaces/IAddQuestion';

const customWindow: IWindow = window;
const { Option } = Select;

/**
 * @remarks
 * Holds the form for adding the new document
 * props - type of `IAddDocumentProps`
 * state - type of `IAddDocumentState`
 */
class AddDocumentBaseForm extends React.Component<IAddDocumentProps & FormComponentProps, IAddDocumentState> {
    documentIds: Array<string> = [];

    mimeType: string = '';

    categoriesChanged: boolean;

    constructor(props: IAddDocumentProps & FormComponentProps) {
        super(props);

        this.state = {
            categoriesOptions: [],
            selectedDocumentUploaded: false,
            urlAddView: true,
            urlInput: '',
            showFeedbackQuestions: false,
            fQuestions: []
        };
        this.categoriesChanged = false;
        this.onCancel = this.onCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCategorieChange = this.handleCategorieChange.bind(this);
        this.hasErrors = this.hasErrors.bind(this);
        this.handleFailedUpload = this.handleFailedUpload.bind(this);
        this.handleStartedUpload = this.handleStartedUpload.bind(this);
        this.handleSuccessUpload = this.handleSuccessUpload.bind(this);
        this.updateTags = this.updateTags.bind(this);
        this.updateQuestions = this.updateQuestions.bind(this);
        this.addSingleQuestion = this.addSingleQuestion.bind(this);
        this.isValidFeedbackQuestions = this.isValidFeedbackQuestions.bind(this);
    }

    /**
     * Validate the form fields to disable the upload button, when component loads
     * Get the document categories
     */
    public componentDidMount() {
        const { getDocumentCategories: getDocumentCategoriesProp } = this.props;
        const { form } = this.props;
        form.validateFields();
        getDocumentCategoriesProp();
        this.setFeedbackQuestions(true);
        this.setState({ showFeedbackQuestions: false });
    }

    /**
     * Make React Elements of options from newly fetched categories
     */
    public componentDidUpdate(prevProps) {
        const { categories, mimeType, fetchingCategories } = this.props;
        const { categories: prevC } = prevProps;

        if (JSON.stringify(prevC) !== JSON.stringify(categories) && !fetchingCategories) {
            const categoriesOptions = this.setCategoryOption(categories);
            this.setState({ categoriesOptions });
        } else if (JSON.stringify(categories) && !this.categoriesChanged) {
            this.categoriesChanged = true;
            const categoriesOptions = this.setCategoryOption(categories);
            this.setState({ categoriesOptions });
        }
        this.mimeType = mimeType || this.mimeType;

        const { uploadingDocument, error, documentUploaded } = this.props;

        const {
            uploadingDocument: uploadingDocumentPrev,
            error: errorPrev,
            documentUploaded: documentUploadedPrev,
        } = prevProps;

        if (uploadingDocument !== uploadingDocumentPrev && !uploadingDocument) {
            if (error !== errorPrev && error) {
                message.error(error);
            } else if (documentUploaded !== documentUploadedPrev && documentUploaded) {
                const { addNewDocumentDrawer: addNewDocumentDrawerProp } = this.props;
                message.success('Document uploaded successfully');
                /**
                 * If Document uploaded successfully then close the drawer and update the list documents
                 */
                addNewDocumentDrawerProp(false);
            }
        }
    }

    // Callback function to close the drawer
    public onCancel(): void {
        const { addNewDocumentDrawer: addNewDocumentDrawerProp } = this.props;
        const { form } = this.props;
        const { resetFields } = form;
        resetFields();
        addNewDocumentDrawerProp(false);
    }

    /**
     * Function which returns React.Elements to render in <select>
     * Options is the number of objects in the categories array
     * @param categories : Array<IDocumentCategory>
     */
    private setCategoryOption = categories => {
        let categoriesOptions: React.ReactElement[] = [];
        categoriesOptions = categories.map(subCategory => {
            const { category } = subCategory;
            return (
                <Option key={`${subCategory.name} - ${category.name}`}>
                    {subCategory.name} - {category.name}
                </Option>
            );
        });
        return categoriesOptions;
    };

    // to handle the url input and make the dispatcher call
    private handleUrlInput = (e: React.FormEvent<HTMLInputElement>) => {
        const { getUrlContentType: getUrlContentTypeProp } = this.props;
        const url = e.currentTarget.value;
        this.setState({ urlInput: url });

        /**
         * Just checkfor the url begin with http:// or https://,
         * no other format is allowed as per the requirement
         */

        if (isUrlValid(url)) {
            getUrlContentTypeProp(url);
        }
    };

    /**
     * @remarks
     * filter out the categories for which one sub category is selected
     * ex: [{id:1, categoryId:1},{id:2, categoryId:1},{id:3, categoryId:2}]
     * if id = 1 is selected then the updated list should contain [{id:3, categoryId:2}]
     * @param selectedCategoryIds - Array<string>
     */
    private handleCategorieChange = selectedCategoryIds => {
        const { categories } = this.props;
        const selections = selectedCategoryIds.map(category => {
            const temp = category.split('-');
            return temp[temp.length - 1].trim();
        });
        if (categories) {
            // define the array of number to hold the filtered ids
            const selectedCategoriesType: Array<string> = [];
            // get all the types for which name is selected
            categories.forEach(subCategory => {
                const { category } = subCategory;
                if (selections.includes(category.name)) {
                    selectedCategoriesType.push(category.id);
                }
            });

            // filter the categories if particular categoryId is already selected
            const filteredCategories = categories.filter(category => {
                return !selectedCategoriesType.includes(category.category.id);
            });

            // get the updated categories of ReactElement
            const categoriesOptions = this.setCategoryOption(filteredCategories);
            // set the categoriesOptions state to reflect the updated list
            this.setState({ categoriesOptions });
        }
    };

    handleShowFeedbackChange = value => {
        if (value === constants.SHOWFEEDBACKFORM) {
            this.setState({ showFeedbackQuestions: false });
        } else {
            this.setState({ showFeedbackQuestions: true });
        }
    }
    /**
     * Form submit handle: this function used to call the dispatcher function
     *
     */
    handleSubmit = e => {
        e.preventDefault();
        const { form, categories } = this.props;
        form.validateFields((err, values) => {
            if (!err) {
                const { addNewDocument: addNewDocumentProp } = this.props;
                const { urlInput, urlAddView, fQuestions, showFeedbackQuestions } = this.state;
                // limitation in select antd component, filter can be done on name only
                const filteredCategoryIds: Array<number> = [];
                if (categories && values.categories && values.categories.length) {
                    values.categories.forEach(category => {
                        const temp = category.split('-')[ZERO].trim();
                        const filteredCategory = categories.find(c => {
                            return c.name === temp;
                        });
                        if (filteredCategory) {
                            filteredCategoryIds.push(filteredCategory.id);
                        }
                    });
                }
                const formData = {
                    categories: filteredCategoryIds,
                    expiryDate: new Date(values.expiryDate),
                    fileType: this.mimeType,
                    isProtected: !!(values.access && values.access === 'Protected'),
                    name: values.name,
                    remarks: values.remarks,
                    sourceType: values.sourceType,
                    sourceValue: urlAddView ? urlInput : this.documentIds,
                    tags: values.tags,
                    visibility: values.visibility,
                    showFeedbackQuestions,
                    fQuestions : showFeedbackQuestions ? fQuestions : [],
                    feedbackTimerDuration: values.timerDuration
                };
                addNewDocumentProp(formData);
            }
        });
    };

    /**
     * callback function when document source type gets changed
     * urlAddView : true, then show user paste url input
     */
    onSourceChange = (e: RadioChangeEvent) => {
        const { value } = e.target;
        if (value === constants.URLSOURCE) {
            this.setState({ urlAddView: true });
        } else if (value === constants.FILEUPLOAD) {
            this.documentIds = [];
            this.setState({ urlAddView: false });
        }
    };

    /**
     * callback function to check for the errors in form and disable the submit button
     * if any errors found
     * @param fieldsError Array<{fieldsName}> - each field contains error string if present
     */
    hasErrors(fieldsError) {
        const { urlAddView } = this.state;
        const { urlLoading, urlError, urlType } = this.props;

        /*
         * condition to check if urlAddView was selected then correct urlType fetched or not
         * if not, then disable the submit button
         */
        const isUrlSourceValid = !!(urlAddView && !urlLoading && !urlError && urlType);

        const isUploadFileValid = this.documentIds.length;

        // check for the errors present in any form field
        const fieldsInvalid = Object.keys(fieldsError).some(field => {
            if (fieldsError[field] && fieldsError[field].length) {
                fieldsError[field] = fieldsError[field].filter( el => el !== null && el !== undefined);
            }
            return fieldsError[field] && fieldsError[field].length;
        });
        if (urlAddView && (fieldsInvalid || !isUrlSourceValid)) {
            return true;
        }
        if (!urlAddView && (!isUploadFileValid || fieldsInvalid)) {
            return true;
        }
        if (!this.isValidFeedbackQuestions()) {
            return true;
        }
        return false;
    }

    isValidFeedbackQuestions() {
        const { showFeedbackQuestions, fQuestions } = this.state;
        if (!showFeedbackQuestions) return true;
        if (showFeedbackQuestions && fQuestions.length === 0) return false;
        let isValid = true;
        fQuestions.forEach(question => {
            if (!question.text || question.text === '' || !question.type || !('isMandatory' in question)) {
                isValid = false;
            }
        })
        return isValid;
    }

    handleSuccessUpload(files) {
        this.documentIds = [];
        const { selectedDocumentUploaded } = this.state;
        this.mimeType = '';
        files.forEach(file => {
            this.documentIds.push(file.fileId);
            this.mimeType = file.fileType;
        });

        const status = selectedDocumentUploaded || true;
        this.setState({ selectedDocumentUploaded: status });
    }

    /* eslint class-methods-use-this: 0 */
    handleStartedUpload() {}

    /* eslint class-methods-use-this: 0 */
    handleFailedUpload() {
        message.error('Error in upload file');
        this.setState({ selectedDocumentUploaded: false });
    }

    updateTags(tags: string[], name: string) {
        const { form } = this.props;
        form.setFieldsValue({
            [name]: tags,
        });
    }

    updateQuestions (questions: ISingleQuestion[]) {
        this.setState({fQuestions: questions});
    }

    addSingleQuestion () {
        this.setFeedbackQuestions(false);
    }

    setFeedbackQuestions (isReset: boolean) {
        const {fQuestions} = this.state;
        const question = {
            id: Date.now() + Math.random(),
            text: '',
            type: 'text',
            isMandatory: true
        }
        if (isReset) {
            this.setState({fQuestions: [question]})
        } else {
            this.setState({fQuestions: [...fQuestions, question]})
        }
    }

    public render() {
        const { Content } = Layout;
        const { urlType, urlExtension, urlError, urlLoading, uploadingDocument, form } = this.props;
        const { getFieldDecorator, getFieldsError, isFieldTouched, getFieldError } = form;

        /**
         * constants defined to show the error message only when the user has touched the input elements
         */
        const nameError = isFieldTouched('name') && getFieldError('name');
        const categoryError = isFieldTouched('categories') && getFieldError('categories');
        const visibilityError = isFieldTouched('visibility') && getFieldError('visibility');
        const documentSourceError = isFieldTouched('sourceType') && getFieldError('sourceType');
        const expiryError = isFieldTouched('expiryDate') && getFieldError('expiryDate');
        const remarksError = isFieldTouched('remarks') && getFieldError('remarks');
        const documentTagError = isFieldTouched('tags') && getFieldError('tags');
        const accessError = isFieldTouched('access') && getFieldError('access');
        const feedbackError = isFieldTouched('feedback') && getFieldError('feedback');
        const timerError = getFieldError('timerDuration');

        const { urlAddView, urlInput, categoriesOptions, showFeedbackQuestions, fQuestions } = this.state;

        /*
         * to make the radio group shown in vertical way: ant.design recommended way
         */
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };
        return (
            <Layout className="addDocumentLayout">
                <Content className="fullHeight">
                    <Row type="flex" justify="start" align="top">
                        <Col sm={24} xs={24} md={12} lg={12}>
                            <Content className="urlDocumentSelect">
                                {!urlAddView && (
                                    <div className="documentAddContainer">
                                        <SCUploader
                                            id="test-upload-div"
                                            modeOptions={{
                                                mode: 'full',
                                            }}
                                            useDropbox={false}
                                            useGoogleDrive={false}
                                            useInstagram={false}
                                            useWebcam
                                            uploadStarted={this.handleStartedUpload}
                                            uploadSuccessful={this.handleSuccessUpload}
                                            uploadFailed={this.handleFailedUpload}
                                            companionUrl={`${customWindow.config.baseUrl}/api/storage/companion-upload`}
                                            xhrUrl={`${customWindow.config.baseUrl}/api/storage/xhr-upload`}
                                        />
                                    </div>
                                )}
                                {urlAddView && (
                                    <div className="urlInputContainer">
                                        <SCInput
                                            placeholder={getString('Please enter the url')}
                                            size="large"
                                            onChange={this.handleUrlInput}
                                            value={urlInput}
                                            label={getString('Paste url')}
                                        />
                                        <div className="urlResponse">
                                            {urlLoading && <SCLoader />}
                                            {!urlLoading && urlError && <span>{urlError}</span>}
                                            {!urlLoading && !urlError && urlType && (
                                                <SCThumbnailByType
                                                    fileType={urlType}
                                                    fileExtension={urlExtension}
                                                    fileUrl={urlInput}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                            </Content>
                        </Col>
                        <Col sm={24} md={12} lg={12} className="addDocumentFormCol">
                            <Form onSubmit={this.handleSubmit} className="addDocumentForm">
                                <Form.Item
                                    label={getString('Document Name')}
                                    validateStatus={nameError ? 'error' : ''}
                                    help={nameError || ''}
                                    hasFeedback
                                >
                                    {getFieldDecorator('name', {
                                        rules: [{ message: 'Please input document name', required: true }],
                                    })(<Input size="large" />)}
                                </Form.Item>
                                <Form.Item
                                    label="Document Category"
                                    validateStatus={categoryError ? 'error' : ''}
                                    help={categoryError || ''}
                                    hasFeedback
                                >
                                    {getFieldDecorator('categories', {
                                        rules: [{ message: 'Please select atleast one category', required: true }],
                                    })(
                                        <Select
                                            mode="multiple"
                                            notFoundContent="No category found"
                                            size="large"
                                            onChange={this.handleCategorieChange}
                                            placeholder="Please select document category"
                                        >
                                            {categoriesOptions}
                                        </Select>,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <span>
                                            {getString('Visibility of document')}&nbsp;
                                            <Tooltip title={getString('Select who can see the document.')}>
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </span>
                                    }
                                    validateStatus={visibilityError ? 'error' : ''}
                                    help={visibilityError || ''}
                                    hasFeedback
                                >
                                    {getFieldDecorator('visibility', {
                                        rules: [
                                            { message: getString('Please select document visibility'), required: true },
                                        ],
                                    })(
                                        <Select placeholder={getString('Visibility of document')} size="large">
                                            <Option value="internal">{getString('Internal')}</Option>
                                            <Option value="external">{getString('External')}</Option>
                                        </Select>,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <span>
                                            {getString('Access of document')}&nbsp;
                                            <Tooltip title={getString('Configure if OTP is required or not.')}>
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </span>
                                    }
                                    validateStatus={accessError ? 'error' : ''}
                                    help={accessError || ''}
                                    hasFeedback
                                >
                                    {getFieldDecorator('access', {
                                        rules: [
                                            { message: getString('Please select document access.'), required: true },
                                        ],
                                    })(
                                        <Select placeholder={getString('Access of document')} size="large">
                                            <Option value="Protected">{getString('Protected')}</Option>
                                            <Option value="Unprotected">{getString('Unprotected')}</Option>
                                        </Select>,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="Document Source"
                                    validateStatus={documentSourceError ? 'error' : ''}
                                    help={documentSourceError || ''}
                                >
                                    {getFieldDecorator('sourceType', {
                                        initialValue: constants.URLSOURCE,
                                        rules: [{ message: 'Please select document source', required: true }],
                                    })(
                                        <Radio.Group onChange={this.onSourceChange}>
                                            <Radio style={radioStyle} value={constants.URLSOURCE}>
                                                {getString('Url Source')}
                                            </Radio>
                                            <Radio style={radioStyle} value={constants.FILEUPLOAD}>
                                                {getString('File Upload')}
                                            </Radio>
                                        </Radio.Group>,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="Document Expiry"
                                    style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                                    validateStatus={expiryError ? 'error' : ''}
                                    help={expiryError || ''}
                                >
                                    {getFieldDecorator('expiryDate', {
                                        rules: [{ message: 'Please choose the document expiry date', required: false }],
                                    })(
                                        <DatePicker
                                            disabledDate={current => {
                                                if (current) return current && current < moment().subtract(1, 'days');
                                                return false;
                                            }}
                                            size="large"
                                        />,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label={getString('Document Tags')}
                                    validateStatus={documentTagError ? 'error' : ''}
                                    help={documentTagError || ''}
                                    hasFeedback
                                    className="tagging-box"
                                >
                                    {getFieldDecorator('tags', {
                                        rules: [{ message: getString('Please enter the tags'), required: true }],
                                    })(
                                        <SCTags
                                            size="small"
                                            newTagLabel={getString('New Tag')}
                                            tags={[]}
                                            updateTags={tags => this.updateTags(tags, 'tags')}
                                        />,
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label={
                                        <span>
                                            {getString('Show feedback form')}&nbsp;
                                            <Tooltip title={getString('Configure if you want to show feeback form.')}>
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </span>
                                    }
                                    validateStatus={feedbackError ? 'error' : ''}
                                    help={feedbackError || ''}
                                    hasFeedback
                                >
                                    {getFieldDecorator('feedback', {
                                        initialValue: 'ShowFeedbackForm',
                                        rules: [
                                            { message: getString('Please select.'), required: true },
                                        ],
                                    })(
                                        <Select placeholder={getString('Feedback form')} size="large"
                                            onChange={this.handleShowFeedbackChange}>
                                            <Option value="HideFeedbackForm">{getString('Show')}</Option>
                                            <Option value="ShowFeedbackForm">{getString('Hide')}</Option>
                                        </Select>,
                                    )}
                                </Form.Item>
                                {showFeedbackQuestions && 
                                <Form.Item
                                    label={getString('Timer duration')}
                                    validateStatus={timerError ? 'error' : ''}
                                    help={timerError || ''}
                                    hasFeedback
                                >
                                    {getFieldDecorator('timerDuration', {
                                        initialValue: 100,
                                        rules: [{ message: 'Please input time duration in seconds', required: showFeedbackQuestions }],
                                    })(<Input size="large" type="number"/>)}
                                </Form.Item>}
                                {showFeedbackQuestions && 
                                    <Form.Item>
                                        {getFieldDecorator('feedbackQuestions', {
                                            rules: [
                                                { message: getString('Please select.'), required: false },
                                            ],
                                        })(
                                            <AddQuestion
                                                questions={fQuestions}
                                                form={form}
                                                updateQuestions={questions => this.updateQuestions(questions)}
                                            />,
                                        )}
                                        <Button
                                            type="primary"
                                            className="submitButton"
                                            onClick={this.addSingleQuestion}
                                        >
                                            {getString('Add')}
                                        </Button>&nbsp; &nbsp; &nbsp;
                                    </Form.Item> 
                                }
                                <Form.Item
                                    label={getString('Remarks')}
                                    /** @todo fix this getString method */
                                    //   validateStatus={remarksError ? getString('error') : getString('success')}
                                    validateStatus={remarksError ? 'error' : 'success'}
                                    help={remarksError || ''}
                                >
                                    {getFieldDecorator('remarks', {
                                        rules: [{ message: 'Please input document remarks' }],
                                    })(<TextArea rows={4} />)}
                                </Form.Item>
                                <Form.Item className="buttonActions">
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        disabled={this.hasErrors(getFieldsError()) || uploadingDocument}
                                        loading={uploadingDocument}
                                        className="submitButton"
                                    >
                                        {getString('Upload')}
                                    </Button>
                                    <Button type="default" className="cancelButton" onClick={this.onCancel}>
                                        {getString('Cancel')}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        );
    }
}

export const stateToProps = state => {
    return {
        categories: state.document.categories,
        documentUploaded: state.document.documentUploaded,
        error: state.document.addDocumentError,
        fetchingCategories: state.document.fetchingCategories,
        mimeType: state.application.mimeType,
        uploadingDocument: state.document.uploadingDocument,
        urlError: state.application.urlError,
        urlExtension: state.application.urlExtension,
        urlLoading: state.application.loadingUrlType,
        urlType: state.application.urlType,
    };
};

const dispatchers = dispatch => {
    return {
        addNewDocument: (params: IAddNewDocument) => dispatch(addNewDocument(params)),
        addNewDocumentDrawer: isOpen => dispatch(addNewDocumentDrawer(isOpen)),
        getDocumentCategories: () => dispatch(getDocumentCategories()),
        getUrlContentType: (url: string) => dispatch(getUrlContentType(url)),
    };
};
const AddDocumentForm = Form.create<IAddDocumentProps & FormComponentProps>()(AddDocumentBaseForm);
const AddDocumentContainer = connect(stateToProps, dispatchers)(AddDocumentForm);
export { AddDocumentContainer, AddDocumentForm, AddDocumentBaseForm };
