import { uploadFormatHashMaps } from './uploadApiFormats';

/**
 * get key from the value of an object
 */
const getKeyFromValue = (obj, value) => {
    if (typeof obj !== 'object') {
        return false;
    }
    return Object.keys(obj).find(key => obj[key] === value);
};

/**
 * transform array of array to array of objects
 */
const arrayToObjects = array => {
    if (!Array.isArray(array)) {
        return false;
    }
    const keys = array.shift();
    const objects = array.map(values => {
        return keys.reduce((o, k, i) => {
            o[k] = values[i]; // eslint-disable-line no-param-reassign
            return o;
        }, {});
    });
    return objects;
};

/**
 * validate headers for the json i.e. array of array
 * ex: [['name','id']['san jose',1]]
 */
const headerValidation = (data, headerName) => {
    const errors: Array<string> = [];
    let json: Object = {};

    /**
     * Header should exists in the system
     */
    const headerHash = uploadFormatHashMaps[headerName];
    if (!headerHash) {
        errors.push('Header configuration not found');
    }
    /**
     * check if the data is string
     * if yes then check JSON.parse returns json or not
     */
    if (typeof data !== 'object' && !errors.length) {
        try {
            json = JSON.parse(data);
        } catch (err) {
            errors.push('Not a valid json format');
        }
    } else {
        // assignment of data which is not object
        json = data;
    }

    // check if the data is in array format
    if (!Array.isArray(json) && !errors.length) {
        errors.push('Only valid array format accepted');
    }

    // get the first row from the data which will have headers only
    const headerRow = json[0];

    if (!Array.isArray(headerRow) && !errors.length) {
        errors.push('Header should be in array format');
    } else if (!errors.length) {
        /**
         * check all headers are present or not
         */
        const columnErrors: Array<string> = [];
        Object.keys(headerHash).forEach(header => {
            if (!headerRow.includes(headerHash[header])) {
                columnErrors.push(headerHash[header]);
            }
        });

        // check if any column missed
        if (columnErrors.length) {
            errors.push(
                `sheet is missing the ${columnErrors.join(', ')} ${columnErrors.length === 1 ? 'column' : 'columns'}`,
            );
        } else {
            // map key to the header row in json data
            headerRow.forEach((hrow, index) => {
                const trimmedHrow = hrow;

                // get the key from headerHash hashmap
                const hname = getKeyFromValue(headerHash, trimmedHrow);
                if (!hname) {
                    errors.push(`${hrow} not a valid column`);
                } else {
                    json[0][index] = hname;
                }
            });
        }
    }

    if (errors.length) {
        return {
            data,
            errors,
            status: false,
        };
    }
    /**
     * transform the data to array of objects
     */
    const transformedData = arrayToObjects(json);
    return {
        data: transformedData,
        status: true,
    };
};

export { headerValidation, getKeyFromValue, arrayToObjects };
