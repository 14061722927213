import * as React from 'react';
import { intersectionWith, differenceWith, isEqual } from 'lodash';
import { Table, Input, Button, Popconfirm, Form, InputNumber } from 'antd';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { enumEFormModes } from '../../interfaces/IEForm';
const Cross = require('./../../images/cross.svg');
const Trash = require('./../../images/trash.svg');

const EditableContext = React.createContext('table');

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);
const inputPattern ='^[a-zA-Z0-9 ,.-]*$';
class EditableCell extends React.Component<any, any> {
    state = {
        editing: false,
    };
    input;
    form;

    toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
            if (editing) {
                this.input.focus();
            }
        });
    };
    checkErrors = () =>{
        if(!this.form){
            return;
        }
        this.form.validateFields((error, values)=>{
            if(error){
                const errorKeys = Object.keys(error);
                this.props.handleTableErrors(errorKeys, false)
            }
        })
    }

    save = e => {
        const { record, handleSave, rootName, handleTableErrors } = this.props;
        this.form.validateFields((error, values) => {
            if(error){
                const errorKeys = Object.keys(error);
                handleTableErrors(errorKeys, false)
            }
            if (!error || !error[e.currentTarget.id]) {
                handleTableErrors([e.currentTarget.id], true)
            }
            this.toggleEdit();
            const actualValues = {};
            Object.entries(values).forEach(([key, value]) => {
                if(key.includes(`:${record.key}:`)){
                    const newKey = key.split(`:${record.key}:`)[1];
                    actualValues[newKey] = value;
                }else{
                    actualValues[key] = value;
                }
            })
            handleSave({ ...record, ...actualValues }, e.currentTarget.id);
        });
    };

    renderCell = form => {
        this.form = form;
        const { VIEW } = enumEFormModes;
        const { children, dataIndex, record, title, min, max, type, placeholder, rootName, formMode } = this.props;
        const { editing } = this.state;
        const fieldName = `${rootName}:${record.key}:${dataIndex}`;
        return editing ? (
            <Form.Item style={{ margin: 0 }} className="tableItem">
                {form.getFieldDecorator(fieldName, {
                    rules: [
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                    ],
                    initialValue: record[dataIndex],
                })(
                    type === 'number' ? (
                        <InputNumber
                            ref={node => (this.input = node)}
                            onPressEnter={this.save}
                            onBlur={this.save}
                            max={max}
                            min={min}
                        />
                    ) : (
                        <Input
                            ref={node => (this.input = node)}
                            onPressEnter={this.save}
                            onBlur={this.save}
                            maxLength={max}
                            placeholder={placeholder}
                        />
                    ),
                )}
            </Form.Item>
        ) : (
            <Form.Item style={{ margin: 0, padding: 0 }} className="tableItem">
            {form.getFieldDecorator(fieldName, {
                rules: [
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                    {
                        message: 'Invalid input',
                        pattern: type === 'number' ? undefined : new RegExp(inputPattern,'g')
                    }
                ],
                initialValue: record[dataIndex],
            })(
                type === 'number' ? (
                    <InputNumber
                        disabled={ formMode === VIEW}
                        ref={node => (this.input = node)}
                        onPressEnter={this.save}
                        onBlur={this.save}
                        max={max}
                        min={min}
                    />
                ) : (
                    <Input
                        disabled={ formMode === VIEW}
                        ref={node => (this.input = node)}
                        onPressEnter={this.save}
                        onBlur={this.save}
                        maxLength={max}
                        placeholder={placeholder}
                        className="table-input"
                    />
                ),
            )}
        </Form.Item>
        );
    };

    componentDidUpdate(){
        const { record} = this.props;
        if(record){
            const recordKeys = Object.keys(record);
            if(recordKeys.length > 1){
                this.checkErrors()
            }
        }
    }

    render() {
        const { editable, dataIndex, title, record, index, handleSave, handleTableErrors, children, formMode, ...restProps } = this.props;
        return (
            <td {...restProps}>
                {editable ? <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer> : children}
            </td>
        );
    }
}

class EditableTable extends React.Component<any, any> {
    columns;
    constructor(props) {
        super(props);
        const { VIEW } = enumEFormModes;
        const { defaultColumns, formMode } = this.props;
        const fomattedColumns = this.props.columns.map(col => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    min: col.min,
                    max: col.max,
                    type: col.type,
                    rootName: this.props.rootName
                }),
            };
        });
        if(formMode !== VIEW){
            this.columns = [
                ...this.props.columns,
                {
                    title: '',
                    dataIndex: 'operation',
                    render: (text, record) =>
                    this.state.dataSource.length > 1 ? (
                        <Popconfirm overlayClassName="eform-row-delete" placement="bottom" title="Are you sure, you want to delete the row?" onConfirm={() => this.handleDelete(record.key)}>
                            <img src={Trash} className="action-img"/>
                        </Popconfirm>
                    ) : null,
                },
            ];
        }else{
            this.columns = [...this.props.columns]
        }

        this.state = {
            dataSource: this.getInitialValue(),
            count: this.props.defaultColumns,
        };
        this.updateData = this.updateData.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.getInitialValue = this.getInitialValue.bind(this)
        this.updateData()
    }

    getInitialValue() {
        let valuesArr:Array<{}> = [];
        const formData = this.props.formJson.form.getFieldValue(this.props.rootName)
        const existing = this.props.formJson && this.props.formJson.formValues && this.props.formJson.formValues[this.props.rootName];
        if(formData && formData.length){
            formData.forEach(ele =>{
                valuesArr.push({key:ele.key})
            })
        }
        else if(existing && existing.length){
            existing.forEach(ele =>{
                valuesArr.push({key:ele.key})
            })
        }else{
            for (let i = 1; i <= this.props.defaultColumns; i++) {
                valuesArr.push({ key: i.toString() });
            }
        }
        return valuesArr;
    }

    componentDidUpdate(prevProp) {
       this.updateData()
    }

    updateData(){
          // Check difference of key values pair between dataSource and formJson.form
          let storedData = this.props.getTableData(this.props.rootName)
          if(!storedData){
            storedData = this.props.formJson && this.props.formJson.formValues && this.props.formJson.formValues[this.props.rootName];
          }
          var dif = differenceWith(
            storedData,
            this.state.dataSource,
            isEqual,
        );
        if(!dif.length){
            return;
        }
        const formValues =  this.props.formJson.formValues;
        var dataArr:Array<{}> = [];
        if(Object.keys(formValues).length) {
            if(storedData && Array.isArray(storedData)){
                dataArr = storedData;
            }else{
                Object.keys(formValues).map((key) => {
                    if(key.includes(this.props.rootName)) {
                        const splitStr: any = key.split('.');
                        if(splitStr.length > 2) {
                            if(!dataArr[Number(splitStr[1])-1]) {
                                const keyName = splitStr[2] || "dummy";
                                dataArr[Number(splitStr[1])-1] = {[keyName]: formValues[key], "key": splitStr[1]}   
                            } else {
                                dataArr[Number(splitStr[1])-1][splitStr[2]] = formValues[key];  
                            }
                        }
                    }
                })
            }
            if(!dataArr.length) {
                dataArr = this.getInitialValue();
            }
            dataArr = dataArr.filter(Boolean);
            if(!this.props.formJson.form.getFieldValue(this.props.rootName)) {
                this.props.setTableData(this.props.rootName, dataArr);
            }
            this.setState({
                dataSource: this.props.getTableData(this.props.rootName),
            });
        }
    }

    handleDelete = key => {
        var dSource = [...this.state.dataSource];
        dSource = dSource.filter(item => item.key !== key).filter(Boolean);
        this.setState({ dataSource: dSource });
        this.props.setTableData(this.props.rootName, dSource)
        this.props.validateFieldsManually(false, true, undefined, false);
    };

    handleAdd = () => {
        const { count, dataSource } = this.state;
        let keys = (dataSource || []).map(d => d.key);
        keys = keys.map(key => parseInt(key, 10));
        const max = Math.max(...keys) || 0;
        const next = max + 1;
        const newSource = [...dataSource, { key: `${next}` }];
        this.setState({
            dataSource: newSource,
            count: count + 1,
        });
        this.props.setTableData(this.props.rootName, newSource)
        this.props.validateFieldsManually(false, true, undefined, false);
    };

    handleSave = (row, targetId) => {
        const newData = [...this.state.dataSource];
        const index = newData.findIndex(item => row.key === item.key);
        newData[index] = row;
        this.setState({ dataSource: newData });
        this.props.setTableData(this.props.rootName, newData)
        this.props.validateFieldsManually(false, true, undefined, false);
    };
    
    render() {
        const { dataSource } = this.state;
        const { formMode } = this.props;
        const { VIEW } = enumEFormModes;
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };
        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                    min: col.min,
                    max: col.max,
                    type: col.type,
                    placeholder: col.placeholder,
                    rootName: this.props.rootName,
                    handleTableErrors: this.props.handleTableErrors,
                    formMode: formMode
                }),
            };
        });
        if (isMobile) {
            return (
                <>
                    <Form.Item style={{padding: 0}}>
                        {this.props.formJson.form.getFieldDecorator(this.props.rootName, {
                            initialValue: this.getInitialValue(),
                        })(<div style={{ display: 'none' }}>Abc</div>)}
                    </Form.Item>
                    {this.state.dataSource.map((val, index) => {
                        return (
                            <div className="mobileTableRow">
                                <div style={{ width: '100%' }}>
                                    {this.props.columns.map(col => {
                                        let title: string = col.title;
                                        let dataIndex = col.dataIndex;
                                        let min = col.min;
                                        let max = col.max;
                                        let type = col.type;
                                        return (
                                            <>
                                                <div
                                                    style={{
                                                        paddingLeft: '5px',
                                                    }}
                                                >
                                                    <span>{title}</span>
                                                </div>
                                                <div></div>
                                                {type === 'number' ? (
                                                    <Form.Item
                                                        style={{ margin: 0, paddingLeft: '5px' }}
                                                        className="tableItem"
                                                    >
                                                        {this.props.formJson.form.getFieldDecorator(index + col.dataIndex, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: `${title} is required.`,
                                                                },
                                                            ],
                                                            initialValue: this.props.formJson.form.getFieldValue(
                                                                this.props.rootName,
                                                            )[index][col.dataIndex],
                                                        })(
                                                            <InputNumber
                                                                disabled={formMode === VIEW}
                                                                onBlur={e => {
                                                                    let newData = [...this.state.dataSource];
                                                                    const item = newData[index];
                                                                    newData.splice(index, 1, {
                                                                        ...item,
                                                                        ...{ [dataIndex]: e.currentTarget.value },
                                                                    });
                                                                    this.props.setTableData(this.props.rootName, newData);
                                                                }}
                                                                min={min}
                                                                max={max}
                                                            />,
                                                        )}
                                                    </Form.Item>
                                                ) : (
                                                    <Form.Item
                                                        style={{ margin: 0, paddingLeft: '5px' }}
                                                        className="tableItem"
                                                    >
                                                        {this.props.formJson.form.getFieldDecorator(index + col.dataIndex, {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: `${title} is required.`,
                                                                },
                                                                {
                                                                    message: `minimum ${min} characters allowed`,
                                                                    min,
                                                                },
                                                                {
                                                                    max,
                                                                    message: `maximum ${max} characters allowed`,
                                                                },
                                                                {
                                                                    message: 'Invalid input',
                                                                    pattern: new RegExp(inputPattern,'g')
                                                                }
                                                            ],
                                                            initialValue: this.props.formJson.form.getFieldValue(
                                                                this.props.rootName,
                                                            )[index][col.dataIndex],
                                                        })(
                                                            <Input
                                                                disabled={formMode === VIEW}
                                                                onBlur={e => {
                                                                    let newData = [...this.state.dataSource];
                                                                    const item = newData[index];
                                                                    newData.splice(index, 1, {
                                                                        ...item,
                                                                        ...{ [dataIndex]: e.currentTarget.value },
                                                                    });
                                                                    this.props.setTableData(this.props.rootName, newData);
                                                                }}
                                                                maxLength={max}
                                                            />,
                                                        )}
                                                    </Form.Item>
                                                )}
                                            </>
                                        );
                                    })}
                                </div>
                                {this.state.dataSource.length > 1 &&  formMode !== VIEW && (
                                    <div>
                                        <img
                                            src={Cross}
                                            onClick={() => {
                                                this.handleDelete(val.key);
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    {
                        formMode !== VIEW && (
                            <Button onClick={this.handleAdd} style={{ margin: 16, display: 'block', color: '#2EA3FB' }}>
                                Add new row
                            </Button>
                        )
                    }
                </>
            );
        }
        return (
            <>
                <Form.Item style={{padding: 0}}>
                    {this.props.formJson.form.getFieldDecorator(this.props.rootName, {
                        initialValue: this.getInitialValue(),
                    })(<div style={{ display: 'none' }}>Abc</div>)}
                </Form.Item>
                <Table
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                />
                {
                    formMode !== VIEW && (
                        <Button onClick={this.handleAdd} style={{ margin: 16, display: 'block',color: '#2EA3FB' }}>
                            Add new row
                        </Button>
                    )
                }
            </>
        );
    }
}

export default EditableTable;
