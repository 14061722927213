import React from 'react';
import { Radio, Button } from 'antd';
import { ConversationHeader } from '../ConversationHeader/ConversationHeader';
import { enumConversationType, IConversationHeader, ChatViewMode } from '../../interfaces/IConversation';
import { IChat, IConversationComponent } from '../../interfaces/IChat';
import Chat from '../Chat/Chat';
import './Conversation.scss';
import { __ChatTabTypes } from '../../constants/chatTabTypes';

import Comments from '../Comments/Comments';

/**
 * Functional Component which renders the conversation UI depending on the props being passed
 * This component has 4 different header levels
 * 1. Secure chat FULL view header
 * 2. ChatBot header
 * 3. Video call header
 * 4. Audio call header
 * @param props : IConversationComponent
 * @returns React Element
 */
const ConversationComponent = (props: IConversationComponent) => {
    const {
        type,
        title,
        hideChatHandler,
        chatTabType,
        fullChatHeight,
        reviewCommentsHistory,
        cobrowsingHandler,
    } = props;

    const tabSelected = chatTabType || __ChatTabTypes.LIVE_CHAT;
    const {
        messages,
        historyMessages,
        sendMessage,
        placeholder,
        onTextInputChange,
        attachmentCallback1,
        attachmentCallback2,
        typingStatus,
        agentLogin,
        linkShareHandler,
        viewMode,
    } = props as IChat;
    const {
        backPressHandler,
        videoCallHandler,
        audioCallHandler,
        showCallConnected,
        documentshareViewOpenHandler,
        fullHeightChatToggleHandler,
        coBrowsingActive,
    } = props as IConversationHeader;

    /**
     * Dispatcher util function which invokes the handler method in consumer app
     * @param e : event 
     */
    const handleTabChange = e => {
        const { chatTabChangeHandler, reviewCommentsLoadingHandler, linkCode, eformVersionId } = props;
        const { value } = e.target;
        if (chatTabChangeHandler) chatTabChangeHandler(value);
        if (value === __ChatTabTypes.COMMENTS && reviewCommentsLoadingHandler && linkCode && eformVersionId) {
            reviewCommentsLoadingHandler(linkCode, eformVersionId as number);
        }
    };

    switch (type) {
        case enumConversationType.CHAT:
            return (
                <React.Fragment>
                    <div className="chatContainerBox">
                        <div className={`chatFakeFlex ${viewMode === ChatViewMode.BOT ? `chatBotView` : ''}`}>
                            <div className="fakeHeader" />
                            <div className="fakeFooter" />
                        </div>
                        <div className={`chatContainerContainer ${viewMode === ChatViewMode.BOT ? `chatBotView` : ''}`}>
                            <ConversationHeader
                              title={title}
                              showCallConnected={showCallConnected}
                              audioCallHandler={audioCallHandler}
                              videoCallHandler={videoCallHandler}
                              documentshareViewOpenHandler={documentshareViewOpenHandler}
                              type={enumConversationType.CHAT}
                              viewMode={viewMode}
                              hideChatHandler={hideChatHandler}
                              fullHeightChatToggleHandler={fullHeightChatToggleHandler}
                              fullChatHeight={fullChatHeight}
                              typingStatus={typingStatus}
                              cobrowsingHandler={cobrowsingHandler}
                              coBrowsingActive={coBrowsingActive}
                            />
                            <div
                              className={`chatTabContentWrapper ${
                                    viewMode === ChatViewMode.BOT ? `chatBotView` : ''
                                }`}
                            >
                                {viewMode === ChatViewMode.BOT && (
                                    <div className="chatRadioTabs">
                                        <Radio.Group onChange={handleTabChange} value={tabSelected}>
                                            <Radio.Button value={__ChatTabTypes.LIVE_CHAT}>
                                                {__ChatTabTypes.LIVE_CHAT}
                                            </Radio.Button>
                                            <Radio.Button value={__ChatTabTypes.CHAT_HISTORY}>
                                                {__ChatTabTypes.CHAT_HISTORY}
                                            </Radio.Button>
                                            <Radio.Button value={__ChatTabTypes.COMMENTS}>
                                                {__ChatTabTypes.COMMENTS}
                                            </Radio.Button>
                                        </Radio.Group>
                                    </div>
                                )}
                                {viewMode === ChatViewMode.BOT && (
                                    <div className="chatBackEndWrapper">
                                        <Button className="returnKey" type="link" onClick={() => hideChatHandler()}>
                                            Return back to Form
                                        </Button>
                                        <Button
                                          className="endButton"
                                          type="danger"
                                          onClick={() => hideChatHandler(true)}
                                        >
                                            End Chat
                                        </Button>
                                    </div>
                                )}
                                <div className="chatTabContents">
                                    {tabSelected === __ChatTabTypes.LIVE_CHAT && (
                                        <Chat
                                          sendMessage={event => sendMessage(event)}
                                          onTextInputChange={onTextInputChange}
                                          placeholder={placeholder}
                                          title={title}
                                          messages={messages}
                                          historyMessages={historyMessages}
                                          attachmentCallback1={attachmentCallback1}
                                          attachmentCallback2={attachmentCallback2}
                                          agentLogin={agentLogin}
                                          typingStatus={typingStatus}
                                          linkShareHandler={linkShareHandler}
                                          viewMode={viewMode}
                                          fullChatHeight={fullChatHeight}
                                        />
                                    )}
                                    {tabSelected === __ChatTabTypes.CHAT_HISTORY && (
                                        <Chat
                                          sendMessage={event => sendMessage(event)}
                                          onTextInputChange={onTextInputChange}
                                          placeholder={placeholder}
                                          title={title}
                                          messages={messages}
                                          historyMessages={historyMessages}
                                          attachmentCallback1={attachmentCallback1}
                                          attachmentCallback2={attachmentCallback2}
                                          agentLogin={agentLogin}
                                          typingStatus={typingStatus}
                                          linkShareHandler={linkShareHandler}
                                          viewMode={viewMode}
                                          noInputView
                                          fullChatHeight={fullChatHeight}
                                        />
                                    )}
                                    {tabSelected === __ChatTabTypes.COMMENTS && (
                                        <div className={`comments-tab ${fullChatHeight ? 'comments-tab-full' : ''}`}>
                                            <Comments reviewCommentsHistory={reviewCommentsHistory} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );

        case enumConversationType.VIDEO:
            return (
                <React.Fragment>
                    <ConversationHeader
                      title={title}
                      backPressHandler={backPressHandler}
                      showCallConnected={showCallConnected}
                      audioCallHandler={audioCallHandler}
                      videoCallHandler={videoCallHandler}
                      type={enumConversationType.VIDEO}
                      fullHeightChatToggleHandler={fullHeightChatToggleHandler}
                      documentshareViewOpenHandler={documentshareViewOpenHandler}
                      viewMode={viewMode}
                      fullChatHeight={fullChatHeight}
                      cobrowsingHandler={cobrowsingHandler}
                      hideChatHandler={hideChatHandler}
                      coBrowsingActive={coBrowsingActive}
                    />
                    {/* {callingSourceUrl && callingSourceUrl.length > 0 && (
                        <iframe src={callingSourceUrl} title={title} allowFullScreen allow="camera;microphone" />
                    )} */}
                </React.Fragment>
            );
        case enumConversationType.AUDIO:
            return (
                <React.Fragment>
                    <ConversationHeader
                      title={title}
                      backPressHandler={backPressHandler}
                      showCallConnected={showCallConnected}
                      audioCallHandler={audioCallHandler}
                      videoCallHandler={videoCallHandler}
                      type={enumConversationType.AUDIO}
                      documentshareViewOpenHandler={documentshareViewOpenHandler}
                      fullHeightChatToggleHandler={fullHeightChatToggleHandler}
                      viewMode={viewMode}
                      fullChatHeight={fullChatHeight}
                      cobrowsingHandler={cobrowsingHandler}
                      hideChatHandler={hideChatHandler}
                      coBrowsingActive={coBrowsingActive}
                    />
                    {/* {callingSourceUrl && callingSourceUrl.length > 0 && <iframe src={callingSourceUrl} title={title} />} */}
                </React.Fragment>
            );
        default:
            return <React.Fragment />;
    }
};
export { ConversationComponent };
