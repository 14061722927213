import React from 'react';
import Input from '../Input/Input';
import PasswordInput from '../PasswordInput/PasswordInput';
import IEFormRenderer from '../../interfaces/IEFormRenderer';

const EFormRenderer = (props: IEFormRenderer) => {
    const { type, placeHolder, callbackEvent, inputvalue, disabled, size, inputLabel, error } = props;
    switch (type) {
        case 'email':
            return (
                <Input
                  placeholder={placeHolder}
                  value={inputvalue}
                  onChange={callbackEvent}
                  disabled={disabled}
                  size={size}
                  label={inputLabel}
                  error={error}
                />
            );
        case 'phone':
            return (
                <Input
                  placeholder={placeHolder}
                  value={inputvalue}
                  onChange={callbackEvent}
                  disabled={disabled}
                  size={size}
                  label={inputLabel}
                  error={error}
                />
            );
        case 'password':
            return (
                <PasswordInput
                  value={inputvalue}
                  onChange={callbackEvent}
                  disabled={disabled}
                  size={size}
                  label={inputLabel}
                  error={error}
                />
            );
        default:
            return (
                <Input
                  placeholder={placeHolder}
                  value={inputvalue}
                  onChange={callbackEvent}
                  disabled={disabled}
                  size={size}
                  error={error}
                />
            );
    }
};

export default EFormRenderer;
