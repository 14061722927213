import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import { SCListDocument, SCPagination, SCLoader, getString } from '@saleskey/frontend-shared';
import { IListDocumentsProp, IListDocumentsState, IListDocumentParam } from '../../interfaces/IDocument';
import { listDocumentAction } from '../../store/rootActions';
import { constants } from '../../constants/ListDocuments';
import './ListDocuments.scss';
import { axiosInstance } from '../../utils/axios';
import { AclPermission } from '../../components/AclPermission/AclPermission';
import { ZERO } from '../../constants/Common';
import { IWindow } from '../../interfaces/IWindow';

const customWindow: IWindow = window;
/**
 * @remarks
 * Parent container for list documents component
 * @param props: type of `{IListDocuments}`
 * @return List documents with pagination component
 */
class ListDocumentsBaseContainer extends React.Component<IListDocumentsProp, IListDocumentsState> {
    _firstReloadState: boolean;

    /**
     *
     * @param props : type of `{IListDocumentsProp}`
     */
    constructor(props: IListDocumentsProp) {
        super(props);
        const { DEFAULT_CURRENTPAGE_NUMBER, DEFAULT_PAGE_SIZE, DEFAULT_LAST_ID } = constants;
        this.state = {
            currentPage: DEFAULT_CURRENTPAGE_NUMBER,
            lastId: DEFAULT_LAST_ID,
            moveForward: true,
            pageSize: DEFAULT_PAGE_SIZE,
        };
        this._firstReloadState = false;
        this.fetchDocuments = this.fetchDocuments.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSizeChange = this.onSizeChange.bind(this);
        this.downloadDocument = this.downloadDocument.bind(this);
    }

    /**
     * fetch the documents once the component is loaded with default states
     */
    async componentDidMount() {
        const { pageSize, lastId, moveForward } = this.state;

        this.fetchDocuments(pageSize, lastId, moveForward);
    }

    componentDidUpdate(prevProps, prevState) {
        const { pageSize: pageSizePrev, lastId: prevLastId } = prevState;
        const { pageSize, lastId, moveForward } = this.state;
        const { documentUploaded } = this.props;

        const { documentUploaded: prevDocumentUploaded } = prevProps;

        const { DEFAULT_CURRENTPAGE_NUMBER, DEFAULT_LAST_ID, DEFAULT_PAGE_SIZE } = constants;
        if (documentUploaded !== prevDocumentUploaded && documentUploaded) {
            this.onPageChange(DEFAULT_CURRENTPAGE_NUMBER, DEFAULT_PAGE_SIZE);
        }

        if (
            pageSizePrev !== pageSize ||
            lastId !== prevLastId ||
            (lastId === DEFAULT_LAST_ID && this._firstReloadState)
        ) {
            this.fetchDocuments(pageSize, lastId, moveForward);
        }
    }

    /**
     *
     * @param nextPage : next list of documents to be fetched
     * @param pageSize : number of documents in a page
     */
    private onPageChange(nextPage, pageSize): void {
        const { currentPage } = this.state;
        const { pagination, documentUploaded } = this.props;
        let { lastId } = pagination || { lastId: '' };
        const { DEFAULT_LAST_ID } = constants;
        if (documentUploaded) {
            lastId = DEFAULT_LAST_ID;
        }
        if (nextPage !== currentPage) {
            if (nextPage < currentPage) {
                this.setState({ currentPage: nextPage, lastId, moveForward: documentUploaded || false, pageSize });
            } else {
                this.setState({ currentPage: nextPage, lastId, moveForward: true, pageSize });
            }
        } else if (nextPage === currentPage && documentUploaded) {
            this._firstReloadState = true;
            this.setState({ currentPage: nextPage, lastId, moveForward: documentUploaded || false, pageSize });
        }
    }

    /**
     *
     * @param currentPage : current list of documents to be fetched
     * @param newPageSize : new number of documents to be shown in a page
     */
    private onSizeChange(currentPage, newPageSize): void {
        const { pageSize } = this.state;
        const { pagination } = this.props;
        const { lastId } = pagination || { lastId: '' };
        if (newPageSize !== pageSize) {
            this.setState({ currentPage, lastId, pageSize: newPageSize });
        }
    }

    /**
     * Function to download the content
     */
    public async downloadDocument(documentId: string | number, source: string) {
        const response = await axiosInstance({
            method: 'GET',
            // url:`api/document/10/internal`,
            url: `api/document/${documentId}/${source}`,
        });
        const { data: dataresponse } = response;
        const {
            data: { content },
        } = dataresponse;
        if (!content) {
            message.error(getString('Document content does not exists'));
            return;
        }
        try {
            const { dmsToken } = this.props;
            const link = document.createElement('a');
            link.href = `${customWindow.config.dmsUrl}/api${content.link}?token=${dmsToken}`;
            link.click();
        } catch (err) {
            message.error(getString('Document content does not exists'));
        }
    }

    /**
     * @remarks
     * method to get the list the documents based on the current state properties
     * calls the dispatcher function `{listDocumentDispatch}` to trigger the action
     */
    private fetchDocuments(pageSize, lastId, moveForward?): void {
        const { listDocumentDispatch, pagination } = this.props;
        const { total } = pagination || { total: 0 };
        this._firstReloadState = false;
        const params: IListDocumentParam = {
            lastId,
            moveForward,
            pageSize,
            total,
        };
        listDocumentDispatch(params);
    }

    /**
     * @returns React Nodes
     */
    public render(): ReactNode {
        const { documents, pagination, loading, error, dmsToken } = this.props;
        const { total } = pagination || { total: 0 };
        const { currentPage, pageSize } = this.state;
        return (
            <div className="listDocument">
                <h1 className="listTitle">Documents</h1>
                {loading && (
                    <div className="listDocumentLoader">
                        <SCLoader fontSize={24} />
                    </div>
                )}
                {!loading && error && <div className="listDocumentLoader listDocumentError">{error}</div>}
                {!loading && !error && documents && (
                    <React.Fragment>
                        <AclPermission feature="document" subfeature="list" action="view" propertytype="element">
                            <div className="listDocumentDiv">
                                <SCListDocument
                                    documents={documents}
                                    downloadDocument={this.downloadDocument}
                                    dmsToken={dmsToken}
                                />
                            </div>
                            {documents && documents.length > ZERO && (
                                <div className="listPagination">
                                    <SCPagination
                                        onPageChange={this.onPageChange}
                                        onSizeChange={this.onSizeChange}
                                        total={total}
                                        simple
                                        currentPage={currentPage}
                                        pageSize={pageSize}
                                    />
                                </div>
                            )}
                        </AclPermission>
                    </React.Fragment>
                )}
                {!loading && !error && !documents && (
                    <React.Fragment>
                        <div className="listDocumentLoader">
                            <h3>{getString('No document found')}.</h3>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}
export const stateToProps = state => {
    return {
        allDocuments: state.document.allDocuments,
        allLastIds: state.document.allLastIds,
        dmsToken: state.auth.dmsToken,
        documentUploaded: state.document.documentUploaded,
        documents: state.document.documents,
        error: state.document.error,
        loading: state.document.loading,
        pagination: state.document.pagination,
    };
};
const dispatchers = dispatch => {
    return {
        listDocumentDispatch: (params: IListDocumentParam) => dispatch(listDocumentAction(params)),
    };
};
const ListDocumentsContainer = connect(stateToProps, dispatchers)(ListDocumentsBaseContainer);
export { ListDocumentsContainer, ListDocumentsBaseContainer };
