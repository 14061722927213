import { IAuthenticateReducer, IAuthenticateAction } from '../../interfaces/IAuthenticateReducer';
import * as actions from '../../utils/actions';

const initialState: IAuthenticateReducer = {
    authenticated: false,
};
const AuthenticateReducer = (state = initialState, action: IAuthenticateAction) => {
    switch (action.type) {
        case actions.LOGIN:
            if (action.payload.loading) {
                return { ...state, loginLoading: action.payload.loading };
            }
            if (action.payload.status) {
                return {
                    ...state,
                    authErrorMessage: undefined,
                    authenticated: action.payload.data.authenticated,
                    dmsToken: action.payload.data.dmsToken,
                    loginLoading: action.payload.loading,
                    permissions: action.payload.data.permissions,
                    user: action.payload.data.user,
                    userType: action.payload.data.userType
                };
            }
            return { ...state, authErrorMessage: action.payload.errors, loginLoading: action.payload.loading };
        case actions.LOGOUT:
            if (action.payload.loading) {
                return { ...state, loginLoading: action.payload.loading };
            }
            if (action.payload.status) {
                return {
                    ...state,
                    authErrorMessage: undefined,
                    authenticated: action.payload.data.authenticated,
                    loginLoading: action.payload.loading,
                };
            }
            return { ...state, authErrorMessage: action.payload.errors, loginLoading: action.payload.loading };
        case actions.CHANGE_PASSWORD:{
            if (action.payload.loading) {
                return { ...state, loginLoading: action.payload.loading };
            }
            if (action.payload.status) {
                return {
                    ...state,
                    authErrorMessage: undefined,
                    authenticated: action.payload.data.authenticated,
                    loginLoading: action.payload.loading,
                    user: action.payload.data.user,
                    userType: action.payload.data.userType
                };
            }
            return { ...state, authErrorMessage: action.payload.errors, loginLoading: action.payload.loading };
        }
        default:
            return state;
    }
};

export default AuthenticateReducer;
