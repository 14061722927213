const ZERO = 0;
const ONE = 1;
const TWO = 2;
const EIGHT = 8;
const FOUR = 4;
const FOURTY = 40;
const ONETHOUSAND_24 = 1024;
const SIX = 6;
const SIXTEEN = 16;
const TWELVE = 12;
const TWENTY = 20;
const TWENTY_FOUR = 24;
const HUNDRED = 100;
const THOUSAND = 1000;
const TEN = 10;
const COMPRESSION_PERCENTANGE = 0.6;
const EFormWsDelayTime = 20;
const RELOAD_INTERVAL_MS = 0;
export {
    ZERO,
    ONE,
    TWO,
    TEN,
    TWENTY_FOUR,
    TWENTY,
    SIXTEEN,
    TWELVE,
    EIGHT,
    FOUR,
    SIX,
    HUNDRED,
    THOUSAND,
    COMPRESSION_PERCENTANGE,
    EFormWsDelayTime,
    RELOAD_INTERVAL_MS,
    FOURTY,
    ONETHOUSAND_24
};
