import React from 'react';
import { connect } from 'react-redux';
import { getString, SCButton } from '@saleskey/frontend-shared';
import { SubmittedEFormsInternal } from '../SubmittedEFormsInternal/SubmittedEFormsInternal';
import { EFormDrawer } from '../EFormDrawer/EFormDrawer';
import { eFormDrawerAction } from '../../store/rootActions';
import { ISubmittedEFormsProp, enumEFormModes } from '../../interfaces/IEForm';
import './SubmittedEForms.scss';
import { AclPermission } from '../../components/AclPermission/AclPermission';

interface ISubmittedEFormsState {
    eformId: string | number;
    isSubmissionView: boolean;
    submissionId: string | number;
    formName:string;
}
class SubmittedEFormsBaseContainer extends React.Component<ISubmittedEFormsProp, ISubmittedEFormsState> {
    /**
     *When component mounts
     *call the addNewEFormSidebarButton dispatcher
     *which will make the EFormDrawer visible
     */
    constructor(props: ISubmittedEFormsProp) {
        super(props);
        const { match } = this.props;
        const { eformId } = match.params;
        this.state = {
            eformId,
            formName:'',
            isSubmissionView: false,
            submissionId: ''
        };
        this.showEFormDrawer = this.showEFormDrawer.bind(this);
        this.viewSubmission = this.viewSubmission.bind(this);
    }

    /**
     *When component closes
     *call the addNewEFormSidebarButton dispatcher
     *which will make the EFormDrawer hide
     */
    componentWillUnmount() {
        const { eFormDrawerDispatch } = this.props;
        eFormDrawerDispatch(false);
    }

    showEFormDrawer() {
        const { eFormDrawerDispatch, eFormDrawerState } = this.props;
        this.setState({
            isSubmissionView: false,
        });
        eFormDrawerDispatch(!eFormDrawerState);
    }

    viewSubmission(id: string | number, name:string) {
        const { eFormDrawerDispatch, eFormDrawerState } = this.props;
        this.setState({
            formName: name,
            isSubmissionView: true,
            submissionId: id
        });
        eFormDrawerDispatch(!eFormDrawerState);
    }

    render() {
        const { eformId, isSubmissionView, submissionId, formName } = this.state;
        return (
            <React.Fragment>
                {eformId && (
                    <div className="submittedEFormsContainer">
                        <AclPermission feature="eform" subfeature="submissions" action="view" propertytype="element">
                            <SubmittedEFormsInternal eformId={eformId} viewSubmission={this.viewSubmission} />
                        </AclPermission>
                        <AclPermission feature="eform" subfeature="config" action="view" propertytype="element">
                            <div className="editEForm">
                                <SCButton text="Edit E-Form" onClick={this.showEFormDrawer} type="primary" />
                            </div>
                        </AclPermission>
                        {!isSubmissionView && (
                            <EFormDrawer title="Edit E-Form" mode={enumEFormModes.EDIT} eformId={eformId} name={formName} />
                        )}
                        {isSubmissionView && (
                            <EFormDrawer
                              title="Submitted E-Form"
                              dontShowRightSpace={true}
                              enableDownload
                              mode={enumEFormModes.VIEW}
                              eformId={eformId}
                              submissionId={submissionId}
                              name={formName}
                            />
                        )}
                    </div>
                )}
                {!eformId && (
                    <div className="submittedEFormsContainer">
                        <div className="eformIdError">{getString('E-Form id not found')}</div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export const stateToProps = state => {
    return {
        eFormDrawerState: state.eform.eFormDrawerState,
    };
};

const dispatchers = dispatch => {
    return {
        eFormDrawerDispatch: isOpen => dispatch(eFormDrawerAction(isOpen)),
    };
};
const SubmittedEFormsContainer = connect(stateToProps, dispatchers)(SubmittedEFormsBaseContainer);
export { SubmittedEFormsContainer, SubmittedEFormsBaseContainer };
