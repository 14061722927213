import React from 'react';
import { Typography } from 'antd';
import IGreeting from '../../interfaces/IGreeting';
import './Greeting.scss';

const Greeting = (props: IGreeting) => {
    const { text } = props;
    const { Text } = Typography;
    return <Text strong>{text}</Text>;
};
export default Greeting;
