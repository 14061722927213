import { IAuthenticateAction as IActionProp } from '../../interfaces/IAuthenticateReducer';
import * as actions from '../../utils/actions';
import { IEFormReducerProp } from '../../interfaces/IEForm';
/**
 * Initial state for the Document Reducer
 */
const initialState: IEFormReducerProp = {};

/**
 * @remarks
 * handles all the eform api reducers and update the store
 * @param state : type of `{IEFormReducerProp}`
 * @param action : type of `{IActionProp}`
 * @return state : redux store with updated state values
 */
const EFormReducer = (state = initialState, action: IActionProp) => {
    switch (action.type) {
        case actions.LIST_EFORMS:
            if (action.payload.loading) {
                return { ...state, loadingEFormsList: action.payload.loading };
            }
            if (action.payload.status) {
                return {
                    ...state,
                    errorEFormsList: undefined,
                    listEForms: action.payload.data,
                    listEFormsPagination: action.payload.pagination,
                    loadingEFormsList: action.payload.loading,
                    reloadRequired: false,
                };
            }
            return {
                ...state,
                errorEFormsList: action.payload.errors,
                loadingEFormsList: action.payload.loading,
            };
        case actions.CREATE_OR_UDPATE_EFORM:
            if (action.payload.loading) {
                return {
                    ...state,
                    savingEForm: action.payload.loading,
                };
            }
            if (action.payload.status) {
                return {
                    ...state,
                    createdEForm: action.payload.createdEForm,
                    errorCreateEForm: undefined,
                    reloadRequired: true,
                    savingEForm: action.payload.loading,
                };
            }
            return {
                ...state,
                createdEForm: undefined,
                errorCreateEForm: action.payload.errors,
                reloadRequired: false,
                savingEForm: action.payload.loading,
            };
        case actions.RESET_EFORM_STORE:
            return {
                ...state,
                createdEForm: undefined,
                errorCreateEForm: undefined,
                savingEForm: false,
            };
        case actions.FETCH_EFORM:
            if (action.payload.loading) {
                return { ...state, fetchingEForm: action.payload.loading };
            }
            if (action.payload.status) {
                return {
                    ...state,
                    errorFetchEForm: undefined,
                    fetchingEForm: action.payload.loading,
                    successFetchEForm: action.payload.successFetchEForm,
                    successFetchEFormValues: action.payload.successFetchEFormValues,
                };
            }
            return {
                ...state,
                errorFetchEForm: action.payload.errors,
                fetchingEForm: action.payload.loading,
                successFetchEFormValues: {},
            };
        case actions.SUBMITTED_EFORMS:
            if (action.payload.loading) {
                return { ...state, loadingSubmissionsList: action.payload.loading };
            }
            if (action.payload.status) {
                return {
                    ...state,
                    errorSubmissionsList: undefined,
                    loadingSubmissionsList: action.payload.loading,
                    submissionsPagination: action.payload.pagination,
                    submittedFormsList: action.payload.submittedFormsList,
                };
            }
            return {
                ...state,
                errorSubmissionsList: action.payload.errors,
                loadingSubmissionsList: action.payload.loading,
            };
        case actions.ARCHIVE_EFORM:
            if (action.payload.loading) {
                return { ...state, archivingEForm: action.payload.loading, successArchiveEForm: false };
            }
            if (action.payload.status) {
                return {
                    ...state,
                    archivingEForm: action.payload.loading,
                    errorArchiveEForm: undefined,
                    successArchiveEForm: action.payload.successArchiveEForm,
                };
            }
            return {
                ...state,
                archivingEForm: action.payload.loading,
                errorArchiveEForm: action.payload.errors,
            };
        /**
         * @remarks
         * Holds the visible | hidden state for the eform drawer
         * Eform drawer holds the creation, updation or preview of E-Form based on different mode.
         */
        case actions.EFORM_DRAWER:
            return {
                ...state,
                eFormDrawerState: action.payload.isOpen,
            };
        default:
            return state;
    }
};

export default EFormReducer;
