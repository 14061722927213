import { enumInputTypes } from '@saleskey/frontend-shared/src/interfaces/IEForm';
import {
    CEFormSchema,
    CDateSchema,
    CInputSchema,
    CInputNumberSchema,
    CRadioSchema,
    CSelectSchema,
    CTextAreaSchema,
    CMultiSelectSchema,
    CCheckBoxSchema,
    CUploadSchema,
    sizesAllowed,
    optionsParent,
    CSectionHeaderchema,
    typesAllowed,
    CTextSchema,
    CTableSchema,
    CPhoneNumber,
    CMaskedInput,
    CCheckBoxItemSchema,
} from '../constants/EForm';
// import { formJson } from '../fixtures/formData';

const validateItemOptions = baseType => {
    if (!baseType.fetchOptions && (!baseType.options || !baseType.options.length)) {
        return 'options should contain atleast one item';
    }
    const requiredkeys = ['value', 'text'];
    let error = '';
    baseType.options.forEach(option => {
        const keys = Object.keys(option);
        if (JSON.stringify(keys) !== JSON.stringify(requiredkeys)) {
            error = 'value and text property are required for options';
        }
    });
    if (error.length) {
        return error;
    }
    return null;
};
const validatePropertyType = (baseType, targetType, key) => {
    const { type, value } = targetType;
    let error;
    switch (type) {
        case 'string':
        case 'boolean':
        case 'number':
            if (baseType[key] && (typeof baseType[key]).toString() !== type) {
                return `${key} should be of ${type} type.`;
            }
            break;
        case 'enumInputTypes':
            if (!typesAllowed.includes(baseType[key]) || baseType[key] !== value) {
                return `valid ${type} is required of ${value} type.`;
            }
            if (optionsParent.includes(value)) {
                // To be kept : future use
                // let targetOptionJson;
                // if (value === 'radiogroup') {
                //     targetOptionJson = CRadioItemSchema
                // } else if (value === 'select') {
                //     targetOptionJson = CSelectItemSchema
                // } else if (value === 'multiselect') {
                //     targetOptionJson = CSelectItemSchema
                // } else if (value === 'checkboxgroup') {
                //     targetOptionJson = CCheckBoxItemSchema
                // } else {
                //     error = `valid property of type is required ${value}`
                //     return;
                // }
                error = validateItemOptions(baseType);
            }
            if (error && error.length) {
                return error;
            }
            break;
        case 'enumSize':
            if (baseType[key] && (!sizesAllowed.includes(baseType[key]) || !value.includes(baseType[key]))) {
                return `valid ${type} can be of ${value} type.`;
            }
            break;
        default:
    }
    return null;
};

const validKeys = (baseJson, targetJson) => {
    const errors: Array<string> = [];
    let result = {
        errors,
        status: false,
    };

    if (!(baseJson instanceof Object) || !(targetJson instanceof Object)) {
        result = {
            errors: ['valid format required'],
            status: false,
        };
        return result;
    }

    const baseJsonKeys = Object.keys(baseJson);
    const targetJsonKeys = Object.keys(targetJson);

    /**
     * core method for checking the type
     */
    targetJsonKeys.forEach(key => {
        if (!baseJsonKeys.includes(key) && targetJson[key].required) {
            errors.push(`${key} is required`);
        } else {
            if (targetJson[key].minLength && !baseJson[key].length) {
                errors.push(`${key} is required of minimum length ${targetJson[key].minLength}`);
            }
            const typeError = validatePropertyType(baseJson, targetJson[key], key);
            if (typeError) {
                errors.push(typeError);
            }
        }
    });

    if (errors.length) {
        result = {
            errors,
            status: false,
        };
    } else {
        result.status = true;
        result.errors = [];
    }
    return result;
};

/**
 * validate the eform schem
 */

const validateEFormItem = itemJson => {
    const errors: Array<string> = [];
    const result = {
        errors,
        status: false,
    };
    let targetSchema;
    /**
     * Check if the form is an object
     */
    if (!(itemJson instanceof Object)) {
        result.errors.push('valid format required');
        return result;
    }

    if (!itemJson.type) {
        result.errors.push('type: property is required');
        return result;
    }
    if (itemJson.type === enumInputTypes.INPUT) {
        targetSchema = CInputSchema;
    } else if (itemJson.type === enumInputTypes.INPUT_NUMBER) {
        targetSchema = CInputNumberSchema;
    } else if (itemJson.type === enumInputTypes.TEXTAREA) {
        targetSchema = CTextAreaSchema;
    } else if (itemJson.type === enumInputTypes.RADIO_GROUP) {
        targetSchema = CRadioSchema;
    } else if (itemJson.type === enumInputTypes.SELECT) {
        targetSchema = CSelectSchema;
    } else if (itemJson.type === enumInputTypes.MULTI_SELECT) {
        targetSchema = CMultiSelectSchema;
    } else if (itemJson.type === enumInputTypes.CHECKBOX_GROUP) {
        targetSchema = CCheckBoxSchema;
    } else if (itemJson.type === enumInputTypes.DATE) {
        targetSchema = CDateSchema;
    } else if (itemJson.type === enumInputTypes.CHECKBOX) {
        targetSchema = CCheckBoxItemSchema;
    }else if (itemJson.type === enumInputTypes.UPLOAD) {
        targetSchema = CUploadSchema;
    } else if (itemJson.type === enumInputTypes.SECTION_HEADER) {
        targetSchema = CSectionHeaderchema;
    } else if (itemJson.type === enumInputTypes.TEXT) {
        targetSchema = CTextSchema;
    } else if (itemJson.type === enumInputTypes.TABLE) {
        targetSchema = CTableSchema;
    } else if (itemJson.type === enumInputTypes.PHONENUMBER) {
        targetSchema = CPhoneNumber;
    } else if (itemJson.type === enumInputTypes.MASKEDINPUT) {
        targetSchema = CMaskedInput;
    }else {
        result.errors.push(`valid enum type is required`);
        return result;
    }

    const isKeysValid = validKeys(itemJson, targetSchema);

    /**
     * check for valid keys present or not
     */

    return isKeysValid;
};
const validateEForm = formJson => {
    /**
     * Check if the form is an object
     */
    if (!(formJson instanceof Object)) {
        return { errors: ['valid json format required'], status: false };
    }

    let isKeysValid = validKeys(formJson, CEFormSchema);

    /**
     * check for valid keys present or not
     */

    if (isKeysValid.status) {
        formJson.metadata.forEach(property => {
            const error = validateEFormItem(property);
            if (!error.status) {
                isKeysValid = error;
            }
        });
    }

    return isKeysValid;
};

export { validateEForm };
