import { getString } from '@saleskey/frontend-shared';
import { Layout, Row, Col, message, Alert } from 'antd';
import { connect } from 'react-redux';
import React from 'react';
import { UploadCard } from '../../components/UploadCard/UploadCard';
import { EIGHT, SIX, TWENTY_FOUR, ZERO } from '../../constants/Common';
import * as uploadTypes from '../../utils/uploadTypeUrl';
import './UploadDocumentCategoryTypeAndValue.scss';
import { IUploadApiActionProp } from '../../interfaces/IUploadApiAction';
import { documentCategoryTypeOrValueAction } from '../../store/rootActions';
import { UploadDocumentCategoryProps } from '../../interfaces/IDocumentUpload';

const { Content } = Layout;

function UploadDocumentCategoryTypeAndValueBase(props: UploadDocumentCategoryProps) {
    const { uploading, uploadErrorMessage, remarks, uploadedSuccess, summary } = props;
    const { created, updated } = summary || { created: ZERO, updated: ZERO };

    /**
     * @remarks
     * callback when file uploading starts
     */
    /* eslint class-methods-use-this: 0 */
    const uploadStarted = () => {
        // console.log('---files started uploading')
    };

    /**
     * @remarks
     * callback when file uploading fails
     */
    /* eslint class-methods-use-this: 0 */
    const uploadFailed = () => {
        const errmsg = 'Error in Uploading files';
        message.error(errmsg);
    };

    /**
     * @remarks
     * callback when file uploading successful
     * call the `{uploadApiDispatcher}` with upload type and required params
     */

    const uploadCategoryTypeSuccessful = (files, uploadType) => {
        const { documentCategoryTypeOrValueDispatcher } = props;
        const params: IUploadApiActionProp = {
            files,
            uploadType,
        };
        documentCategoryTypeOrValueDispatcher(params);
        /**
         * Only single file upload for the admin uploads
         */
    };

    return (
        <>
            <Layout className="document-upload-layout">
                <Content className="document-upload-content">
                    {!uploading && !uploadedSuccess && uploadErrorMessage && (
                        <React.Fragment>
                            <Alert message={uploadErrorMessage} type="error" showIcon banner closable />
                            <Row type="flex" justify="center" align="middle" className="error-container">
                                <Col xs={TWENTY_FOUR}>
                                    {!uploading &&
                                        remarks instanceof Array &&
                                        remarks.length > ZERO &&
                                        remarks.map(remark => (
                                            <h4 className="errorText" key={remark}>
                                                {remark}
                                            </h4>
                                        ))}
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
                    {!uploading && uploadedSuccess && (
                        <Alert
                            message={`File uploaded successfully - ${created} created, ${updated} updated`}
                            type="success"
                            showIcon
                            banner
                            closable
                        />
                    )}
                    <Row className="document-upload-row">
                        <Col xs={TWENTY_FOUR} sm={TWENTY_FOUR} md={TWENTY_FOUR} lg={EIGHT} xl={SIX}>
                            <UploadCard
                                title={getString('Upload document category type')}
                                subtitle={getString('This will upload the document category type')}
                                uploadType={uploadTypes.UPLOAD_DOCUMENT_CATEGORY_TYPE}
                                uploadStarted={uploadStarted}
                                uploadSuccessful={uploadCategoryTypeSuccessful}
                                uploadFailed={uploadFailed}
                            />
                        </Col>
                        <Col xs={TWENTY_FOUR} sm={TWENTY_FOUR} md={TWENTY_FOUR} lg={EIGHT} xl={SIX}>
                            <UploadCard
                                title={getString('Upload document category value')}
                                subtitle={getString('This will upload the document category value')}
                                uploadType={uploadTypes.UPLOAD_DOCUMENT_CATEGORY_VALUE}
                                uploadStarted={uploadStarted}
                                uploadSuccessful={uploadCategoryTypeSuccessful}
                                uploadFailed={uploadFailed}
                            />
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </>
    );
}

export const stateToProps = state => {
    return {
        remarks: state.documentCategoryTypeOrValue.remarks,
        summary: state.documentCategoryTypeOrValue.summary,
        uploadErrorMessage: state.documentCategoryTypeOrValue.uploadErrorMessage,
        uploadedSuccess: state.documentCategoryTypeOrValue.uploadedSuccess,
        uploading: state.documentCategoryTypeOrValue.uploading,
    };
};

const dispatchers = dispatch => {
    return {
        documentCategoryTypeOrValueDispatcher: params => dispatch(documentCategoryTypeOrValueAction(params)),
    };
};

const UploadDocumentCategoryTypeAndValue = connect(stateToProps, dispatchers)(UploadDocumentCategoryTypeAndValueBase);

export { UploadDocumentCategoryTypeAndValue, UploadDocumentCategoryTypeAndValueBase };
