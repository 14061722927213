import React from 'react';
import { Card, Icon } from 'antd';
import { IEFormItemProp } from '../../interfaces/IEForm';
import { tabIndex } from '../../constants/listFormItem';
import './ListEFormItem.scss';
import { ONE } from '../../constants/numberConstants';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { ThumbnailByType } from '../ThumbnailByType/ThumbnailByType';
import { getString } from '../../utils/localization';

/**
 * @remarks
 * EForm list item which can generate certain actions
 * @param props : type of `{IEFormItemProp}`
 * @return EForm list item
 */
const ListEFormItem = (props: IEFormItemProp) => {
    const {
        id,
        isHorizontalView,
        name,
        header,
        description,
        thumbnailUrl,
        archiveFormItem,
        viewSubmissions,
        viewDetailSubmission,
        percent,
    } = props;
    /**
     * @remarks
     * callback handler to archive eform
     */
    const archiveFormItemHandler = eformId => {
        archiveFormItem(eformId);
    };

    /**
     * @remarks
     * callback handler to view detailed submission
     */
    const viewDetailSubmissionHandler = eformId => {
        if (viewDetailSubmission) {
            viewDetailSubmission(eformId);
        }
    };

    return (
        <React.Fragment>
            {!isHorizontalView && (
                <Card className="listEFormItem" hoverable>
                    <div className="topRow">
                        <div className="leftRow">
                            <h3>{name}</h3>
                            <p>{header}</p>
                            <p>{description}</p>
                        </div>
                        <div className="rightRow">
                            <ThumbnailByType
                              fileExtension="png"
                              fileType="png"
                              fileUrl={thumbnailUrl}
                              isUrlInvalid={!thumbnailUrl}
                            />
                        </div>
                    </div>
                    <div className="bottomRow">
                        <div
                          className="submissionRow"
                          tabIndex={tabIndex}
                          onClick={() => viewSubmissions(id)}
                          onKeyUp={() => viewSubmissions(id)}
                          role="button"
                        >
                            {getString("View submissions")}
                            {/* : <div className="submissionView"> {submissions}</div> */}
                        </div>
                        <div className="archiveIcon">
                            <Icon
                              type="delete"
                              theme="filled"
                              viewBox="0 0 24 24"
                              onClick={() => archiveFormItemHandler(id)}
                            />
                        </div>
                    </div>
                </Card>
            )}
            {isHorizontalView && (
                <div className="listEFormItemHorizontal">
                    <div
                      className="horizontalRow"
                      onClick={() => viewDetailSubmissionHandler(id)}
                      onKeyUp={() => viewDetailSubmissionHandler(id)}
                      role="button"
                      tabIndex={ONE}
                    >
                        <div className="leftRow">
                            <h3>{header}</h3>
                            <p>{description}</p>
                        </div>
                        <div className="imageSection">
                            <ProgressBar type="circle" percent={percent || 0} />
                            <ThumbnailByType
                              fileExtension="png"
                              fileType="png"
                              fileUrl={thumbnailUrl}
                              isUrlInvalid={!thumbnailUrl}
                            />
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export { ListEFormItem };
