import React from 'react';
import { Tag, Input, Tooltip, Icon } from 'antd';
import { ITags } from '../../interfaces/ITags';

import './Tags.scss';
import { ZERO, TWENTY } from '../../constants/numberConstants';

class Tags extends React.Component<ITags, any> {
    private input: React.RefObject<any>;

    constructor(props: ITags) {
        super(props);

        const { tags, size, newTagLabel } = this.props;
        this.state = {
            inputValue: '',
            inputVisible: false,
            newTagLabel,
            size,
            tags: tags || [],
        };
        this.input = React.createRef<HTMLInputElement>();
    }

    componentDidUpdate() {
        if (this.input && this.input.current) {
            this.input.current.focus();
        }
    }

    handleClose = removedTag => {
        let { tags } = this.state;
        tags = tags.filter(tag => tag !== removedTag);
        this.setState({ tags }, this.updateTagsHandler);
    };

    handleInputChange = e => {
        this.setState({ inputValue: e.target.value });
    };

    handleInputConfirm = () => {
        const { inputValue } = this.state;
        let { tags } = this.state;
        if (inputValue && !tags.includes(inputValue)) {
            tags = [...tags, inputValue];
        }
        this.setState(
            {
                inputValue: '',
                inputVisible: false,
                tags,
            },
            this.updateTagsHandler,
        );
    };

    showInput = () => {
        this.setState({ inputVisible: true });
    };

    updateTagsHandler() {
        const { updateTags } = this.props;
        const { tags } = this.state;
        updateTags(tags);
    }

    render() {
        const { tags, inputVisible, inputValue, size, newTagLabel } = this.state;
        return (
            <div className="tags-container">
                {tags.map(tag => {
                    const isLongTag = tag.length > TWENTY;

                    const tagElem = (
                        <Tag className="edit-tag" key={tag} closable onClose={() => this.handleClose(tag)}>
                            {isLongTag ? `${tag.slice(ZERO, TWENTY)}...` : tag}
                        </Tag>
                    );
                    return isLongTag ? (
                        <Tooltip title={tag} key={tag}>
                            {tagElem}
                        </Tooltip>
                    ) : (
                        tagElem
                    );
                })}
                {inputVisible && (
                    <Input
                      ref={this.input}
                      type="text"
                      size={size}
                      className="tag-input"
                      value={inputValue}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputConfirm}
                      onPressEnter={this.handleInputConfirm}
                    />
                )}
                {!inputVisible && (
                    <Tag className="site-tag-plus" onClick={this.showInput}>
                        <Icon type="plus" className="plus-sign" />
                        {newTagLabel}
                    </Tag>
                )}
            </div>
        );
    }
}

export { Tags };
