import { combineReducers } from 'redux';
import AuthenticateReducer from './Authentication/Authentication.reducer';
import ApplicationReducer from './Application/Application.reducer';
import UploadApiReducer from './UploadApi/UploadApi.reducer';
import DocumentReducer from './Document/Document.reducer';
import EFormReducer from './EForm/EForm.reducer';
import DocumentCategoryTypeOrValueReducer from './DocumentUpload/UploadDocumentCategoryTypeOrValue.reducer';

const RootReducer = combineReducers({
    application: ApplicationReducer,
    auth: AuthenticateReducer,
    document: DocumentReducer,
    documentCategoryTypeOrValue: DocumentCategoryTypeOrValueReducer,
    eform: EFormReducer,
    uploadApi: UploadApiReducer,
});

export default RootReducer;
