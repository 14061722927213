import { IAuthenticateAction } from '../../interfaces/IAuthenticateReducer';
import * as actions from '../../utils/actions';
import { IUploadApiReducer } from '../../interfaces/IUploadApiAction';

const initialState: IUploadApiReducer = {
    uploading: false,
};
const UploadApiReducer = (state = initialState, action: IAuthenticateAction) => {
    switch (action.type) {
        case actions.UPLOAD_API:
            if (action.payload.loading) {
                return { ...state, uploading: action.payload.loading };
            }
            if (action.payload.status) {
                return {
                    ...state,
                    remarks: [],
                    summary: action.payload.summary,
                    uploadedSuccess: action.payload.uploadedSuccess,
                    uploading: action.payload.loading,
                };
            }
            return {
                ...state,
                remarks: action.payload.remarks,
                uploadErrorMessage: action.payload.errors,
                uploadedSuccess: action.payload.uploadedSuccess,
                uploading: action.payload.loading,
            };
        case actions.UPLOAD_API_RESET:
            return {
                ...state,
                remarks: [],
                summary: undefined,
                uploadErrorMessage: undefined,
                uploadedSuccess: false,
                uploading: false,
            };
        default:
            return state;
    }
};

export default UploadApiReducer;
