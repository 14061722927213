import React from 'react';
import { Spin, Icon } from 'antd';
import ILoader from '../../interfaces/ILoader';

const Loader = (props: ILoader) => {
    const { fontSize } = props;
    const antIcon = <Icon type="loading" style={{ fontSize }} spin />;
    return <Spin indicator={antIcon} />;
};
export default Loader;
