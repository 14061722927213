import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAuthenticatedState } from '../../store/Protected/Protected.state';

const ProtectedBaseRoute = ({ authenticated, component: Component, ...rest }: any) => {
    return (
        <Route
          {...rest}
          render={props => {
                if (authenticated) {
                    return <Component {...props} />;
                }
                return (
                    <Redirect
                      to={{
                            pathname: '/',
                            state: {
                                from: props.location,
                            },
                        }}
                    />
                );
            }}
        />
    );
};

const ProtectedRoute = connect(state => ({ authenticated: getAuthenticatedState(state) }))(ProtectedBaseRoute);

export { ProtectedRoute, ProtectedBaseRoute };
