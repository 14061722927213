import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { SCSubmittedEForms, SCPagination, SCLoader } from '@saleskey/frontend-shared';
import { IEFormApiParam, ISubmittedEFormsInternalProp, ISubmittedEFormsInternalState } from '../../interfaces/IEForm';
import { submittedEFormsAction } from '../../store/rootActions';
import { constants } from '../../constants/ListDocuments';
import './SubmittedEFormsInternal.scss';
/**
 * @remarks
 * Parent container for list documents component
 * @param props: type of `{ISubmittedEFormsInternalProp}`
 * @param state: type of `{ISubmittedEFormsInternalState}`
 * @return List documents with pagination component
 */
class SubmittedEFormsInternalBaseContainer extends React.Component<
    ISubmittedEFormsInternalProp,
    ISubmittedEFormsInternalState
> {
    /**
     *
     * @param props : type of `{ISubmittedEFormsInternalProp}`
     */
    constructor(props: ISubmittedEFormsInternalProp) {
        super(props);
        this.state = {
            currentPage: constants.DEFAULT_CURRENTPAGE_NUMBER,
            pageSize: constants.DEFAULT_PAGE_SIZE,
        };
        this.fetchSubmittedEForms = this.fetchSubmittedEForms.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSizeChange = this.onSizeChange.bind(this);
    }

    /**
     * fetch the eforms once the component is loaded with default states
     */
    componentDidMount() {
        const { currentPage, pageSize } = this.state;
        this.fetchSubmittedEForms(currentPage, pageSize);
    }

    componentDidUpdate(prevProps, prevState) {
        const { currentPage: currentPagePrev, pageSize: pageSizePrev } = prevState;
        const { currentPage, pageSize } = this.state;

        if (currentPagePrev !== currentPage || pageSizePrev !== pageSize) {
            this.fetchSubmittedEForms(currentPage, pageSize);
        }
    }

    /**
     *
     * @param nextPage : next list of documents to be fetched
     * @param pageSize : number of documents in a page
     */
    private onPageChange(nextPage, pageSize): void {
        const { currentPage } = this.state;
        if (nextPage !== currentPage) {
            this.setState({ currentPage: nextPage, pageSize });
        }
    }

    /**
     *
     * @param currentPage : current list of documents to be fetched
     * @param newPageSize : new number of documents to be shown in a page
     */
    private onSizeChange(currentPage, newPageSize): void {
        const { pageSize } = this.state;
        if (newPageSize !== pageSize) {
            this.setState({ currentPage, pageSize: newPageSize });
        }
    }

    /**
     * @remarks
     * method to get the list the submissions for a eformid passed as prop
     * calls the dispatcher function `{submittedEFormsDispatch}` to trigger the action
     */
    private fetchSubmittedEForms(currentPage, pageSize): void {
        const { submittedEFormsDispatch, eformId } = this.props;
        const params: IEFormApiParam = {
            limit: pageSize,
            skip: currentPage > 1 ? (currentPage - 1) * pageSize : 0,
        };
        submittedEFormsDispatch(params, eformId);
    }

    /**
     * @remarks
     */

    /**
     * @returns React Nodes
     */
    public render(): ReactNode {
        const {
            loadingSubmissionsList,
            submittedFormsList,
            errorSubmissionsList,
            submissionsPagination,
            viewSubmission,
        } = this.props;
        const { currentPage, pageSize } = this.state;
        const { total } = submissionsPagination || { total: 0 };
        return (
            <div className="submittedEFormsInternal">
                <h1 className="submittedEFormsTitle">Submissions: {submittedFormsList && submittedFormsList[0] && submittedFormsList[0].header}</h1>
                {loadingSubmissionsList && (
                    <div className="submittedEFormsLoader">
                        <SCLoader fontSize={24} />
                    </div>
                )}
                {!loadingSubmissionsList && errorSubmissionsList && (
                    <div className="submittedEFormsLoader submittedEFormError">{errorSubmissionsList}</div>
                )}
                {!loadingSubmissionsList && submittedFormsList && (
                    <React.Fragment>
                        <div className="submittedEFormsDiv">
                            <SCSubmittedEForms submittedEforms={submittedFormsList} viewSubmission={viewSubmission} />
                        </div>
                        <div className="listPagination">
                            <SCPagination
                              onPageChange={this.onPageChange}
                              onSizeChange={this.onSizeChange}
                              total={total}
                              currentPage={currentPage}
                              pageSize={pageSize}
                            />
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}
export const stateToProps = state => {
    return {
        errorSubmissionsList: state.eform.errorSubmissionsList,
        loadingSubmissionsList: state.eform.loadingSubmissionsList,
        submissionsPagination: state.eform.submissionsPagination,
        submittedFormsList: state.eform.submittedFormsList,
    };
};
const dispatchers = dispatch => {
    return {
        submittedEFormsDispatch: (params: IEFormApiParam, eformId: string) =>
            dispatch(submittedEFormsAction(params, eformId)),
    };
};
const SubmittedEFormsInternal = connect(stateToProps, dispatchers)(SubmittedEFormsInternalBaseContainer);
export { SubmittedEFormsInternal, SubmittedEFormsInternalBaseContainer };
