/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-extraneous-dependencies */
import * as React from 'react';
import axios from 'axios';
import {
    Form,
    Input,
    Button,
    Typography,
    Icon,
    Select,
    InputNumber,
    Radio,
    Checkbox,
    DatePicker,
    message,
    Steps,
    Tooltip,
    Divider,
    Row,
    Col,
    Table,
    Rate,
    Modal,
} from 'antd';
import { isMobile } from 'react-device-detect';
import EditableTable from './EformTableView';
import { FormComponentProps } from 'antd/lib/form/Form';
import CanvasDraw from 'react-canvas-draw';
import { wsConnection, socketpubsub } from '@adhityan/gc-realtime-sdk';
import { isEqual, isArray, keyBy, cloneDeep } from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import moment, { relativeTimeThreshold } from 'moment';
import ReactRating from 'react-rating';
import MaskedInput from 'react-maskedinput';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags';
import 'react-phone-number-input/style.css'
import IWindow from '../../interfaces/IWindow';
import PhoneNumber from '../../containers/PhoneNumber/PhoneNumber';
import OtpInput from '../OtpInput/otpInput';
import CashOnDeliveryModal from './CashOnDeliveryModal';
import {
    IEFormRootProps,
    enumInputTypes,
    IFormComponent,
    IRadioGroupSchema,
    ITextAreaSchema,
    ICheckBoxSchema,
    ISelectSchema,
    IInputNumberSchema,
    enumEFormModes,
    IOptionItem,
    IUploadSchema,
    IEformVersionId,
    IUploadedFileDescriptor,
    PaymentOptions,
    CurrentStepNumbers,
    UserTypeValues,
    productEformConstants,
    IFormValues,
    IFileUploaded,
    enumFormStatus,
    IMaskedInputSchema,
    IPhoneNumberSchema
} from '../../interfaces/IEForm';
import './EFormRoot.scss';
import Uploader from '../../containers/Uploader/Uploader';
import {
    getDateTypeFields,
    getUploadTypeFields,
    changeToFileId,
    changeMomentDateToDate,
    changeDateToMomentDate,
    removeUnwantedFields,
    changeArrayDataToKeyValue,
    getProductLandingPageData,
} from '../../utils/eFormUtils';
import { ThumbnailByType } from '../ThumbnailByType/ThumbnailByType';
import getTimeDifferenceInMilliseconds from '../../utils/timeDifferenceMilliseconds';
import { ISuccessfulUploadedFile } from '../../interfaces/IUploader';
import { enumDocumentStatus } from '../../interfaces/IDocument';
import { FETCH_UPLOADED_DATA, ZERO, THOUSAND, TEN, ONE, EFormWsDelayTime, UserType } from '../../constants';
import { ReviewComments } from '../ReviewComments/ReviewComments';
import { NotesModal } from '../NotesModal/NotesModal';
import { getString } from '../../utils/localization';
import { RejectionReasonModal } from '../..';

const GuestUser = require('./../../images/hacker.svg');
const LoginUser = require('./../../images/verify.svg');
const Envelope = require('./../../images/envelope.svg');
const Call = require('./../../images/phone-call.svg');
const PlusIcon = require('./../../images/plusIcon.svg');
const CreditCard = require('./../../images/creditCard.svg');
const BankAccount = require('./../../images/bankAccount.svg');
const COD = require('./../../images/COD.svg');
const ProductBack = require('./../../images/product-back.svg');

const { Step } = Steps;

const { Text, Title, Paragraph } = Typography;

const customWindow: IWindow = window;

const dateFormat = 'DD/MM/YYYY';

const { Option } = Select;

const inputPattern = '^[a-zA-Z0-9 ,.-]*$';

let connectionObj;
let emitter;
let formValueChange: BehaviorSubject<{ [name: string]: string }>;
let saveableCanvas;

function initWebsockets(formCode, eformVersionId) {
    console.log('in socket connection init method')
    console.log('config values', customWindow.config)
    connectionObj = wsConnection
        .eformVersionId(eformVersionId)
        .rootWsUrl(customWindow.config.wsUrl)
        .volatile(true)
        .formCode(formCode)
        .build();
    emitter = new socketpubsub.Emitter<{ subject: string; data: Object }>(connectionObj);
    console.log('socket conneted object', emitter)
    formValueChange = new BehaviorSubject<any>({});

    formValueChange
        .pipe(
            debounceTime(EFormWsDelayTime),
            distinctUntilChanged((prev: any, current: any) => {
                return isEqual(prev, current);
            }),
        )
        .subscribe(values => {
            console.log('Sending: form data for realtime sync', values)
            emitter.publish({
                data: values,
                subject: 'eform',
            });
        });
}

class EFormRoot extends React.Component<IEFormRootProps & FormComponentProps, any> {
    prevFieldsValue: string;

    initialTimeStamp: Date;

    currentTimeStamp: Date;

    closePropCalled: boolean;

    openEformEventCalled: boolean;

    timer: any;

    anyDocumentRejected: boolean;

    rejectedDocuments: Object;
    uploadedFileNames: Object = {};

    tableData:Object = {};
    tableErrors: string[] = [];

    formCode: string;

    eformVersionId: string | number;

    steps: any[] = [];

    constructor(props: IEFormRootProps & FormComponentProps) {
        super(props);
        const { code, eformVersionId } = this.props;
        this.getUploadedFileUrl = this.getUploadedFileUrl.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateFieldsManually = this.validateFieldsManually.bind(this);
        this.uploadFailed = this.uploadFailed.bind(this);
        this.uploadStarted = this.uploadStarted.bind(this);
        this.uploadSuccessful = this.uploadSuccessful.bind(this);
        this.checkAndUpdateValues = this.checkAndUpdateValues.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onCloseHandler = this.onCloseHandler.bind(this);
        this.onInputBlurHandler = this.onInputBlurHandler.bind(this);
        this.onTextAreaBlurHandler = this.onTextAreaBlurHandler.bind(this);
        this.prevFieldsValue = '{}';
        this.closePropCalled = false;
        this.openEformEventCalled = false;
        this.initialTimeStamp = new Date();
        this.currentTimeStamp = new Date();
        this.anyDocumentRejected = false;
        this.rejectedDocuments = {};
        this.formCode = code;
        this.eformVersionId = eformVersionId;
        this.onCanvasChange = this.onCanvasChange.bind(this);
        // this.enableCanvas = this.enableCanvas.bind(this);
        this.onCanvasClear = this.onCanvasClear.bind(this);
        this.saveCommentsMethod = this.saveCommentsMethod.bind(this);
        this.resolveCommentsMethod = this.resolveCommentsMethod.bind(this);
        this.setRejectedDocuments = this.setRejectedDocuments.bind(this);
        this.updateDependents = this.updateDependents.bind(this);
        this.rejectionReasonCloseHandler = this.rejectionReasonCloseHandler.bind(this)
        this.rejectionReasonSaveHandler = this.rejectionReasonSaveHandler.bind(this)
        this.storeTableData = this.storeTableData.bind(this)
        this.getTableData = this.getTableData.bind(this)
        this.handleTableErrors = this.handleTableErrors.bind(this)
        // this.state = {
        //     canvasEnabled: !props.agentLogin
        // }
        this.state = {
            currentStep: CurrentStepNumbers.One,
            productQuantity: 1,
            imageUrl: '',
            registrationValue: '',
            showOTPPage: false,
            showAddress: false,
            disablePhoneNext: true,
            phoneNumber: '',
            otpValue: '',
            disableOtpNext: true,
            showCashOnDeliveryModal: false,
            guestPhoneNumber: '',
            paymentOption: '',
            hideLoginPassword: false,
            guestDetails: {},
            showProductLandingPage: true,
            codOtp: false,
            showRejectionReasonModel: false,
            rejectingDocument:{},
            uploadedFileName:{},
            isModalVisible:false
        };

        this.getLabel = this.getLabel.bind(this);
        // this.state = {
        //     canvasEnabled: !props.agentLogin,
        // };
        this.disableCanvasScroll = this.disableCanvasScroll.bind(this);
        this.gotoNextPage = this.gotoNextPage.bind(this);
    }

    public gotoNextPage(){
        this.setState(prevState => ({ currentStep: prevState.currentStep + 1 }));
        emitter.publish({
            data: {
                action: 'step-change',
                step: this.state.currentStep + 1,
            },
            subject: 'eform',
        });
    }

    public componentWillMount() {
        initWebsockets(this.formCode, this.eformVersionId);
    }

    public disableCanvasScroll(e) {
        if (e.target && e.target.localName === 'canvas') {
            e.preventDefault();
        }
    }

    public componentDidMount(): void {
        const { agentLogin } = this.props;
        if (agentLogin) {
            document.body.addEventListener('touchstart', this.disableCanvasScroll, { passive: false });
            document.body.addEventListener('touchend', this.disableCanvasScroll, { passive: false });
            document.body.addEventListener('touchmove', this.disableCanvasScroll, { passive: false });
        }

        const {
            form,
            formJson,
            addEformFieldReviewCommentLiveHandler,
            resolveEformFieldReviewCommentLiveHandler,
            user,
        } = this.props;
        if (formJson.metadata.length) {
            this.checkAndUpdateValues();
            form.validateFields();
        }

        // this can emit anything that we send from gc realtime, please add your types here seperated by |
        emitter.subscribe(({ data }: { data: IUploadedFileDescriptor | any }) => {
            if (!data) {
                return;
            }
            if (data.action === 'canvas_draw' && saveableCanvas) {
                const { clientHeight, clientWidth } = saveableCanvas.canvas.grid;
                const scaleX = clientWidth / data.width;
                const scaleY = clientHeight / data.height;
                const scaleAvg = (scaleX + scaleY) / 2;

                saveableCanvas.simulateDrawingLines({
                    immediate: true,
                    lines: data.lines.map(line => ({
                        brushColor: line.brushColor,
                        brushRadius: line.brushRadius * scaleAvg,
                        points: line.points.map(p => ({
                            x: p.x * scaleX,
                            y: p.y * scaleY,
                        })),
                    })),
                });
                return;
            }

            if (data.action === 'canvas_clear' && saveableCanvas) {
                saveableCanvas.clear();
                return;
            }

            if (data.action === 'add_review_comment') {
                if (user && addEformFieldReviewCommentLiveHandler) {
                    addEformFieldReviewCommentLiveHandler(data.fieldName, data.comment, data.name);
                }

                return;
            }

            if (data.action === 'resolve_review_comment') {
                if (resolveEformFieldReviewCommentLiveHandler) {
                    resolveEformFieldReviewCommentLiveHandler(data.fieldName);
                }

                return;
            }

            if (data.action === 'step-change') {
                this.setState({
                    currentStep: data.step,
                });
            }

            const { downloadLink, fileId, fileType, attributeName, fileName } = data;
            if (data.action === FETCH_UPLOADED_DATA) {
                this.getUploadedFileUrl({
                    action: FETCH_UPLOADED_DATA,
                    attributeName,
                    dmsToken: this.props.dmsToken,
                    downloadLink,
                    emitToAll: false,
                    fileId,
                    fileType,
                    fileName
                });
                return;
            }
            console.log('Receiving: form data from realtime', data)
            // parsing date values with moment, ideally we should know the name of all fields that are date fields
            // so that we dont have to run it for each field
            Object.keys(data).forEach(key => {
                // 2020-09-17T12:10:24.779Z
                const dt = moment(data[key], 'YYYY-MM-DDTHH:mm:ss.sssZ', true);
                if (dt.isValid()) {
                    // eslint-disable-next-line no-param-reassign
                    data[key] = dt;
                }
            });
            const { serverTimestamp, ...others } = data;
            form.setFieldsValue(others);
        });
    }

    public async componentDidUpdate(prevProp: IEFormRootProps & FormComponentProps, prevState: any): Promise<any> {
        const {
            form,
            formJson,
            formMode,
            formValues,
            timeInterval,
            onOpen,
            clearCanvas,
            textDetectedData,
            extractDataFromFileMesage,
            optionsLoadingMessage
        } = this.props;

        const { setFieldsValue } = form;
        const {
            formJson: formJsonPrev,
            clearCanvas: prevClearCanvas,
            textDetectedData: prevTextDetectedData,
            formValues: formValuesPrev,
            extractDataFromFileMesage: extractDataFromFileMesagePrev,
            optionsLoadingMessage: optionsLoadingMessagePrev
        } = prevProp;

        const { FILLED, VIEW } = enumEFormModes;
        if (JSON.stringify(formJsonPrev) !== JSON.stringify(formJson) && formJson.metadata.length) {
            this.checkAndUpdateValues();
            form.validateFields();
            /**
             * make call to open event dispatcher only once in the lifecycle.
             */
            if (onOpen && !this.openEformEventCalled) {
                onOpen(formJson);
                this.openEformEventCalled = true;
            }
        }
        if(JSON.stringify(formValues) !== JSON.stringify(formValuesPrev)){
            this.checkAndUpdateValues();
            form.validateFields();
            this.validateFieldsManually(false, true, undefined, false);
        }

        console.log(optionsLoadingMessage, optionsLoadingMessagePrev, 'option')
        if(optionsLoadingMessage !== optionsLoadingMessagePrev){
            message.info(optionsLoadingMessage)
        }
        if(extractDataFromFileMesage && extractDataFromFileMesage !== extractDataFromFileMesagePrev){
            if(extractDataFromFileMesage.includes('Error:')){
                message.error(extractDataFromFileMesage.replace('Error:',''))
            }else{
                message.info(extractDataFromFileMesage)
            }
        }

        if (formValues && Object.keys(formValues).length) {
            this.prevFieldsValue = JSON.stringify(formValues);
        }
        if (this.props.numberVerified && this.props.numberVerified !== prevProp.numberVerified) {
            // this.validateFieldsManually(true, false);
            let productEForm = false;
            if (formJson) {
                const { header, tags } = formJson;
                if (isArray(tags) && tags.includes('e-shopping')) productEForm = true;
            }
            if (productEForm) {
                this.setState({
                    showOTPPage: false,
                    currentStep: CurrentStepNumbers.Three,
                });
            }
        }
        if (
            this.props.loginValue !== prevProp.loginValue &&
            this.state.currentStep === CurrentStepNumbers.Two &&
            this.state.registrationValue === UserTypeValues.GuestUser
        ) {
            this.setState({
                showOTPPage: true,
            });
        }
        if (this.props.loginScreen && this.props.loginScreen !== prevProp.loginScreen) {
            this.setState({
                showOTPPage: true,
            });
        }
        if (this.props.user && this.props.user !== prevProp.user) {
            if (this.state.registrationValue !== UserTypeValues.RegisteredUser) {
                this.setState({
                    showOTPPage: false,
                    currentStep: CurrentStepNumbers.Three,
                });
            } else {
                this.setState({
                    showAddress: true,
                    showOTPPage: false,
                });
            }
        }
        if (this.props.authErrorMessage !== prevProp.authErrorMessage && this.props.authErrorMessage) {
            message.error(this.props.authErrorMessage);
        }
        if (this.props.errorRegisteringEform && this.props.errorRegisteringEform !== prevProp.errorRegisteringEform) {
            message.error(this.props.errorRegisteringEform);
        }
        if (this.props.eFormRegister && this.props.eFormRegister !== prevProp.eFormRegister) {
            // this.setState({
            //     currentStep: 4
            // })
            // this.props.resendOtpActionProp(this.props.code, "Phone", this.state.guestPhoneNumber);
            this.props.preLogin(this.props.code, 'phone', this.state.guestPhoneNumber);
        }
        if (this.state.imageUrl !== '' && this.state.imageUrl !== prevState.imageUrl) {
            let self = this;
            setTimeout(() => {
                let element: any = document.getElementById('productImage');
                let url = self.state.imageUrl + '&time=' + new Date().getTime();
                element.src = url;
            }, 100);
        }
        if (timeInterval && !this.timer) {
            this.timer = setInterval(() => {
                if (formMode === FILLED || formMode === VIEW) {
                    this.invokeIntervalHandler();
                }
            }, timeInterval * THOUSAND);
        }

        if (clearCanvas && clearCanvas !== prevClearCanvas) {
            this.onCanvasClear();
        }
        /**
             TextDetected fetches the array of all text in an image along the labels.
        */
        if (textDetectedData && textDetectedData !== prevTextDetectedData) {
            if (
                textDetectedData.responses &&
                textDetectedData.responses[0] &&
                textDetectedData.responses[0].textAnnotations &&
                textDetectedData.responses[0].textAnnotations.length > 1
            ) {
                let dateOfBirth, passportNumber;
                let passportRegex = '^[A-Za-z][1-9]\\d' + '\\s?\\d{4}[1-9]$';
                textDetectedData.responses[0].textAnnotations.forEach(annotation => {
                    if (annotation.description.match('^([0-9]{2}/[0-9]{2}/[0-9]{4})') && !dateOfBirth) {
                        dateOfBirth = annotation.description.replace(/[^a-z0-9/]/gi, '');
                    }
                    if (annotation.description.match(passportRegex) && !passportNumber) {
                        passportNumber = annotation.description.replace(/[^a-z0-9]/gi, '');
                    }
                });
                setFieldsValue({ DOB: dateOfBirth, passportNumber });
            }
        }
    }

    public componentWillUnmount() {
        if (!this.closePropCalled) this.onCloseHandler(false);
        formValueChange.unsubscribe();

        if (emitter) {
            emitter.clear();
        }
        const { agentLogin } = this.props;
        if (agentLogin) {
            document.body.removeEventListener('touchstart', this.disableCanvasScroll);
            document.body.removeEventListener('touchend', this.disableCanvasScroll);
            document.body.removeEventListener('touchmove', this.disableCanvasScroll);
        }
    }

    public onClose(): void {
        this.onCloseHandler(true);
    }

    public onCloseHandler(closeEform) {
        this.stopTimer();
        const { onClose: onCloseProp, formJson } = this.props;
        const timeSpentMs = getTimeDifferenceInMilliseconds(new Date(), this.initialTimeStamp);
        onCloseProp(formJson, timeSpentMs, closeEform);
        this.closePropCalled = true;
    }

    public onDateChange(): void {
        const {
            form: { getFieldsError },
        } = this.props;
        this.hasErrors(getFieldsError);
    }

    /**
     * Function to handle the onblur event for an input type
     * fire the event of draft save if any value present in the input value
     * @param e - FormEvent
     * @returns void
     *
     */
    public onInputBlurHandler(e: React.FormEvent<HTMLInputElement>): void {
        const { value } = e.currentTarget;
        if (value) {
            this.validateFieldsManually(false, true, undefined, false);
        }
    }

    /**
     * Function to handle the onblur event for an textarea type
     * fire the event of draft save if any value present in the textarea value
     * @param e - FormEvent
     * @returns void
     *
     */
    public onTextAreaBlurHandler(e: React.FormEvent<HTMLTextAreaElement>): void {
        const { value } = e.currentTarget;
        if (value) {
            // no event needed, kept it for future if required any revert
            this.validateFieldsManually(false, true, undefined, false);
        }
    }

    public saveCommentsMethod(fieldName: string, linkCode: string, eformVersionId: IEformVersionId, comment: string) {
        const { saveCommentsHandler, addEformFieldReviewCommentLiveHandler, user } = this.props;
        if (saveCommentsHandler && user) {
            saveCommentsHandler(fieldName, linkCode, eformVersionId as number, comment);
            if (addEformFieldReviewCommentLiveHandler) {
                addEformFieldReviewCommentLiveHandler(fieldName, comment, user.name);
            }
            console.log({
                action: 'add_review_comment',
                comment,
                fieldName,
            });
            emitter.publish({
                data: {
                    action: 'add_review_comment',
                    comment,
                    fieldName,
                    name: user.name,
                },
                subject: 'eform',
            });
        }
    }

    public resolveCommentsMethod(fieldName: string, linkCode: string, eformVersionId: IEformVersionId) {
        const { resolveCommentsHandler, resolveEformFieldReviewCommentLiveHandler } = this.props;
        if (resolveCommentsHandler) {
            resolveCommentsHandler(fieldName, linkCode, eformVersionId as number);
            if (resolveEformFieldReviewCommentLiveHandler) {
                resolveEformFieldReviewCommentLiveHandler(fieldName);
            }
            console.log({
                action: 'resolve_review_comment',
                fieldName,
            });
            emitter.publish({
                data: {
                    action: 'resolve_review_comment',
                    fieldName,
                },
                subject: 'eform',
            });
        }
    }

    public getLabel(
        fieldName: string,
        label?: string,
        commentThreads?: any,
        reviewComments?: any,
        productEForm?: any,
        fromRadio?: any,
        fromPayment?: any,
    ): React.ReactNode {
        const { showReviewComments, code: linkCode, eformVersionId, fetchReviewCommentsHandler, user, formMode } = this.props;
        const { VIEW } = enumEFormModes;
        const threads = (commentThreads && commentThreads[fieldName]) || [];
        const activeThreads = threads.filter(t => !t.resolved);
        const threadIds = activeThreads.map(t => t.id);
        let comments = (reviewComments && reviewComments[fieldName]) || undefined;
        if (!comments) {
            comments = [];
        }
        return (
            <div
                style={
                    fromRadio && productEForm
                        ? {
                              marginBottom:
                                  label === productEformConstants.paymentOptions ? '0px' : fromPayment ? '4px' : '10px',
                              ...(label === productEformConstants.paymentOptions ? { marginTop: '16px' } : {}),
                          }
                        : {}
                }
            >
                <span
                    className={`${productEForm ? 'product-label' : ''}${
                        fromRadio && productEForm ? ' product-radio-label' : ''
                    }${fromPayment ? ' product-payment-label' : ''}`}
                    style={
                        fromRadio && productEForm && label === productEformConstants.CheckoutAs
                            ? { display: 'none' }
                            : {}
                    }
                >
                    {label === productEformConstants.paymentOptions ? productEformConstants.SelectPaymentMethod : label}
                </span>
                {showReviewComments && formMode !== VIEW && user && !productEForm && label && (
                    <ReviewComments
                        threadIds={threadIds}
                        fieldName={fieldName}
                        linkCode={linkCode}
                        eformVersionId={eformVersionId}
                        reviewComments={comments}
                        saveCommentsHandler={this.saveCommentsMethod}
                        resolveCommentsHandler={this.resolveCommentsMethod}
                        fetchReviewCommentsHandler={fetchReviewCommentsHandler}
                        user={user}
                    />
                )}
            </div>
        );
    }

    /**
     * @remarks
     * function which returns the item based on the formjson
     * any new formItem can be added here for future purpose
     * @param json
     * @param formMode
     * @param formJsonValues
     */
    public getTheFormItem(
        json,
        formMode: enumEFormModes,
        formJsonValues: object,
        userPreviewOnly?: boolean,
        agentLogin?: boolean,
        reviewComments?: any,
        commentThreads?: any,
        productEForm?: any,
        fromPayment?: any,
        fromAccountOpening?: any,
    ): React.ReactNode {
        const { form, approveRejectDocument, user, targetId, targetType, targetName, generator, dmsToken } = this.props;
        const { FILLED, VIEW } = enumEFormModes;
        const { REJECTED } = enumDocumentStatus;
        const { getFieldDecorator, isFieldTouched, getFieldError } = form;
        let {
            required,
            requiredErrorMsg,
            lengthErrorMsg,
            name,
            label,
            type,
            doNotRender,
            groupInTwo,
            formGroup,
            visibleTo,
            visibleOn,
            dependents,
            fetchOptions,
            allowedFileTypes,
            modalHeader,
            anchorText,
            modalItems,
            count
        } = json as IFormComponent & {count: number, allowedFileTypes?: string[], fetchOptions:boolean, dependents:string[], visibleTo: string[], visibleOn:{name:string, value: string | boolean} };
        let { disabled } = json as IFormComponent;
        const { placeholder, rows } = json as ITextAreaSchema;
        const { options } = json as ISelectSchema;
        const { columns, defaultColumns } = json;
        const { min, max, defaultValue, slug } = json as IInputNumberSchema;
        const { isVertical, defaultValue: defaultValueRadio } = json as IRadioGroupSchema;
        const { initialValue } = json as ICheckBoxSchema;
        const { isReviewable, documentStatus } = json as IUploadSchema;
        const { pattern }  = json as IMaskedInputSchema;
        let { onlyCountries = [] } = json as IPhoneNumberSchema;
        const { value, text, width } = json;
        const showError = isFieldTouched(name) && getFieldError(name);
        const { TextArea } = Input;
        const inputMin = min || ZERO;
        const inputMax = max || Number.MAX_SAFE_INTEGER;
        let inputType = 'text';
        const countryOptions = {};
        if(onlyCountries.length){
            onlyCountries = onlyCountries.map(c => c.toUpperCase())
            countryOptions['countries'] = onlyCountries;
        }

        let sameStepItem = json.step ? (json.step === this.state.currentStep) : true;
        if(visibleOn !== undefined && form && sameStepItem){
            const { name, value } = visibleOn;
            if(name && value !== undefined){
                const actualvalue = form.getFieldValue(name)
                if(value === actualvalue){
                    required = true;
                    doNotRender= false;
                }else{
                    required = false;
                    doNotRender = true;
                    return;
                    // do no go process this element it is not need as per the conditions
                }
            }
        }


        // If visibleTo is configured for the field and logged in user details not matching with visibleTo logic then show the field as password.
        // currently this login applies only for input text type fields
        // 'targetUser' indicates the user/customer against whom the link is created
        // 'generator' indicates the link created user.
        if (user && visibleTo && Array.isArray(visibleTo) && visibleTo.length) {
            if (!visibleTo.includes('targetUser')) {
                if (user.userType === UserType.customer) {
                    inputType = 'password';
                    disabled = true;
                } else if (user.userType === targetType && user.id === targetId) {
                    inputType = 'password';
                    disabled = true;
                }
            }
            // if visibleTo does not have generator but generator is logged in then don't show the content, show as password
            if (generator && !visibleTo.includes('generator')) {
                if (generator.id === user.id && generator.userType === user.userType) {
                    inputType = 'password';
                    disabled = true;
                }
            }
        }

        let uploadButtonText = 'Upload';
        if(json.type === enumInputTypes.UPLOAD){
            if (isReviewable) {
                if (documentStatus === REJECTED) {
                    uploadButtonText = 'Reupload';
                }
            }
        }
        // verify allowedFileTypes are properly configured or not
        if(allowedFileTypes && allowedFileTypes.length){
            let invalid = false;
            allowedFileTypes.forEach(ext => {
                if(typeof ext !== 'string'){
                    invalid = true;
                }
                if(!ext.startsWith('.')){
                    invalid = true;
                }
            })
            if(invalid){
                allowedFileTypes = undefined;
            }
        }

        switch (type) {
            case enumInputTypes.TABLE:
                return (
                    <div className={doNotRender ? 'doNotRender' : ''}>
                        {label !== '' && (
                            <div className="tableLabel">
                                {this.getLabel(name, label, commentThreads, reviewComments, productEForm)}
                            </div>
                        )}
                        <EditableTable
                            formMode={formMode}
                            columns={columns}
                            defaultColumns={defaultColumns}
                            getLabel={this.getLabel}
                            rootForm={this.props.form}
                            rootName={name}
                            isMobile={isMobile}
                            validateFieldsManually={this.validateFieldsManually}
                            formJson={this.props}
                            setTableData={this.storeTableData}
                            getTableData={this.getTableData}
                            handleTableErrors={this.handleTableErrors}
                        />
                    </div>
                );
            case enumInputTypes.TEXT:
                return (
                    <div style={{ padding: '15px 15px' }} className={doNotRender ? 'doNotRender' : ''}>
                        <Typography>
                            <Title>{label}</Title>
                            <div dangerouslySetInnerHTML={{ __html: text }} />
                        </Typography>
                    </div>
                );
            case enumInputTypes.INPUT:
                let newWidth = width || '100%';
                if (isMobile) newWidth = '100%';
                if (productEForm && disabled) {
                    if (value && value !== '') {
                        return (
                            <p className={`eform-diasble-para${name === 'Product Price' ? ' product-price-text' : ''}`}>
                                {name === 'Product Price' && <span>&#8377;</span>}
                                {value}
                            </p>
                        );
                    }
                    return <></>;
                }
                if (label === 'Password') {
                    return (
                        <Form.Item
                            key={name}
                            validateStatus={showError ? 'error' : ''}
                            help={showError || ''}
                            label={this.getLabel(
                                name,
                                label,
                                commentThreads,
                                reviewComments,
                                productEForm,
                                false,
                                fromPayment,
                            )}
                            className={`${fromPayment ? 'form-item-payment' : ''}${
                                fromAccountOpening ? 'accountOpening' : ''
                            } ${doNotRender ? 'doNotRender': ''}`}
                        >
                            {getFieldDecorator(name, {
                                rules: [
                                    {
                                        message: requiredErrorMsg,
                                        required,
                                    },
                                    {
                                        message: `minimum ${min} characters allowed`,
                                        min,
                                    },
                                    {
                                        max,
                                        message: `maximum ${max} characters allowed`,
                                    },
                                ],
                            })(
                                <Input.Password
                                    type={inputType}
                                    disabled={disabled || formMode === VIEW || userPreviewOnly}
                                    onBlur={this.onInputBlurHandler}
                                    placeholder={formMode === VIEW || productEForm ? placeholder : ''}
                                />,
                            )}
                        </Form.Item>
                    );
                }
                return (
                    <Form.Item
                        key={name}
                        validateStatus={showError ? 'error' : ''}
                        help={showError || ''}
                        label={this.getLabel(
                            name,
                            label,
                            commentThreads,
                            reviewComments,
                            productEForm,
                            false,
                            fromPayment,
                        )}
                        style={doNotRender ? {} : { width: newWidth, display: 'inline-block' }}
                        className={`${fromPayment ? 'form-item-payment' : ''}${
                            fromAccountOpening ? 'accountOpening' : ''
                        } ${doNotRender ? 'doNotRender': ''}`}
                    >
                        {getFieldDecorator(name, {
                            rules: [
                                {
                                    message: requiredErrorMsg,
                                    required,
                                },
                                {
                                    message: `minimum ${min} characters allowed`,
                                    min,
                                },
                                {
                                    max,
                                    message: `maximum ${max} characters allowed`,
                                },
                                {
                                    message: 'Invalid input',
                                    pattern: pattern ? new RegExp(pattern,'g') : new RegExp(inputPattern,'g')
                                }
                            ],
                        })(
                            <Input
                                type={inputType}
                                disabled={disabled || formMode === VIEW || userPreviewOnly}
                                onBlur={this.onInputBlurHandler}
                                placeholder={placeholder}
                                pattern={pattern}
                            />,
                        )}
                    </Form.Item>
                );
            case enumInputTypes.TEXTAREA:
                return (
                    <Form.Item
                        key={name}
                        validateStatus={showError ? 'error' : ''}
                        help={showError || ''}
                        label={this.getLabel(name, label, commentThreads, reviewComments, productEForm)}
                        className={doNotRender ? 'doNotRender' : ''}
                    >
                        {getFieldDecorator(name, {
                            rules: [
                                {
                                    message: requiredErrorMsg,
                                    required,
                                },
                                {
                                    message: `minimum ${min} characters allowed`,
                                    min,
                                },
                                {
                                    max,
                                    message: `maximum ${max} characters allowed`,
                                },
                                {
                                    message: 'Invalid input',
                                    pattern: pattern ? new RegExp(pattern,'g') : new RegExp(inputPattern,'g')
                                }
                            ],
                        })(
                            <TextArea
                                placeholder={formMode !== VIEW ? placeholder : ''}
                                rows={rows}
                                onBlur={this.onTextAreaBlurHandler}
                                disabled={disabled || formMode === VIEW || userPreviewOnly}
                            />,
                        )}
                    </Form.Item>
                );
            case enumInputTypes.SELECT:
                let newWidthSelect = width || '100%';
                if (isMobile) newWidthSelect = '100%';
                return (
                    <Form.Item
                        key={name}
                        validateStatus={showError ? 'error' : ''}
                        help={showError || ''}
                        label={
                            !productEForm && this.getLabel(name, label, commentThreads, reviewComments, productEForm)
                        }
                        className={`${productEForm ? 'product-select-form' : ''}${
                            fromAccountOpening ? 'accountOpening' : ''
                        } ${doNotRender ? 'doNotRender': ''}`}
                        style={doNotRender ? {} : { width: newWidthSelect, display: 'inline-block' }}
                    >
                        {getFieldDecorator(name, {
                            initialValue: productEForm ? value : '',
                            rules: [{ message: { requiredErrorMsg }, required }],
                        })(
                            <Select
                                className={productEForm ? 'product-select' : ''}
                                disabled={disabled || formMode === VIEW || userPreviewOnly}
                                placeholder={placeholder}
                                showSearch={fetchOptions}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children && option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false
                                }
                                onChange={e => {
                                    productEForm && this.setState({ productQuantity: e });
                                    !productEForm && this.updateDependents(dependents, name, e)
                                }}
                            >
                                {this.prepareSelectOptions(options, productEForm)}
                            </Select>,
                        )}
                    </Form.Item>
                );
            case enumInputTypes.MULTI_SELECT:
                return (
                    <Form.Item
                        key={name}
                        validateStatus={showError ? 'error' : ''}
                        help={showError || ''}
                        label={this.getLabel(name, label, commentThreads, reviewComments, productEForm)}
                        className={doNotRender ? 'doNotRender': ''}
                    >
                        {getFieldDecorator(name, {
                            rules: [{ message: { requiredErrorMsg }, required }],
                        })(
                            <Select
                                mode="multiple"
                                placeholder={placeholder}
                                disabled={disabled || formMode === VIEW || userPreviewOnly}
                            >
                                {this.prepareSelectOptions(options)}
                            </Select>,
                        )}
                    </Form.Item>
                );
            case enumInputTypes.INPUT_NUMBER:
                return (
                    <Form.Item
                        key={name}
                        validateStatus={showError ? 'error' : ''}
                        help={showError || ''}
                        label={this.getLabel(name, label, commentThreads, reviewComments, productEForm)}
                        className={`${groupInTwo ? 'only-half-render' : ''}
                         ${doNotRender ? 'doNotRender': ''}`}
                    >
                        {getFieldDecorator(name, {
                            initialValue: defaultValue,
                            rules: [{
                                    pattern: /^(?:\d*)$/,
                                    message: `${label} should contain just number`,
                                    required
                                },{
                                    min: inputMin,
                                    max: inputMax,
                                    ...!max && ({ message: (lengthErrorMsg) ? `${lengthErrorMsg}`: `Must enter ${inputMin} digits`})
                                }],
                        })(
                            <Input
                                type="number"
                                minLength={inputMin}
                                maxLength={inputMax}
                                disabled={disabled || formMode === VIEW || userPreviewOnly}
                                onBlur={(e) => {this.updateDependents(dependents, name, e.currentTarget.value)}}
                            />,
                        )}
                    </Form.Item>
                );
            case enumInputTypes.NUMBER:
                return (
                    <Form.Item
                        key={name}
                        validateStatus={showError ? 'error' : ''}
                        help={showError || ''}
                        label={this.getLabel(name, label, commentThreads, reviewComments, productEForm)}
                        className={`${groupInTwo ? 'only-half-render' : ''}
                         ${doNotRender ? 'doNotRender' : ''}`}
                    >
                        {getFieldDecorator(name, { initialValue: defaultValue })(
                            <InputNumber
                                min={min}
                                max={max}
                                disabled={disabled || formMode === VIEW || userPreviewOnly}
                                onBlur={this.onInputBlurHandler}
                            />,
                        )}
                    </Form.Item>
                );
            case enumInputTypes.RADIO_GROUP:
                return (
                    <Form.Item
                        key={name}
                        validateStatus={showError ? 'error' : ''}
                        help={showError || ''}
                        label={
                            label !== ''
                                ? this.getLabel(name, label, commentThreads, reviewComments, productEForm, true)
                                : null
                        }
                        className={`${formGroup ? 'formGroup' : ''}${
                            productEForm && label === productEformConstants.OtherPaymentOptions
                                ? 'product-other-payment'
                                : ''
                        } ${doNotRender ? 'doNotRender': ''}`}
                        onChange={() => {
                            if (
                                form.getFieldValue('citizenId') === 'Cash on Delivery' &&
                                this.state.registrationValue !== UserTypeValues.RegisteredUser
                            ) {
                                if (!this.state.codOtp) {
                                    this.props.resendOtpActionProp(
                                        this.props.code,
                                        'Phone',
                                        this.state.guestPhoneNumber,
                                    );
                                    this.setState({
                                        codOtp: true,
                                    });
                                }
                            }
                            if (
                                form.getFieldValue('loginUser') !== '' &&
                                this.state.currentStep === CurrentStepNumbers.Two &&
                                productEForm
                            ) {
                                this.setState({
                                    currentStep: CurrentStepNumbers.Two,
                                    registrationValue: form.getFieldValue('loginUser'),
                                });
                                form.setFieldsValue({
                                    loginUser: '',
                                });
                            }
                            // if (
                            //     form.getFieldValue('citizenId') === 'Cash on Delivery' &&
                            //     this.state.registrationValue === UserTypeValues.GuestUser
                            // ) {
                            //     // if (this.props.loginValue === '' || this.props.loginValue) {
                            //     //     this.setState({
                            //     //         showCashOnDeliveryModal: true,
                            //     //     });
                            //     // }
                            //     this.props.resendOtpActionProp(this.props.code, 'Phone', this.state.guestPhoneNumber);
                            // }
                        }}
                    >
                        {getFieldDecorator(name, {
                            initialValue: productEForm ? '' : defaultValueRadio,
                            rules: [{ message: { requiredErrorMsg }, required }],
                        })(
                            <Radio.Group
                                key={name}
                                className={`${
                                    productEForm &&
                                    label !== productEformConstants.CheckoutAs &&
                                    label !== productEformConstants.paymentOptions
                                        ? 'radio-product'
                                        : ' '
                                }
                                ${
                                    productEForm && label === productEformConstants.CheckoutAs
                                        ? 'radio-product-checkout'
                                        : ' '
                                }
                                ${
                                    productEForm && label === productEformConstants.paymentOptions
                                        ? 'radio-product-payment'
                                        : ' '
                                }`}
                                disabled={disabled || formMode === VIEW || userPreviewOnly}
                                style={productEForm ? { width: '100%' } : {}}
                            >
                                {this.prepareRadioOptions(options, isVertical, productEForm)}
                            </Radio.Group>,
                        )}
                    </Form.Item>
                );
            case enumInputTypes.CHECKBOX_GROUP:
                return (
                    <Form.Item
                        key={name}
                        validateStatus={showError ? 'error' : ''}
                        help={showError || ''}
                        label={this.getLabel(name, label, commentThreads, reviewComments, productEForm)}
                        className={doNotRender ? 'doNotRender' : ''}
                    >
                        {getFieldDecorator(name, {
                            initialValue,
                            rules: [
                                {
                                    required,
                                    type: 'array',
                                    validator: this.checkCheckBox,
                                },
                            ],
                        })(<Checkbox.Group>{this.prepareCheckboxOptions(options, isVertical)}</Checkbox.Group>)}
                    </Form.Item>
                );
            case enumInputTypes.DATE:
                return (
                    <Form.Item
                        key={name}
                        validateStatus={showError ? 'error' : ''}
                        help={showError || ''}
                        label={this.getLabel(name, label, commentThreads, reviewComments, productEForm)}
                        className={`${fromAccountOpening ? 'accountOpening' : ''}
                         ${doNotRender ? 'doNotRender' : ''}`}
                    >
                        {min &&
                            min.toString().toLowerCase() === 'today' &&
                            getFieldDecorator(name, { rules: [{ message: requiredErrorMsg, required }] })(
                                <DatePicker
                                    style={{ width: '100%' }}
                                    disabled={disabled || formMode === VIEW || userPreviewOnly}
                                    disabledDate={this.disabledMinDate}
                                    format={dateFormat}
                                    onChange={(e) => this.updateDependents(dependents, name, e)}
                                />,
                            )}
                        {max &&
                            max.toString().toLowerCase() === 'today' &&
                            getFieldDecorator(name, { rules: [{ message: requiredErrorMsg, required }] })(
                                <DatePicker
                                    style={{ width: '100%' }}
                                    disabled={disabled || formMode === VIEW || userPreviewOnly}
                                    disabledDate={this.disabledMaxDate}
                                    format={dateFormat}
                                    onChange={(e) => this.updateDependents(dependents, name, e)}
                                />,
                            )}
                        {!min &&
                            !max &&
                            getFieldDecorator(name, { rules: [{ message: requiredErrorMsg, required }] })(
                                <DatePicker
                                    style={{ width: '100%' }}
                                    disabled={disabled || formMode === VIEW || userPreviewOnly}
                                    format={dateFormat}
                                    onChange={(e) => this.updateDependents(dependents, name, e)}
                                />,
                            )}
                    </Form.Item>
                );

            case enumInputTypes.CHECKBOX:
                const { formJson } = this.props;
                return (
                    <Form.Item
                        key={name}
                        validateStatus={showError ? 'error' : ''}
                        help={showError || ''}
                        className={doNotRender ? 'doNotRender' : ''}
                    >
                        {getFieldDecorator(name, {
                            valuePropName: 'checked',
                            rules: [
                                {
                                   required,
                                  // validator: this.checkCheckBox
                                },
                            ],
                        })(<><Checkbox value={value} disabled={disabled || formMode === VIEW || userPreviewOnly}/>
                            {' '}{text}{' '}{ anchorText!="" && <a href="#" onClick={this.showModal} style={{color:"red", fontWeight:600}}> {anchorText}</a>}
                            {
                                <Modal title={anchorText} 
                                    visible={this.state.isModalVisible} 
                                    onCancel={this.handleCancel}
                                    footer={null}
                                    >
                                    <p>{modalHeader}</p>
                                    <ol type="a">
                                        {
                                            modalItems && modalItems.map(item => {
                                                return (
                                                    <li >
                                                        {item}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ol>
                                    {/* <h3>{(formJson.formStatus == "submitted" || formJson.formStatus == "sentToReview") ? "": moment(new Date()).utcOffset(420).format('DD/MM/YYYY')}</h3> */}
                                    <br/>
                                    <h3>{targetName}</h3>
                                </Modal>
                            }
                            </>
                        )}
                    </Form.Item>
                );
            case enumInputTypes.PHONENUMBER:
                return (
                    <Form.Item
                        key={name}
                        validateStatus={showError ? 'error' : ''}
                        help={showError || ''}
                        label={this.getLabel(name, label, commentThreads, reviewComments, productEForm)}
                        className={`no-overlap ${doNotRender ? 'doNotRender' : ''}`}
                    >
                        {getFieldDecorator(name, {
                            rules: [
                                {validator:(rule, value,callback)=>{
                                    if(!value){
                                        if(required){
                                            return callback(requiredErrorMsg)
                                        }
                                        return callback()
                                    }
                                    const parsed = parsePhoneNumber(value)
                                    console.log(parsed,'parsed', onlyCountries)
                                    if(parsed && parsed.country && onlyCountries.length){
                                        if(!onlyCountries.includes(parsed.country)){
                                            return callback('Invalid Country phone number')
                                        }
                                    }
                                    if(!isValidPhoneNumber(value)){
                                       return callback('Invalid Phone number')
                                    }
                                    
                                    callback()
                                }}
                            ],
                        })(<PhoneInput
                                style={{borderRadius: '4px',paddingLeft: '4px'}}
                                international={false}
                                flags={flags}
                                placeholder={placeholder}
                                value={form.getFieldValue(name)}
                                limitMaxLength={true}
                                disabled={disabled || formMode === VIEW || userPreviewOnly}
                                onChange={(value)=>{this.updateDependents(dependents, name, value)}}
                                {...countryOptions}
                            />
                            )}
                    </Form.Item>
                );

            case enumInputTypes.MASKEDINPUT:
                return (
                    <Form.Item
                        key={name}
                        validateStatus={showError ? 'error' : ''}
                        help={showError || ''}
                        label={this.getLabel(name, label, commentThreads, reviewComments, productEForm)}
                        className={doNotRender ? 'doNotRender': ''}
                    >
                        {getFieldDecorator(name, {
                            rules: [
                                {
                                    message: requiredErrorMsg || `${label} is required`,
                                    required,
                                }
                            ],
                        })(<MaskedInput
                            className="ant-input"
                            mask={pattern}
                            inputProps={{min: required ? pattern.length : 0, max:pattern.length}}
                            disabled={disabled || formMode === VIEW || userPreviewOnly}
                            value={form.getFieldValue(name)}
                            onChange={(e)=>{this.updateDependents(dependents, name, e.target.value)}}
                            />
                            )}
                    </Form.Item>
                );
            case enumInputTypes.RATING:
                return (
                    <Form.Item
                        key={name}
                        validateStatus={showError ? 'error' : ''}
                        help={showError || ''}
                        label={this.getLabel(name, label, commentThreads, reviewComments, productEForm)}
                        className={doNotRender ? 'doNotRender' : ''}
                    >
                        {getFieldDecorator(name, {
                            rules: [
                                {
                                    required,
                                },
                            ],
                        })(<ReactRating
                            stop={count}
                            readonly={disabled || formMode === VIEW || userPreviewOnly}
                            initialRating={form.getFieldValue(name)}
                            emptySymbol={Array(count).fill(0).map((_, index) => (<div className="rating-icon-text"><div className="rating-number">{index+1}</div></div>))}
                            fullSymbol={Array(count).fill(0).map((_, index) => (
                            <div className="rating-icon-text rating-icon-filled"><div className="rating-number">{index+1}</div></div>
                              ))}
                            onChange={(rating)=>{this.updateDependents(dependents, name, rating)}}
                            />
                            )}
                    </Form.Item>
                );

            case enumInputTypes.UPLOAD:
                return (
                    <>
                        <Form.Item
                            key={name}
                            validateStatus={showError ? 'error' : ''}
                            help={showError || ''}
                            label={this.getLabel(name, label, commentThreads, reviewComments, productEForm)}
                            className={doNotRender ? 'doNotRender' : ''}
                        >
                            {formMode !== VIEW &&
                                getFieldDecorator(name, {
                                    rules: [
                                        {
                                            required,
                                            // validator: this.checkCheckBox
                                        },
                                    ],
                                })(
                                    <div
                                        className={`uppyLoader ${
                                            formMode !== FILLED || userPreviewOnly ? 'disabled' : ''
                                        } ${required ? 'add-star' : ''}`}
                                    >
                                        <Uploader
                                            companionUrl={`${customWindow.config.baseUrl}/api/storage/companion-upload`}
                                            id={`test-upload-div-button${name}`}
                                            modeOptions={{
                                                buttonText: `${uploadButtonText}`,
                                                mode: 'button',
                                            }}
                                            restrictions={{
                                                allowedFileTypes: allowedFileTypes || [
                                                    '.pdf',
                                                    '.doc',
                                                    '.docx',
                                                    '.xls',
                                                    '.xlsx',
                                                    '.csv',
                                                    '.jpg',
                                                    '.jpeg',
                                                    '.png',
                                                    '.gif',
                                                ],
                                                maxFileSize: null,
                                                maxNumberOfFiles: null,
                                                minNumberOfFiles: null,
                                            }}
                                            allowMultipleUploads
                                            uploadFailed={this.uploadFailed}
                                            uploadStarted={this.uploadStarted}
                                            uploadSuccessful={files => this.uploadSuccessful(files, json)}
                                            xhrUrl={`${customWindow.config.baseUrl}/api/storage/xhr-upload`}
                                        />
                                    </div>,
                                )}
                            {(formMode === VIEW || formMode === FILLED) && (
                                <React.Fragment>
                                    {formJsonValues &&
                                        formJsonValues[name] &&
                                        formJsonValues[name].length > ZERO &&
                                        formJsonValues[name].map(uvalue => (
                                            <div className="uploadedDocument">
                                                <div className="uploadedFilename">Filename: {uvalue.fileName}</div>
                                                <div className="uploadedFilename">{uvalue.uploadedPurpose ? `${uvalue.uploadedPurpose},` : `${customWindow.config.documentUploadPurpose}, `} {uvalue.uploadedAt || new Date().toLocaleDateString()}</div>
                                                <div className="thumbnailDownloadWrapper">
                                                    <ThumbnailByType
                                                        className="thumbnail"
                                                        mimeType={uvalue.fileType}
                                                        fileType={uvalue.fileType}
                                                        fileUrl={`${customWindow.config.dmsUrl}/api${uvalue.sourceUrl}?token=${dmsToken}`}
                                                        isUrlInvalid={!uvalue.sourceUrl}
                                                        showPreviewButton
                                                        isReviewable={isReviewable && agentLogin && !uvalue.status}
                                                    />
                                                    {/* {!agentLogin && (
                                                    <a
                                                      role="button"
                                                      rel="noopener noreferrer"
                                                      target="_blank"
                                                      href={`${customWindow.config.dmsUrl}/api${uvalue.downloadUrl}?token=${dmsToken}`}
                                                      className="download"
                                                    >
                                                        {getString("Download")}
                                                        Download 
                                                    </a>
                                                )} */}
                                                    {isReviewable &&
                                                        agentLogin &&
                                                        !uvalue.status &&
                                                        approveRejectDocument && (
                                                            <div className="documentActionButtons">
                                                                <Button
                                                                    role="button"
                                                                    className="reject"
                                                                    tabIndex={ONE}
                                                                    type="link"
                                                                    onKeyUp={() =>
                                                                        this.approveRejectHandler(
                                                                            name,
                                                                            uvalue.fileId,
                                                                            enumDocumentStatus.REJECTED,
                                                                        )
                                                                    }
                                                                    onClick={() =>
                                                                        this.approveRejectHandler(
                                                                            name,
                                                                            uvalue.fileId,
                                                                            enumDocumentStatus.REJECTED,
                                                                        )
                                                                    }
                                                                >
                                                                    Reject
                                                                </Button>
                                                            </div>
                                                        )}
                                                </div>
                                                {isReviewable && uvalue.status === REJECTED && (
                                                    <div className="documentStatusInfo">
                                                        <div className="status">
                                                            <span>{getString('Status')} :</span>
                                                            <strong> {uvalue.status}</strong>
                                                        </div>
                                                        {uvalue.reason && (
                                                            <div className="reason">
                                                                <span>{getString('Reason')} :</span>
                                                                <strong> {uvalue.reason}</strong>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                </React.Fragment>
                            )}
                        </Form.Item>
                        {!doNotRender && this.renderDivider()}
                    </>
                );
            case enumInputTypes.SECTION_HEADER:
                return (
                    <>
                        <div
                            className={`section-header${productEForm ? ' section-product' : ''}${
                                label === productEformConstants.Payment ||
                                label === productEformConstants.AddNewAddress ||
                                label === productEformConstants.Cart
                                    ? ' payment-header'
                                    : ''
                            }${
                                label === productEformConstants.CustomerDetails ||
                                label === productEformConstants.DeliverTo
                                    ? ' customer-details-header'
                                    : ''
                            }
                        ${
                            label === productEformConstants.PersonalDetails ||
                            label === productEformConstants.AddressDetails
                                ? 'customer-personal-details'
                                : ''
                        }
                        ${label === productEformConstants.AddressDetails ? 'customer-address-details' : ''}
                         ${doNotRender ? 'doNotRender':''}`}
                        >
                            {(label === productEformConstants.Payment ||
                                label === productEformConstants.AddNewAddress ||
                                label === productEformConstants.Cart) && (
                                <img
                                    className="payment-header-img"
                                    src={ProductBack}
                                    onClick={() => {
                                        if (label === productEformConstants.Cart) {
                                            this.setState({ showProductLandingPage: true });
                                        } else {
                                            this.setState({ currentStep: this.state.currentStep - 1 });
                                        }
                                    }}
                                />
                            )}
                            <Text strong>
                                {label === productEformConstants.Cart && productEForm ? 'Shopping Bag' : label}
                            </Text>
                            {defaultValue && <Text className="subText">{defaultValue}</Text>}
                            {/* {label === productEformConstants.Cart && productEForm && <p className="cart-subheading">Total Items: 1</p>} */}
                        </div>
                        {label === productEformConstants.AddNewAddress && <div style={{ marginBottom: '8px' }}></div>}
                        {label === productEformConstants.Cart && productEForm && (
                            <div className={`section-header section-product`}>
                                <Text strong>Bag (1 product)</Text>
                            </div>
                        )}
                    </>
                );
            case enumInputTypes.IMAGE:
                let newValue;
                if (this.state.imageUrl !== '') {
                    newValue = this.state.imageUrl;
                } else {
                    let imageUrlValue = value || '';
                    let newUrl = imageUrlValue[0] + this.props.dmsToken;
                    newValue = newUrl;
                }
                return (
                    <img
                        src={newValue}
                        className={`previewImage ${
                            this.state.showProductLandingPage ? 'landing-image' : 'product-image'
                        } `}
                        alt="Thumbnail"
                        id="productImage"
                    />
                );
            default:
                return (
                    <Form.Item
                        key={name}
                        validateStatus={showError ? 'error' : ''}
                        help={showError || ''}
                        label={this.getLabel(name, label, commentThreads, reviewComments, productEForm, fromPayment)}
                        className={doNotRender ? 'doNotRender' : ''}
                    >
                        {getFieldDecorator(name, { rules: [{ message: requiredErrorMsg, required }] })(
                            <Input
                                disabled={disabled || formMode === VIEW || userPreviewOnly}
                                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder={placeholder}
                            />,
                        )}
                    </Form.Item>
                );
        }
    }

    checkCheckBox = (rule, value, callback) => {
        if (!value) {
            callback('Please select atleast one checkbox');
        } else {
            callback();
        }
    };

    public prepareSelectOptions = (options: Array<IOptionItem>, productEForm?: any): Array<React.ReactElement> => {
        let selectOptions: React.ReactElement[] = [];
        selectOptions = options.map(option => {
            return (
                <Option key={option.value} value={option.value}>
                    {productEForm ? 'Qty: ' + option.text : option.text}
                </Option>
            );
        });
        return selectOptions;
    };

    public prepareRadioOptions = (
        options: Array<IOptionItem>,
        isVertical: boolean | undefined,
        productEForm,
    ): Array<React.ReactElement> => {
        let radioOptions: React.ReactElement[] = [];
        const {
            form,
            formJson,
            formMode,
            formValues,
            userPreviewOnly,
            submittingEform,
            savingEform,
            noSubmissionEformType,
            agentLogin,
            isFormReviewable,
            sendToReview,
            reviewComments,
            commentThreads,
            canvasEnabled,
            showNotesModal,
            notesModalCloseHandler,
            code: linkCode,
            eformVersionId,
            fetchReviewCommentsHandler,
            user,
            verifyingNumber,
        } = this.props;
        let style = {};
        let radioValue = (productEForm && form.getFieldValue('citizenId')) || undefined;
        let paymentPage = options[0].text === PaymentOptions.CreditCard;
        let creditMockData: any = [
            {
                type: 'input',
                required: false,
                label: 'Card Number',
                name: 'Card Number',
                requiredErrorMsg: 'Card Number is required',
                step: 2,
                placeholder: '1234 5678 9876 5432',
            },
            {
                type: 'input',
                required: false,
                label: 'Name on Card',
                name: 'Name on Card',
                requiredErrorMsg: 'Name on Card is required',
                step: 2,
                placeholder: 'Enter your name',
            },
            {
                type: 'input',
                required: false,
                label: 'Valid Upto',
                name: 'Valid Upto',
                requiredErrorMsg: 'Valid Date is required',
                step: 2,
                placeholder: 'MM/YYYY',
            },
            {
                type: 'input',
                required: false,
                label: 'Security Code',
                name: 'Security Code',
                requiredErrorMsg: 'Security Code is required',
                step: 2,
                placeholder: 'CVV',
            },
        ];
        let bankMockData: any = [
            {
                type: 'input',
                required: false,
                label: 'Account Holder Name',
                name: 'Account Holder Name',
                requiredErrorMsg: 'Account Holder Name is required',
                step: 2,
                placeholder: 'Name as on Bank Accounts',
            },
            {
                type: 'input',
                required: false,
                label: 'Account Number',
                name: 'Account Number',
                requiredErrorMsg: 'Account Number is required',
                step: 2,
                placeholder: 'Enter your Account Number',
            },
            {
                type: 'input',
                required: false,
                label: 'Re-enter Account Number',
                name: 'Re-enter Account Number',
                requiredErrorMsg: 'Re-enter Account Number is required',
                step: 2,
                placeholder: 'Re-enter your Account Number',
            },
            {
                type: 'input',
                required: false,
                label: 'Account Type',
                name: 'Account Type',
                requiredErrorMsg: 'Account Type is required',
                step: 2,
                placeholder: 'Select your account type',
            },
            {
                type: 'input',
                required: false,
                label: 'IFSC Code',
                name: 'IFSC Code',
                requiredErrorMsg: 'IFSC Code is required',
                step: 2,
                placeholder: 'Enter your IFSC Code',
            },
        ];
        const radioStyle = {
            display: paymentPage ? 'inline-flex' : 'block',
            height: productEForm ? '100%' : '30px',
            minHeight: productEForm && !paymentPage ? '42px' : '30px',
            width: productEForm ? '100%' : 'auto',
        };
        if (isVertical) {
            style = radioStyle;
        }
        radioOptions = options.map(option => {
            let registeredRadio =
                option.text === UserTypeValues.GuestUser || option.text === UserTypeValues.RegisteredUser;
            if (productEForm && option.text === PaymentOptions.DebitCard) return;
            return (
                <>
                    <Radio
                        key={option.value}
                        value={option.value}
                        disabled={option.disabled}
                        style={style}
                        className={`${registeredRadio ? 'registered-radio' : ''} ${
                            paymentPage ? 'product-eform-radio' : ''
                        }
                        ${paymentPage && option.text === PaymentOptions.BankAccount ? 'bank-account-radio' : ''}`}
                    >
                        {option.text === UserTypeValues.GuestUser || option.text === UserTypeValues.RegisteredUser ? (
                            <>
                                <div>{option.text}</div>
                                {option.text === UserTypeValues.GuestUser ? (
                                    <>
                                        <p>Checkout as Guest User</p>
                                    </>
                                ) : (
                                    <p>
                                        Not Registered?{' '}
                                        <strong
                                            style={{ color: '#3A92D1', fontWeight: 600 }}
                                            onClick={() => {
                                                this.setState({
                                                    registrationValue: 'Register Now User',
                                                });
                                            }}
                                        >
                                            Register Here
                                        </strong>
                                    </p>
                                )}
                                <img src={option.text === UserTypeValues.GuestUser ? GuestUser : LoginUser} />
                            </>
                        ) : productEForm ? (
                            <span>
                                <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '16px' }}>
                                    <div>
                                        <img
                                            src={
                                                option.text === PaymentOptions.CashOnDelivery
                                                    ? COD
                                                    : option.text === PaymentOptions.BankAccount
                                                    ? BankAccount
                                                    : CreditCard
                                            }
                                            style={{ marginRight: '8px' }}
                                        />
                                        {option.text === PaymentOptions.CreditCard ? 'Credit/Debit Card' : option.text}
                                    </div>
                                    {/* <div>
                                        {option.text === this.state.paymentOption ? (
                                            <div
                                                className="payment-display-option"
                                                onClick={() => this.setState({ paymentOption: '' })}
                                            ></div>
                                        ) : (
                                            <img
                                                src={PlusIcon}
                                                onClick={() => {
                                                    // if(option.text === 'Cash on Delivery') return;
                                                    this.setState({ paymentOption: option.text })
                                                }}
                                            />
                                        )}
                                    </div> */}
                                </div>
                            </span>
                        ) : (
                            <>{option.text}</>
                        )}
                        {((option.text === PaymentOptions.CreditCard && radioValue === PaymentOptions.CreditCard) ||
                            (option.text === PaymentOptions.DebitCard && radioValue === PaymentOptions.DebitCard)) && (
                            <Row style={{ margin: '16px 0px -24px -30px' }}>
                                <Col span={24}>
                                    {this.getTheFormItem(
                                        creditMockData[0],
                                        formMode,
                                        formValues as object,
                                        userPreviewOnly,
                                        agentLogin,
                                        reviewComments,
                                        commentThreads,
                                        true,
                                        true,
                                    )}
                                </Col>
                                <Col span={24}>
                                    {this.getTheFormItem(
                                        creditMockData[1],
                                        formMode,
                                        formValues as object,
                                        userPreviewOnly,
                                        agentLogin,
                                        reviewComments,
                                        commentThreads,
                                        true,
                                        true,
                                    )}
                                </Col>
                                <Col span={12} className="valid-code">
                                    {this.getTheFormItem(
                                        creditMockData[2],
                                        formMode,
                                        formValues as object,
                                        userPreviewOnly,
                                        agentLogin,
                                        reviewComments,
                                        commentThreads,
                                        true,
                                        true,
                                    )}
                                </Col>
                                <Col span={12} style={{ paddingLeft: '16px' }}>
                                    {this.getTheFormItem(
                                        creditMockData[3],
                                        formMode,
                                        formValues as object,
                                        userPreviewOnly,
                                        agentLogin,
                                        reviewComments,
                                        commentThreads,
                                        true,
                                        true,
                                    )}
                                </Col>
                            </Row>
                        )}
                        {option.text === PaymentOptions.BankAccount && radioValue === PaymentOptions.BankAccount && (
                            <Row style={{ margin: '16px 0px -24px -30px' }}>
                                {bankMockData.map(item => {
                                    return (
                                        <Col span={24}>
                                            {this.getTheFormItem(
                                                item,
                                                formMode,
                                                formValues as object,
                                                userPreviewOnly,
                                                agentLogin,
                                                reviewComments,
                                                commentThreads,
                                                true,
                                                true,
                                            )}
                                        </Col>
                                    );
                                })}
                            </Row>
                        )}
                        {option.text === PaymentOptions.CashOnDelivery && radioValue === PaymentOptions.CashOnDelivery && (
                            <div
                                style={{
                                    marginTop: '12px',
                                }}
                            >
                                <div className="cash-otp-input">
                                    Please type the verification code sent to you at {this.state.guestPhoneNumber}
                                </div>
                                <OtpInput
                                    count={6}
                                    callbackOtpEvent={this.handleOtp}
                                    errorMessage=""
                                    productEkart={true}
                                />
                            </div>
                        )}
                    </Radio>
                    {option.text === UserTypeValues.GuestUser ? (
                        <h2 className="register-divider">
                            <span>Or</span>
                        </h2>
                    ) : (
                        <></>
                    )}
                </>
            );
        });
        return radioOptions;
    };

    public prepareCheckboxOptions = (
        options: Array<IOptionItem>,
        isVertical: boolean | undefined,
    ): Array<React.ReactElement> => {
        let checkboxOptions: React.ReactElement[] = [];
        let style = {};
        const checkboxStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
            marginLeft: '0',
        };
        if (isVertical) {
            style = checkboxStyle;
        }
        checkboxOptions = options.map(option => {
            return (
                <Checkbox value={option.value} disabled={option.disabled} style={style}>
                    {option.text}
                </Checkbox>
            );
        });
        return checkboxOptions;
    };

    handleSubmit = e => {
        // whether or not to propagate events on both sides of eform
        // if(e.type !== 'synthetic') {
        //     emitter.publish({
        //         data: {
        //             action: 'handle_submit',
        //             payload: {}
        //         },
        //         subject: 'eform',
        //     });
        // }
        e.preventDefault();
        this.validateFieldsManually(true, false);
    };

    public invokeIntervalHandler(): void {
        const { formJson, intervalHandler } = this.props;
        let { timeInterval } = this.props;
        const timeSpentMs = getTimeDifferenceInMilliseconds(new Date(), this.currentTimeStamp);
        timeInterval = timeInterval || TEN;
        if (intervalHandler && timeSpentMs >= timeInterval * THOUSAND) {
            this.currentTimeStamp = new Date();
            intervalHandler(formJson, timeSpentMs);
        }
    }

    /**
     * callback function to check for the errors in form and disable the submit button
     * if any errors found
     * @param fieldsError Array<{fieldsName}> - each field contains error string if present
     */
    public hasErrors({ fieldsError, viaReview, metadata }: { fieldsError: any; viaReview?: boolean; metadata?: any }) {
        console.log(fieldsError,'errors')
        if (
            this.state.currentStep === CurrentStepNumbers.Two &&
            this.state.registrationValue === UserTypeValues.RegisteredUser
        ) {
            if (!this.state.hideLoginPassword) {
                return this.state.disablePhoneNext || !this.props.form.getFieldValue('password');
            }
            return this.state.disablePhoneNext;
        }
        if (this.state.showOTPPage) return this.state.disableOtpNext;
       
        const requiredUploads = metadata.filter(m => m.type === enumInputTypes.UPLOAD && m.required);
        let allUploaded:boolean = true;

        const { formValues } = this.props;
        requiredUploads.forEach(upload => {
            const uploadedData = formValues && formValues[upload.name];
            if(!uploadedData || !Array.isArray(uploadedData) || !uploadedData.length){
                allUploaded = false;
            }
        })
        if(!allUploaded){
            return true;
        }
        if(this.tableErrors.length){
            return true;
        }
        const documentError = Object.keys(this.rejectedDocuments).length;
        console.log('documentError',documentError)
        if (viaReview && documentError) {
            return documentError;
        }
        let productEForm = false;
        const { header, tags } = this.props.formJson;
        if (isArray(tags) && tags.includes('e-shopping')) productEForm = true;
        if (this.state.currentStep === CurrentStepNumbers.Three && productEForm) {
            return (
                this.props.form.getFieldValue('citizenId') === '' ||
                (this.props.form.getFieldValue('citizenId') === PaymentOptions.CashOnDelivery &&
                    this.state.disableOtpNext)
            );
        }
        if (this.state.currentStep === CurrentStepNumbers.Two && this.state.registrationValue === '' && productEForm)
            return false;
        if (
            this.state.currentStep === CurrentStepNumbers.Two &&
            this.state.registrationValue !== UserTypeValues.RegisteredUser &&
            productEForm
        ) {
            const values = this.props.form.getFieldsValue();
            if (!values.firstName || !values.lastName || !values.email || !values.mobileNumber) return true;
        }
        let userDetails: any = this.props.user;
        if (
            this.state.registrationValue === UserTypeValues.RegisteredUser &&
            userDetails &&
            userDetails.firstName &&
            productEForm
        ) {
            return false;
        }
        if (
            this.state.currentStep === CurrentStepNumbers.Four &&
            this.state.registrationValue === UserTypeValues.GuestUser &&
            this.props.form.getFieldValue('citizenId') === PaymentOptions.CashOnDelivery &&
            productEForm &&
            this.state.disableOtpNext
        )
            return true;

        const metaKeyByName = keyBy(metadata, 'name');

        // check every element in/before this step, if all fine allow to move forward
        return (
            Object.keys(fieldsError).some(field => {
                return (
                    fieldsError[field]
                );
            })
        );
    }

    /**
     *
     */
    public approveRejectHandler(
        documentField: string,
        documentId: number,
        status: enumDocumentStatus,
        reason?: string,
    ) {
        const { approveRejectDocument } = this.props;
        if (approveRejectDocument) {
            if (status === enumDocumentStatus.APPROVED) {
                delete this.rejectedDocuments[documentId];
                approveRejectDocument(documentField, documentId, status, reason);
            } else if (status === enumDocumentStatus.REJECTED) {
                this.setState({showRejectionReasonModel:true, rejectingDocument:{ documentId, documentField, status }})
            }
        }
    }

    private stopTimer() {
        clearInterval(this.timer);
    }

    public validateFieldsManually(
        requiredCheck: boolean,
        invokeBlurHandler: boolean,
        attachedDocument?: { invokeDocumentAttached: boolean; name: string },
        goToNextPage: boolean = true,
    ) {
        const {
            form,
            formJson,
            blurHandler,
            onDocumentAttached,
            formValuesHandler,
            preLogin,
            preLoginLoading,
            code,
            eformRegisterAction,
            loginDetailsAction,
        } = this.props;
        formJson.steps = formJson.steps || [];

        let productEForm = false;
        const { header, tags } = formJson;
        if (isArray(tags) && tags.includes('e-shopping')) productEForm = true;

        // if(formJson.steps && isArray(formJson.steps)) {
        //     const result = formJson.metadata.every((item)=>!!item.step);
        //     if(!result) {
        //         message.error('If steps is present, every metadata entry must have step added');
        //         return;
        //     }
        // }

        if (goToNextPage && formJson.steps.length && formJson.steps.length !== this.state.currentStep) {
            if (
                this.state.registrationValue === '' &&
                this.state.currentStep === CurrentStepNumbers.Two &&
                productEForm
            ) {
                this.setState({
                    registrationValue: form.getFieldValue('loginUser'),
                });
            } else if (
                this.state.currentStep === CurrentStepNumbers.Two &&
                productEForm &&
                this.state.registrationValue === UserTypeValues.GuestUser &&
                !this.state.showOTPPage
            ) {
                this.setState({
                    guestPhoneNumber: form.getFieldValue('mobileNumber'),
                    guestDetails: form.getFieldsValue(),
                    currentStep: this.state.currentStep + 1,
                });
                // this.props.resendOtpActionProp(this.props.code, 'Phone', form.getFieldValue('mobileNumber'));
            } else if (
                this.state.registrationValue === UserTypeValues.RegisteredUser &&
                this.state.currentStep === CurrentStepNumbers.Two &&
                !this.state.showOTPPage &&
                !this.state.showAddress &&
                productEForm
            ) {
                const phoneNumber = this.state.phoneNumber;
                if (!this.state.hideLoginPassword) {
                    loginDetailsAction(
                        code,
                        'phone',
                        form.getFieldValue('mobileNumber'),
                        form.getFieldValue('password'),
                    );
                } else {
                    preLogin(code, 'phone', phoneNumber);
                }
            } else if (
                this.state.registrationValue !== UserTypeValues.RegisteredUser &&
                this.state.currentStep === CurrentStepNumbers.Two &&
                !this.state.showOTPPage &&
                productEForm
            ) {
                this.setState(prevState => ({
                    guestPhoneNumber: form.getFieldValue('mobileNumber'),
                    // currentStep: prevState.currentStep + 1,
                }));
                eformRegisterAction(code, form.getFieldsValue());
            } else if (this.state.showOTPPage && productEForm) {
                let phoneN;
                if (this.state.registrationValue === UserTypeValues.GuestUser) {
                    this.props.verifyContactNumberAction(
                        this.props.code,
                        this.state.otpValue,
                        this.state.guestPhoneNumber,
                    );
                } else {
                    if (this.state.registrationValue !== UserTypeValues.RegisteredUser) {
                        phoneN = this.state.guestPhoneNumber;
                    } else {
                        phoneN = this.state.phoneNumber;
                    }
                    loginDetailsAction(code, 'phone', phoneN, this.state.otpValue);
                }
            } else if (this.state.showAddress && productEForm) {
                this.setState({
                    showAddress: false,
                    currentStep: CurrentStepNumbers.Three,
                });
                let userDetails: any = this.props.user;
                const { firstName, lastName, address, city, pincode, email, phoneNumber, landmark } = userDetails;
                form.setFieldsValue({
                    firstName: firstName,
                    lastName: lastName,
                    mobileNumber: phoneNumber,
                    email: email,
                    address: address,
                    city: city,
                    pincode: pincode,
                    landmark: landmark || '',
                });
            } else if (
                this.state.registrationValue !== UserTypeValues.GuestUser &&
                this.state.currentStep === CurrentStepNumbers.Two &&
                productEForm
            ) {
                eformRegisterAction(code, form.getFieldsValue());
            } else {
                this.setState(prevState => ({ currentStep: prevState.currentStep + 1 }));
                emitter.publish({
                    data: {
                        action: 'step-change',
                        step: this.state.currentStep + 1,
                    },
                    subject: 'eform',
                });
            }
            return;
        }

        if (requiredCheck) {
            console.log('here in requiredCheck')
            form.validateFields((err, formData) => {
                console.log(err,'err', formData)
                // let userDetails: any = this.props.user;
                if (!err && formValuesHandler) {
                    const datearr = getDateTypeFields(formJson.metadata);
                    let formTransformedValues = changeMomentDateToDate(formData, datearr);
                    const uploadFields = getUploadTypeFields(formJson.metadata);
                    formTransformedValues = changeToFileId(formData, uploadFields);
                    formTransformedValues = removeUnwantedFields(formTransformedValues);
                    formValuesHandler(formJson, formTransformedValues);
                }
            });
        } else {
            const tempValues = JSON.stringify(form.getFieldsValue() || '');
            if(tempValues && Object.keys(JSON.parse(tempValues)).length){
                form.validateFields((err, formData) => {
                    console.error('err',err)
                    this.prevFieldsValue = tempValues;
                    const datearr = getDateTypeFields(formJson.metadata);
                    let formTransformedValues = changeMomentDateToDate(formData, datearr);
                    const uploadFields = getUploadTypeFields(formJson.metadata);
                    formTransformedValues = changeToFileId(formData, uploadFields);
                    formTransformedValues = removeUnwantedFields(formTransformedValues);
                    const tableTypeKeys = formJson.metadata.filter(m => m.type === enumInputTypes.TABLE).map(m => m.name)
                    formTransformedValues = changeArrayDataToKeyValue(formTransformedValues, tableTypeKeys);

                    // will invoke the draft save api
                    if (invokeBlurHandler && blurHandler) {
                        blurHandler(formJson, formTransformedValues);
                    }

                    if (onDocumentAttached && attachedDocument && attachedDocument.invokeDocumentAttached) {
                        const { name } = attachedDocument;
                        onDocumentAttached(formJson, name);
                        // call draft save after every document upload
                        if (blurHandler) blurHandler(formJson, formTransformedValues);
                    }
                });
            }
        }
    }

    public renderDivider(width = 1) {
        return <Divider className="divider" style={width > 1 ? { borderRadius: width } : {}} />;
    }

    public renderHeightDivider() {
        return <Divider className="divider product-divider" />;
    }

    public disabledMaxDate(current) {
        // Can not select days after today and today
        return current && current.valueOf() >= Date.now();
    }

    public disabledMinDate(current) {
        // Can not select days before today
        const presentDate = new Date();
        presentDate.setHours(0);
        return current && current.valueOf() < presentDate;
    }

    public uploadSuccessful(files: ISuccessfulUploadedFile[], item: IUploadSchema): void {
        const { extractData, name, label, documentType } = item;
        /**
         * As per the discussion only 1 file needs to be supported at a time
         *
         */
        const file = files[ZERO];
        const { fileId, fileType, downloadLink, fileStream, name:fileName } = file;
        this.uploadedFileNames[name] = fileName;
        if (name === 'passport2') {
            const { fetchTextFromImage } = this.props;
            this.getBase64(fileStream, result => {
                const base64Data: any = result.split(',')[1];
                fetchTextFromImage && fetchTextFromImage(base64Data);
            });
        }
        const { form } = this.props;
        form.setFieldsValue({
            [name]: [fileId],
        });

        // update urls in the web and emit this event to gc realtime to replay in other devices
        this.getUploadedFileUrl({
            action: '',
            attributeName: name,
            dmsToken: this.props.dmsToken,
            downloadLink,
            emitToAll: true,
            fileId,
            fileType,
            fileName
        });
        this.validateFieldsManually(false, false, { invokeDocumentAttached: true, name }, false);

        // extract data from uploaded document
        if(extractData && documentType && fileName && label){
            const { extractDataFromFileDispatcher } = this.props;
            message.info('extracting the data from file')
            if(extractDataFromFileDispatcher){
                extractDataFromFileDispatcher(fileStream, fileName, label, documentType)
            }
        }
    }

    public getBase64(file: File, cb: Function): void {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function() {
            cb(reader.result);
        };
        reader.onerror = function(error) {
            console.log('Error: ', error);
        };
    }

    /* eslint class-methods-use-this: 0 */
    public uploadStarted(): void {
        message.info('started file uploading');
    }

    /* eslint class-methods-use-this: 0 */
    public uploadFailed(): void {
        message.error('Error in uploading the file');
    }

    public checkAndUpdateValues(): void {
        const { form, formJson, formValues } = this.props;
        if (formValues) {
            const datearr = getDateTypeFields(formJson.metadata);
            const transformedFormValues = changeDateToMomentDate(formValues, datearr);
            console.log('checkAndUpdateValues',transformedFormValues)
            form.setFieldsValue(transformedFormValues);
        }
    }

    public onCanvasChange(): void {
        const { agentLogin } = this.props;
        if (agentLogin && saveableCanvas) {
            const { lines } = saveableCanvas;
            const { clientHeight, clientWidth } = saveableCanvas.canvas.grid;

            const lastLine = lines[lines.length - 1];
            emitter.publish({
                data: {
                    action: 'canvas_draw',
                    height: clientHeight,
                    lines: [lastLine],
                    width: clientWidth,
                },
                subject: 'eform',
            });
        }
    }

    public onCanvasClear(): void {
        const { agentLogin } = this.props;
        if (agentLogin) {
            emitter.publish({
                data: {
                    action: 'canvas_clear',
                },
                subject: 'eform',
            });
            if (saveableCanvas) {
                saveableCanvas.clear();
            }
        }
    }

    public getUploadedFileUrl(fileDescriptor: IUploadedFileDescriptor): void {
        // race around hack
        // eslint-disable-next-line no-param-reassign
        fileDescriptor.action = FETCH_UPLOADED_DATA;
        if (this.props.getUploadedFileUrl) {
            this.props.getUploadedFileUrl(fileDescriptor);
            if (fileDescriptor.emitToAll) {
                // eslint-disable-next-line no-param-reassign
                emitter.publish({
                    data: fileDescriptor,
                    subject: 'eform',
                });
            }
        }
    }
    // public enableCanvas(): void {
    //     const { canvasEnabled } = this.state;
    //     this.setState({ canvasEnabled: !canvasEnabled });
    // }

    public getProductStringName() {
        if (this.state.currentStep === CurrentStepNumbers.One) return 'Proceed to Buy';
        else if (this.state.currentStep === CurrentStepNumbers.Two) {
            if (
                this.state.registrationValue === UserTypeValues.RegisteredUser &&
                !this.state.showOTPPage &&
                !this.state.showAddress
            )
                return 'Sign in';
            if (
                this.state.registrationValue !== UserTypeValues.GuestUser &&
                this.state.registrationValue !== UserTypeValues.RegisteredUser
            ) {
                if (this.state.showOTPPage) return 'Next';
                return 'Register';
            }
            return 'Add Address';
        } else if (this.state.currentStep === CurrentStepNumbers.Three) {
            if (this.state.registrationValue === UserTypeValues.GuestUser) {
                return 'Pay Now';
            }
            if (this.state.showOTPPage) return 'Next';
            return 'Pay Now';
        } else return 'Pay Now';
    }

    public getOtpPage() {
        const {
            form,
            formJson,
            formMode,
            formValues,
            userPreviewOnly,
            submittingEform,
            savingEform,
            noSubmissionEformType,
            agentLogin,
            isFormReviewable,
            sendToReview,
            reviewComments,
            commentThreads,
            canvasEnabled,
            showNotesModal,
            notesModalCloseHandler,
            code: linkCode,
            eformVersionId,
            fetchReviewCommentsHandler,
            user,
            loginValue,
        } = this.props;
        let phoneN;
        if (this.state.registrationValue !== UserTypeValues.RegisteredUser) {
            phoneN = this.state.guestPhoneNumber;
        } else {
            phoneN = this.state.phoneNumber;
        }
        const OtpHeading = {
            type: 'sectionheader',
            label: 'Verification Code',
            name: 'Verification Code',
            defaultValue: 'Please type the verification code sent to you at ' + phoneN,
            doNotRender: false,
        };
        return (
            <div className="otp-product">
                {this.getTheFormItem(
                    OtpHeading,
                    formMode,
                    formValues as object,
                    userPreviewOnly,
                    agentLogin,
                    reviewComments,
                    commentThreads,
                    true,
                )}
                <OtpInput count={6} callbackOtpEvent={this.handleOtp} errorMessage="" productEkart={true} />
            </div>
        );
    }

    public showAddressPage(productPrice) {
        const {
            form,
            formJson,
            formMode,
            formValues,
            userPreviewOnly,
            submittingEform,
            savingEform,
            noSubmissionEformType,
            agentLogin,
            isFormReviewable,
            sendToReview,
            reviewComments,
            commentThreads,
            canvasEnabled,
            showNotesModal,
            notesModalCloseHandler,
            code: linkCode,
            eformVersionId,
            fetchReviewCommentsHandler,
            user,
            loginValue,
        } = this.props;
        if (user) {
            let userDetails: any = user;
            const { firstName, lastName, address, city, pincode, email, phoneNumber, landmark } = userDetails;
            let addressDetails;
            if (landmark) {
                addressDetails = address + ', ' + landmark + ', ' + city + '-' + pincode;
            } else {
                addressDetails = address + ', ' + city + '-' + pincode;
            }
            const loginMetaData = [
                {
                    type: 'sectionheader',
                    label: 'Address',
                    name: 'Address',
                    doNotRender: false,
                },
                {
                    type: 'sectionheader',
                    label: 'Customer Details',
                    name: 'Customer Details',
                    doNotRender: false,
                },
            ];
            const deliverMetadata = {
                type: 'sectionheader',
                label: 'Deliver To',
                name: 'Deliver To',
                doNotRender: false,
            };
            return (
                <React.Fragment>
                    {loginMetaData.map(item => {
                        return this.getTheFormItem(
                            item,
                            formMode,
                            formValues as object,
                            userPreviewOnly,
                            agentLogin,
                            reviewComments,
                            commentThreads,
                            true,
                        );
                    })}
                    <Row>
                        <Col span={24} className="address-product-name-details">
                            {firstName} {lastName}
                        </Col>
                        <Col span={24} className="address-phone-margin">
                            <img src={Envelope} className="address-icon" />
                            <span className="address-phone-detail"> {email}</span>
                        </Col>
                        <Col span={24} className="address-email-margin">
                            <img src={Call} className="address-icon" />
                            <span className="address-phone-detail"> {phoneNumber}</span>
                        </Col>
                        {this.getTheFormItem(
                            deliverMetadata,
                            formMode,
                            formValues as object,
                            userPreviewOnly,
                            agentLogin,
                            reviewComments,
                            commentThreads,
                            true,
                        )}
                        <Col span={24} className="product-address-details">
                            <div className="product-address-name">
                                {firstName} {lastName},
                            </div>
                            <span className="product-address-name"> {addressDetails}</span>
                        </Col>
                        <Col span={24} className="pricing-section">
                            <div style={{ marginBottom: '16px' }}>{this.renderDivider(8)}</div>
                            <div>
                                <strong className="pricing-strong-text">Payment Details</strong>
                            </div>
                            <div className="pricing-section-div">
                                <div>Sum</div>
                                <div>
                                    {(
                                        productPrice *
                                        Number(this.state.productQuantity.toString().replace(/\D/g, '') || 0.0)
                                    ).toFixed(2)}
                                </div>
                            </div>
                            <div className="pricing-section-div" style={{ marginBottom: '4px' }}>
                                <div>VAT</div>
                                <div>0.00</div>
                            </div>
                            {this.renderDivider()}
                            <div className="pricing-section-div payable-section">
                                <div style={{ fontSize: '16px' }}>Total Payable</div>
                                <div>
                                    {(
                                        productPrice *
                                        Number(this.state.productQuantity.toString().replace(/\D/g, '') || 0.0)
                                    ).toFixed(2)}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </React.Fragment>
            );
        } else {
            return <></>;
        }
    }

    showModal = (e) => {
        e.preventDefault();
        this.setState({
            isModalVisible: true});
    };
    
    handleCancel = () => {
        this.setState({
            isModalVisible: false});
    };

    handleAddressMobileNumber = (isValid, value) => {
        if (!isValid) {
            this.setState({ disablePhoneNext: true });
        } else {
            this.setState({ disablePhoneNext: false });
        }
    };

    handleMobileNumber = (isValid, value) => {
        if (!isValid) {
            this.setState({ disablePhoneNext: true });
        } else {
            this.setState({ disablePhoneNext: false, phoneNumber: value });
        }
    };

    handleOtp = (otp: number) => {
        let otpLength = 6;
        this.setState({ otpValue: otp });
        if (otp && otp.toString().length === otpLength) {
            this.setState({ disableOtpNext: false });
        } else {
            this.setState({ disableOtpNext: true });
        }
    };

    public getLoginPage() {
        const {
            form,
            formJson,
            formMode,
            formValues,
            userPreviewOnly,
            submittingEform,
            savingEform,
            noSubmissionEformType,
            agentLogin,
            isFormReviewable,
            sendToReview,
            reviewComments,
            commentThreads,
            canvasEnabled,
            showNotesModal,
            notesModalCloseHandler,
            code: linkCode,
            eformVersionId,
            fetchReviewCommentsHandler,
            user,
        } = this.props;
        const loginMetaData = {
            type: 'sectionheader',
            label: UserTypeValues.RegisteredUser,
            name: 'RegisteredUser',
            doNotRender: false,
        };
        const loginPasswordMetaData = [
            {
                type: 'input',
                required: true,
                label: 'Password',
                name: 'password',
                requiredErrorMsg: 'Password is required',
                step: 2,
                placeholder: 'Enter your password',
            },
        ];
        return (
            <React.Fragment>
                {this.getTheFormItem(
                    loginMetaData,
                    formMode,
                    formValues as object,
                    userPreviewOnly,
                    agentLogin,
                    reviewComments,
                    commentThreads,
                    true,
                )}
                {!this.state.hideLoginPassword ? (
                    <div style={{ marginTop: '24px', marginBottom: '8px' }} className="phone-section-login">
                        <PhoneNumber validNumber={this.handleMobileNumber} />
                        {loginPasswordMetaData.map(item => {
                            return this.getTheFormItem(
                                item,
                                formMode,
                                formValues as object,
                                userPreviewOnly,
                                agentLogin,
                                reviewComments,
                                commentThreads,
                                true,
                            );
                        })}
                    </div>
                ) : (
                    <>
                        <Text className="login-number-text">Enter your Phone Number</Text>
                        <div className="inputElement provideMobile login-mobile-margin">
                            <PhoneNumber validNumber={this.handleMobileNumber} />
                        </div>
                    </>
                )}
            </React.Fragment>
        );
    }

    public getRegisteredPage() {
        const {
            form,
            formJson,
            formMode,
            formValues,
            userPreviewOnly,
            submittingEform,
            savingEform,
            noSubmissionEformType,
            agentLogin,
            isFormReviewable,
            sendToReview,
            reviewComments,
            commentThreads,
            canvasEnabled,
            showNotesModal,
            notesModalCloseHandler,
            code: linkCode,
            eformVersionId,
            fetchReviewCommentsHandler,
            user,
        } = this.props;
        const registerMetaData = [
            {
                type: 'sectionheader',
                label: productEformConstants.CheckoutAs,
                name: 'sectionheader2',
                doNotRender: false,
            },
            {
                type: 'radiogroup',
                options: [
                    { value: UserTypeValues.GuestUser, text: UserTypeValues.GuestUser },
                    { value: UserTypeValues.RegisteredUser, text: UserTypeValues.RegisteredUser },
                ],
                required: false,
                label: productEformConstants.CheckoutAs,
                name: 'loginUser',
                isReviewable: true,
                defaultValue: '',
                isVertical: true,
                doNotRender: false,
            },
        ];
        return (
            <div>
                {registerMetaData.map((item: any) => {
                    return this.getTheFormItem(
                        item,
                        formMode,
                        formValues as object,
                        userPreviewOnly,
                        agentLogin,
                        reviewComments,
                        commentThreads,
                        true,
                    );
                })}
            </div>
        );
    }

    public setRejectedDocuments(metadata: IFormComponent[], formValues:IFormValues):void{
        if(!formValues){
            return;
        }
        const uploads = metadata.filter(m => m.type === enumInputTypes.UPLOAD);
        this.rejectedDocuments = {};
        uploads.forEach(upload => {
            const uploadedData = formValues[upload.name];
            if(Array.isArray(uploadedData) && uploadedData[0]){
                const uploadedDetails = uploadedData[0] as IFileUploaded;
                if(uploadedDetails.status === enumDocumentStatus.REJECTED){
                    this.rejectedDocuments[upload.name] = 1;
                }
            }
        })
    }
    public setUploadedFileName(metadata: IFormComponent[], formValues:IFormValues):void{
        if(!formValues){
            return;
        }
        const uploads = metadata.filter(m => m.type === enumInputTypes.UPLOAD);
        uploads.forEach(upload => {
            const uploadedData = formValues[upload.name];
            if(Array.isArray(uploadedData) && uploadedData[0]){
                const {fileName} = uploadedData[0] as IFileUploaded;
                if(fileName && !this.uploadedFileNames[upload.name]){
                    this.uploadedFileNames[upload.name] = fileName;
                }
            }
        })
    }

    public rejectionReasonCloseHandler():void{
        this.setState({showRejectionReasonModel : false, rejectingDocument:{}})
    }

    public rejectionReasonSaveHandler(reason: string): void{
        const { rejectingDocument } = this.state;
        const { approveRejectDocument } = this.props;
        if(rejectingDocument && approveRejectDocument){
            const { documentId, documentField, status } = rejectingDocument;
            this.rejectedDocuments[documentId] = 1;
            approveRejectDocument(documentField, documentId, status, reason);
            this.setState({showRejectionReasonModel : false, rejectingDocument:{}})
        }
    }

    public updateDependents(dependents:string[] = [], fieldName:string, value:any): void{
        const {
            form,
            formJson,
            resetDependentDropdownOptionsHandler,
            fetchDropdownOptionsHandler,
            fetchDataHandler,
            fetchAutoCalculateHandler
        } = this.props;
        const { setFieldsValue } = form;
        setFieldsValue({ [fieldName]: value }) 
        if(dependents && dependents.length){
            dependents.forEach(name => {
                setFieldsValue({[name]:undefined})
            })
            if(resetDependentDropdownOptionsHandler){
                resetDependentDropdownOptionsHandler(dependents)
            }
            
            if(!fetchDropdownOptionsHandler || !fetchDataHandler || !fetchAutoCalculateHandler){
                return;
            }
            const { metadata } = formJson;
            dependents.forEach(name => {
                const meta = metadata.find(m => m.name === name) as ISelectSchema | undefined;
                if(meta){
                    const { fetchOptions, fetchData, fetchAutoCalculate, dependsOn = [], defaultApiValues = {}, apiKeyName, responseKeys={}, formula } = meta;
                    if(fetchOptions && apiKeyName){
                        const prerequisites:{[key:string]:string | boolean | number} = {};
                        if(Object.entries(defaultApiValues).length){
                            for (const [key, val] of Object.entries(defaultApiValues)) {
                                if(val && typeof(val)==='string' && val.startsWith('::')){
                                    prerequisites[key] = form.getFieldValue(val.substring(2))
                                }else{
                                    prerequisites[key] = val;
                                }
                            }
                        }
                        dependsOn.forEach(name => {
                            const value = form.getFieldValue(name)
                            const {apiKeyName:dependsOnApiKeyName} = metadata.find(m => m.name === name) as ISelectSchema;
                            if(dependsOnApiKeyName){
                                if(value){
                                    prerequisites[dependsOnApiKeyName] = value;
                                }
                            }
                        })
                        const dependsOnLength = (dependsOn.length || 0) + (Object.keys(defaultApiValues).length || 0);

                        if(!dependsOn || (dependsOnLength === Object.keys(prerequisites).length)){
                            fetchDropdownOptionsHandler(meta.name, meta.label || meta.name, apiKeyName, prerequisites)
                        }
                    }
                    if(fetchData && apiKeyName){
                        const prerequisites:{[key:string]:string} = {};
                        if(Object.entries(defaultApiValues).length){
                            for (const [key, val] of Object.entries(defaultApiValues)) {
                                if(val && typeof(val)==='string' && val.startsWith('::')){
                                    prerequisites[key] = form.getFieldValue(val.substring(2))
                                }else{
                                    prerequisites[key] = val;
                                }
                            }
                        }
                        dependsOn.forEach(name => {
                            const value = form.getFieldValue(name)
                            const {apiKeyName:dependsOnApiKeyName} = metadata.find(m => m.name === name) as ISelectSchema;
                            if(dependsOnApiKeyName){
                                if(value){
                                    prerequisites[dependsOnApiKeyName] = value;
                                }
                            }
                        })
                        const dependsOnLength = (dependsOn.length || 0) + (Object.keys(defaultApiValues).length || 0);

                        if(!dependsOn || (dependsOnLength === Object.keys(prerequisites).length)){
                            fetchDataHandler(meta.name, meta.label || meta.name, apiKeyName, prerequisites, responseKeys)
                        }
                    }
                    if(fetchAutoCalculate && formula){
                        const prerequisites:{[key:string]:number} = {};
                        dependsOn.forEach(name => {
                            const value = form.getFieldValue(name)
                            if(value){
                                prerequisites[name] = parseInt(value);
                            }
                        })
                        const dependsOnLength = (dependsOn.length || 0);

                        if(!dependsOn || (dependsOnLength === Object.keys(prerequisites).length)){
                            fetchAutoCalculateHandler(meta.name, meta.label || meta.name, formula, prerequisites, dependsOn)
                        }
                    }
                }
            })
        }
        this.validateFieldsManually(false, true, undefined, false);
    }

    public storeTableData(key:string, value:unknown):void{
        this.tableData[key]=value;
        this.props.form.setFieldsValue({[key]:value})
    }
    public getTableData(key:string):unknown{
        return this.tableData[key];
    }
    public handleTableErrors(errors:string[], resolved:boolean):void{
        if(resolved){
            errors.forEach(error => {
                this.tableErrors = this.tableErrors.filter(err => err !== error)
            })
        }else{
            this.tableErrors.push(...errors)
        }
    }
    public render(): React.ReactNode {
        const {
            form,
            formJson,
            formMode,
            formValues,
            userPreviewOnly,
            submittingEform,
            savingEform,
            noSubmissionEformType,
            agentLogin,
            isFormReviewable,
            sendToReview,
            reviewComments,
            commentThreads,
            canvasEnabled,
            showNotesModal,
            notesModalCloseHandler,
            code: linkCode,
            eformVersionId,
            fetchReviewCommentsHandler,
            user,
            preLoginLoading,
            loginLoading,
            registeringEform,
        } = this.props;

        const { FILLED, VIEW, PREVIEW } = enumEFormModes;
        const { header, tags, formStatus, allowEdit } = formJson;
        let dataEForm = { ...formJson };
        let metadata: any = cloneDeep(dataEForm.metadata);
        let productEForm = false;
        let pageWithImage: any = 0;
        let imagePageMetadata: Array<any> = [];
        let imageObject: any;
        let sectionImageObject: any;
        let productPrice: any = 1;
        let steps = formJson.steps || [];
        let onlyTargetUserCanSubmit = formJson.onlyTargetUserCanSubmit || false;
        let accountOpening = false;
        let landingPageData: any = {};
        if (isArray(tags) && tags.includes('e-shopping')) productEForm = true;
        if (isArray(tags) && tags.includes('account') && tags.includes('opening')) accountOpening = true;
        if (productEForm && metadata) {
            landingPageData = getProductLandingPageData(metadata);
            imageObject = metadata.filter(item => item.type === enumInputTypes.IMAGE);
            let imageValueUrl = imageObject[0].value;
            let newUrl = imageValueUrl + '?token=' + this.props.dmsToken;
            imageObject && this.state.imageUrl === '' && this.setState({ imageUrl: newUrl });
            productPrice = Number(metadata.filter(item => item.name === 'Product Price')[0].value);
            sectionImageObject = metadata.filter(item => item.type === enumInputTypes.SECTION_HEADER);
            if (isArray(imageObject) && imageObject.length) pageWithImage = imageObject[0].step;
            // steps.length === 3 && steps.push({ title: 'Page 4', description: 'Page 4', order: 4 });
            let sectionLabel = '';
            if (this.state.registrationValue === UserTypeValues.GuestUser) {
                sectionLabel = UserTypeValues.GuestUser;
            } else {
                sectionLabel = 'Register';
            }
            const customerMetaData = [
                {
                    type: 'sectionheader',
                    label: 'Add a New Address',
                    name: 'sectionheader2',
                    step: 2,
                    doNotRender: true,
                },
                {
                    type: 'input',
                    label: 'First Name',
                    name: 'firstName',
                    placeholder: 'Please enter your first name',
                    required: true,
                    requiredErrorMsg: 'First Name is required',
                    step: 2,
                    doNotRender: true,
                },
                {
                    type: 'input',
                    label: 'Last Name',
                    name: 'lastName',
                    placeholder: 'Please enter your last name',
                    required: true,
                    requiredErrorMsg: 'Last Name is required',
                    step: 2,
                    doNotRender: true,
                },
                {
                    type: 'input',
                    label: 'Email',
                    name: 'email',
                    placeholder: 'Please enter your email',
                    required: true,
                    requiredErrorMsg: 'Email is required',
                    step: 2,
                    doNotRender: true,
                },
                {
                    type: 'input',
                    label: 'Mobile Number',
                    name: 'mobileNumber',
                    placeholder: '+91 1234567890',
                    required: true,
                    requiredErrorMsg: 'MObile Number is required',
                    step: 2,
                    doNotRender: true,
                },
                {
                    type: 'input',
                    label: 'Address',
                    name: 'address',
                    placeholder: 'Please enter your address',
                    required: true,
                    requiredErrorMsg: 'Address is required',
                    step: 2,
                    doNotRender: true,
                },
                {
                    type: 'input',
                    label: 'Pincode',
                    name: 'pincode',
                    placeholder: 'Please enter your pincode',
                    required: true,
                    requiredErrorMsg: 'Pincode is required',
                    step: 2,
                    doNotRender: true,
                },
                {
                    type: 'input',
                    label: 'Landmark (optional)',
                    name: 'landmark',
                    placeholder: 'Please enter your landmark',
                    required: false,
                    // requiredErrorMsg: 'Email is required',
                    step: 2,
                    doNotRender: true,
                },
            ];
            if (this.state.registrationValue !== UserTypeValues.GuestUser) {
                customerMetaData.splice(6, 0, {
                    type: 'input',
                    label: 'Password',
                    name: 'password',
                    placeholder: 'At least 6 characters',
                    required: true,
                    requiredErrorMsg: 'Password is required',
                    step: 2,
                    doNotRender: true,
                    min: 6,
                });
            }
            let newMetadata = [...metadata];
            const temp = newMetadata[5];
            newMetadata[5] = newMetadata[3];
            newMetadata[3] = temp;
            let filteredMetaData = newMetadata.filter(item => item.step !== 2);
            metadata = [...filteredMetaData, ...customerMetaData];
            imagePageMetadata = metadata.filter(
                item =>
                    item.step === pageWithImage &&
                    item.type !== enumInputTypes.IMAGE &&
                    item.type !== enumInputTypes.SECTION_HEADER,
            );
        }
        isArray(steps) && steps.sort((a, b) => a.order - b.order);

        this.setRejectedDocuments(metadata, formValues as IFormValues)
        this.setUploadedFileName(metadata, formValues as IFormValues)

        const { getFieldsError } = form;

        const { showRejectionReasonModel } = this.state;

        return (
            <React.Fragment>
                <Row>
                    {steps && steps.length > 0 && !productEForm && (
                        <Col span={24} xs={2} md={24}>
                            <Tooltip title={steps[this.state.currentStep - 1].title} placement="left">
                                <Steps
                                    direction="horizontal"
                                    size="small"
                                    current={this.state.currentStep - 1}
                                    style={{ marginBottom: '12px' }}
                                >
                                    {isArray(steps) &&
                                        steps.map((s, index) => (
                                            <Step
                                                style={{ cursor: 'pointer' }}
                                                //   title={s.title}
                                                //   description={s.description}
                                                onClick={() => {
                                                    (!productEForm || this.state.currentStep > index + 1) &&
                                                        this.setState({ currentStep: index + 1 });
                                                }}
                                            />
                                        ))}
                                </Steps>
                            </Tooltip>
                        </Col>
                    )}
                    <Col span={24} xs={productEForm ? 24 : 22} md={24}>
                        {formMode !== VIEW && formMode !== FILLED && <h3>{header}</h3>}
                        {accountOpening && <div className="pageTitleInMobileView">{header}</div>}
                        <Form
                            onSubmit={this.handleSubmit}
                            className={`eFormRoot${userPreviewOnly ? ' previewOnly' : ''}${
                                productEForm ? ' product-eform' : ''
                            }${
                                steps && steps.length > 0 ? ' fixed-steps' : ''
                            }`}
                        >
                            {metadata.length !== ZERO &&
                                !productEForm &&
                                metadata.map((item, index) => {
                                    // to show only the fields in the current step or if the steps array was not present in the
                                    // form, build the entire form
                                    if (!(isArray(steps) && item.step !== this.state.currentStep)) {
                                        item.doNotRender = false;
                                    } else if (isArray(steps)) {
                                        item.doNotRender = true;
                                    }
                                    let addDivider = false
                                    if(index && item.step && item.step !== metadata[index-1].step){
                                        addDivider = true;
                                    }
                                   
                                    const rNode = this.getTheFormItem(
                                        item,
                                        formMode,
                                        formValues as object,
                                        userPreviewOnly,
                                        agentLogin,
                                        reviewComments,
                                        commentThreads,
                                        false,
                                        false,
                                        accountOpening,
                                    );
                                    if(addDivider){
                                        return <>
                                            <div className="page-divider">{this.renderDivider()}</div>
                                            {rNode}
                                        </>   
                                    }
                                    return rNode;
                                })}
                            {metadata.length !== ZERO && productEForm && this.state.showProductLandingPage && (
                                <>
                                    <Row>
                                        <Col span={24}>
                                            {imageObject &&
                                                imageObject.length &&
                                                this.getTheFormItem(
                                                    imageObject[0],
                                                    formMode,
                                                    formValues as object,
                                                    userPreviewOnly,
                                                    agentLogin,
                                                    reviewComments,
                                                    commentThreads,
                                                    true,
                                                )}
                                            {landingPageData.name && (
                                                <div className="landing-data">
                                                    <div className="landing-title">{landingPageData.name}</div>
                                                    <div className="landing-price">
                                                        <span>&#36;</span>
                                                        {Number(landingPageData.price).toFixed(2)}
                                                    </div>
                                                    <div className="landing-description">
                                                        {landingPageData.description}
                                                    </div>
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                    <div className="product-eform-footer">
                                        <Button
                                            className="submitButton button-product-text eform-product-button landing-bag-button"
                                            style={{ marginTop: '10px !important', marginBottom: '10px !important' }}
                                            onClick={() => this.setState({ showProductLandingPage: false })}
                                        >
                                            {' '}
                                            Add to Bag
                                        </Button>
                                        <Button
                                            type="primary"
                                            className="submitButton button-product-text eform-product-button footer-color-button"
                                            style={{ marginTop: '10px !important', marginBottom: '10px !important' }}
                                            onClick={() => this.setState({ showProductLandingPage: false })}
                                        >
                                            {' '}
                                            Buy Now
                                        </Button>
                                    </div>
                                </>
                            )}
                            {metadata.length !== ZERO && productEForm && !this.state.showProductLandingPage && (
                                <>
                                    {imagePageMetadata.length !== ZERO &&
                                        imagePageMetadata[0].step === this.state.currentStep && (
                                            <Row>
                                                <Col span={24}>
                                                    {this.getTheFormItem(
                                                        sectionImageObject[0],
                                                        formMode,
                                                        formValues as object,
                                                        userPreviewOnly,
                                                        agentLogin,
                                                        reviewComments,
                                                        commentThreads,
                                                        true,
                                                    )}
                                                </Col>
                                                {
                                                    <>
                                                        <Col span={10}>
                                                            {this.getTheFormItem(
                                                                imageObject[0],
                                                                formMode,
                                                                formValues as object,
                                                                userPreviewOnly,
                                                                agentLogin,
                                                                reviewComments,
                                                                commentThreads,
                                                                true,
                                                            )}
                                                        </Col>
                                                        <Col span={14} className="right-product-data">
                                                            {imagePageMetadata.map(item => {
                                                                if (
                                                                    item.label === productEformConstants.ProductQuantity
                                                                ) {
                                                                    item.label = '';
                                                                    item.placeholder = '';
                                                                    if (item.value) {
                                                                        item.value = 'Qty: ' + item.value;
                                                                    } else {
                                                                        item.value = 'Qty: ' + 1;
                                                                    }
                                                                    item.type = enumInputTypes.SELECT;
                                                                    let options: Array<any> = [];
                                                                    for (let i = 1; i <= 10; i++) {
                                                                        options.push({ value: i, text: i });
                                                                    }

                                                                    item.options = options;
                                                                }
                                                                if (
                                                                    item.label ===
                                                                    productEformConstants.ProductDescription
                                                                )
                                                                    return <></>;
                                                                return this.getTheFormItem(
                                                                    item,
                                                                    formMode,
                                                                    formValues as object,
                                                                    userPreviewOnly,
                                                                    agentLogin,
                                                                    reviewComments,
                                                                    commentThreads,
                                                                    true,
                                                                );
                                                            })}
                                                        </Col>
                                                        <Col span={24} className="pricing-section">
                                                            <div style={{ marginBottom: '16px' }}>
                                                                {this.renderHeightDivider()}
                                                            </div>
                                                            <div>
                                                                <strong className="pricing-strong-text pricing-order-margin">
                                                                    Order Details
                                                                </strong>
                                                            </div>
                                                            <div className="pricing-section-div">
                                                                <div>Sum</div>
                                                                <div>
                                                                    <span>&#36;</span>
                                                                    {(
                                                                        productPrice *
                                                                        Number(
                                                                            form
                                                                                .getFieldValue('Product Quantity')
                                                                                .toString()
                                                                                .replace(/\D/g, '') || 0.0,
                                                                        )
                                                                    ).toFixed(2)}
                                                                </div>
                                                            </div>
                                                            <div className="pricing-section-div">
                                                                <div>VAT</div>
                                                                <div>
                                                                    <span>&#36;</span>0.00
                                                                </div>
                                                            </div>
                                                            <div className="pricing-section-div">
                                                                <div>Delivery</div>
                                                                <div style={{ color: '#098B1E' }}>FREE</div>
                                                            </div>
                                                            {this.renderDivider()}
                                                            <div className="pricing-section-div payable-section">
                                                                <div style={{ fontSize: '16px' }}>Total Amount</div>
                                                                <div>
                                                                    <span>&#36;</span>
                                                                    {(
                                                                        productPrice *
                                                                        Number(
                                                                            form
                                                                                .getFieldValue('Product Quantity')
                                                                                .toString()
                                                                                .replace(/\D/g, '') || 0.0,
                                                                        )
                                                                    ).toFixed(2)}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </>
                                                }
                                            </Row>
                                        )}
                                    {this.state.currentStep === CurrentStepNumbers.Two &&
                                        this.state.registrationValue === '' &&
                                        this.getRegisteredPage()}
                                    {this.state.currentStep === CurrentStepNumbers.Two &&
                                        this.state.registrationValue === UserTypeValues.RegisteredUser &&
                                        !this.state.showOTPPage &&
                                        !this.state.showAddress &&
                                        this.getLoginPage()}
                                    {this.state.showOTPPage && this.getOtpPage()}
                                    {this.state.showAddress && this.showAddressPage(productPrice)}
                                    {metadata.map(item => {
                                        if (
                                            (imagePageMetadata.length !== ZERO &&
                                                imagePageMetadata[0].step === item.step) ||
                                            item.label === productEformConstants.SavedPaymentOptions
                                        )
                                            return;
                                        if (
                                            item.label === productEformConstants.Checkout &&
                                            this.state.registrationValue !== UserTypeValues.GuestUser
                                        ) {
                                            item.label = 'Register';
                                        }
                                        if (!(isArray(steps) && item.step !== this.state.currentStep)) {
                                            item.doNotRender = false;
                                        } else if (isArray(steps)) {
                                            item.doNotRender = true;
                                        }
                                        if (
                                            this.state.currentStep === CurrentStepNumbers.Two &&
                                            (this.state.registrationValue === '' ||
                                                this.state.registrationValue === UserTypeValues.RegisteredUser)
                                        )
                                            return;
                                        if (this.state.showOTPPage) return;
                                        if (
                                            (item.label === productEformConstants.OtherPaymentOptions ||
                                                item.label === productEformConstants.paymentOptions) &&
                                            !item.doNotRender
                                        ) {
                                            item.label = productEformConstants.paymentOptions;
                                            return (
                                                <>
                                                    {this.getTheFormItem(
                                                        item,
                                                        formMode,
                                                        formValues as object,
                                                        userPreviewOnly,
                                                        agentLogin,
                                                        reviewComments,
                                                        commentThreads,
                                                        true,
                                                    )}
                                                    <div style={{ margin: '-18px 0px 16px 0px' }}>
                                                        {this.renderHeightDivider()}
                                                    </div>
                                                    <div
                                                        className="product-payment-details"
                                                        style={{ paddingTop: '9px !important' }}
                                                    >
                                                        <strong className="pricing-strong-text">Order Details</strong>
                                                        <div className="pricing-section-div">
                                                            <div style={{ fontWeight: 400 }}>Bag Total</div>
                                                            <div>
                                                                <span>&#36;</span>
                                                                {(
                                                                    productPrice *
                                                                    Number(
                                                                        this.state.productQuantity
                                                                            .toString()
                                                                            .replace(/\D/g, '') || 0.0,
                                                                    )
                                                                ).toFixed(2)}
                                                            </div>
                                                        </div>
                                                        <div className="pricing-section-div">
                                                            <div>VAT</div>
                                                            <div>
                                                                <span>&#36;</span>0.00
                                                            </div>
                                                        </div>
                                                        <div className="pricing-section-div">
                                                            <div>Delivery</div>
                                                            <div style={{ color: '#098B1E' }}>FREE</div>
                                                        </div>
                                                        {this.renderDivider()}
                                                        <div className="pricing-section-div payable-section">
                                                            <div style={{ fontSize: '16px' }}>Total Amount</div>
                                                            <div>
                                                                <span>&#36;</span>
                                                                {(
                                                                    productPrice *
                                                                    Number(
                                                                        this.state.productQuantity
                                                                            .toString()
                                                                            .replace(/\D/g, '') || 0.0,
                                                                    )
                                                                ).toFixed(2)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        }
                                        return this.getTheFormItem(
                                            item,
                                            formMode,
                                            formValues as object,
                                            userPreviewOnly,
                                            agentLogin,
                                            reviewComments,
                                            commentThreads,
                                            true,
                                        );
                                    })}
                                </>
                            )}
                            {productEForm && (
                                <>
                                    {(this.state.registrationValue === '' &&
                                        this.state.currentStep === CurrentStepNumbers.Two) ||
                                    this.state.showProductLandingPage ? null : (
                                        <>
                                            <Form.Item className="actionButtons">
                                                <div className="product-eform-footer">
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        disabled={this.hasErrors({
                                                            fieldsError: getFieldsError(),
                                                            metadata,
                                                        })}
                                                        loading={
                                                            submittingEform ||
                                                            (preLoginLoading &&
                                                                this.state.currentStep !== CurrentStepNumbers.Four) ||
                                                            loginLoading ||
                                                            registeringEform
                                                        }
                                                        className="submitButton button-product-text eform-product-button footer-color-button"
                                                    >
                                                        {this.getProductStringName()}
                                                        {/* {this.getProductStringName() === 'Pay Now' && (
                                                <span>
                                                    {' '}
                                                    &nbsp;(&#8377;
                                                    {(
                                                        productPrice *
                                                        Number(
                                                            this.state.productQuantity.toString().replace(/\D/g, '') ||
                                                                0.0,
                                                        )
                                                    ).toFixed(2)}
                                                    )
                                                </span>
                                            )} */}
                                                    </Button>
                                                </div>
                                            </Form.Item>
                                            {this.state.showOTPPage ? (
                                                <div className="login-otp-text">
                                                    Didn't receive the six digit code?
                                                    <div
                                                        className="login-otp-button"
                                                        onClick={() => {
                                                            this.props.resendOtpActionProp(
                                                                this.props.code,
                                                                'Phone',
                                                                this.state.guestPhoneNumber,
                                                            );
                                                        }}
                                                    >
                                                        Resend Code
                                                    </div>
                                                </div>
                                            ) : null}
                                            {this.state.currentStep === CurrentStepNumbers.Two &&
                                                this.state.registrationValue === UserTypeValues.RegisteredUser &&
                                                !this.state.showOTPPage &&
                                                !this.state.showAddress &&
                                                !this.state.hideLoginPassword && (
                                                    <div>
                                                        <h2 className="register-divider login-register-divider">
                                                            <span>Or</span>
                                                        </h2>
                                                        <Button
                                                            className="otp-button"
                                                            onClick={() => {
                                                                this.setState({
                                                                    hideLoginPassword: true,
                                                                });
                                                            }}
                                                        >
                                                            Get an OTP on your phone
                                                        </Button>
                                                    </div>
                                                )}
                                        </>
                                    )}
                                </>
                            )}
                            {formMode === FILLED && !agentLogin && !userPreviewOnly && !productEForm && (
                                <Form.Item className="actionButtons">
                                  
                                     {!noSubmissionEformType && (!isArray(steps) || steps.length === this.state.currentStep) && (
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            disabled={this.hasErrors({ fieldsError: getFieldsError(), metadata })}
                                            loading={submittingEform}
                                            className="submitButton"
                                        >
                                            { getString('Submit')}
                                        </Button>
                                    )}
                                    {!noSubmissionEformType && (isArray(steps) && steps.length !== this.state.currentStep) && (
                                        <Button
                                            type="primary"
                                            className="submitButton"
                                            onClick={()=> this.gotoNextPage()}
                                        >
                                            { getString('Next')}
                                        </Button>
                                    )}
                                    {noSubmissionEformType && (
                                        <Button
                                            type="primary"
                                            onClick={() => this.validateFieldsManually(false, true, undefined, true)}
                                            loading={savingEform}
                                            disabled={this.hasErrors({ fieldsError: getFieldsError(), metadata })}
                                            className="submitButton onlySubmitbutton"
                                        >
                                            {!isArray(steps) ||
                                            steps.length === this.state.currentStep ||
                                            steps.length === this.state.currentStep
                                                ? getString('Submit')
                                                : 'Next'}
                                        </Button>
                                    )}
                                    {!noSubmissionEformType && (
                                        <Button
                                            type="link"
                                            loading={savingEform}
                                            className="draftButton"
                                            onClick={() => this.validateFieldsManually(false, true, undefined, false)}
                                        >
                                            {getString('Save as draft')}
                                        </Button>
                                    )}

                                     <Button
                                        type="default"
                                        onClick={this.onClose}
                                        className={noSubmissionEformType ? 'cancelButton move-right' : 'cancelButton'}
                                    >
                                        {getString('Cancel')}
                                    </Button>
                                </Form.Item>
                            )}
                            {(formMode === VIEW || formMode === PREVIEW) && !agentLogin && !userPreviewOnly && (
                                <Form.Item className="actionButtons">
                                    {(isArray(steps) && steps.length !== this.state.currentStep) && (
                                        <Button
                                            type="primary"
                                            className="submitButton"
                                            onClick={()=> this.gotoNextPage()}
                                        >
                                            { getString('Next')}
                                        </Button>
                                    )}
                                     <Button type="default" className="cancelButton" onClick={this.onClose}>
                                        {getString('Close')}
                                    </Button>
                                </Form.Item>
                            )}
                            {agentLogin && !userPreviewOnly && !productEForm && (
                                <Form.Item className="actionButtons">
                                    {isFormReviewable && (!isArray(steps) || steps.length === this.state.currentStep) && (formStatus !== enumFormStatus.ACCEPTED || allowEdit) && (
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            disabled={(this.hasErrors({ fieldsError: getFieldsError(), metadata, viaReview:true }))}
                                            loading={submittingEform}
                                            onClick={() => this.validateFieldsManually(true, false, undefined, false)}
                                            className="submitButton agentView"
                                        >
                                            {getString('Submit')}
                                        </Button>
                                    )}
                                    {isArray(steps) && steps.length !== this.state.currentStep && (
                                         <Button
                                         type="primary"
                                         onClick={() => this.gotoNextPage()}
                                         className="submitButton agentView"
                                     >
                                        {getString('Next')}
                                     </Button>
                                    )}

                                    {isFormReviewable && sendToReview && formStatus !== enumFormStatus.ACCEPTED && (
                                        <Button
                                            type="link"
                                            loading={savingEform}
                                            className="draftButton agentView sendAsDraft"
                                            disabled={this.hasErrors({
                                                fieldsError: getFieldsError(),
                                                metadata,
                                                viaReview: true,
                                            })}
                                            onClick={() => sendToReview(true)}
                                        >
                                                {getString('Send for review')}
                                        </Button>
                                    )}
                                    <Button type="default" onClick={this.onClose} className="cancelButton">
                                        {getString('Cancel')}
                                    </Button>
                                </Form.Item>
                            )}
                            {(canvasEnabled || !agentLogin) && (
                                <CanvasDraw
                                    id="canvas"
                                    onChange={this.onCanvasChange}
                                    ref={canvasDraw => (saveableCanvas = canvasDraw)}
                                    className={`canvas-container ${!agentLogin ? 'disable-pointer-events' : ''}`}
                                    hideGrid
                                    hideInterface
                                    brushColor="pink"
                                    brushRadius={6}
                                />
                            )}
                        </Form>
                        {/* {agentLogin && (
                    <div className="canvas-options">
                        <div onClick={this.enableCanvas} className={`${canvasEnabled ? 'selected' : ''}`}>
                            {canvasEnabled && <img src={enabledCanvas} alt="canvas" />}
                            {!canvasEnabled && (
                                <Tooltip title="Remove Highlighter" placement="bottomLeft">
                                    <img src={disabledCanvas} alt="canvas" />
                                </Tooltip>
                            )}
                        </div>
                        {canvasEnabled && (
                            <div onClick={this.onCanvasClear} className="margin-top-0">
                                <Tooltip title="Erase" placement="bottomLeft">
                                    <img src={closeCanvas} alt="close" />
                                </Tooltip>
                            </div>
                        )}
                    </div>
                )} */}
                        {showNotesModal && (
                            <NotesModal
                                showNotesModal={showNotesModal}
                                linkCode={linkCode}
                                eformVersionId={eformVersionId}
                                reviewComments={reviewComments}
                                saveCommentsHandler={this.saveCommentsMethod}
                                fetchReviewCommentsHandler={fetchReviewCommentsHandler}
                                notesModalCloseHandler={notesModalCloseHandler}
                                user={user}
                            />
                        )}
                        {showRejectionReasonModel && (
                            <RejectionReasonModal
                                showModel={showRejectionReasonModel}
                                rejectionReasonSaveHandler={this.rejectionReasonSaveHandler}
                                rejectionReasonCloseHandler={this.rejectionReasonCloseHandler}
                            />
                        )}
                    </Col>
                </Row>
                {this.state.showCashOnDeliveryModal && (
                    <CashOnDeliveryModal
                        onCancel={() => {
                            this.setState({ showCashOnDeliveryModal: false });
                            form.setFieldsValue({ citizenId: PaymentOptions.CreditCard });
                        }}
                        verifyContactNumberAction={this.props.verifyContactNumberAction}
                        phoneNumber={this.state.guestPhoneNumber}
                        code={this.props.code}
                        submitLoading={this.props.verifyingNumber || submittingEform}
                    />
                )}
            </React.Fragment>
        );
    }
}

const EFormRootComponent = Form.create<IEFormRootProps & FormComponentProps>({
    // depending on what we are concerned with we can either send the entire form or just the value.
    // The benefit of sending the entire form is that for missed values we can very quickly sync up between the
    // customer and the agent
    onValuesChange(_, values) {
        formValueChange.next(values);
    },
})(EFormRoot);

export { EFormRootComponent };
