import React from 'react';
import { Uppy, UploadResult, UploadedUppyFile, FailedUppyFile } from '@uppy/core';
import UppyGoogleDrive from '@uppy/google-drive';
import UppyDropbox from '@uppy/dropbox';
import UppyInstagram from '@uppy/instagram';
import UppyWebcam from '@uppy/webcam';
import UppyXHRUpload from '@uppy/xhr-upload';
import FileInput from '@uppy/file-input';
import StatusBar from '@uppy/status-bar';
import { Dashboard, DashboardModal } from '@uppy/react';
import ImageCompressor from 'uppy-plugin-image-compressor';
import uuidv4 from 'uuid/v4';
import IUploader, {
    IFullModeOptions,
    IModalModeOptions,
    IButtonModeOptions,
    ISuccessfulUploadedFile,
    UploadedUppyFileWithResponse,
    IFailedUploadedFile,
    IBaseUploadedFile,
} from '../../interfaces/IUploader';

import './Uploader.scss';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/file-input/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import { ZERO, COMPRESSION_PERCENTANGE } from '../../constants/numberConstants';

class Uploader extends React.Component<IUploader> {
    private uppy: Uppy;

    private uuid: string;

    constructor(props: IUploader) {
        super(props);
        const {
            id,
            modeOptions,
            debug,
            autoProceed,
            allowMultipleUploads,
            restrictions,
            companionUrl,
            xhrUrl,
            useGoogleDrive,
            useDropbox,
            useInstagram,
            useWebcam,
            uploadStarted,
            uploadSuccessful,
            uploadFailed,
        } = this.props;

        const { mode } = modeOptions;

        this.uuid = uuidv4();

        this.uppy = new Uppy({
            allowMultipleUploads,
            autoProceed: mode === 'button' ? true : autoProceed,
            debug,
            id: `${this.uuid}-${id}`,
            onBeforeUpload: files => {
                if (uploadStarted) {
                    const uploadedFiles: IBaseUploadedFile[] = Object.entries(files).map(([key, uppyFile]) => {
                        return {
                            clientFileId: key,
                            fileSize: uppyFile.size,
                            fileStream: uppyFile.data as File,
                            fileType: uppyFile.type,
                        };
                    });
                    uploadStarted(uploadedFiles);
                }
                return files;
            },
            restrictions,
        });
        this.uppy.use(ImageCompressor, { quality: COMPRESSION_PERCENTANGE });
        this.uppy.use(UppyXHRUpload, { endpoint: xhrUrl });

        if (mode !== 'button') {
            if (useGoogleDrive) {
                this.uppy.use(UppyGoogleDrive, { companionUrl });
            }
            if (useDropbox) {
                this.uppy.use(UppyDropbox, { companionUrl });
            }
            if (useInstagram) {
                this.uppy.use(UppyInstagram, { companionUrl });
            }
            if (useWebcam) {
                this.uppy.use(UppyWebcam, { companionUrl });
            }
        }

        /*  @remarks - result format from uppy
            UploadResult looks like {
                successful: UploadedUppyFile<TMeta>[];
                failed: FailedUppyFile<TMeta>[];
            }
            UploadedUppyFile looks like {
                {
                    uploadURL: string;
                    data: Blob | File;
                    extension: string;
                    id: string;
                    isPaused?: boolean;
                    isRemote: boolean;
                    meta: {
                    name: string;
                    type?: string;
                    } & TMeta;
                    name: string;
                    preview?: string;
                    progress?: {
                    uploadStarted: number | null;
                    uploadComplete: boolean;
                    percentage: number;
                    bytesUploaded: number;
                    bytesTotal: number;
                    };
                    remote?: {
                    host: string;
                    url: string;
                    body?: object;
                    };
                    size: number;
                    source?: string;
                    type?: string;
                }
            }
            FailedUppyFile looks like {
                error: string
                data: Blob | File;
                extension: string;
                id: string;
                isPaused?: boolean;
                isRemote: boolean;
                meta: {
                name: string;
                type?: string;
                } & TMeta;
                name: string;
                preview?: string;
                progress?: {
                uploadStarted: number | null;
                uploadComplete: boolean;
                percentage: number;
                bytesUploaded: number;
                bytesTotal: number;
                };
                remote?: {
                host: string;
                url: string;
                body?: object;
                };
                size: number;
                source?: string;
                type?: string;
            }
        */
        this.uppy.on('complete', (result: UploadResult) => {
            if (!!uploadSuccessful && result.successful.length > ZERO) {
                const files: ISuccessfulUploadedFile[] = result.successful.map(
                    (uppyFile: UploadedUppyFile<any, any>) => {
                        const uppyFileWithResponse = uppyFile as UploadedUppyFileWithResponse;
                        return {
                            clientFileId: uppyFile.id,
                            downloadLink: uppyFileWithResponse.response.body.data.downloadLinks[ZERO].downloadLink,
                            fileId: uppyFileWithResponse.response.body.data.keys[ZERO] as number,
                            fileSize: uppyFile.size,
                            fileStream: uppyFile.data as File,
                            fileType: uppyFile.type,
                            name: uppyFile.name
                        };
                    },
                );
                uploadSuccessful(files);
            }
            if (!!uploadFailed && result.failed.length > ZERO) {
                const files: IFailedUploadedFile[] = result.failed.map((uppyFile: FailedUppyFile<any, any>) => {
                    return {
                        clientFileId: uppyFile.id,
                        error: uppyFile.error,
                        fileSize: uppyFile.size,
                        fileStream: uppyFile.data as File,
                        fileType: uppyFile.type,
                    };
                });
                uploadFailed(files);
            }
        });
    }

    public componentDidMount(): void {
        const { modeOptions, id } = this.props;
        const { mode, buttonText } = modeOptions as IButtonModeOptions;
        if (mode === 'button') {
            // In this case the handlers for uploadSuccessful and uploadFailed will still be used
            // from the constructor due to the line `this.uppy.on('complete', (...) => {...})`
            this.uppy.use(FileInput, {
                locale: {
                    strings: {
                        chooseFiles: buttonText || 'Choose Files',
                    },
                },
                replaceTargetContent: true,
                target: `#UppyFileInputForm-${this.uuid}-${id}`,
            });
            this.uppy.use(StatusBar, {
                fixed: true,
                hideAfterFinish: false,
                hideCancelButton: false,
                hidePauseResumeButton: false,
                hideRetryButton: false,
                hideUploadButton: false,
                id: `statusbar-${this.uuid}-${id}`,
                locale: {},
                showProgressDetails: true,
                target: `#UppyFileInputForm-${this.uuid}-${id}`,
            });
        }
    }

    public componentWillUnmount(): void {
        this.uppy.close();
    }

    public render(): JSX.Element | null {
        const plugins: Array<string> = [];
        const { useGoogleDrive, useDropbox, useInstagram, useWebcam } = this.props;

        if (useGoogleDrive) {
            plugins.push('GoogleDrive');
        }
        if (useDropbox) {
            plugins.push('Dropbox');
        }
        if (useInstagram) {
            plugins.push('Instagram');
        }
        if (useWebcam) {
            plugins.push('Webcam');
        }

        const { modeOptions } = this.props;
        const { mode } = modeOptions;
        // const { ...others } = modeOptions;

        if (mode === 'full') {
            const {
                width,
                height,
                showLinkToFileUploadResult,
                showProgressDetails,
                hideUploadButton,
                hideRetryButton,
                hidePauseResumeButton,
                hideCancelButton,
                hideProgressAfterFinish,
                showSelectedFiles,
                note,
                metaFields,
                disableStatusBar,
                disableInformer,
                disableThumbnailGenerator,
                thumbnailWidth,
            } = modeOptions as IFullModeOptions;

            return (
                <Dashboard
                  uppy={this.uppy}
                  inline
                  plugins={plugins}
                  proudlyDisplayPoweredByUppy={false}
                  width={width}
                  height={height}
                  showLinkToFileUploadResult={showLinkToFileUploadResult}
                  showProgressDetails={showProgressDetails}
                  hideUploadButton={hideUploadButton}
                  hideRetryButton={hideRetryButton}
                  hidePauseResumeButton={hidePauseResumeButton}
                  hideCancelButton={hideCancelButton}
                  hideProgressAfterFinish={hideProgressAfterFinish}
                  showSelectedFiles={showSelectedFiles}
                  note={note}
                  metaFields={metaFields}
                  disableStatusBar={disableStatusBar}
                  disableInformer={disableInformer}
                  disableThumbnailGenerator={disableThumbnailGenerator}
                  thumbnailWidth={thumbnailWidth}
                />
            );
        }
        if (mode === 'modal') {
            const {
                width,
                height,
                showLinkToFileUploadResult,
                showProgressDetails,
                hideUploadButton,
                hideRetryButton,
                hidePauseResumeButton,
                hideCancelButton,
                hideProgressAfterFinish,
                showSelectedFiles,
                note,
                metaFields,
                disableStatusBar,
                disableInformer,
                disableThumbnailGenerator,
                thumbnailWidth,
                open,
            } = modeOptions as IModalModeOptions;

            return (
                <DashboardModal
                  uppy={this.uppy}
                  open={open}
                  inline={false}
                  plugins={plugins}
                  proudlyDisplayPoweredByUppy={false}
                  width={width}
                  height={height}
                  showLinkToFileUploadResult={showLinkToFileUploadResult}
                  showProgressDetails={showProgressDetails}
                  hideUploadButton={hideUploadButton}
                  hideRetryButton={hideRetryButton}
                  hidePauseResumeButton={hidePauseResumeButton}
                  hideCancelButton={hideCancelButton}
                  hideProgressAfterFinish={hideProgressAfterFinish}
                  showSelectedFiles={showSelectedFiles}
                  note={note}
                  metaFields={metaFields}
                  disableStatusBar={disableStatusBar}
                  disableInformer={disableInformer}
                  disableThumbnailGenerator={disableThumbnailGenerator}
                  thumbnailWidth={thumbnailWidth}
                />
            );
        }
        if (mode === 'button') {
            // This is a fallback html, which will be used if Uppy cannot be loaded.
            // In that case, handlers will not be used as uppy in general will not be used.
            // Uppy's JS code is supposed to replace the following html with it's own version.
            // When that happens, the handlers for uploadSuccessful and uploadFailed will be used
            // from the constructor due to the line `this.uppy.on('complete', (...) => {...})`

            const { allowMultipleUploads, xhrUrl, id } = this.props;
            const { buttonText } = modeOptions as IButtonModeOptions;
            return (
                <div className="UppyForm" id={`UppyFileInputForm-${this.uuid}-${id}`}>
                    <form action={xhrUrl}>
                        <input type="file" name="files[]" multiple={!!allowMultipleUploads} />
                        <button type="submit">{buttonText || 'Choose Files'}</button>
                    </form>
                </div>
            );
        }

        return null;

        // console.log('plugins', plugins);
        // console.log('junkPlugins', junkPlugins);
        // console.log('this.uppy.getPlugin("GoogleDrive")', this.uppy.getPlugin('GoogleDrive'));
    }
}

export default Uploader;
