import React from 'react';
import { Button } from 'antd';
import { IFloatingChatModal } from '../../interfaces/IChat';
import './FloatingChatModal.scss';
import { getString } from '../../utils/localization';

const FloatingChatModal = (props: IFloatingChatModal) => {
    const { currentUserName, botAnswerHandler } = props;
    return (
        <section className="floatingChatModal">
            <header>{getString("Form Assistant Bot")}</header>
            <article>
                <p>
                    {getString("Hi")} {currentUserName}, {getString("would you like LIVE assistance on this form? One of our Agents will be delighted to assist you?")}
                </p>
                <Button className="positive-cta" onClick={() => botAnswerHandler(true)}>
                    {getString("Yes, I would like that.")}
                </Button>
                <Button className="negative-cta" onClick={() => botAnswerHandler(false)}>
                    {getString("No, it's not required.")}
                </Button>
            </article>
        </section>
    );
};

export { FloatingChatModal };
