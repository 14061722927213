import React from 'react';
import { Layout, Row, Col } from 'antd';
import IError from '../../interfaces/IError';
import './Error.scss';
import {getString} from '../../utils/localization'

const ErrorComponent = (props: IError) => {
    const { text, label, errorCode } = props;
    const { Content } = Layout;
    return (
        <React.Fragment>
            <Layout className="error">
                <Content className="content" style={{ height: '100vh' }}>
                    <Row type="flex" justify="center" align="middle" className="errorRow">
                        <Col xs={18} sm={18} md={4}>
                            <div className="errorCol">
                                {errorCode && errorCode === 404 && <h2>{getString("Page not found")}</h2>}
                                {!errorCode && <h2>{label}</h2>}
                                <span>{text}</span>
                            </div>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </React.Fragment>
    );
};

export default ErrorComponent;
