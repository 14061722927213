import React from 'react';
import { Button, Icon } from 'antd';
import Video from '../../images/videocam.svg';
import Call from '../../images/call.svg';
import Back from '../../images/back.svg';
import videoCam from '../../images/videocam_chat.svg';
import fullHeight from '../../images/full-height.svg';
import MoreIcon from '../../images/more.svg';
import CoBrowse from '../../images/co-browse_chat.svg';
import documentshare from '../../images/documentshare.svg';
import { IConversationHeader, enumConversationType, ChatViewMode } from '../../interfaces/IConversation';
import './ConversationHeader.scss';
import { ZERO, ONETHOUSAND_24 } from '../../constants/numberConstants';
import { getString } from '../../utils/localization';

const ConversationHeader = (props: IConversationHeader) => {
    const { innerWidth: width } = window;
  
    const {
        title,
        showCallConnected,
        audioCallHandler,
        videoCallHandler,
        backPressHandler,
        documentshareViewOpenHandler,
        hideChatHandler,
        cobrowsingHandler,
        fullHeightChatToggleHandler,
        type,
        viewMode,
        fullChatHeight,
        typingStatus,
        coBrowsingActive
    } = props;
    const chatBotView = viewMode === ChatViewMode.BOT;
    return (
        <React.Fragment>
            <header
              className={`header conversationHeader ${
                    showCallConnected && type !== enumConversationType.CHAT ? 'fullHeader' : ''
                } ${type === enumConversationType.VIDEO? 'videoHeader':''} ${chatBotView ? 'chatBotView' : ''}`}
            >
                {type === enumConversationType.VIDEO && (
                    <Button onClick={backPressHandler} className={`showBackButton ${chatBotView ? 'chatBotView':''}`}>
                        {!chatBotView && <img src={Back} alt="back button" />}
                        {chatBotView && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g transform="translate(0 0)" fill="#fff"><path className="a" d="M7.445,15.764l-.014-.014c-.294-.287-.582-.58-.873-.871L4.765,13.089,2.678,11.006.961,9.292C.734,9.063.506,8.837.278,8.608L.237,8.573a.81.81,0,0,1,0-1.143l.025-.024c.285-.291.576-.575.864-.863L2.92,4.753,5.006,2.669,6.723.955l.72-.718A.843.843,0,0,1,8.015,0a.805.805,0,0,1,.808.807.827.827,0,0,1-.236.572l-.013.013c-.288.294-.582.581-.874.872L5.909,4.055Q4.865,5.1,3.822,6.138L2.765,7.194H15.191a.842.842,0,0,1,.572.236.81.81,0,0,1,0,1.143.841.841,0,0,1-.572.236h-.024c-.186,0-.373,0-.56,0H2.766l1.3,1.3,2.086,2.083L7.868,13.9l.72.719a.843.843,0,0,1,.238.571.814.814,0,0,1-.236.571.813.813,0,0,1-1.145,0Z" transform="translate(0 0)" /></g></svg>}
                    </Button>
                )}
                <h1 className="header-title">
                    <Icon type="user" />
                    {title}
                    {typingStatus && <div className="typingStatusMessage">{getString("Typing")}...</div>}
                </h1>
                {chatBotView && (
                    <div className="header-icons">
                        <Button onClick={()=>hideChatHandler()} className="header-icon more-icon">
                            <img alt="more icon" src={width > ONETHOUSAND_24 ? MoreIcon : MoreIcon} className="header-action" />
                        </Button>
                        <Button onClick={()=>fullHeightChatToggleHandler(!fullChatHeight)} className="header-icon increase-height">
                            <img alt="full height" src={width > ONETHOUSAND_24 ? fullHeight: fullHeight} className="header-action" />
                        </Button>
                        <Button onClick={videoCallHandler} className="header-icon video">
                            <img alt="video icon" src={width > ONETHOUSAND_24 ? videoCam: videoCam} className="header-action" />
                        </Button>
                        <Button onClick={cobrowsingHandler} className="header-icon co-browse">
                            <img alt="co-browse" src={width > ONETHOUSAND_24 ? CoBrowse: CoBrowse} className="header-action" />
                            {coBrowsingActive && <div className="active" />}
                        </Button>
                    </div>
                )}
                {!chatBotView && (!showCallConnected || type === enumConversationType.CHAT) && (
                    <React.Fragment>
                        <Button onClick={documentshareViewOpenHandler} className="documentShareAction">
                            <img alt="" src={documentshare} className="header-action documentShareImg" />
                        </Button>
                        <Button onClick={videoCallHandler}>
                            <img alt="" src={Video} className="header-action videoAction" />
                        </Button>
                        <Button onClick={audioCallHandler}>
                            <img alt="" src={Call} className="header-action callAction" />
                        </Button>
                    </React.Fragment>
                )}
                {showCallConnected && type === enumConversationType.CHAT && (
                    <div
                      className="callConnectedView ellipsis"
                      onClick={videoCallHandler}
                      role="button"
                      tabIndex={ZERO}
                      onKeyPress={videoCallHandler}
                    >
                        {getString("Tap to return to call")}
                    </div>
                )}
            </header>
        </React.Fragment>
    );
};
export { ConversationHeader };
