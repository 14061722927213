import React from 'react';
import { IMessageBox, EMessageExchangeType } from '../../../../interfaces/IChat';

import './MessageBox.scss';
import { parseLink } from '../../../../utils/linkparser.util';
import { ZERO, FOURTY } from '../../../../constants/numberConstants';
import MessageTime from '../MessageTime/MessageTime';
import MessageStatus from '../MessageStatus/MessageStatus';

/**
 * Core component to show Time and Text on the chat list
 * @param props : IMessageBox
 */
const MessageContent = (props: IMessageBox) => {
    const { message, type, addAnchor, timestamp } = props;
    const text = parseLink(message);
    const { preText, link, suffixText, linkCode } = text.formattedText;
    // TODO: render dymanic image for link
    // let hideLoader = false
    // // if(link){
    // //     setTimeout(() => {
    // //         hideLoader = true
    // //     },100)
    // // }
    const linkLength = link ? link.length:ZERO
    const suffixLength = suffixText ? suffixText.length:ZERO
    const moveToNewLine = preText && preText.length > FOURTY || (preText.length+ linkLength + suffixLength) > FOURTY
    const linkHandler = () => {
        const { linkShareHandler } = props;
        if (link && text.isSaleskeyLink) 
            linkShareHandler(link, linkCode);
        else if(link)
            linkShareHandler(link);
    };
    if (props && type === EMessageExchangeType.SENT) {
        return (
            <div className={addAnchor ? 'message sent-msg addAnchor' : 'message sent-msg'}>
                {preText.length > 0 && <span>{preText}</span>}
                {link && (
                    <a
                      className="anchor-tag"
                      tabIndex={ZERO}
                      role="button"
                      onKeyDown={linkHandler}
                      onClick={linkHandler}
                    >
                        {link}
                    </a>
                )}
                {suffixText && <span>{suffixText}</span>}
                {/* {link && (
                    <div className="lazy-loading">
                        {!hideLoader && <Loader></Loader>}
                        {hideLoader && <div> Image loaded </div>}
                    </div>)} */}
                    <span className={`timeStatusWrapper  ${moveToNewLine?'moveToNewLine':''}`}>
                        <MessageTime timestamp={timestamp} moveToNewLine={moveToNewLine} />
                        <MessageStatus status="" />
                    </span>
            </div>
        );
    }

    return (
        <div className={addAnchor ? 'message received-msg addAnchor' : 'message received-msg'}>
            {preText.length > 0 && <span>{preText}</span>}
            {link && (
                <a className="anchor-tag" tabIndex={ZERO} role="button" onKeyDown={linkHandler} onClick={linkHandler}>
                    {link}
                </a>
            )}
            {suffixText && <span>{suffixText}</span>}
            <span className={`timeStatusWrapper  ${moveToNewLine?'moveToNewLine':''}`}>
            <MessageTime timestamp={timestamp} moveToNewLine={moveToNewLine} />
            </span>
            {/* {link && (
                <div className="lazy-loading">
                    {!hideLoader && <Loader></Loader>}
                    {hideLoader && <div> Image loaded </div>}
                </div>)} */}
        </div>
    );
};

export default MessageContent;
