export const arrayToKeyValue = array => {
    const result = {};
    if (!Array.isArray(array)) {
        return result;
    }
    array.forEach(item => {
        Object.keys(item).forEach(key => {
            result[key] = item[key];
        });
    });
    return result;
};

/* eslint-disable no-param-reassign */
export const arrayToObject = (array, keyField) => {
    if (array instanceof Array) {
        return array.reduce((obj, item) => {
            const name = item[keyField];
            delete item[keyField];
            Object.keys(item).forEach(key => {
                if (item[key] instanceof Array && key !== 'actions') {
                    item[key] = arrayToObject(item[key], 'name');
                } else if (item[key] instanceof Array && key === 'actions') {
                    item[key] = arrayToKeyValue(item[key]);
                }
            });
            obj[name] = item;
            return obj;
        }, {});
    }
    return false;
};
