import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import IAclPermissionProps, { ptypes } from '../../interfaces/IAclPermission';
// import IFeaturePermission from '../../interfaces/IFeaturePermission';
// import { permissions as aclpermission } from "../../fixtures/permission"
/** Return type for `{@filterChildRequired}` */
type childRequiredResponse = {
    required: boolean;
    aclvalue: string;
};

/**
 * AclPermission Component
 */
class AclPermissionBaseContainer extends React.Component<IAclPermissionProps, {}> {
    /**
     * Function to retrieve the aclvalue from the permissions hashmap.
     * @param permissions - hashmap of the acl permission object
     * @param featureParam - feature name of the permission
     * @param subfeatureParam - subfeature name of the feature
     * @param action - action type of the feature
     * @return returns the object type of `childRequiredResponse`
     */
    /* eslint-disable no-multi-str */
    public static filterChildRequired(
        permissions: object | undefined,
        featureParam: string,
        subfeatureParam: string | undefined,
        action: string,
    ): childRequiredResponse {
        const result = {
            aclvalue: '',
            required: false,
        };
        if (!permissions) {
            return result;
        }
        const feature = permissions[featureParam];
        if (!feature) {
            return result;
        }

        if (subfeatureParam) {
            const { subFeatures } = feature;
            if (!subFeatures) {
                return result;
            }
            const subFeature = subFeatures[subfeatureParam];
            if (!subFeature) {
                return result;
            }
            result.aclvalue = subFeature.actions[action];
        } else {
            const { actions } = feature;
            if (!actions) {
                return result;
            }
            result.aclvalue = actions[action];
        }
        result.required = true;
        return result;
    }

    /**
     * Function to add the prop type, class name to the react nodes.
     * @param child - ReactNode on which properties need to be injected
     * @param aclproperty - property on which rule to be imposed
     * @param aclvalue - acl value to be imposed on `{aclproperty}`
     * @param propertytype - property of type `{@ptypes}`
     * @returns ReactNode with modified props
     */
    public static modifyChildren(child, aclproperty: string, aclvalue: string | boolean, propertytype: ptypes) {
        // view type acl only
        if (propertytype === 'element') {
            if (aclvalue) {
                return child;
            }
            return null;
        }

        let props;
        if (propertytype === 'class') {
            const classes = classNames(child.props.className, aclproperty);
            props = {
                className: classes,
            };
        } else {
            const property = aclproperty.toLowerCase();
            let value = aclvalue;
            if (typeof aclvalue === 'boolean') {
                // inversion of boolean required as if view: false, then hidden : true required
                // this will happen only in case of view which we have currently.
                value = !value;
            }
            props = {
                [property]: value,
            };
        }
        return React.cloneElement(child, props);
    }

    /**
     *
     * @param props - type of `{IAclPermissionProps}`
     */
    constructor(props: IAclPermissionProps) {
        super(props);
        // Bind the method to the component context
        this.renderChildren = this.renderChildren.bind(this);
    }

    /**
     * @returns ReactNodes to be rendered by the compositional components
     */
    public renderChildren(): any {
        const { feature, subfeature, action, propertytype, permissions } = this.props;
        // const acl = aclpermission;
        const filterChild = AclPermissionBaseContainer.filterChildRequired(permissions, feature, subfeature, action);
        let { aclproperty } = this.props;
        if (!aclproperty) {
            aclproperty = 'hidden';
        }
        const { children } = this.props;
        return React.Children.map(children, child => {
            if (!filterChild.required) {
                return child;
            }
            return AclPermissionBaseContainer.modifyChildren(child, aclproperty, filterChild.aclvalue, propertytype);
        });
    }

    render() {
        return <React.Fragment>{this.renderChildren()}</React.Fragment>;
    }
}

export const stateToProps = state => {
    return {
        authenticated: state.auth.authenticated,
        permissions: state.auth.permissions,
    };
};

const AclPermission = connect(
    stateToProps,
    null,
)(AclPermissionBaseContainer);
export { AclPermission, AclPermissionBaseContainer };
