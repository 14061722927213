import * as React from 'react';
import { connect } from 'react-redux';
import { Drawer } from 'antd';
import { getString } from '@saleskey/frontend-shared';
import { AddDocumentContainer } from '../AddDocument/AddDocument';
import { addNewDocumentDrawer } from '../../store/rootActions';
import './DocumentDrawer.scss';

export interface IDocumentDrawerProp {
    addDocumentDrawerState: boolean | undefined;
    addNewDocumentDrawer: (boolean) => void;
}

export interface IDocumentDrawerState {
    isDrawerVisible: boolean | undefined;
}

/**
 * @remarks
 * AddDocument container contains the form to upload the new document.
 * props - type of `IDocumentDrawerProp`
 * state - type of `IDocumentDrawerState`
 */
class DocumentDrawerBaseContainer extends React.Component<IDocumentDrawerProp, IDocumentDrawerState> {
    constructor(props: IDocumentDrawerProp) {
        super(props);
        const { addDocumentDrawerState } = this.props;
        this.state = {
            isDrawerVisible: addDocumentDrawerState,
        };
        this.onClose = this.onClose.bind(this);
    }

    componentDidUpdate() {
        const { addDocumentDrawerState } = this.props;
        const { isDrawerVisible } = this.state;
        if (isDrawerVisible !== addDocumentDrawerState) {
            this.setState({ isDrawerVisible: addDocumentDrawerState });
        }
    }

    // Callback function to close the drawer
    onClose(): void {
        const { addNewDocumentDrawer: addNewDocumentDrawerProp, addDocumentDrawerState } = this.props;
        addNewDocumentDrawerProp(!addDocumentDrawerState);
    }

    public render(): React.ReactNode {
        const { isDrawerVisible } = this.state;
        return (
            <div className="documentDrawer">
                <Drawer
                    title={getString('Add new document')}
                    placement="right"
                    closable={false}
                    width="90%"
                    style={{ top: '64px' }}
                    onClose={this.onClose}
                    visible={isDrawerVisible}
                    destroyOnClose
                >
                    <AddDocumentContainer />
                </Drawer>
            </div>
        );
    }
}

export const stateToProps = state => {
    return {
        addDocumentDrawerState: state.document.addDocumentDrawerState,
    };
};

const dispatchers = dispatch => {
    return {
        addNewDocumentDrawer: isOpen => dispatch(addNewDocumentDrawer(isOpen)),
    };
};

const DocumentDrawerContainer = connect(stateToProps, dispatchers)(DocumentDrawerBaseContainer);
export { DocumentDrawerContainer, DocumentDrawerBaseContainer };
