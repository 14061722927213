import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { message, Layout, Row, Col, Alert } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { getString, SCButton, SCLoader } from '@saleskey/frontend-shared';
import { ICreateEFormProps, ICreateEFormState, enumEFormModes, enumFormActionType } from '../../interfaces/IEForm';
import {
    fetchEFormAction,
    eFormCreateOrUpdateAction,
    viewSubmissionAction,
    resetEFormStoreAction,
} from '../../store/rootActions';
import './EFormModes.scss';
import { validateEForm } from '../../utils/eFormValidation';

const SCEForm = lazy(() => import('@saleskey/frontend-shared').then(module => ({ default: module.SCEForm })));

/**
 * @remarks
 * It is a common EForm container which can be opened in different modes: CREATE | EDIT | VIEW
 * props : type of `ICreateEFormProps`
 * state : type of `ICreateEFormState`
 */
class CreateEFormBase extends React.Component<ICreateEFormProps, ICreateEFormState> {
    _formJson: any;

    _formStringified: string = '';

    /**
     * Initializes the constructor with default formJson, which is required
     * to pass it as a prop to child containers
     */
    constructor(props: ICreateEFormProps) {
        super(props);
        this.state = {
            formJson: {
                description: '',
                header: '',
                metadata: [],
                name: '',
                tags: [],
            },
            formValues: {},
            isFormJsonValid: false,
            jsonErrors: undefined,
            updatedOnce: false,
            viaTextarea: false,
        };
        this.formValuesHandler = this.formValuesHandler.bind(this);
    }

    public componentDidMount() {
        const { mode, fetchEFormDispatch, viewSubmissionDispatch, eformId, submissionId } = this.props;
        const { CREATE, VIEW, EDIT } = enumEFormModes;

        /**
         * If udpate or view mode for the form then
         * form id is must to fetch the form json
         * in VIEW mode, submissionId is required
         */
        if (mode !== CREATE) {
            if (!eformId) {
                message.error('E-Form id is required to fetch the details');
            } else if (mode === EDIT) {
                fetchEFormDispatch(eformId);
            } else if (mode === VIEW) {
                if (!submissionId) {
                    message.error('Submission id is required to fetch the submission details');
                } else {
                    viewSubmissionDispatch(submissionId);
                }
            }
        }
    }

    /**
     * Lifecycle method will be used to assing the fetch form json from api to state
     */
    public componentDidUpdate(prevProps) {
        const {
            eformFetchJson,
            mode,
            successFetchEFormValues,
            resetEFormStoreDispatch,
            createdEForm,
            onEFormClose,
        } = this.props;
        const { formJson: formJsonPrevProp } = prevProps;
        const { viaTextarea, isFormJsonValid, updatedOnce } = this.state;

        const { CREATE, EDIT, VIEW } = enumEFormModes;

        /**
         * only update when data is from text area
         */
        if (viaTextarea) {
            const updates = {
                formJson: this._formJson,
                isFormJsonValid,
                viaTextarea: false,
            };
            this.setState(updates);
        }

        /**
         * @remarks
         * condition to check for the EDIT mode, only formjson available
         */
        if (
            JSON.stringify(formJsonPrevProp) !== JSON.stringify(eformFetchJson) &&
            eformFetchJson &&
            mode === EDIT &&
            !updatedOnce
        ) {
            this._formJson = eformFetchJson;
            this._formStringified = JSON.stringify(eformFetchJson, undefined, 4);
            const updates = {
                formJson: eformFetchJson,
                isFormJsonValid: true,
                updatedOnce: true,
                viaTextarea: false,
            };
            this.setState(updates);
        }

        /**
         * @remarks
         * condition to check for the VIEW mode, formjson and formvalues are available
         */
        if (
            JSON.stringify(formJsonPrevProp) !== JSON.stringify(eformFetchJson) &&
            eformFetchJson &&
            mode === VIEW &&
            !updatedOnce
        ) {
            this._formJson = eformFetchJson;
            this._formStringified = JSON.stringify(eformFetchJson, undefined, 4);
            const updates = {
                formJson: eformFetchJson,
                formValues: successFetchEFormValues,
                isFormJsonValid: true,
                updatedOnce: true,
                viaTextarea: false,
            };
            this.setState(updates);
        }

        if (createdEForm && mode === CREATE) {
            message.success('EForm created successfully');
            resetEFormStoreDispatch();
            onEFormClose();
        }
    }

    componentWillUnmount() {
        const { resetEFormStoreDispatch } = this.props;
        resetEFormStoreDispatch();
    }

    /**
     * @remarks
     * callback function to save the eform
     */
    public saveEform = actionType => {
        const { formJson } = this.state;
        const { eFormCreateOrUpdate } = this.props;
        eFormCreateOrUpdate(formJson, actionType);
    };

    /**
     * @remarks
     * Function called when the json of a form is submitted from the textarea
     */
    public formJsonHandler = (e: React.FormEvent<HTMLTextAreaElement>) => {
        const { value } = e.currentTarget;
        this._formStringified = value;
        try {
            this._formJson = JSON.parse(value);
            const result = validateEForm(this._formJson);
            this._formStringified = JSON.stringify(this._formJson, undefined, 4);
            const { status, errors } = result;
            if (status) {
                this.setState({ isFormJsonValid: true, jsonErrors: undefined, viaTextarea: true });
            } else {
                this.setState({ isFormJsonValid: false, jsonErrors: errors, viaTextarea: true });
            }
            /**
             * prettify the string for textarea
             */
        } catch (error) {
            this.setState({ isFormJsonValid: false, jsonErrors: ['Please type valid json'], viaTextarea: true });
        }
    };

    /**
     * @remarks
     * Function called when the values are submitted from the eform
     * TODO: next customer sprint task, filled form
     */
    /* eslint class-methods-use-this: 0 */
    public formValuesHandler = () => {};

    public render() {
        const { Content } = Layout;
        const { EDIT, CREATE, VIEW, PREVIEW, FILLED } = enumEFormModes;
        const { mode, savingEForm, createdEForm, errorCreateEForm, onEFormClose, user, dmsToken } = this.props;
        const { formJson, formValues, isFormJsonValid, jsonErrors } = this.state;
        return (
            <Layout className="EFormModes">
                <Content className="paddedFullHeight">
                    {!savingEForm && (
                        <Row type="flex" justify="space-around" align="top">
                            <Col xs={24} sm={24} md={24} lg={24}>
                                {errorCreateEForm && !createdEForm && (
                                    <Alert message={errorCreateEForm} type="error" showIcon banner closable />
                                )}
                                {createdEForm && !errorCreateEForm && (
                                    <Alert message="Eform successfully added" type="success" showIcon banner closable />
                                )}
                            </Col>
                        </Row>
                    )}
                    <Row type="flex" justify="space-around" align="top" className="fullHeight">
                        {/* EForm can be edited to add new fields */}
                        {mode === EDIT && (
                            <Col xs={24} sm={24} md={24} lg={24} className="colFullHeight">
                                <Content className="fullHeight contentTag">
                                    <Col xs={24} sm={24} md={12} lg={12} className="fullHeight">
                                        <TextArea
                                          rows={10}
                                          onChange={this.formJsonHandler}
                                          value={this._formStringified}
                                          placeholder={getString("Start building your e-form schema")}
                                        />
                                        <div className="buttonActions">
                                            <SCButton
                                              text={getString("Update")}
                                              type="primary"
                                              className="primary saveButton"
                                              onClick={() => this.saveEform(enumFormActionType.UPDATE)}
                                              size="small"
                                              disabled={!isFormJsonValid}
                                            />
                                            <SCButton
                                              text={getString("Cancel")}
                                              type="default"
                                              className="cancelButton"
                                              onClick={onEFormClose}
                                              size="small"
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} className="fullHeight">
                                        {isFormJsonValid &&
                                            formJson &&
                                            formJson.metadata &&
                                            formJson.metadata.length >= 1 && (
                                                <div className="EFormContainer">
                                                    <Suspense fallback={<SCLoader />}>
                                                        <SCEForm
                                                          formJson={formJson}
                                                          formValuesHandler={this.formValuesHandler}
                                                          formValues={formValues}
                                                          formMode={mode}
                                                          onClose={() => {}}
                                                          code="code"
                                                          submissionId={1}
                                                          eformVersionId={1}
                                                          dmsToken={dmsToken}
                                                          user={user}
                                                          targetId={user.id}
                                                          targetType={user.userType}
                                                          generator={user}
                                                        />
                                                    </Suspense>
                                                </div>
                                            )}
                                        {!isFormJsonValid && jsonErrors && (
                                            <div className="errorDiv">
                                                {jsonErrors.map(error => (
                                                    <div className="errorLine">{error}</div>
                                                ))}
                                            </div>
                                        )}
                                    </Col>
                                </Content>
                            </Col>
                        )}

                        {/* EForm can only be viewed with data with close button */}
                        {mode === VIEW && (
                            <Col xs={24} sm={24} md={24} lg={24} className="colFullHeight">
                                <Content className="fullHeight contentTag">
                                    <Col xs={24} sm={24} md={{ span: 24 }} lg={{ span: 24 }} className="fullHeight">
                                        <div className="EFormContainer">
                                            <Suspense fallback={<SCLoader />}>
                                                <SCEForm
                                                  formJson={formJson}
                                                  formValuesHandler={this.formValuesHandler}
                                                  formValues={formValues}
                                                  formMode={mode}
                                                  onClose={() => {}}
                                                  code="code"
                                                  submissionId={1}
                                                  eformVersionId={1}
                                                  dmsToken={dmsToken}
                                                  user={user}
                                                  targetId={user.id}
                                                  targetType={user.userType}
                                                  generator={user}
                                                />
                                            </Suspense>
                                        </div>
                                    </Col>
                                </Content>
                            </Col>
                        )}

                        {/* EForm can be edited with data for customers */}
                        {mode === FILLED && (
                            <Col xs={24} sm={24} md={24} lg={24} className="colFullHeight">
                                <Content className="fullHeight">
                                    <Col
                                      xs={24}
                                      sm={24}
                                      md={{ offset: 4, span: 16 }}
                                      lg={{ offset: 4, span: 16 }}
                                      className="fullHeight"
                                    >
                                        <div className="EFormContainer">
                                            <Suspense fallback={<SCLoader />}>
                                                <SCEForm
                                                  formJson={formJson}
                                                  formValuesHandler={this.formValuesHandler}
                                                  formValues={formValues}
                                                  formMode={mode}
                                                  onClose={() => {}}
                                                  code="code"
                                                  submissionId={1}
                                                  eformVersionId={1}
                                                  dmsToken={dmsToken}
                                                  user={user}
                                                  targetId={user.id}
                                                  targetType={user.userType}
                                                  generator={user}
                                                />
                                            </Suspense>
                                        </div>
                                    </Col>
                                </Content>
                            </Col>
                        )}

                        {/* EForm can only be viewed without data with close button */}
                        {mode === PREVIEW && (
                            <Col xs={24} sm={24} md={24} lg={24} className="colFullHeight">
                                <Content className="fullHeight">
                                    <Col
                                      xs={24}
                                      sm={24}
                                      md={{ offset: 4, span: 16 }}
                                      lg={{ offset: 4, span: 16 }}
                                      className="fullHeight"
                                    >
                                        <div className="EFormContainer">
                                            <Suspense fallback={<SCLoader />}>
                                                <SCEForm
                                                  formJson={formJson}
                                                  formValuesHandler={this.formValuesHandler}
                                                  formMode={mode}
                                                  onClose={() => {}}
                                                  code="code"
                                                  submissionId={1}
                                                  eformVersionId={1}
                                                  dmsToken={dmsToken}
                                                  user={user}
                                                  targetId={user.id}
                                                  targetType={user.userType}
                                                  generator={user}
                                                />
                                            </Suspense>
                                        </div>
                                    </Col>
                                </Content>
                            </Col>
                        )}

                        {/* EForm can be created from scratch */}
                        {mode === CREATE && (
                            <Col xs={24} sm={24} md={24} lg={24} className="colFullHeight">
                                <Content className="fullHeight contentTag">
                                    <Col xs={24} sm={24} md={12} lg={12} className="fullHeight">
                                        <TextArea
                                          rows={10}
                                          onChange={this.formJsonHandler}
                                          placeholder={getString("Start building your e-form schema")}
                                        />
                                        <div className="buttonActions">
                                            <SCButton
                                              text={getString('Save')}
                                              type="primary"
                                              className="primary saveButton"
                                              onClick={() => this.saveEform(enumFormActionType.CREATE)}
                                              size="small"
                                              disabled={!isFormJsonValid}
                                            />
                                            <SCButton
                                              text={getString('Cancel')}
                                              type="default"
                                              className="cancelButton"
                                              onClick={onEFormClose}
                                              size="small"
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} className="fullHeight">
                                        {isFormJsonValid &&
                                            formJson &&
                                            formJson.metadata &&
                                            formJson.metadata.length > 1 && (
                                                <div className="EFormContainer">
                                                    <Suspense fallback={<SCLoader />}>
                                                        <SCEForm
                                                          formJson={formJson}
                                                          formValuesHandler={this.formValuesHandler}
                                                          formMode={mode}
                                                          onClose={() => {}}
                                                          code="code"
                                                          submissionId={1}
                                                          eformVersionId={1}
                                                          dmsToken={dmsToken}
                                                          user={user}
                                                          targetId={user.id}
                                                          targetType={user.userType}
                                                          generator={user}
                                                        />
                                                    </Suspense>
                                                </div>
                                            )}
                                        {(!isFormJsonValid || !formJson) && (
                                            <div className="errorDiv">
                                                {jsonErrors &&
                                                    jsonErrors.map(error => <div className="errorLine">{error}</div>)}
                                            </div>
                                        )}
                                    </Col>
                                </Content>
                            </Col>
                        )}
                    </Row>
                </Content>
            </Layout>
        );
    }
}

export const stateToProps = state => {
    return {
        createdEForm: state.eform.createdEForm,
        dmsToken: state.auth.dmsToken,
        eformFetchJson: state.eform.successFetchEForm,
        errorCreateEForm: state.eform.errorCreateEForm,
        savingEForm: state.eform.savingEForm,
        successFetchEFormValues: state.eform.successFetchEFormValues,
        user: state.auth.user,
    };
};

const dispatchers = dispatch => {
    return {
        eFormCreateOrUpdate: (eform, actionType) => dispatch(eFormCreateOrUpdateAction(eform, actionType)),
        fetchEFormDispatch: eformId => dispatch(fetchEFormAction(eformId)),
        resetEFormStoreDispatch: () => dispatch(resetEFormStoreAction()),
        viewSubmissionDispatch: (id: string | number) => dispatch(viewSubmissionAction(id)),
    };
};

const CreateEForm = connect(stateToProps, dispatchers)(CreateEFormBase);
export { CreateEForm, CreateEFormBase };
