import React from 'react';
import chatBotIcon from '../../images/ChatBotIcon.svg';
import bellIcon from '../../images/bell-solid.svg';
import coBrowseIcon from '../../images/co-browse.svg';
import './ChatBotFloatingButton.scss';
import { ZERO } from '../../constants/numberConstants';
import { FloatingChatModal } from "../FloatingChatModal/FloatingChatModal";
import Draggable from 'react-draggable';

class ChatBotFloatingButton extends React.Component <any, any> {

    constructor(props) {
        super(props);
        this.onStop = this.onStop.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.state = {
            dragging: false
        }
    }

    onDrag = () =>{
        this.setState({dragging: true})
    }

    onDrop = (...args) => {
        console.log(args);
    }

    onStop = (...args) => {
        const {dragging} = this.state;

        this.setState({dragging: false})
        if (dragging) {
          this.onDrop(...args);
        } else {
          this.props.onClick()
        }
      }

    render() {
        const { onClick, showNotificationIcon, agentLogin, currentUserName, botAnswerHandler, hideChatFloatModal, productEForm } = this.props;
        if(productEForm) {
            return (
                <div onClick={onClick} onKeyDown={onClick}>
                <Draggable
                onDrag={this.onDrag} 
                onStop={this.onStop} 
                >
                    <div className="chatBotFloatingButton" role="button" tabIndex={ZERO}>
                        <div className="innerContent">
                            <div style={{ position: 'relative' }}>
                                <img src={chatBotIcon} alt="chatbot icon" onClick={onClick} onKeyDown={onClick} />
                                {showNotificationIcon && (
                                    <img src={bellIcon} alt="notification icon" className="notification-icon" />
                                )}
                                {!agentLogin && !hideChatFloatModal &&(
                                    <FloatingChatModal
                                    currentUserName={currentUserName}
                                    botAnswerHandler={botAnswerHandler}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </Draggable>
                </div>
            );
        }
        return (
                <div className="chatBotFloatingButton" role="button" tabIndex={ZERO}>
                    <div className="innerContent">
                        <div style={{ position: 'relative' }}>
                            <img src={chatBotIcon} alt="chatbot icon" onClick={onClick} onKeyDown={onClick} />
                            {showNotificationIcon && (
                                <img src={bellIcon} alt="notification icon" className="notification-icon" />
                            )}
                            {!agentLogin && !hideChatFloatModal &&(
                                <FloatingChatModal
                                currentUserName={currentUserName}
                                botAnswerHandler={botAnswerHandler}
                                />
                            )}
                        </div>
                    </div>
                </div>
        );
    }
};

export { ChatBotFloatingButton };
