import React from 'react';
import { IMessageStatus, messageDeliveryStatus } from '../../../../interfaces/IChat';

import read from '../../../../images/ReadStatus.svg';

import './MessageStatus.scss';

const MessageStatus = (props: IMessageStatus) => {
    const { status } = props;
    if (status === messageDeliveryStatus.READ) {
        return <img alt="" src={read} />;
    }
    if (status === messageDeliveryStatus.DELIVERED) {
        return <img alt="" src={read} />;
    }
    return <img alt="" src={read} />;
};

export default MessageStatus;
