import { RouteComponentProps } from 'react-router-dom';
import { enumFormStatus, enumInputTypes } from '@saleskey/frontend-shared/src/interfaces/IEForm';

/**
 * Size enum which represents the size accepted from the antd design
 */
export enum enumSize {
    LARGE = 'large',
    SMALL = 'small',
    DEFAULT = 'default',
}

/**
 *ItemSchema which will be common for the other input types
 */
interface IItemSchema {
    label: string;
    name: string;
    size?: enumSize;
    required: boolean;
    doNotRender?: boolean;
    appendCurrency?: boolean;
    currencyKey?: boolean;
    requiredErrorMsg?: string | undefined;
    // value of the fields which can be any format depending on the input type
    value?: string | boolean | Array<string | number> | number;
    disabled?: boolean | undefined;
}

/**
 * Input Schema
 */
// INPUT
interface IInputSchema extends IItemSchema {
    type: enumInputTypes;
    placeholder?: string | undefined;
}

/**
 * InputNumber Schema
 */
// INPUT_NUMBER
interface IInputNumberSchema extends IItemSchema {
    type: enumInputTypes;
    min?: number;
    max?: number;
    defaultValue?: number;
}

/**
 * TextArea Schema
 */
// TEXTAREA
interface ITextAreaSchema extends IItemSchema {
    type: enumInputTypes;
    placeholder?: string | undefined;
    rows?: number;
}

/**
 * Date Schema
 */
// DATE
interface IDateSchema extends IItemSchema {
    type: enumInputTypes;
    minDate?: Date;
    maxDate?: Date;
    default?: Date;
}

/**
 * RadioGroup Schema
 */
interface IRadioItem {
    value: string | number;
    text: string | number;
    disabled?: boolean;
}
// RADIO_GROUP
interface IRadioGroupSchema extends IItemSchema {
    type: enumInputTypes;
    isVertical?: boolean;
    defaultValue?: string;
    options: Array<IRadioItem>;
}

/**
 * Select and MultiSelect schema
 */
interface IOptionItem {
    value: string | number; // act as key
    text: string | number; // act as text on ui
    disabled?: boolean;
}
// SELECT
interface ISelectSchema extends IItemSchema {
    type: enumInputTypes;
    initialValue: Array<IOptionItem['value']>;
    options: Array<IOptionItem>;
}

// MULTI_SELECT
interface IMultiSelectSchema extends IItemSchema {
    type: enumInputTypes;
    mode: 'multiple';
    initialValue: Array<IOptionItem['value']>;
    options: Array<IOptionItem>;
}

/**
 * Checkbox Schema
 */
interface ICheckBoxItem {
    value: string | number;
    disabled?: boolean;
    text: string | number;
}
// CHECKBOX_GROUP
interface ICheckBoxSchema extends IItemSchema {
    type: enumInputTypes;
    initialValue: Array<ICheckBoxItem['value']>;
    options: Array<ICheckBoxItem>;
}

/**
 * Switch Schema
 */
interface ISwitchSchema extends IItemSchema {
    valuePropName: string;
}

/**
 * Upload schema
 */
interface IUploadSchema extends IItemSchema {
    type: enumInputTypes;
    value: Array<string>;
}

interface INumberSchema extends IItemSchema {
    type: enumInputTypes;
    value: number;
}

interface ISectionHeaderSchema extends IItemSchema {
    type: enumInputTypes;
    defaultValue: string;
}

export interface IEFormJson {
    id?: string | number;
    description: string;
    disabled?: boolean | undefined;
    header: string;
    name: string;
    tags: Array<string>;
    eformType?: string;
    metadata: Array<
        | IInputSchema
        | ITextAreaSchema
        | IDateSchema
        | IRadioGroupSchema
        | ISelectSchema
        | IMultiSelectSchema
        | ICheckBoxSchema
        | IInputNumberSchema
        | ISwitchSchema
        | IUploadSchema
        | INumberSchema
        | ISectionHeaderSchema
    >;
}

export interface IEFormRootProps {
    formJson: IEFormJson;
    formValues?: object;
    formValuesHandler?: (formJson: IEFormJson, formValues: object) => void;
}

/**
 * Props for EForm.reducer and EForm.action
 */
interface ISubmittedUser {
    name: string;
}

/**
 * Interface for `NewEForm`
 */
export interface INewEForm extends IEFormJson {
    inputsAvailable: number;
    inputsRequired: number;
    eformId?: string | number;
    id?: string | number;
}

/**
 * common pagination object
 */
export interface IPagination {
    total?: number;
    skip: number;
    limit: number;
}

/**
 * `EForm.reducer.ts`
 * @remarks
 * Interface for the Form Reducer which contains listEForm, submittedEForm, createEForm, fetchEForm, updateEForm
 */

/**
 * interface for the single list item in eform list
 */
export interface ISingleEForm {
    id: string;
    name: string;
    header: string;
    description: string;
    isActive: boolean;
    thumbnailUrl: string;
    submissions?: number;
    tags: Array<string> | [];
}

export interface IListEForm {
    errorEFormsList?: string | undefined;
    listEForms?: Array<ISingleEForm>;
    listEFormsPagination?: IPagination;
    loadingEFormsList?: boolean;
}

/**
 * interface for the single submitted form list item
 */
export interface ISubmittedEForm {
    id: string;
    eformVersionId: string;
    thumbnailUrl?: string;
    submittedFields: Array<string> | [];
    isComplete: boolean;
    user: ISubmittedUser;
    docsRequiredCount: number;
    docsUploadedCount: number;
    header?:string;
}
export interface IListSumbmittedEForm {
    errorSubmissionsList?: string | undefined;
    loadingSubmissionsList?: boolean;
    submittedFormsList?: Array<ISubmittedEForm> | [];
    submissionsPagination?: IPagination;
}

/**
 * Interface for create eform
 */
interface ICreatedEFormSuccess {
    eformId: string;
    eformVersionId: string;
}
export interface ICreateEForm {
    errorCreateEForm?: string | undefined;
    savingEForm?: boolean;
    createdEForm?: ICreatedEFormSuccess;
}

/**
 * Interface for update eform
 */
export interface IUpdateEForm {
    errorUpdateEForm?: string | undefined;
    updatingEForm?: boolean;
    updatedEForm?: object;
}

/**
 * Single EForm with details included for fetch api
 */
export interface ISingleDetailEForm extends IEFormJson {
    inputsAvailable: number;
    inputsRequired: number;
    isLatest?: boolean;
}
export interface IFetchEForm {
    errorFetchEForm?: string | undefined;
    fetchingEForm?: boolean;
    successFetchEForm?: ISingleDetailEForm;
}

export interface IArchiveEForm {
    errorArchiveEForm?: string | undefined;
    archivingEForm?: boolean;
    successArchiveEForm?: object | boolean;
}

export interface IEFormReducerProp
    extends IListEForm,
        IListSumbmittedEForm,
        ICreateEForm,
        IFetchEForm,
        IArchiveEForm,
        IUpdateEForm {
    eFormDrawerState?: boolean;
    reloadRequired?: boolean;
}

/**
 * `CreateEForm.tsx` Props and States
 */
export enum enumEFormModes {
    CREATE = 'create', // EForm can be created from scratch - no action buttons on eform
    VIEW = 'view', // EForm can only be viewed with data - close button
    EDIT = 'edit', // EForm can be edited to add new fields - no action buttons
    PREVIEW = 'preview', // EForm can only be viewed without data - close button
    FILLED = 'filled', // EForm can be edited with data for customers - action buttons
}

export interface ICreateEFormProps {
    mode: enumEFormModes;
    eformId?: string | number | undefined;
    savingEForm?: boolean;
    createdEForm?: boolean;
    errorCreateEForm?: boolean;
    fetchEFormDispatch: (eformId) => void;
    viewSubmissionDispatch: (id: string | number) => void;
    eFormCreateOrUpdate: (eform: object, string) => void;
    eformFetchJson: ISingleDetailEForm;
    onEFormClose: () => void;
    submissionId?: string | number;
    successFetchEFormValues: object;
    resetEFormStoreDispatch: () => void;
    user: any;
    dmsToken: string;
}

export interface initialEFormJson {
    name: string;
    disabled?: undefined;
    description: string;
    tags: Array<string>;
    header: string;
    eformType?: string;
    metadata: [];
}

export interface ICreateEFormState {
    formJson: IEFormJson | initialEFormJson;
    formValues?: object;
    isFormJsonValid?: boolean;
    viaTextarea: boolean;
    updatedOnce: boolean;
    jsonErrors: Array<string> | undefined;
}

/**
 * `ListEFormsInternal.tsx` Props and States
 */
export interface IEFormApiParam {
    skip: number;
    limit: number | undefined;
    eformId?: string | number;
}
/**
 * here id and eformId are referring to the same entity
 */
export interface IListEFormsInternalProp extends IEFormReducerProp {
    listEFormDispatch: (IEFormApiParam) => void;
    viewSubmissions: (eformId: string | number) => void;
    archiveEFormDispatch: (id: string | number) => void;
}

/**
 * `ListEForms.tsx` Props and States
 */
export interface IListEFormProp extends RouteComponentProps {
    addNewEFormSidebarButton: (isVisible: boolean) => void;
}

export interface IListFormsInternalState {
    currentPage: number;
    pageSize: number;
}

/**
 * `SubmittedEFormsInternal.tsx` Props and States
 */
export interface ISubmittedEFormsInternalProp extends IEFormReducerProp {
    eformId: string | number;
    submittedEFormsDispatch: (IEFormApiParam, eformId) => void;
    viewSubmission: (id: string | number, name:string) => void;
}
export interface ISubmittedEFormsInternalState {
    currentPage: number;
    pageSize: number;
}

/**
 * `SubmittedEForms.tsx` Props and States
 */
export interface ISubmittedEFormsProp
    extends RouteComponentProps<{
        eformId: string;
    }> {
    eFormDrawerDispatch: (isOpen: boolean) => void;

    eFormDrawerState?: boolean | undefined;
}

/**
 * `EFormDrawer.tsx` Props and States
 */
export enum enumFormActionType {
    CREATE = 'create',
    UPDATE = 'update',
}
export interface IEFormDrawerProp extends IEFormReducerProp {
    fetchEFormDispatch: (id: string) => void;
    eFormCreateOrUpdateDispatch: (eform: INewEForm, actionType: enumFormActionType) => void;
}

interface IPartialEFormResponse {
    description: string;
    header: string;
    metadata: Array<object>;
    name: string;
    tags: Array<string>;
}

export interface ISingleEFormSubmitted {
    id: string;
    eformVersionId: string;
    submittedData: Array<object>;
    thumbnailId: string | null;
    submittedFields: Array<string>;
    isComplete: boolean;
    eformVersion: IPartialEFormResponse;
    uploadedDocuments: Array<object>;
    formStatus?: enumFormStatus;
}
