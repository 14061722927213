import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tooltip, Layout, Icon } from 'antd';
import { getString } from '@saleskey/frontend-shared';
import { UserType } from '@saleskey/frontend-shared/src/constants';
import { addNewDocumentDrawer, eFormDrawerAction } from '../../store/rootActions';

// import { AclPermission } from '../../components/AclPermission/AclPermission';
import { ISider } from '../../interfaces/ISider';
import './Sidebar.scss';
import { AclPermission } from '../../components/AclPermission/AclPermission';

const { Sider } = Layout;

/**
 * AclPermission logic has been implemented at core, different permissions to component will be given
 * once api integration is complete
 */

/**
 * Sidebar Container
 * @remarks
 * contains the sidebar navigation menu for the admin.
 * This component is a part of {@link @saleskey/frontend-admin | Frontend Admin App}
 */
class SidebarBaseContainer extends React.Component<ISider, any> {
    private ulRef1: React.RefObject<any>;

    private ulRef2: React.RefObject<any>;

    private ulRef3: React.RefObject<any>;

    /**
     * @remarks
     * creates the refs for the ul
     * @param props - type of `{ISider}`
     */
    constructor(props: ISider) {
        super(props);
        this.showDocumentDrawer = this.showDocumentDrawer.bind(this);
        this.showEFormDrawer = this.showEFormDrawer.bind(this);
        this.ulRef1 = React.createRef<any>();
        this.ulRef2 = React.createRef<any>();
        this.ulRef3 = React.createRef<any>();
    }

    /**
     * @remakrs
     * add the display property to parent, none if no child found
     * @return void
     */
    public componentDidMount(): void {
        if (this.ulRef1.current && !this.ulRef1.current.childElementCount) {
            this.ulRef1.current.parentNode.style.display = 'none';
        }
        if (this.ulRef2.current && !this.ulRef2.current.childElementCount) {
            this.ulRef2.current.parentNode.style.display = 'none';
        }
        if (this.ulRef3.current && !this.ulRef3.current.childElementCount) {
            this.ulRef3.current.parentNode.style.display = 'none';
        }
    }

    /**
     * new document drawer open dispatcher function
     */
    public showDocumentDrawer() {
        const { addDocumentDrawerState, addNewDocumentDrawer: addNewDocumentDrawerProp } = this.props;
        addNewDocumentDrawerProp(!addDocumentDrawerState);
    }

    /**
     * eform drawer open dispatcher function
     */
    public showEFormDrawer() {
        const { eFormDrawerState, eFormDrawerDispatch } = this.props;
        eFormDrawerDispatch(!eFormDrawerState);
    }

    /**
     * @returns ReactNode - parsed as HTML DOM
     */
    public render(): ReactNode {
        const { sidebarCollapsed, showAddNewDocumentButton, showAddNewEFormButton, userType } = this.props;
        return (
            <React.Fragment>
                <Sider breakpoint="xs" trigger={null} collapsible collapsed={sidebarCollapsed} className="sidebar">
                    <div className="navigation">
                        <ul>
                            {showAddNewDocumentButton && (
                                <AclPermission
                                    feature="document"
                                    subfeature="upload"
                                    action="view"
                                    propertytype="element"
                                >
                                    <button
                                        type="button"
                                        className="addNewDocumentButton"
                                        onClick={this.showDocumentDrawer}
                                    >
                                        <Tooltip placement="bottom" title="New document" defaultVisible={false}>
                                            <Icon
                                                type="plus"
                                                className={!sidebarCollapsed ? 'nopointer' : 'alignRight'}
                                            />
                                        </Tooltip>
                                        {!sidebarCollapsed && (
                                            <span className="title">{getString('New document')}</span>
                                        )}
                                    </button>
                                </AclPermission>
                            )}
                            {showAddNewEFormButton && (
                                <AclPermission feature="eform" subfeature="config" action="view" propertytype="element">
                                    <button type="button" className="addNewEFormButton" onClick={this.showEFormDrawer}>
                                        <Tooltip placement="bottom" title="New EForm" defaultVisible={false}>
                                            <Icon
                                                type="plus"
                                                className={!sidebarCollapsed ? 'nopointer' : 'alignRight'}
                                            />
                                        </Tooltip>
                                        {!sidebarCollapsed && <span className="title">New E-Form</span>}
                                    </button>
                                </AclPermission>
                            )}

                            {userType === UserType.admin && (
                                <li className="has-sub">
                                    <Tooltip placement="bottom" title="Uploads" defaultVisible={false}>
                                        <Icon type="user" className={!sidebarCollapsed ? 'nopointer' : 'alignRight'} />
                                    </Tooltip>
                                    {!sidebarCollapsed && <span className="title">{getString('Upload')}</span>}
                                    <ul ref={this.ulRef1}>
                                        <li>
                                            <Link to="/dashboard/upload/groupadmin">
                                                {getString('Upload admin users')}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/dashboard/upload/users">{getString('Upload users')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/dashboard/upload/thirdparty">
                                                {getString('Upload third-party users')}
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            )}
                            <li className="has-sub">
                                <Tooltip placement="bottom" title="E-Form" defaultVisible={false}>
                                    <Icon type="form" className={!sidebarCollapsed ? 'nopointer' : 'alignRight'} />
                                </Tooltip>
                                {!sidebarCollapsed && <span className="title">E-Form</span>}
                                <ul ref={this.ulRef2}>
                                    <li>
                                        <Link to="/dashboard/eform/list">{getString('List e-form')}</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="liTag has-sub">
                                <Tooltip placement="bottom" title="Documents" defaultVisible={false}>
                                    <Icon
                                        type="folder-add"
                                        className={!sidebarCollapsed ? 'nopointer' : 'alignRight'}
                                    />
                                </Tooltip>
                                {!sidebarCollapsed && <span className="title">{getString('Documents')}</span>}
                                <ul ref={this.ulRef2}>
                                    <li>
                                        <Link to="/dashboard/documents/upload">
                                            {getString('Upload Documents Category Type & Value')}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/dashboard/documents">{getString('List Documents')}</Link>
                                    </li>
                                </ul>
                            </li>
                            {userType === UserType.admin && (
                                <li className="liTag">
                                    <Link to="/dashboard/geolocation">
                                        <Tooltip placement="bottom" title="Geolocation" defaultVisible={false}>
                                            <Icon
                                                type="folder-add"
                                                className={!sidebarCollapsed ? 'nopointer' : 'alignRight'}
                                            />
                                        </Tooltip>
                                        {!sidebarCollapsed && <span className="title">Geolocation</span>}
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </div>
                </Sider>
            </React.Fragment>
        );
    }
}

export const stateToProps = state => {
    return {
        addDocumentDrawerState: state.document.addDocumentDrawerState,
        eFormDrawerState: state.eform.eFormDrawerState, // state to show the add new document button
        showAddNewDocumentButton: state.application.showAddNewDocumentButton, // state to show the add new eform button
        showAddNewEFormButton: state.application.showAddNewEFormButton, // state for the document drawer: either true or false
        sidebarCollapsed: state.application.sidebarCollapsed, // state for the eform drawer: either true or false
        userType: state.auth.userType,
    };
};

const dispatchers = dispatch => {
    return {
        addNewDocumentDrawer: isOpen => dispatch(addNewDocumentDrawer(isOpen)),
        eFormDrawerDispatch: isOpen => dispatch(eFormDrawerAction(isOpen)),
    };
};

const SidebarContainer = connect(stateToProps, dispatchers)(SidebarBaseContainer);
export { SidebarContainer, SidebarBaseContainer };
