import * as React from 'react';
import { connect } from 'react-redux';
import { Drawer, Icon, message } from 'antd';
import FileSaver from "file-saver";

import { SCLoader, SCGeneratePDFDataUri, SCPrepareHtmlToPdf } from '@saleskey/frontend-shared';
import { eFormDrawerAction } from '../../store/rootActions';
import { enumEFormModes } from '../../interfaces/IEForm';
import './EFormDrawer.scss';

const CreateEForm = React.lazy(() =>
    import('../CreateEForm/CreateEForm').then(module => ({ default: module.CreateEForm })),
);
export interface IEFormDrawerProp {
    dontShowRightSpace?: boolean,
    eFormDrawerState: boolean | undefined; // redux store property
    title: string;
    mode: enumEFormModes;
    eformId?: string | number;
    eFormDrawerDispatch: (isOpen: boolean) => void;
    submissionId?: string | number;
    enableDownload?: boolean;
    name?:string;
}

export interface IEFormDrawerState {
    isDrawerVisible: boolean | undefined;
    isDownloading: boolean;
}

/**
 * @remarks
 * EForm container contains the view for add new E-Form, update E-form or to preview it.
 * props - type of `IEFormDrawerProp`
 * state - type of `IEFormDrawerState`
 */
class EFormDrawerBaseContainer extends React.Component<IEFormDrawerProp, IEFormDrawerState> {
    public eformRef = React.createRef<any>();

    constructor(props: IEFormDrawerProp) {
        super(props);
        const { eFormDrawerState } = props;
        this.state = {
            isDownloading: false,
            isDrawerVisible: eFormDrawerState
        };
        this.onClose = this.onClose.bind(this);
        this.eformOnClose = this.eformOnClose.bind(this);
        this.download = this.download.bind(this)
    }

    componentDidUpdate() {
        const { eFormDrawerState } = this.props;
        const { isDrawerVisible } = this.state;
        if (isDrawerVisible !== eFormDrawerState) {
            this.setState({ isDrawerVisible: eFormDrawerState });
        }
    }

    // Callback function to close the drawer
    onClose(): void {
        const { eFormDrawerDispatch, eFormDrawerState } = this.props;
        eFormDrawerDispatch(!eFormDrawerState);
    }

    eformOnClose(): void {
        const { eFormDrawerDispatch, eFormDrawerState } = this.props;
        eFormDrawerDispatch(!eFormDrawerState);
    }

    async download():Promise<void>{
        const node = this.eformRef.current;
        this.setState({ isDownloading: true });
        if (node instanceof HTMLElement) {
            SCPrepareHtmlToPdf(node)
            const efRoot = node.querySelector('.eFormRoot');
            if (efRoot) {
                const data = await SCGeneratePDFDataUri(efRoot, {margin:{bottom:20,left:20,right:20, top:20}, paperSize:'A4'}, true);
                const { name } = this.props;
                const pdfName = `${name}.pdf`;
                FileSaver.saveAs(data, pdfName);
                this.onClose()
                message.info('E-form converted to pdf')
                this.setState({ isDownloading:false })
            }
        }


    }

    public render(): React.ReactNode {
        const { isDrawerVisible, isDownloading } = this.state;
        const { title, mode, eformId, submissionId, enableDownload, dontShowRightSpace } = this.props;
        return (
            <div className="eFormDrawer">
                <Drawer
                  title={<div>{title} {enableDownload && (isDownloading ? <Icon style={{paddingLeft:'20px'}} type="loading" /> : <Icon style={{paddingLeft:'20px'}} type="download" onClick={this.download} />)}</div>}
                  placement="right"
                  closable={dontShowRightSpace ? true : false}
                  width={dontShowRightSpace ? "50%":"80%"}
                  style={{ top: '64px' }}
                  onClose={this.onClose}
                  visible={isDrawerVisible}
                  destroyOnClose
                >
                    <div ref={this.eformRef}>
                        <React.Suspense fallback={<SCLoader />}>
                            <CreateEForm
                              mode={mode}
                              eformId={eformId}
                              onEFormClose={this.eformOnClose}
                              submissionId={submissionId}
                            />
                        </React.Suspense>
                    </div>
                </Drawer>
            </div>
        );
    }
}

export const stateToProps = state => {
    return {
        eFormDrawerState: state.eform.eFormDrawerState,
    };
};

const dispatchers = dispatch => {
    return {
        eFormDrawerDispatch: isOpen => dispatch(eFormDrawerAction(isOpen)),
    };
};

const EFormDrawer = connect(stateToProps, dispatchers)(EFormDrawerBaseContainer);
export { EFormDrawer, EFormDrawerBaseContainer };
