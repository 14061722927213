import React from 'react';
import OtpInput from 'react-otp-input';
import IOtpInput from '../../interfaces/IOtpInput';
import './otpInput.scss';

const otpInput = (props: IOtpInput) => {
    const { count, seperator, callbackOtpEvent, errorMessage } = props;
    return (
        <React.Fragment>
            <OtpInput
              onChange={callbackOtpEvent}
              containerStyle="otpContainer"
              numInputs={count}
              isInputNum
              className="otpInput"
              separator={<span>{seperator}</span>}
            />
            <span className="errorMessage">{errorMessage}</span>
        </React.Fragment>
    );
};

export default otpInput;
