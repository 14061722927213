import React from 'react';

import Moment from 'react-moment'

import './Comments.scss';
import { ZERO } from '../../constants';
import { IComments } from '../../interfaces/IComments';

const Comments = (props: IComments) => {
    const {reviewCommentsHistory } = props;

    const msgContent = reviewCommentsHistory && reviewCommentsHistory.length !== ZERO && reviewCommentsHistory.map(message =>{
        return (
            <div>
                <div className={`chat-box ${message.myMessage ? 'right-card':'left-card'} message-bg-color`}>
                    <div className="inner-message inner-message-bg-color ">
                        <div className="inner-left" />
                        <div className="message-box">
                            <div className="msg-header header-color">{message.from_user}</div>
                            <div className="msg">{message.from_message}</div>
                        </div>
                    </div>
                    <div className="reply-message-box">
                        <div className="user-details">
                            <div className="user">{message.reply_user}</div>
                            <div className="time"><Moment fromNow>{message.createdAt}</Moment></div>
                        </div>
                        <div className="reply-message"> {message.reply_message}</div>
                    </div>
                </div>
                <br />
            </div>
        )
    })
    return (
        <div className="comments-container">
            {msgContent}
        </div>
    )
}

export default Comments