import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import middleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './store/rootReducer';
import { LoginContainer } from './containers/Login/Login';
import { DashboardBaseContainer } from './containers/Dashboard/Dashboard';
import { ProtectedRoute } from './components/Protected/ProtectedRoute';
import Error from './components/Error/Error';
import { init } from './utils/localisation';
import { ChangePassword } from './containers/ChangePassword/ChangePassword';

const RStore = createStore(rootReducer, composeWithDevTools(applyMiddleware(middleware)));

const routing = (
    <Router>
        <Switch>
            <Route path="/" exact component={LoginContainer} />
            <ProtectedRoute path="/dashboard" component={DashboardBaseContainer} />
            <ProtectedRoute path="/changepassoword" exact component={ChangePassword} />
            <Route component={Error} />
        </Switch>
    </Router>
);

init().then(() => {
    ReactDOM.render(<Provider store={RStore}>{routing}</Provider>, document.getElementById('root'));
});
