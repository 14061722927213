import React from 'react';
import { Card, Icon, Button, message } from 'antd';
import { IDocThumbnailCard, enumDocumentStatus } from '../../interfaces/IDocument';
import './DocThumbnailCard.scss';
import { ThumbnailByType } from '../ThumbnailByType/ThumbnailByType';
import IWindow from '../../interfaces/IWindow';
import { ONE } from '../../constants/numberConstants';
import { getString } from '../../utils/localization';

const { Meta } = Card;

const customWindow: IWindow = window;
/**
 * @remarks
 * Document thumbnail component which can generate certain actions
 * @param props : type of `{IDocThumbnailCard}`
 * @return Document thumbnail card dom
 */
const DocThumbnailCard = (props: IDocThumbnailCard) => {
    const {
        isHorizontalView,
        name,
        thumbnailUrl,
        source,
        status,
        documentId,
        downloadDocument,
        fileType,
        viewDetailDocument,
        dmsToken
    } = props;

    const downloadDocumentHandler = () => {
        if (source) {
            downloadDocument(documentId, source);
        } else {
            message.error('Source is required to download the document.');
        }
    };

    /**
     * @remarks
     * handler to view the document based on document id and source type
     */
    const viewDetailDocumentHandler = () => {
        if (viewDetailDocument && source) {
            viewDetailDocument(documentId, source);
        }
    };

    /**
     * @return HTML Dom
     */
    return (
        <React.Fragment>
            {!isHorizontalView && (
                <Card
                  className="thumbnailCard"
                  cover={(
                        <ThumbnailByType
                          fileType={fileType}
                          fileUrl={`${customWindow.config.dmsUrl}/api${thumbnailUrl}?token=${dmsToken}`}
                          isUrlInvalid={!thumbnailUrl}
                          mimeType={fileType}
                        />
                      )}
                  hoverable
                >
                    <Meta title={name} description={`(source - ${source})`} />
                    <div className="downloadIcon">
                        <Button rel="noopener noreferrer" className="downloadButton" onClick={downloadDocumentHandler}>
                            <Icon type="download" viewBox="0 0 24 24" />
                        </Button>
                    </div>
                    {status === enumDocumentStatus.EXPIRED && <div className="ExpiredOverlay">{getString("Expired")}</div>}
                    {status === enumDocumentStatus.ARCHIVED && <div className="ArchivedOverlay">{getString("Archived")}</div>}
                </Card>
            )}
            {isHorizontalView && (
                <div className="thumbnailCardHorizontal">
                    <div
                      className="horizontalRow"
                      onClick={viewDetailDocumentHandler}
                      onKeyUp={viewDetailDocumentHandler}
                      role="button"
                      tabIndex={ONE}
                    >
                        <div className="leftRow">
                            <h3>{name}</h3>
                            <p>{`(source - ${source})`}</p>
                        </div>
                        <div className="imageSection">
                            <ThumbnailByType
                              fileType={fileType}
                              fileUrl={`${customWindow.config.baseUrl}/api${thumbnailUrl}`}
                              isUrlInvalid={!thumbnailUrl}
                              mimeType={fileType}
                            />
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export { DocThumbnailCard };
