import React from 'react';
import { Form, Input, Select, Radio, Button } from 'antd';

import { IAddQuestion, ISingleQuestion } from '../../../interfaces/IAddQuestion';
import { getString } from '@saleskey/frontend-shared';
import { FormComponentProps } from 'antd/lib/form/Form';

const { Option } = Select;

class AddQuestion extends React.Component<IAddQuestion & FormComponentProps, any> {
    constructor(props: IAddQuestion & FormComponentProps) {
        super(props);

        this.removeSingleQuestion = this.removeSingleQuestion.bind(this);
    }

    removeSingleQuestion (index: number) {
        const {questions, updateQuestions} = this.props;
        questions.splice(index, 1);
        updateQuestions(questions)
    }

    updateInputValue(value, index, type) {
        const {questions, updateQuestions} = this.props;
        questions[index][type] = value;
        updateQuestions(questions)
    }

    render() {
        const { questions, form } = this.props;
        const { getFieldDecorator, isFieldTouched, getFieldError } = form;
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };

        return (
            (questions as ISingleQuestion[]).map((question:ISingleQuestion, i:number) => (
                <div key={question.id} className="questions-container">
                    <h3>Question - {i + 1}</h3>
                    <Form.Item
                        label={getString('Question text')}
                        validateStatus={isFieldTouched(`questionText${question.id}`) && getFieldError(`questionText${question.id}`) ? 'error' : ''}
                        help={isFieldTouched(`questionText${question.id}`) && getFieldError(`questionText${question.id}`) || ''}
                        hasFeedback
                    >
                        {getFieldDecorator(`questionText${question.id}`, {
                            initialValue: question.text,
                            rules: [{ message: 'Please input question text', required: true }],
                        })(<Input size="large" onChange={(e) => this.updateInputValue(e.target.value, i, 'text')}/>)}
                    </Form.Item>
                    <Form.Item
                        label={getString('Select question type')}
                        validateStatus={isFieldTouched(`questionType${question.id}`) && getFieldError(`questionType${question.id}`) ? 'error' : ''}
                        help={isFieldTouched(`questionType${question.id}`) && getFieldError(`questionType${question.id}`) || ''}
                        hasFeedback
                    >
                        {getFieldDecorator(`questionType${question.id}`, {
                            initialValue: question.type,
                            rules: [
                                { message: getString('Please select question type.'), required: true },
                            ],
                        })(
                            <Select placeholder={getString('Question type')} size="large" 
                            onChange={(e) => this.updateInputValue(e, i, 'type')} >
                                <Option value="dropdown">{getString('Dropdown')}</Option>
                                <Option value="text">{getString('Text')}</Option>
                            </Select>,
                        )}
                    </Form.Item>
                    <Form.Item
                        label={getString('Is mandatory question')}
                        validateStatus={isFieldTouched(`isMandatory${question.id}`) && getFieldError(`isMandatory${question.id}`) ? 'error' : ''}
                        help={isFieldTouched(`isMandatory${question.id}`) && getFieldError(`isMandatory${question.id}`) || ''}
                    >
                        {getFieldDecorator(`isMandatory${question.id}`, {
                            initialValue: true,
                            rules: [{ message: 'Please select if question is mandatory', required: true }],
                        })(
                            <Radio.Group 
                                onChange={(e) => this.updateInputValue(e.target.value, i, 'isMandatory')} >
                                <Radio style={radioStyle} value={true}>
                                    {getString('Yes')}
                                </Radio>
                                <Radio style={radioStyle} value={false}>
                                    {getString('No')}
                                </Radio>
                            </Radio.Group>,
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="default" className="cancelButton" onClick={() => this.removeSingleQuestion(i)}>
                            {getString('Delete')}
                        </Button>
                    </Form.Item>
                </div>
            ))
        )
    }
}

export { AddQuestion };