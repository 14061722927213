import React from 'react';
import Moment from 'react-moment';
import { ISharedContentItem } from '../../interfaces/ISharedContentItem';
import { contentType } from '../../interfaces/IContentType';
import './SharedContentItem.scss'
import { ThumbnailByType } from '../ThumbnailByType/ThumbnailByType';
// import IWindow from '../../interfaces/IWindow';

// const customWindow: IWindow = window;

export const SharedContentItem = (props: ISharedContentItem) => {
    const calendarStrings = {
        lastDay: '[Yesterday]',
        lastWeek: '[last] dddd []',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd []',
        sameDay: '[Today]',
        sameElse: 'L',
    };

    const calendarStringsTime = {
        lastDay: 'LT',
        lastWeek: 'LT',
        nextDay: 'LT',
        nextWeek: 'LT',
        sameDay: 'LT',
        sameElse: 'L',
    };
    const { contentType: ContentType, contentName, senderName,addBottomBorder, timestamp, direction } = props;
    switch (ContentType) {
        case contentType.DOCUMENT:
            return (
                <div className={`chatSharedDocument ${addBottomBorder?'addBottomBorder':''} ${direction}`}>
                    <div className="imageSrc">
                        <ThumbnailByType className="thumbnail" mimeType="" fileType="" fileUrl="" isUrlInvalid />
                    </div>
                    <div className="documentInfo">
                        <div className="name">{contentName}</div>
                        <div className="secondInfo">{senderName}</div>
                        <div className="thirdInfo">
                            <div className="dateInfo">
                                <Moment calendar={calendarStrings}>{timestamp}</Moment>
                            </div>
                            <div className="timeI">
                                <Moment calendar={calendarStringsTime}>{timestamp}</Moment>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case contentType.EFORM:
            return (
                <div className={`chatSharedDocument ${addBottomBorder?'addBottomBorder':''} ${direction}`}>
                    <div className="imageSrc">
                    {/* <ThumbnailByType className="thumbnail" mimeType={type} fileType={fileType} fileUrl={`${customWindow.config.baseUrl}/api${thumbnailUrl}`} isUrlInvalid /> */}
                    <ThumbnailByType className="thumbnail" mimeType="" fileType="" fileUrl="" isUrlInvalid />
                    </div>
                    <div className="documentInfo">
                        <div className="name"> <a href={contentName}>{contentName}</a>   </div>
                        <div className="secondInfo">{senderName}</div>
                        <div className="thirdInfo">
                            <div className="dateInfo">
                                <Moment calendar={calendarStrings}>{timestamp}</Moment>
                            </div>
                            <div className="timeI">
                                <Moment calendar={calendarStringsTime}>{timestamp}</Moment>
                            </div>
                        </div>
                    </div>
                </div>
            );

        default:
            return <div />;
    }
};
