import React from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './PhoneNumber.scss';

import IPhoneNumber from '../../interfaces/IPhoneNumber';

class PhoneNumber extends React.Component<IPhoneNumber, any> {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            value: '',
        };
    }

    handlePhoneNumber = value => {
        this.setState({ value });
        const isValid = isValidPhoneNumber(value);
        const { validNumber } = this.props;
        if (!isValid) {
            if (value && value.length) {
                validNumber(isValid, value);
                this.setState({ errorMessage: 'Invalid phone number' });
            } else {
                this.setState({ errorMessage: 'Phone number is required' });
            }
        } else {
            validNumber(isValid, value);
            this.setState({ errorMessage: '' });
        }
    };

    render() {
        const { value, errorMessage } = this.state;
        return (
            <PhoneInput
                placeholder="+971 5599873423"
                value={value}
                className="phoneNumber"
                onChange={pvalue => this.handlePhoneNumber(pvalue)}
                error={errorMessage}
            />
        );
    }
}

export default PhoneNumber;
