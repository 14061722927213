import { IUploadType } from '../interfaces/IUploadApiAction';

export const UPLOAD_ADMIN_USERS: IUploadType = 'adminusers';
export const UPLOAD_THIRD_PARTY: IUploadType = 'thirdparty';
export const UPLOAD_ADMIN_GROUPS: IUploadType = 'admingroups';
export const UPLOAD_INTERNAL_USERS: IUploadType = 'internalusers';
export const UPLOAD_THIRD_PARTY_ORGS: IUploadType = 'thirdpartyorg';
export const UPLOAD_DOCUMENT_CATEGORY_TYPE: IUploadType = 'documentcategorytype';
export const UPLOAD_DOCUMENT_CATEGORY_VALUE: IUploadType = 'documentcategoryvalue';

export const uploadUrls = {
    [UPLOAD_ADMIN_GROUPS]: '/api/admin/groups',
    [UPLOAD_ADMIN_USERS]: '/api/admin/users',
    [UPLOAD_DOCUMENT_CATEGORY_TYPE]: '/api/documentCategory/types',
    [UPLOAD_DOCUMENT_CATEGORY_VALUE]: '/api/documentCategory/values',
    [UPLOAD_INTERNAL_USERS]: '/api/internalUser',
    [UPLOAD_THIRD_PARTY]: '/api/thirdparty/users',
    [UPLOAD_THIRD_PARTY_ORGS]: '/api/thirdparty/organizations',
};

export const getUploadUrl = (type: IUploadType) => {
    return uploadUrls[type];
};
