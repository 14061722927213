import React from 'react';
import { getString } from '../../utils/localization';

interface IErrorBoundaryState {
    hasError: boolean;
}
class ErrorBoundary extends React.Component<{}, IErrorBoundaryState> {
    constructor(props: {}) {
        super(props);
        this.state = { hasError: false };
    }

    public static getDerivedStateFromError(): object {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public render(): React.ReactNode {
        const { hasError } = this.state;
        const { children } = this.props;
        if (hasError) {
            // You can render any custom fallback UI
            return <h4>{getString("Oops... looks like something missing. Please refresh.")}</h4>;
        }

        return children;
    }
}

export { ErrorBoundary };
