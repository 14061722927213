import { enumInputTypes } from '@saleskey/frontend-shared/src/interfaces/IEForm';

// import { CEFormSchema } from "../constants/EForm";
// import { formJson } from '../fixtures/formData';
/**
 * validate the eform schem
 */
const typesAllowed = Object.values(enumInputTypes);
const optionsParent = ['radiogroup', 'select', 'multiselect', 'checkboxgroup'];
const sizesAllowed = ['large', 'small', 'default'];
const CEFormSchema = {
    description: {
        required: true,
        type: 'string',
    },
    header: {
        required: true,
        type: 'string',
    },
    metadata: {
        minLength: 1,
        required: true,
        type: 'array',
    },
    name: {
        required: true,
        type: 'string',
    },
    tags: {
        minLength: 1,
        required: true,
        type: 'array',
    },
};
const CItemSchema = {
    label: {
        required: false,
        type: 'string',
    },
    name: {
        required: true,
        type: 'string',
        value: 'string',
    },
    required: {
        required: false,
        type: 'boolean',
    },
    requiredErrorMsg: {
        required: false,
        type: 'string',
    },
    size: {
        required: false,
        type: 'enumSize',
        value: sizesAllowed,
    },
};
const CInputSchema = {
    ...CItemSchema,
    placeholder: {
        required: false,
        type: 'string',
    },
    type: {
        required: true,
        type: 'enumInputTypes',
        value: 'input',
    },
};
const CInputNumberSchema = {
    ...CItemSchema,
    defaultValue: {
        required: false,
        type: 'number',
    },
    max: {
        required: false,
        type: 'number',
    },
    min: {
        required: false,
        type: 'number',
    },
    placeholder: {
        required: false,
        type: 'string',
    },
    type: {
        required: true,
        type: 'enumInputTypes',
        value: 'inputnumber',
    },
};
const CTextAreaSchema = {
    ...CItemSchema,
    placeholder: {
        required: false,
        type: 'string',
    },
    rows: {
        required: false,
        type: 'number',
    },
    type: {
        required: true,
        type: 'enumInputTypes',
        value: 'textarea',
    },
};
const CRadioItemSchema = {
    disabled: {
        required: false,
        type: 'boolean',
    },
    text: {
        required: true,
        type: 'string',
    },
    value: {
        required: true,
        type: 'string',
    },
};
const CRadioSchema = {
    ...CItemSchema,
    initialValue: {
        required: false,
        type: 'string',
    },
    isVertical: {
        required: false,
        type: 'boolean',
    },
    options: {
        required: true,
        type: 'array',
        value: [CRadioItemSchema],
    },
    style: {
        type: 'string',
        value: ['vertical', 'horizontal'],
    },
    type: {
        required: true,
        type: 'enumInputTypes',
        value: 'radiogroup',
    },
};

const CCheckBoxItemSchema = {
    disabled: {
        required: false,
        type: 'boolean',
    },
    text: {
        required: true,
        type: 'string',
    },
    value: {
        required: true,
        type: 'string',
    },
};

const CCheckBoxSchema = {
    ...CItemSchema,
    initialValue: {
        required: false,
        type: 'array',
    },
    isVertical: {
        required: false,
        type: 'boolean',
    },
    options: {
        required: true,
        type: 'array',
        value: [CCheckBoxItemSchema],
    },
    style: {
        type: 'string',
        value: ['vertical', 'horizontal'],
    },
    type: {
        required: true,
        type: 'enumInputTypes',
        value: 'checkboxgroup',
    },
};

const CSelectItemSchema = {
    disabled: {
        required: false,
        type: 'boolean',
    },
    text: {
        required: true,
        type: 'string',
    },
    value: {
        required: true,
        type: 'string',
    },
};

const CSelectSchema = {
    ...CItemSchema,
    initialValue: {
        required: false,
        type: 'array',
    },
    options: {
        required: true,
        type: 'array',
        value: [CSelectItemSchema],
    },
    type: {
        required: true,
        type: 'enumInputTypes',
        value: 'select',
    },
};

const CMultiSelectSchema = {
    ...CItemSchema,
    mode: {
        type: 'string',
        value: ['multiple'],
    },
    options: {
        required: true,
        type: 'array',
        value: [CSelectItemSchema],
    },
    type: {
        required: true,
        type: 'enumInputTypes',
        value: 'multiselect',
    },
};

const CUploadSchema = {
    ...CItemSchema,
    type: {
        required: true,
        type: 'enumInputTypes',
        value: 'upload',
    },
};

const CDateSchema = {
    ...CItemSchema,
    default: {
        required: false,
        type: 'string',
        value: 'today',
    },
    minDate: {
        required: false,
        type: 'string',
        value: ['today', 'tomorrow'],
    },
    type: {
        required: true,
        type: 'enumInputTypes',
        value: 'date',
    },
};

const CSectionHeaderchema = {
    ...CItemSchema,
    type: {
        required: false,
        type: 'enumInputTypes',
        value: 'sectionheader',
    },
};

const CTextSchema = {
    ...CItemSchema,
    type: {
        required: true,
        type: 'enumInputTypes',
        value: 'text',
    },
};

const CTableSchema = {
    ...CItemSchema,
    addMore: {
        required: false,
        type: 'boolean',
    },
    defaultColumns: {
        required: true,
        type: 'number',
    },
    type: {
        required: true,
        type: 'enumInputTypes',
        value: 'table',
    },
};

const CPhoneNumber = {
    ...CItemSchema,
    type: {
        required: true,
        type: 'enumInputTypes',
        value: 'phonenumber',
    },
};

const CMaskedInput = {
    ...CItemSchema,
    type: {
        required: true,
        type: 'enumInputTypes',
        value: 'maskedinput',
    },
    pattern: {
        required: true,
        type: 'string',
    },
};

export {
    CEFormSchema,
    CDateSchema,
    CInputSchema,
    CInputNumberSchema,
    CRadioSchema,
    CRadioItemSchema,
    CSelectSchema,
    CSelectItemSchema,
    CTextAreaSchema,
    CMultiSelectSchema,
    CCheckBoxSchema,
    CCheckBoxItemSchema,
    CUploadSchema,
    sizesAllowed,
    typesAllowed,
    optionsParent,
    CSectionHeaderchema,
    CTextSchema,
    CTableSchema,
    CPhoneNumber,
    CMaskedInput
};
