/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/anchor-is-valid */

import 'antd/dist/antd.css';
import './geolocation.css';
import { Table } from 'antd';
import React from 'react';
import { axiosInstance } from '../../utils/axios';
import { IWindow } from '../../interfaces/IWindow';
import moment from '@saleskey/frontend-shared/node_modules/moment';

declare let window: IWindow;
const columns = [
    {
        dataIndex: 'userName',
        key: 'userName',
        sorter: true,
        title: 'Username'
    },
    {
        dataIndex: 'userType',
        key: 'userType',
        title: 'User Type'
    },
    {
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: date => `${moment(new Date(date)).format("LLLL")}`,
        title: 'CREATED AT',
    },
    {
        dataIndex: 'address',
        key: 'address',
        title: 'Address',
    },
    // {
    //     dataIndex: 'id',
    //     key: 'id',
    //     render: text => <a>{text}</a>,
    //     title: 'Id',
    // },
    {
        dataIndex: 'lat',
        key: 'lat',
        title: 'Latitude',
    },
    {
        dataIndex: 'long',
        key: 'long',
        title: 'Longitude',
    },
    // {
    //     dataIndex: 'accuracy',
    //     key: 'accuracy',
    //     title: 'Accuracy',
    // },
    {
        dataIndex: 'linkCode',
        key: 'linkCode',
        title: 'Link Code',
    },
    {
        dataIndex: 'eventType',
        key: 'eventType',
        title: 'Event Type',
    }, 
    // {
    //     dataIndex: 'userId',
    //     key: 'userId',
    //     title: 'User Id',
    // },
    {
        dataIndex: 'altitude',
        key: 'altitude',
        title: 'Altitude',
    }
];


  
  export class Geolocation extends React.Component {
    state = {
      data: [],
      loading: false,
      pagination: {},
    };
  
    componentDidMount() {
      this.fetch();
    }
  
    handleTableChange = (pagination, filters, sorter) => {
      const pager = { ...this.state.pagination } as any;
      pager.current = pagination.current;
      this.setState({
        pagination: pager,
      });
      this.fetch({
        page: pagination.current,
        results: pagination.pageSize,
        sortField: sorter.field || 'createdAt',
        sortOrder: sorter.order === 'ascend'?'ASC': 'DESC',
        ...filters,
      });
    };
  
    fetch = (params = {page:1, sortField: 'createdAt', sortOrder: 'DESC'}) => {
      this.setState({ loading: true });
      axiosInstance.get(`${window.config.baseUrl}/api/auditTrail/fetch/location?perPage=${10}&page=${params.page}&sortField=${params.sortField}&sortOrder=${params.sortOrder}`).then(data => {
        const pagination = { ...this.state.pagination } as any;
        pagination.total = data.data.data.total;

        this.setState({
          data: data.data.data.results,
          loading: false,
          pagination,
        });
      });
    };
  
    render() {
      return (
        <Table
          columns={columns}
          rowKey={(record: any) => record.id}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          loading={this.state.loading}
          onChange={this.handleTableChange}
        />
      );
    }
  }