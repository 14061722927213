import * as React from 'react';
import { EFormRootComponent } from '../../components/EFormRoot/EFormRoot';
import { IEFormProps, IEFormState } from '../../interfaces/IEForm';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';

export default class EForm extends React.Component<IEFormProps, IEFormState> {
    constructor(props: IEFormProps) {
        super(props);

        this.state = {};
    }

    public render() {
        const {
            formJson,
            formValues,
            formValuesHandler,
            formMode,
            onClose,
            timeInterval,
            intervalHandler,
            onDocumentAttached,
            onOpen,
            blurHandler,
            userPreviewOnly,
            noSubmissionEformType,
            submittingEform,
            savingEform,
            agentLogin,
            isFormReviewable,
            approveRejectDocument,
            getUploadedFileUrl,
            sendToReview,
            user,
            targetId,
            targetType,
            targetName,
            generator,
            code,
            submissionId,
            eformVersionId,
            dmsToken,
            saveCommentsHandler,
            resolveCommentsHandler,
            showReviewComments,
            fetchReviewCommentsHandler,
            reviewComments,
            addEformFieldReviewCommentLiveHandler,
            resolveEformFieldReviewCommentLiveHandler,
            commentThreads,
            showNotesModal,
            notesModalCloseHandler,
            canvasEnabled,
            clearCanvas,
            fetchTextFromImage,
            textDetectedData,
            loginScreen,
            preLogin,
            preLoginLoading,
            eFormRegister,
            errorRegisteringEform,
            registeringEform,
            eformRegisterAction,
            loginDetailsAction,
            resendOtpActionProp,
            loginLoading,
            loginValue,
            numberVerified,
            errorNumberVerify,
            verifyingNumber,
            verifyContactNumberAction,
            fetchDropdownOptionsHandler,
            fetchDataHandler,
            fetchAutoCalculateHandler,
            resetDependentDropdownOptionsHandler,
            extractDataFromFileDispatcher,
            extractDataFromFileMesage,
            optionsLoadingMessage
        } = this.props;
        return (
            <ErrorBoundary>
                <EFormRootComponent
                    formJson={formJson}
                    formValues={formValues}
                    formValuesHandler={formValuesHandler}
                    formMode={formMode}
                    onClose={onClose}
                    timeInterval={timeInterval}
                    intervalHandler={intervalHandler}
                    onDocumentAttached={onDocumentAttached}
                    onOpen={onOpen}
                    blurHandler={blurHandler}
                    userPreviewOnly={userPreviewOnly}
                    submittingEform={submittingEform}
                    savingEform={savingEform}
                    noSubmissionEformType={noSubmissionEformType}
                    agentLogin={agentLogin}
                    isFormReviewable={isFormReviewable}
                    sendToReview={sendToReview}
                    approveRejectDocument={approveRejectDocument}
                    user={user}
                    targetId={targetId}
                    targetType={targetType}
                    targetName={targetName}
                    generator={generator}
                    submissionId={submissionId}
                    eformVersionId={eformVersionId}
                    code={code}
                    getUploadedFileUrl={getUploadedFileUrl}
                    dmsToken={dmsToken}
                    saveCommentsHandler={saveCommentsHandler}
                    resolveCommentsHandler={resolveCommentsHandler}
                    showReviewComments={showReviewComments}
                    fetchReviewCommentsHandler={fetchReviewCommentsHandler}
                    reviewComments={reviewComments}
                    commentThreads={commentThreads}
                    resolveEformFieldReviewCommentLiveHandler={resolveEformFieldReviewCommentLiveHandler}
                    addEformFieldReviewCommentLiveHandler={addEformFieldReviewCommentLiveHandler}
                    canvasEnabled={canvasEnabled}
                    showNotesModal={showNotesModal}
                    notesModalCloseHandler={notesModalCloseHandler}
                    clearCanvas={clearCanvas}
                    fetchTextFromImage={fetchTextFromImage}
                    textDetectedData={textDetectedData}
                    loginScreen={loginScreen}
                    preLogin={preLogin}
                    preLoginLoading={preLoginLoading}
                    eFormRegister={eFormRegister}
                    errorRegisteringEform={errorRegisteringEform}
                    registeringEform={registeringEform}
                    eformRegisterAction={eformRegisterAction}
                    loginDetailsAction={loginDetailsAction}
                    resendOtpActionProp={resendOtpActionProp}
                    loginLoading={loginLoading}
                    loginValue={loginValue}
                    numberVerified={numberVerified}
                    errorNumberVerify={errorNumberVerify}
                    verifyingNumber={verifyingNumber}
                    verifyContactNumberAction={verifyContactNumberAction}
                    authErrorMessage={this.props.authErrorMessage}
                    fetchDropdownOptionsHandler={fetchDropdownOptionsHandler}
                    fetchDataHandler={fetchDataHandler}
                    fetchAutoCalculateHandler={fetchAutoCalculateHandler}
                    resetDependentDropdownOptionsHandler={resetDependentDropdownOptionsHandler}
                    extractDataFromFileDispatcher={extractDataFromFileDispatcher}
                    extractDataFromFileMesage={extractDataFromFileMesage}
                    optionsLoadingMessage={optionsLoadingMessage}
                />
            </ErrorBoundary>
        );
    }
}
