import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { SCListEForms, SCPagination, SCLoader } from '@saleskey/frontend-shared';
import { message } from 'antd';
import { IListEFormsInternalProp, IListFormsInternalState, IPagination } from '../../interfaces/IEForm';
import { listEFormsAction, archiveEFormAction } from '../../store/rootActions';
import { constants } from '../../constants/ListDocuments';
import './ListEFormsInternal.scss';
/**
 * @remarks
 * Parent container for list documents component
 * @param props: type of `{IListEFormsInternalProp}`
 * @param state: type of `{IListFormsInternalState}`
 * @return List documents with pagination component
 */
class ListEFormsInternalBaseContainer extends React.Component<IListEFormsInternalProp, IListFormsInternalState> {
    /**
     * @remarks
     * !IMPORTANT
     * Its use to track the reloading of the page for the first time,
     * very important to track the initial loading
     */
    firstReloadState: boolean;

    /**
     *
     * @param props : type of `{IListEFormsInternalProp}`
     */
    constructor(props: IListEFormsInternalProp) {
        super(props);
        this.state = {
            currentPage: constants.DEFAULT_CURRENTPAGE_NUMBER,
            pageSize: constants.DEFAULT_PAGE_SIZE,
        };
        this.firstReloadState = true;
        this.fetchEForms = this.fetchEForms.bind(this);
        this.archiveFormItemHandler = this.archiveFormItemHandler.bind(this);
        this.viewSubmissionsHandler = this.viewSubmissionsHandler.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSizeChange = this.onSizeChange.bind(this);
    }

    /**
     * fetch the documents once the component is loaded with default states
     */
    componentDidMount() {
        const { currentPage, pageSize } = this.state;
        this.fetchEForms(currentPage, pageSize);
    }

    componentDidUpdate(prevProps, prevState) {
        const { currentPage: currentPagePrev, pageSize: pageSizePrev } = prevState;
        const { currentPage, pageSize } = this.state;
        const { reloadRequired } = this.props;
        const { reloadRequired: prevReloadRequired } = prevProps;

        /**
         * @remarks
         * condition to check that new eform has been uploaded or not
         * if yes then reloadRequired will be true and data will be fetched again
         * in the reducer of listEform, reloadRequired will become `false` to
         * stop the infinite reloading of the component.
         */
        const { DEFAULT_CURRENTPAGE_NUMBER, DEFAULT_PAGE_SIZE } = constants;
        if (reloadRequired !== prevReloadRequired && reloadRequired) {
            this.onPageChange(DEFAULT_CURRENTPAGE_NUMBER, DEFAULT_PAGE_SIZE);
        }

        if (
            currentPagePrev !== currentPage ||
            pageSizePrev !== pageSize ||
            (currentPage === DEFAULT_CURRENTPAGE_NUMBER && this.firstReloadState)
        ) {
            this.fetchEForms(currentPage, pageSize);
        }

        const {
            archivingEForm: archivingEFormPrev,
            errorArchiveEForm: errorArchiveEFormPrev,
            successArchiveEForm: successArchiveEFormPrev,
        } = prevProps;
        const { archivingEForm, errorArchiveEForm, successArchiveEForm } = this.props;
        if (archivingEForm !== archivingEFormPrev) {
            if (errorArchiveEForm !== errorArchiveEFormPrev && errorArchiveEForm) {
                message.error(errorArchiveEForm);
            } else if (successArchiveEForm !== successArchiveEFormPrev && successArchiveEForm) {
                message.success('E-Form archived successfully');
                this.fetchEForms(currentPage, pageSize);
            }
        }
    }

    /**
     *
     * @param nextPage : next list of eforms to be fetched
     * @param pageSize : number of eforms in a page
     */
    private onPageChange(nextPage, pageSize): void {
        const { currentPage } = this.state;
        const { reloadRequired } = this.props;
        const { DEFAULT_CURRENTPAGE_NUMBER } = constants;
        if (nextPage !== currentPage) {
            this.setState({ currentPage: nextPage, pageSize });
        } else if (reloadRequired) {
            this.firstReloadState = true;
            this.setState({ currentPage: DEFAULT_CURRENTPAGE_NUMBER, pageSize });
        }
    }

    /**
     *
     * @param currentPage : current page number eforms to be fetched
     * @param newPageSize : new number of documents to be shown in a page
     */
    private onSizeChange(currentPage, newPageSize): void {
        const { pageSize } = this.state;
        if (newPageSize !== pageSize) {
            this.setState({ currentPage, pageSize: newPageSize });
        }
    }

    /**
     * @remarks
     * method to get the list the eforms based on the current state properties
     * calls the dispatcher function `{listEFormDispatch}` to trigger the action
     */
    private fetchEForms(currentPage, pageSize): void {
        const { listEFormDispatch } = this.props;
        this.firstReloadState = false;
        const params: IPagination = {
            limit: pageSize,
            skip: currentPage > 1 ? (currentPage - 1) * pageSize : 0,
        };
        listEFormDispatch(params);
    }

    /**
     * @remarks
     * archive the eform item from the list of eforms
     */
    private archiveFormItemHandler(id: string | number) {
        const { archiveEFormDispatch } = this.props;
        archiveEFormDispatch(id);
    }

    /**
     * @remarks
     * navigate to view submissions page
     */
    private viewSubmissionsHandler(eformId: string | number) {
        const { viewSubmissions } = this.props;
        viewSubmissions(eformId);
    }

    /**
     * @returns React Nodes
     */
    public render(): ReactNode {
        const { listEForms, listEFormsPagination, loadingEFormsList, errorEFormsList } = this.props;
        const { currentPage, pageSize } = this.state;

        const { total } = listEFormsPagination || { total: 0 };
        return (
            <div className="listEFormsInternal">
                <h1 className="listTitle">E-Forms</h1>
                {loadingEFormsList && (
                    <div className="listEFormLoader">
                        <SCLoader fontSize={24} />
                    </div>
                )}
                {!loadingEFormsList && errorEFormsList && (
                    <div className="listEFormLoader listEFormError">{errorEFormsList}</div>
                )}
                {!loadingEFormsList && listEForms && (
                    <React.Fragment>
                        <div className="listEFormDiv">
                            <SCListEForms
                                eforms={listEForms}
                                archiveFormItem={this.archiveFormItemHandler}
                                viewSubmissions={this.viewSubmissionsHandler}
                            />
                        </div>
                        {listEForms && listEForms.length > 0 && (
                            <div className="listPagination">
                                <SCPagination
                                    onPageChange={this.onPageChange}
                                    onSizeChange={this.onSizeChange}
                                    total={total}
                                    currentPage={currentPage}
                                    pageSize={pageSize}
                                />
                            </div>
                        )}
                    </React.Fragment>
                )}
                {!loadingEFormsList && !errorEFormsList && !listEForms && (
                    <React.Fragment>
                        <div className="listEFormLoader">
                            <h3>No E-form found.</h3>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}
export const stateToProps = state => {
    return {
        archivingEForm: state.eform.archivingEForm,
        errorArchiveEForm: state.eform.errorArchiveEForm,
        errorEFormsList: state.eform.errorEFormsList,
        listEForms: state.eform.listEForms,
        listEFormsPagination: state.eform.listEFormsPagination,
        loadingEFormsList: state.eform.loadingEFormsList,
        reloadRequired: state.eform.reloadRequired,
        successArchiveEForm: state.eform.successArchiveEForm,
    };
};
const dispatchers = dispatch => {
    return {
        archiveEFormDispatch: (id: string | number) => dispatch(archiveEFormAction(id)),
        listEFormDispatch: (params: IPagination) => dispatch(listEFormsAction(params)),
    };
};
const ListEFormsInternal = connect(stateToProps, dispatchers)(ListEFormsInternalBaseContainer);
export { ListEFormsInternal, ListEFormsInternalBaseContainer };
