export const rootPermissions = {
    admin: {
        subFeatures: {
            groups: {
                actions: {
                    edit: true,
                    view: true,
                },
            },
            users: {
                actions: {
                    edit: true,
                    view: true,
                },
            },
        },
    },
    document: {
        subFeatures: {
            list: {
                actions: {
                    view: true,
                },
            },
            upload: {
                actions: {
                    edit: true,
                    view: true,
                },
            },
        },
    },
    eform: {
        subFeatures: {
            config: {
                actions: {
                    delete: true,
                    edit: true,
                    view: true,
                },
            },
            submissions: {
                actions: {
                    view: true,
                },
            },
        },
    },
    internalUser: {
        actions: {
            edit: true,
            view: true,
        },
    },
    thirdparty: {
        subFeatures: {
            organizations: {
                actions: {
                    edit: true,
                    view: true,
                },
            },
            users: {
                actions: {
                    edit: true,
                    view: true,
                },
            },
        },
    },
};

export const internalUserPermissions = {
    document: {
        subFeatures: {
            list: {
                actions: {
                    view: true,
                },
            }
        }
    },
    eform: {
        subFeatures: {
            submissions: {
                actions: {
                    view: true,
                },
            },
        },
    },
};
