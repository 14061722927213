export const LOGIN = 'LOGIN';
export const AUTHENTICATE = 'AUTHENTICATE';
export const LOGOUT = 'LOGOUT';
export const LIST_DOCUMENT = 'LIST_DOCUMENT';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const UPLOAD_API = 'UPLOAD_API';
export const UPLOAD_API_RESET = 'UPLOAD_API_RESET';
export const UPLOAD_DOCUMENT_CATEGORY_TYPE_OR_VALUE_API = 'UPLOAD_DOCUMENT_CATEGORY_TYPE_OR_VALUE_API';
export const DOCUMENT_DRAWER = 'DOCUMENT_DRAWER';
export const ADD_NEWDOCUMENT = 'ADD_NEW_DOCUMENT';
export const GET_URLCONTENT = 'GET_URLCONTENT';
export const GET_DOCUMENT_CATEGORIES = 'GET_DOCUMENT_CATEGORIES';
export const SHOW_ADDNEW_DOCUMENT = 'SHOW_ADDNEW_DOCUMENT';
export const LIST_EFORMS = 'LIST_EFORMS';
export const SUBMITTED_EFORMS = 'SUBMITTED_EFORMS';
export const ARCHIVE_EFORM = 'ARCHIVE_EFORM';
export const FETCH_EFORM = 'FETCH_EFORM';
export const CREATE_OR_UDPATE_EFORM = 'CREATE_OR_UDPATE_EFORM';
export const SHOW_ADDNEW_EFORM_BUTTON = 'SHOW_ADDNEW_EFORM_BUTTON';
export const EFORM_DRAWER = 'EFORM_DRAWER';
export const RESET_EFORM_STORE = 'RESET_EFORM_STORE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
