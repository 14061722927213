import React, { Component} from 'react';
import { Icon, Spin } from 'antd';
// import './App.css';
import WebViewer from '../WebViewer/WebViewer';
import IDocumentViewer from '../../interfaces/IDocumentViewer';
import './PDFViewerContainer.scss';
import getTimeDifferenceInMilliseconds from '../../utils/timeDifferenceMilliseconds';
import { HUNDRED, SIXTEEN } from '../../constants/numberConstants';
import { pdfjs , Document, Page } from 'react-pdf';
import { getString } from '../../utils/localization';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
class PDFViewer extends Component<IDocumentViewer, any> {
    webviewer: any;

    public contentLoaded: boolean;

    public isResizeRequired = true

    // maintains the flag, whether the document close api was called or not
    closeApiInvoked: boolean;

    constructor(props) {
        super(props);
        this.webviewer = React.createRef<any>();
        this.state = {
            currentPage: 1,
            currentTimeStamp: new Date(),
            initialTimeStamp: new Date(),
            scale: 1.2,
            showSourceError: false,
            totalPages: 1,
            width: 780
        };
        this.closeApiInvoked = false;
        this.contentLoaded = false;
        this.documentLoadHandler = this.documentLoadHandler.bind(this)
        this.zoomInHandler = this.zoomInHandler.bind(this)
        this.zoomOutHandler = this.zoomOutHandler.bind(this)
        this.onSourceErrorHandler = this.onSourceErrorHandler.bind(this)
        this.resize= this.resize.bind(this)
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize);
        this.resize()
    }

    resize() {
        if(this.isResizeRequired){
            if(window.innerWidth <= 760){
                this.setState({ scale: 0.8, width : 420 })
            }else{
                this.setState({scale: 1.2, width : 780 })
            }
        }
    }

    zoomInHandler(){
        this.isResizeRequired = false
        const {scale} = this.state
        const temp = scale - 0.2
        this.setState({scale: temp })
    }

    zoomOutHandler(){
        this.isResizeRequired = false
        const {scale} = this.state
        const temp = scale + 0.2
        this.setState({scale: temp })
    } 

    onSourceErrorHandler(){
        this.setState({showSourceError: true})
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize)
        const { document, documentCloseEvent } = this.props;
        if (document && !this.closeApiInvoked) {
            const { unifiedDocId, name } = document;
            const { initialTimeStamp} = this.state;
            const timeStamp = new Date();
            const timeSpentMs = getTimeDifferenceInMilliseconds(new Date(), initialTimeStamp);

            if (documentCloseEvent) {
                documentCloseEvent(unifiedDocId, name, timeStamp, timeSpentMs);
            }
        }
    }

    componentDidUpdate = prevProps => {
        const { invokeDocumentCloseApi, document, documentCloseEvent } = this.props;
        const { invokeDocumentCloseApi: prevInvokeDocumentCloseApi } = prevProps;
        if (invokeDocumentCloseApi && invokeDocumentCloseApi !== prevInvokeDocumentCloseApi && !this.closeApiInvoked) {
            if (document) {
                const { unifiedDocId, name } = document;
                const { initialTimeStamp } = this.state;
                const timeStamp = new Date();
                const timeSpentMs = getTimeDifferenceInMilliseconds(new Date(), initialTimeStamp);

                /**
                 * As per the requirement, if the document was open for more than 100 ms then audit api will be called
                 */
                if (timeSpentMs > HUNDRED && documentCloseEvent) {
                    this.closeApiInvoked = true;
                    documentCloseEvent(unifiedDocId, name, timeStamp, timeSpentMs);
                }
            }
        }
    };

    documentLoadHandler = (pages) => {
        const { document, documentOpenEvent } = this.props;
        const { unifiedDocId, name } = document;
        const timestamp = new Date();
        if (documentOpenEvent) 
            documentOpenEvent(unifiedDocId, name, timestamp);
        this.setState(currentPage => ({currentPage: currentPage+1, showSourceError: false, totalPages: pages }))
    }

    render() {
        const { document } = this.props;
        const {totalPages, scale, width, showSourceError} = this.state
        return (
            <div className={!this.contentLoaded ? 'PDFViewer pdfLoading' : 'PDFViewer'}>
                {
                    document && document.contentUrl && !showSourceError && (
                    <>
                    <Document
                      loading={(
                        <div className="pdf-loader">
                            <div className="loading-card" style={{marginTop:'15%'}}>
                                <Spin indicator={<Icon type="loading" style={{ fontSize: SIXTEEN }} spin />} />
                                <span>{getString("Please wait, loading...")}</span>
                            </div>
                        </div>
                      )} 
                      file={document.contentUrl} 
                      onSourceError={this.onSourceErrorHandler}
                      onLoadSuccess={({ numPages })=>this.documentLoadHandler(numPages)}
                    > 
                        {
                            Array.apply(null, Array(totalPages))
                            .map((x, i)=>i+1)
                            .map(page => <Page key={page} pageNumber={page} width={width} scale={scale} />)
                        }
                        
                    <div className="zoomContainerBar">
                        <div className="zoomContainer">
                            <div className="zoomIn" onClick={this.zoomInHandler}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m0 0h24v24h-24z" fill="none" /><path d="m7 11v2h10v-2zm5-9c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg></div>
                            <div className="zoomOut" onClick={this.zoomOutHandler}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m0 0h24v24h-24z" fill="none" /><path d="m13 7h-2v4h-4v2h4v4h2v-4h4v-2h-4zm-1-5c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" /></svg></div>
                            <div className="zoomPercent">{(scale*100).toFixed(0)} %</div>
                        </div>
                    </div>
                    </Document>
                    </>
                  )}
                {showSourceError && (
                        <div className="pdf-loader pdf-loader-error">
                            <div className="loading-card">
                                <span>{getString("Error occured in loading the pdf.")}</span>
                            </div>
                        </div>
                      )}
            </div>
        );
    }
}

export default PDFViewer;
