import React from 'react';
import './SubmittedEFormsWrapper.scss';
import { Layout, Row, Col } from 'antd';
import { SubmittedEFormItem } from '../SubmittedEFormItem/SubmittedEFormItem';
import { ISubmittedEFormsWrapperProp } from '../../interfaces/IEForm';
import { TWENTY_FOUR, FOUR, TWELVE, ZERO, EIGHT, SIX } from '../../constants/numberConstants';
import { getString } from '../../utils/localization';

const { Content } = Layout;
/**
 * @remarks
 * Act as wrapper for the submited eforms
 * @param props : type of `{ ISubmittedEFormsWrapperProp }`
 * @return List submissions for an eform
 */
export const SubmittedEFormsWrapper = (props: ISubmittedEFormsWrapperProp) => {
    const { submittedEforms, viewSubmission } = props;
    const minDocLength = ZERO;

    // specific for this page, need to define variables because of codacy.
    const xsScreen = TWENTY_FOUR;
    const smScreen = TWELVE;
    const mdScreen = EIGHT;
    const lgScreen = SIX;
    const xlScreen = FOUR;
    /**
     * @return React Node for Submitted E-Form
     */
    return (
        <Layout>
            <Content className="sumittedEFormsWrapper">
                {submittedEforms.length > minDocLength && (
                    <Row type="flex" justify="start">
                        {submittedEforms.map(submission => (
                            <Col
                              xs={xsScreen}
                              sm={smScreen}
                              md={mdScreen}
                              lg={lgScreen}
                              xl={xlScreen}
                              key={submission.id}
                            >
                                <SubmittedEFormItem {...submission} viewSubmission={viewSubmission} />
                            </Col>
                        ))}
                    </Row>
                )}
                {!submittedEforms.length && (
                    <Row type="flex" justify="center" align="middle" className="noDataRow">
                        <Col
                          xs={xsScreen}
                          sm={smScreen}
                          md={mdScreen}
                          lg={lgScreen}
                          xl={xlScreen}
                          className="noDataCol"
                        >
                            <span> {getString("No Submissions yet")} </span>
                        </Col>
                    </Row>
                )}
            </Content>
        </Layout>
    );
};
