import React from 'react';
import { Breadcrumb } from 'antd';
import { IBreadcrumb } from '../../interfaces/IBreadcrumb';

/**
 * Breadcrumb Component
 * @remarks
 * spreads the breadcrumb array into HTML Dom
 *
 * @param props - type of `{IBreadcrumb}`
 */
const BreadCrumb = (props: IBreadcrumb) => {
    const { crumbsArray } = props;
    /**
     * Returns the Breadcrumb
     *
     * @returns HTML Dom
     *
     */
    return (
        <React.Fragment>
            <Breadcrumb style={{ margin: '16px 0' }}>
                {crumbsArray.length && crumbsArray.map(path => <Breadcrumb.Item key={path}>{path === 'admin' ? 'Dashboard' : path}</Breadcrumb.Item>)}
            </Breadcrumb>
        </React.Fragment>
    );
};
export default BreadCrumb;
