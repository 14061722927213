// eslint-disable-next-line import/no-extraneous-dependencies
import { drawDOM, exportPDF } from '@progress/kendo-drawing';

const generatePDFDataUri = (html, options?, shouldReturnUri?) => {
    return drawDOM(html, options)
        .then(group => {
            return exportPDF(group);
        })
        .then(dataUri => {
            if (shouldReturnUri) return dataUri;
            const splittedData = dataUri.split(',');
            return splittedData[1];
        });
};

const prepareHtmlToPdf = (node: HTMLElement): void => {
    const radioWrappers = node.querySelectorAll('.ant-radio-inner') as any;
    if (radioWrappers && radioWrappers.length) {
        radioWrappers.forEach(ele => {
            ele.classList.add('addGrayToMe');
        });
    }

    const pageTitle = node.querySelector('.pageTitleInMobileView');
    if (pageTitle && pageTitle.parentElement) {
        pageTitle.parentElement.removeChild(pageTitle);
    }
    const canvas = node.querySelector('.canvas-container');
    if (canvas && canvas.parentElement) {
        canvas.remove();
    }
    const doNotRenderItems = node.querySelectorAll('.doNotRender') as NodeListOf<Element>;
    if (doNotRenderItems && doNotRenderItems.length) {
        doNotRenderItems.forEach(ele => {
            ele.classList.remove('doNotRender');
        });
    }
    const badges = node.querySelectorAll('.ant-badge') as NodeListOf<Element>;
    if (badges && badges.length) {
        badges.forEach(badge => {
            if (badge && badge.parentElement) {
                badge.parentElement.removeChild(badge);
            }
        });
    }
    const steps = node.querySelector('.ant-steps');
    if (steps && steps.parentElement) {
        steps.parentElement.removeChild(steps);
    }
    const toRemove = node.querySelector('.actionButtons');
    if (toRemove && toRemove.parentElement) {
        toRemove.parentElement.removeChild(toRemove);
    }
    const deleteBtnIcons = node.querySelectorAll('.action-img') as NodeListOf<Element>;
    if (deleteBtnIcons && deleteBtnIcons.length) {
        deleteBtnIcons.forEach(deleteBtnIcon => {
            if (deleteBtnIcon && deleteBtnIcon.parentElement) {
                deleteBtnIcon.parentElement.removeChild(deleteBtnIcon);
            }
        })
    }

    const addNewRowBtns = node.querySelectorAll('.ant-btn') as NodeListOf<Element>;
    if (addNewRowBtns && addNewRowBtns.length) {
        addNewRowBtns.forEach(addNewRowBtn => {
            if (addNewRowBtn && addNewRowBtn.parentElement) {
                addNewRowBtn.parentElement.removeChild(addNewRowBtn);
            }
        })
    }
    const efRoot = node.querySelector('.eFormRoot');
    if (efRoot) {
        efRoot.classList.remove('fixed-steps');
    }
    const eFormContainer = node.querySelector('.EFormContainer');
    if (eFormContainer) {
        eFormContainer.classList.add('no-overflow');
    }
}

export { generatePDFDataUri, prepareHtmlToPdf }