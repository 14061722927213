import qs from 'qs';
import * as actions from '../../utils/actions';
import { axiosInstance } from '../../utils/axios';
import { loadingState, successState, failedState } from '../../utils/responseStates';
import { IListDocumentParam, IAddNewDocument } from '../../interfaces/IDocument';
// import { listDocuments, categories } from '../../fixtures/documentdata';
import { constants } from '../../constants/ListDocuments';

const defaultError = { message: 'Something went wrong. Please check the internet connection or use a valid link..' };
const { LIST_DOCUMENT, DOCUMENT_DRAWER, ADD_NEWDOCUMENT, GET_DOCUMENT_CATEGORIES } = actions;

/**
 * @remarks
 * handles all the document api actions
 * @param params : `{IListDocumentParam}`
 * @return asynchronus dispatchers to reducer
 */
export const listDocumentAction = (listParam: IListDocumentParam) => {
    return async dispatch => {
        dispatch({ payload: { ...loadingState }, type: LIST_DOCUMENT });
        try {
            const { pageSize: limit, lastId, total, moveForward } = listParam;
            const param = {
                lastId,
                limit,
            };

            if (moveForward || lastId === constants.DEFAULT_LAST_ID) {
                const result = await axiosInstance.get(`/api/document?${qs.stringify(param)}`);
                const {
                    data: { data, pagination },
                } = result.data;
                return dispatch({
                    payload: {
                        ...successState,
                        data,
                        documentUploaded: false,
                        moveForward,
                        pagination,
                    },
                    type: LIST_DOCUMENT,
                });
            }
            return dispatch({
                payload: {
                    ...successState,
                    data: [],
                    moveForward,
                    pagination: {
                        lastId,
                        total,
                    },
                },
                type: LIST_DOCUMENT,
            });
        } catch (err) {
            let error = defaultError;
            if (err.response) {
                const { data } = err.response;
                error = { message: data.message };
            } else if (err.request) {
                error = { message: 'Network Unreachable' };
            }
            return dispatch({ payload: { ...failedState, errors: error.message }, type: LIST_DOCUMENT });
        }
    };
};

/**
 * @remarks
 * handles the add new document api
 * @param params : `IAddNewDocument`
 * @return asynchronus dispatchers to reducer
 */
export const addNewDocument = (formData: IAddNewDocument) => {
    return async dispatch => {
        dispatch({ payload: { ...loadingState }, type: ADD_NEWDOCUMENT });
        try {
            let uploadReferenceIds: string[] = [];
            const formDataBody = Object.assign({}, formData);
            if (formDataBody.sourceValue instanceof Array) {
                uploadReferenceIds = formDataBody.sourceValue;
            }
            if (!formDataBody.remarks) {
                formDataBody.remarks = '';
            }
            await axiosInstance.post(`/api/document/upload`, {
                data: formDataBody,
                uploadReferenceIds,
            });
            return dispatch({
                payload: {
                    ...successState,
                    documentUploaded: true,
                },
                type: ADD_NEWDOCUMENT,
            });
        } catch (err) {
            let error = defaultError;
            if (err.response) {
                const { data } = err.response;
                let errors = data.errors ? data.errors[0] : undefined;
                if (errors) {
                    errors = errors.remarks;
                    error = { message: errors };
                } else {
                    error = { message: data.message };
                }
            } else if (err.request) {
                error = { message: 'Network Unreachable' };
            }
            return dispatch({ payload: { ...failedState, errors: error.message }, type: ADD_NEWDOCUMENT });
        }
    };
};

/**
 * @remarks
 * toggle the state of the side drawer for adding new document
 * @param isOpen : boolean
 */
export const addNewDocumentDrawer = (isOpen: boolean) => {
    return async dispatch => {
        dispatch({ payload: { isOpen }, type: DOCUMENT_DRAWER });
    };
};

export const getDocumentCategories = () => {
    return async dispatch => {
        dispatch({ payload: { ...loadingState }, type: GET_DOCUMENT_CATEGORIES });
        try {
            const result = await axiosInstance.get(`api/documentCategory/list`);
            const {
                data: { categoryValues },
            } = result.data;
            // const { data : {categoryTypes, categoryValues} } = result.data;
            // if(categoryValues && categoryValues.length){
            //     categoryValues.map( category => {
            //         const parentCategory = categoryTypes.find( type => {
            //             if(category. === type.id)
            //         })
            //     })
            // }

            return dispatch({
                payload: {
                    ...successState,
                    categories: categoryValues,
                },
                type: GET_DOCUMENT_CATEGORIES,
            });
        } catch (err) {
            let error = defaultError;
            if (err.response) {
                const { data } = err.response;
                error = { message: data.message };
            } else if (err.request) {
                error = { message: 'Network Unreachable' };
            }
            return dispatch({ payload: { ...failedState, errors: error.message }, type: GET_DOCUMENT_CATEGORIES });
        }
    };
};
