import { IAuthenticateAction } from '../../interfaces/IAuthenticateReducer';
import * as actions from '../../utils/actions';
import { IDocumentReducerProp } from '../../interfaces/IDocument';

/**
 * Initial state for the Document Reducer
 */
const initialState: IDocumentReducerProp = {
    addDocumentDrawerState: false,
    allDocuments: {},
    allLastIds: [],
    loading: false,
};

/**
 * @remarks
 * handles all the document api reducers and update the store
 * @param state : type of `{IDocumentReducerProp}`
 * @param action : type of `{IAuthenticateAction}`
 * @return state : redux store with updated state values
 */
const DocumentReducer = (state = initialState, action: IAuthenticateAction) => {
    switch (action.type) {
        case actions.LIST_DOCUMENT:
            if (action.payload.loading) {
                return { ...state, loading: action.payload.loading };
            }
            if (action.payload.status) {
                const { pagination } = action.payload;
                let { lastId } = pagination;
                const { allDocuments, documents } = state;
                const tempAllDocuments = Object.assign({}, allDocuments);

                const allLastIds: Array<string> = Object.keys(allDocuments);

                let tempDocuments = documents;

                if (action.payload.moveForward) {
                    if (!allLastIds.includes(lastId)) {
                        allLastIds.push(lastId);
                    }
                    tempAllDocuments[lastId] = action.payload.data;
                    tempDocuments = tempAllDocuments[lastId];
                } else {
                    const indexOfLastId = allLastIds.indexOf(lastId);
                    lastId = allLastIds.length > 1 ? allLastIds[indexOfLastId - 1] : allLastIds[0];
                    pagination.lastId = lastId;
                    tempDocuments = tempAllDocuments[lastId];
                }

                return {
                    ...state,
                    allDocuments: tempAllDocuments,
                    allLastIds,
                    documentUploaded: false,
                    documents: tempDocuments,
                    loading: action.payload.loading,
                    pagination,
                };
            }
            return {
                ...state,
                error: action.payload.errors,
                loading: action.payload.loading,
            };
        case actions.ADD_NEWDOCUMENT:
            if (action.payload.loading) {
                return {
                    ...state,
                    addDocumentError: undefined,
                    documentUploaded: false,
                    uploadingDocument: action.payload.loading,
                };
            }
            if (action.payload.status) {
                return {
                    ...state,
                    addDocumentError: undefined,
                    documentUploaded: action.payload.documentUploaded,
                    uploadingDocument: action.payload.loading,
                };
            }
            return {
                ...state,
                addDocumentError: action.payload.errors,
                documentUploaded: false,
                uploadingDocument: action.payload.loading,
            };
        case actions.GET_DOCUMENT_CATEGORIES:
            if (action.payload.loading) {
                return { ...state, fetchingCategories: action.payload.loading };
            }
            if (action.payload.status) {
                return {
                    ...state,
                    categories: action.payload.categories,
                    categoryError: undefined,
                    fetchingCategories: action.payload.loading,
                };
            }
            return {
                ...state,
                categoryError: action.payload.errors,
                fetchingCategories: action.payload.loading,
            };
        case actions.DOCUMENT_DRAWER:
            return {
                ...state,
                addDocumentDrawerState: action.payload.isOpen,
            };
        default:
            return state;
    }
};

export default DocumentReducer;
