import React, { ReactElement } from 'react';
import { Input, Tooltip, Icon } from 'antd';
import './Email.scss';
import EmailValidator from '../../utils/emailValidator';
import { IEmailProp, IEmailState } from '../../interfaces/IEmail';
import { getString } from '../../utils/localization';

/**
 * Email Component
 */
class EmailInput extends React.Component<IEmailProp, IEmailState> {
    private invalidMailError: string;

    /**
     * Constructor for the React Component : EmailInput
     * sets the private variables, states and bind the handler
     * functions for the component
     * @returns void
     *
     */
    constructor(props: IEmailProp) {
        super(props);
        this.invalidMailError = getString('Please enter valid email id.');
        this.onChange = this.onChange.bind(this);
        this.onBlurHandler = this.onBlurHandler.bind(this);
        this.onFocusHandler = this.onFocusHandler.bind(this);
        this.state = {
            errorMsg: null,
        };
    }

    /**
     * ComponentDidMount: Lifecyle Hook for the React component
     * checks for the valid email exists or not and error state accordingly
     * Generate the validEmail event to the parent
     * @returns void
     *
     */
    public componentDidMount(): void {
        const { emailHandler, value } = this.props;
        const isValid = EmailValidator(value);
        if (value) {
            if (!isValid) {
                this.setState({ errorMsg: this.invalidMailError });
            } else {
                this.setState({ errorMsg: null });
            }
            emailHandler(value.toString(), isValid);
        }
    }

    /**
     * Function to handle the onChange event for an input type
     * checks for the valid email exists or not and error state accordingly
     *
     * @param e - FormEvent
     * @returns void
     *
     */

    public onChange(e: React.FormEvent<HTMLInputElement>): void {
        const { value } = e.currentTarget;
        const { emailHandler } = this.props;
        const isValid = EmailValidator(value);
        this.setState({ errorMsg: null });
        emailHandler(value, isValid);
    }
    /**
     * Function to handle the onblur event for an input type
     * checks for the valid email exists or not and error state accordingly
     *
     * @param e - FormEvent
     * @returns void
     *
     */

    public onBlurHandler(e: React.FormEvent<HTMLInputElement>): void {
        const { value } = e.currentTarget;
        const isValidEmail = EmailValidator(value);
        if (!isValidEmail) {
            this.setState({ errorMsg: this.invalidMailError });
        } else {
            this.setState({ errorMsg: null });
        }
    }

    /**
     * Function to handle the onfocus event for an input type
     * checks for the valid email exists or not and error state accordingly
     *
     * @param e - FormEvent
     * @returns void
     *
     */
    public onFocusHandler(e: React.FormEvent<HTMLInputElement>): void {
        const { value } = e.currentTarget;
        const isValidEmail = EmailValidator(value);
        if (!isValidEmail) {
            this.setState({ errorMsg: this.invalidMailError });
        } else {
            this.setState({ errorMsg: null });
        }
    }

    /**
     * Returns the Email type Input
     *
     * @returns HTML Dom
     *
     */

    public render(): ReactElement {
        const { errorMsg } = this.state;
        const { placeholder, width, disabled, value, size, label, hidden } = this.props;
        return (
            <React.Fragment>
                {!hidden && (
                    <div className="inputComponent">
                        <span>{label}</span>
                        <Input
                          placeholder={placeholder}
                          className={`input ${errorMsg ? 'error' : ''}`}
                          style={{ width }}
                          onFocus={this.onFocusHandler}
                          onChange={this.onChange}
                          onBlur={this.onBlurHandler}
                          disabled={disabled}
                          value={value}
                          size={size}
                          suffix={(
<Tooltip title="Extra information">
                                    <Icon type="mail" style={{ color: 'rgba(0,0,0,.45)' }} />
</Tooltip>
)}
                        />
                        <span className="errorText">{errorMsg}</span>
                    </div>
                )}
            </React.Fragment>
        );
    }
}
export default EmailInput;
