import React from 'react';
import './ListEFormsWrapper.scss';
import { Layout, Row, Col } from 'antd';
import { ListEFormItem } from '../ListEFormItem/ListEFormItem';
import { IListEFormsWrapperProp } from '../../interfaces/IEForm';
import { getString } from '../../utils/localization';

const { Content } = Layout;
/**
 * @remarks
 * Act as wrapper for the document thumbnails, takes documents as input
 * @param props : type of `{ IListEFormsWrapperProp }`
 * @return List EForms with EForm Thumbnail card
 */
export const ListEFormsWrapper = (props: IListEFormsWrapperProp) => {
    const { eforms, archiveFormItem, viewSubmissions } = props;
    const minDocLength = 0;

    // specific for this page, need to define variables because of codacy.
    const xsScreen = 24;
    const smScreen = 12;
    const mdScreen = 8;
    const lgScreen = 6;
    const xlScreen = 4;
    const archiveFormItemHandler = (eformId: string | number) => {
        archiveFormItem(eformId);
    };

    /**
     * To view the submissions
     */
    const viewSubmissionsHandler = (eformId: string | number) => {
        viewSubmissions(eformId);
    };

    /**
     * @return React Node for Document Thumbnail Card
     */
    return (
        <Layout>
            <Content className="listEFormsWrapper">
{
    eforms.length && <Row>{eforms[0].header}</Row>
}
                <Row type="flex" justify="start">
                    {eforms.length > minDocLength &&
                        eforms.map(eform => (
                            <Col xs={xsScreen} sm={smScreen} md={mdScreen} lg={lgScreen} xl={xlScreen} key={eform.id}>
                                <ListEFormItem
                                  {...eform}
                                  archiveFormItem={archiveFormItemHandler}
                                  viewSubmissions={viewSubmissionsHandler}
                                />
                            </Col>
                        ))}
                    {!eforms.length && (
                        <Col xs={xsScreen}>
                            <div className="no-eform">
                                <h3> {getString("No E-Form found")} </h3>
                            </div>
                        </Col>
                    )}
                </Row>
            </Content>
        </Layout>
    );
};
