export interface IFormattedText {
    isSaleskeyLink: boolean;
    formattedText: {
        preText: string;
        link?: string;
        suffixText?: string;
        linkCode?: string;
    };
}

/**
 * TODO: This helper method only parse 1 url in the string
 * Multiple url functionality can be enhanced later depending on the requirement.
 */
const replaceWithButton = (text: string, position: number) => {
    const tempText = text.split('');
    let finalPosition = -1;
    for (let i = position; i < tempText.length; i += 1) {
        if (tempText[i] === ' ') {
            finalPosition = i;
            // break is necessary because as soon as we hit the first ' ' (spacee) that is our link ending point.
            break;
        }
    }
    if (finalPosition < 0) {
        finalPosition = tempText.length;
    }
    const parsedLink = text.substr(position, finalPosition - position);
    return {
        link: parsedLink,
        linkCode: parsedLink && parsedLink.split('/')[4],
        preText: text.substr(0, position),
        suffixText: text.substr(finalPosition, text.length),
    };
};

export const parseLink = (text: string): IFormattedText => {
    let isSaleskeyLink = false;
    if (!text) {
        return {
            formattedText: { preText: text },
            isSaleskeyLink,
        };
    }

    // TODO: pass the regex from the consumer
    const saleskeyRegExpression = /(https?):\/\/(www\.)?(sales-key.com)/;
    // let saleskeyRegExpression = /(https?):\/\/(www\.)?(localhost\:3000)/

    let isAnchorLinkExist = saleskeyRegExpression.exec(text);

    if (isAnchorLinkExist) {
        isSaleskeyLink = true;
    } else {
        const domainRegExpression = /(https?):\/\/(www\.)?[a-z0-9.:].*[a-z]*/;
        isAnchorLinkExist = domainRegExpression.exec(text);
    }

    if (isAnchorLinkExist) {
        return {
            formattedText: replaceWithButton(text, isAnchorLinkExist.index),
            isSaleskeyLink,
        };
    }
    return {
        formattedText: { preText: text },
        isSaleskeyLink,
    };
};
