import React from 'react';
import { Input as AntInput } from 'antd';

import './Input.scss';

import IInput from '../../interfaces/IInput';

const Input: React.FunctionComponent<IInput> & { defaultProps: Partial<IInput> } = (props: IInput) => {
    const { placeholder, width, disabled, onChange, onKeyPress, value, size, label, error, name } = props;
    return (
        <div className="inputComponent">
            <span>{label}</span>
            <AntInput
              placeholder={placeholder}
              className="input"
              name={name}
              style={{ width }}
              onChange={onChange}
              onKeyPress={onKeyPress}
              disabled={disabled}
              value={value}
              size={size}
            />
            <span className="errorText">{error}</span>
        </div>
    );
};
Input.defaultProps = {
    disabled: false,
    width: '100%',
};
export default Input;
