import { enumDocumentStatus } from './IDocument';
import { ILoggedInUser } from './ILoggedInUser';

/**
 * Enum types supported from saleskey renderer
 */
export enum enumInputTypes {
    INPUT = 'input',
    TEXTAREA = 'textarea',
    DATE = 'date',
    RADIO_GROUP = 'radiogroup',
    SELECT = 'select',
    MULTI_SELECT = 'multiselect',
    CHECKBOX_GROUP = 'checkboxgroup',
    INPUT_NUMBER = 'inputnumber',
    CHECKBOX = 'checkbox',
    UPLOAD = 'upload',
    NUMBER = 'number',
    SECTION_HEADER = 'sectionheader',
    IMAGE = 'image',
    TABLE = 'table',
    TEXT = 'text',
    RATING = 'rating',
    MASKEDINPUT = 'maskedinput',
    PHONENUMBER = 'phonenumber'
}

export enum enumEFormModes {
    CREATE = 'create', // EForm can be created from scratch - no action buttons on eform
    VIEW = 'view', // EForm can only be viewed with data - close button
    EDIT = 'edit', // EForm can be edited to add new fields - no action buttons
    PREVIEW = 'preview', // EForm can only be viewed without data - close button
    FILLED = 'filled', // EForm can be edited with data for customers - action buttons
}

/**
 * E-Form modes
 */

/**
 * Size enum which represents the size accepted from the antd design
 */
export enum enumSize {
    LARGE = 'large',
    SMALL = 'small',
    DEFAULT = 'default',
}

/**
 * form status
 */
export enum enumFormStatus {
    ACCEPTED = 'accepted',
    SENT_TO_REVIEW = 'sentToReview',
    SAVED = 'saved',
    SUBMITTED = 'submitted',
}

export interface IUploadedFileDescriptor {
    action: string;
    attributeName: string;
    downloadLink: string;
    fileType?: string;
    dmsToken: string;
    fileId: number;
    emitToAll?: boolean;
    fileName?: string
}

/**
 *ItemSchema which will be common for the other input types
 */
export interface IItemSchema {
    label?: string;
    type?: enumInputTypes;
    name: string;
    size?: enumSize;
    required: boolean;
    doNotRender?: boolean;
    appendCurrency?: boolean;
    currencyKey?: boolean;
    groupInTwo?: boolean;
    formGroup?: boolean;
    requiredErrorMsg?: string | undefined;
    lengthErrorMsg?: string | undefined;
    value?: string | boolean | Array<string | number> | number;
    disabled?: boolean | undefined;
    step?: number;
    anchorText?: string | number;
    modalHeader?: string;
    modalItems?: Array<string>;
}

/**
 * Input Schema
 */
export interface IInputSchema extends IItemSchema {
    type: enumInputTypes; // INPUT
    placeholder?: string | undefined;
    min?: number;
}

/**
 * InputNumber Schema
 */
export interface IInputNumberSchema extends IItemSchema {
    type: enumInputTypes; // INPUT_NUMBER
    min?: number;
    max?: number;
    defaultValue?: number;
    slug?: string;
}

/**
 * TextArea Schema
 */
export interface ITextAreaSchema extends IItemSchema {
    type: enumInputTypes; // TEXTAREA
    placeholder?: string | undefined;
    rows?: number;
}

/**
 * Date Schema
 */
interface IDateSchema extends IItemSchema {
    type: enumInputTypes;
    minDate: Date;
    maxDate: Date;
    default: Date;
}

/**
 * RadioGroup Schema
 */
interface IRadioItem {
    value: string | number;
    text: string | number;
    disabled?: boolean;
}
export interface IRadioGroupSchema extends IItemSchema {
    type: enumInputTypes; // RADIO_GROUP
    isVertical?: boolean;
    defaultValue?: string;
    options: Array<IRadioItem>;
}

/**
 * Select and MultiSelect schema
 */
export interface IOptionItem {
    value: string | number;
    text: string | number;
    disabled?: boolean;
}
export interface ISelectSchema extends IItemSchema {
    type: enumInputTypes; // SELECT
    initialValue: Array<IOptionItem['value']>;
    options: Array<IOptionItem>;
    fetchOptions?: boolean;
    fetchData?: boolean;
    fetchAutoCalculate?: boolean;
    dependsOn?: string[];
    defaultApiValues?: { [key: string]: string };
    apiKeyName?: string;
    responseKeys?: { [key: string]: string };
    formula?: string
}
interface IMultiSelectSchema extends IItemSchema {
    type: enumInputTypes;
    mode: 'multiple';
    initialValue: Array<IOptionItem['value']>;
    options: Array<IOptionItem>;
}

/**
 * Checkbox Schema
 */
export interface ICheckBoxItem {
    value: string | number;
    text: string | number;
    disabled?: boolean;
    isChecked?: boolean;
}
export interface ICheckBoxSchema extends IItemSchema {
    type: enumInputTypes; // CHECKBOX_GROUP
    initialValue: Array<ICheckBoxItem['value']>;
    options: Array<ICheckBoxItem>;
}

/**
 * Switch Schema
 */
interface ISwitchSchema extends IItemSchema {
    valuePropName: string;
}

/**
 * Upload schema
 */
export interface IUploadSchema extends IItemSchema {
    type: enumInputTypes;
    value: Array<string>;
    documentStatus?: enumDocumentStatus;
    reason?: string;
    isReviewable?: boolean;
    extractData?: boolean;
    documentType?: string;
    allowedFileTypes?: string[];
}

interface INumberSchema extends IItemSchema {
    type: enumInputTypes;
    value: number;
}

interface ISectionHeaderSchema extends IItemSchema {
    type: enumInputTypes;
    defaultValue: string;
}

interface IRatingSchema extends IItemSchema {
    type: enumInputTypes;
    count: number;
    value: number;
}

export interface IMaskedInputSchema extends IItemSchema {
    type: enumInputTypes;
    pattern: string;
}

export interface IPhoneNumberSchema extends IItemSchema {
    type: enumInputTypes.PHONENUMBER;
    value: string;
    onlyCountries?: string[];
    preferredCountries?: string[];
    excludeCountries?: string[];
}

export type IFormComponent =
    | IInputSchema
    | ITextAreaSchema
    | IDateSchema
    | IRadioGroupSchema
    | ISelectSchema
    | IMultiSelectSchema
    | ICheckBoxSchema
    | IInputNumberSchema
    | ISwitchSchema
    | IUploadSchema
    | INumberSchema
    | ISectionHeaderSchema
    | IRatingSchema
    | IMaskedInputSchema
    | IPhoneNumberSchema
;

export interface IEFormJson {
    id?: string | number;
    name: string;
    disabled?: boolean | undefined;
    tags: Array<string>;
    description: string;
    header: string;
    eformType?: string;
    metadata: Array<IFormComponent>;
    steps?: any[];
    formStatus?: enumFormStatus;
    onlyTargetUserCanSubmit?: boolean;
    allowEdit?: boolean;
}

export interface ILinkGenerator {
    email: string;
    id: number;
    name: string;
    userType: string;
}

export interface textDetector {
    responses: Array<{ textAnnotations: any }>;
}
export type IEformId = number | string;
export type IEformVersionId = string | number;
export interface IEFormRootProps {
    code: string;
    submissionId?: IEformId;
    formJson: IEFormJson;
    formValues?: object;
    eformVersionId: IEformVersionId;
    formValuesHandler?: (formJson: IEFormJson, formValues: object) => void;
    formMode: enumEFormModes;
    onClose: (formJson?: IEFormJson, timeSpentMs?: number, closeEform?: boolean) => void;
    timeInterval?: number;
    intervalHandler?: (formJson: IEFormJson, timeSpentMs: number) => void;
    blurHandler?: (formJson: IEFormJson, formValues: object) => void;
    onDocumentAttached?: (formJson: IEFormJson, name: string) => void;
    onOpen?: (formJson: IEFormJson) => void;
    approveRejectDocument?: (
        documentField: string,
        documentId: number,
        status: enumDocumentStatus,
        reason?: string,
    ) => void;
    sendToReview?: (sendSMStoCustomer: boolean) => void;
    userPreviewOnly?: boolean;
    agentLogin?: boolean;
    submittingEform?: boolean;
    savingEform?: boolean;
    noSubmissionEformType?: boolean;
    isFormReviewable?: boolean;
    saveCommentsHandler?: (fieldName: string, linkCode: string, eformVersionId: number, comment: string) => void;
    resolveCommentsHandler?: (fieldName: string, linkCode: string, eformVersionId: number) => void;
    showReviewComments?: boolean;
    fetchReviewCommentsHandler?: (fieldName: string, linkCode: string, eformVersionId: number) => void;
    reviewComments?: [];
    commentThreads?: { [key: string]: Array<{ id: number; resolved: boolean; createdAt: Date }> };
    addEformFieldReviewCommentLiveHandler?: (fieldName: string, comment: string, userName?: string) => void;
    resolveEformFieldReviewCommentLiveHandler?: (fieldName: string) => void;
    showNotesModal?: boolean;
    notesModalCloseHandler?: () => void;
    user?: ILoggedInUser;
    targetId?: string | number;
    targetType?: string;
    targetName?: string;
    generator?: ILinkGenerator;
    getUploadedFileUrl?: (fileDescriptor: IUploadedFileDescriptor) => void;
    dmsToken: string;
    canvasEnabled?: boolean;
    clearCanvas?: boolean;
    fetchTextFromImage?: (imageData: string) => void;
    textDetectedData?: textDetector;
    loginScreen?: any;
    preLogin?: any;
    preLoginLoading?: any;
    eFormRegister?: any;
    errorRegisteringEform?: any;
    registeringEform?: any;
    eformRegisterAction?: any;
    loginDetailsAction?: any;
    resendOtpActionProp?: any;
    loginLoading?: any;
    loginValue?: any;
    numberVerified?: any;
    errorNumberVerify?: any;
    verifyingNumber?: any;
    verifyContactNumberAction?: any;
    authErrorMessage?:any;
    updateDependents?:(dependents:string[]) => void;
    fetchDropdownOptionsHandler?:(name:string, label:string, apiKeyName:string, prerequisites:{[key:string]:string | boolean | number}) => void;
    fetchDataHandler?:(field: string, label: string, apiKeyName: string, prerequisites: { [key: string]: string }, responseKeys: { [key: string]: string }) => void;
    fetchAutoCalculateHandler?:(field: string, label: string, formula: string, inputs: { [key: string]: number }, keys: Array<string>) => void;
    resetDependentDropdownOptionsHandler?:(dependents:string[]) => void;
    extractDataFromFileDispatcher?:(file:File, fileName:string, label:string, documentType:string) => void;
    extractDataFromFileMesage?: string;
    optionsLoadingMessage?: string
}

/**
 * interface for the single list item in eform list
 */
export interface ISingleEForm {
    id: string | number;
    name?: string;
    header: string | undefined;
    description: string;
    isActive?: boolean;
    thumbnailUrl: string | undefined;
    submissions?: number;
    submissionId?: IEformId;
    percent?: number;
    tags?: Array<string> | [];
}

/**
 * EForm.tsx Props and States
 */

export interface IEFormProps extends IEFormRootProps {}

export interface IEFormState {}

/**
 * `EFormitem.tsx`
 * here id and eformId are referring to the same entity i.e. id
 * @remarks
 * eformId is used to refer when we are navigating from list eforms to submissions
 */
export interface IEFormItemProp extends ISingleEForm {
    isHorizontalView?: boolean;
    archiveFormItem: (id: string | number) => void;
    viewSubmissions: (eformId: string | number) => void;
    viewDetailSubmission?: (eformId: string | number, submissionId?: IEformId) => void;
}

/**
 * `ListEFormsWrapper.tsx` prop
 */
export interface IListEFormsWrapperProp {
    archiveFormItem: (id: string | number) => void;
    viewSubmissions: (eformId: string | number) => void;
    eforms: Array<ISingleEForm>;
}

/**
 * `SubmittedEFormsWrapper.tsx` prop
 */
/**
 * Props for EForm.reducer and EForm.action
 */
interface ISubmittedUser {
    name: string;
}
/**
 * interface for the single submitted form list item
 */

export interface IViewSubmission {
    viewSubmission: (id: string | number, name:string) => void;
}

export interface ISubmittedEForm {
    id: string;
    eformVersionId: string;
    thumbnailUrl?: string;
    submittedFields: Array<string> | [];
    isComplete: boolean;
    docsRequiredCount: number;
    docsUploadedCount: number;
    user: ISubmittedUser;
    header?:string
}
export interface ISubmittedEFormsWrapperProp extends IViewSubmission {
    submittedEforms: Array<ISubmittedEForm>;
}


export enum CurrentStepNumbers {
    One = 1,
    Two = 2,
    Three = 3,
    Four = 4
}

export const PaymentOptions  = {
    BankAccount: 'Bank Account',
    CashOnDelivery: 'Cash on Delivery',
    CreditCard: 'Credit Card',
    DebitCard: 'Debit Card'
} 

export const UserTypeValues = {
    GuestUser: 'Guest User',
    RegisteredUser: 'Registered User'
}

export const productEformConstants= {
    AddressDetails: 'Address Details',
    Cart: 'Cart',
    Checkout: 'Checkout',
    CheckoutAs: 'Checkout as',
    AddNewAddress: 'Add a New Address',
    CustomerDetails: 'Customer Details',
    DeliverTo: 'Deliver To',
    OtherPaymentOptions: 'Other Payment Options',
    Payment: 'Payment',
    PersonalDetails: 'Personal Details',
    ProductDescription: 'Product Description',
    paymentOptions: 'Payment Options',
    ProductQuantity: 'Product Quantity',
    SavedPaymentOptions: 'Saved Payment Options',
    SelectPaymentMethod: 'Select a payment method'
}

export type IFileUploaded = {
    downloadUrl: string;
    fileId: string;
    fileType: string;
    reason: string;
    sourceUrl: string;
    status: string;
    fileName?: string;

}

export type IFormValues = {
    [key:string]: string | number | boolean | string[] | IFileUploaded[]
}