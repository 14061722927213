const excelType = ['msi', 'xlsx', 'xls'];
const pdftType = ['pdf'];
const imageType = ['jpeg', 'jpg', 'png', 'gif', 'webp'];

export const getFileType = fileExtension => {
    if (excelType.includes(fileExtension)) {
        return 'excel';
    }
    if (pdftType.includes(fileExtension)) {
        return 'pdf';
    }
    if (imageType.includes(fileExtension)) {
        return 'image';
    }
    return 'default'; // static defaul icon will be shown
};
