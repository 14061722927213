import React from 'react';
import ITimer, { TimerTypes } from '../../interfaces/ITimer';

class Timer extends React.Component<ITimer, any> {
    timer: any;

    constructor(props) {
        super(props);
        this.state = {
            hour: 0,
            min:0,
            seconds:0,
            time: 1
        };
    }

    componentDidMount() {
        const { sec, type } = this.props;
        if(type === TimerTypes.FORWARD_TICKING){
            this.setState({
                hour: 0,
                min:0,
                seconds:1
            });
        }else{
            this.setState({
                time: sec,
            });
        }
        this.timer = setInterval(() => {
            const {type} = this.props;
            if(type === TimerTypes.FORWARD_TICKING){
                
                const {seconds, hour, min} = this.state
                let tempH = hour 
                let tempM = min
                let tempS = seconds+1
                if(tempS > 59){
                    tempM +=1
                    if(tempM > 59){
                        tempH +=1
                        tempM = 0
                    }
                    tempS = 0
                }
                this.setState(prevState => ({
                    hour: tempH,
                    min: tempM,
                    seconds: tempS
                }));
            }else{
                this.setState(prevState => ({
                    time: prevState.time - 1,
                }));
            }
        }, 1000);
    }

    componentWillUpdate() {
        const { time } = this.state;
        if (!time) {
            const { timerStopEvent } = this.props;
            timerStopEvent();
            this.stopTimer();
        }
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    stopTimer() {
        clearInterval(this.timer);
    }

    // const onlyTimeParser = () => {
    //     const {time} = this.state

    // }

    render() {
        const {type} = this.props;
        if(type === TimerTypes.FORWARD_TICKING){
            const { hour, min, seconds } = this.state;
            const tempH = hour/10 < 1 ? `0${hour}`:hour
            const tempM = min/10 < 1 ? `0${min}`:min
            const tempS = seconds/10 < 1 ? `0${seconds}`:seconds
            return <React.Fragment>{tempH} : {tempM} : {tempS}</React.Fragment>;
        }
            const { time } = this.state;
            let dtime = time;
            if (time / 10 < 1) {
                dtime = `0${time}`;
            } else {
                dtime = time;
            }
            return <React.Fragment>00:{dtime}</React.Fragment>;
        
    }
}
export default Timer;
