export default {
    'Add new document': 'Add new document',
    Archived: 'Archived',
    Attachments: 'Attachments',
    Cancel: 'Cancel',
    Close: 'Close',
    Documents: 'Documents',
    Download: 'Download',
    'Error occured in loading the pdf.': 'Error occured in loading the pdf.',
    Expired: 'Expired',
    'File Upload': 'File Upload',
    Filled: 'Filled',
    'Form Assistant Bot': 'Form Assistant Bot',
    Header: 'Header',
    Links: 'Links',
    Media: 'Media',
    'New document': 'New document',
    No: 'No',
    'No document found': 'No document found',
    "No, it's not required.": "No, it's not required.",
    'Oops... looks like something missing. Please refresh.': 'Oops... looks like something missing. Please refresh.',
    'Please enter valid email id.': 'Please enter valid email id.',
    'Please input valid form json': 'Please input valid form json',
    'Please use navigation menu on left to nagivate.': 'Please use navigation menu on left to nagivate.',
    'Please wait, loading...': 'Please wait, loading...',
    Reason: 'Reason',
    Save: 'Save',
    'Save as draft': 'Save as draft',
    'Send for review': 'Send for review',
    'Something went wrong. Please check the internet connection or use a valid link..':
        'Something went wrong. Please check the internet connection or use a valid link..',
    Status: 'Status',
    Submit: 'Submit',
    'The page you are looking for does not exist.': 'The page you are looking for does not exist.',
    Upload: 'Upload',
    'Upload admin users': 'Upload admin users',
    'Upload internal users': 'Upload internal users',
    'Upload third-party users': 'Upload third-party users',
    'Upload users': 'Upload users',
    'Url Source': 'Url Source',
    'User Name': 'User Name',
    View: 'View',
    'View submissions': 'View submissions',
    'Visibility of document': 'Visibility of document',
    'Yes, I would like that.': 'Yes, I would like that.',
    'Your are in': 'Your are in',
    found: 'found',
    group: 'group',
    'would you like LIVE assistance on this form? One of our Agents will be delighted to assist you?':
        'would you like LIVE assistance on this form? One of our Agents will be delighted to assist you?',
};
