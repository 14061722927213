import { IEFormRootProps, ISingleEFormSubmitted, INewEForm } from '../interfaces/IEForm';

export const transformToEForm = (rawJson: ISingleEFormSubmitted): IEFormRootProps => {
    const transformedObject = {
        formJson: {
            ...rawJson.eformVersion,
        },
        formValues: {
            ...rawJson.submittedData,
            ...rawJson.uploadedDocuments,
        },
        inputsAvailable: 0,
        inputsRequired: 0,
        isLatest: true,
    };

    return transformedObject as IEFormRootProps;
};

export const extendedEFormProperties = (eform: INewEForm) => {
    const deepCopyEform = Object.assign({}, eform);
    deepCopyEform.inputsAvailable = deepCopyEform.metadata.length;
    deepCopyEform.inputsRequired = 0;
    deepCopyEform.metadata.forEach(metadata => {
        if (metadata.required) {
            deepCopyEform.inputsRequired += 1;
        }
    });

    return deepCopyEform;
};
