import React from 'react';
import { Progress } from 'antd';
import { IProgressBar } from '../../interfaces/IProgressBar';
import './ProgressBar.scss';
/**
 * @remarks
 * Progress bar component which can be used any place.
 * @param props : type of `{IProgressBar}`
 * @return ProgressBar react component
 */
const ProgressBar = (props: IProgressBar) => {
    const { percent, type, format } = props;
    /**
     * @return HTML Dom
     */
    return <Progress type={type} percent={percent} format={format} />;
};

export { ProgressBar };
