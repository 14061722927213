import { UserType } from '@saleskey/frontend-shared/src/constants';
import * as actions from '../../utils/actions';
import { axiosInstance } from '../../utils/axios';
import { loadingState, successState, failedState } from '../../utils/responseStates';
import { arrayToObject as aclPermissionsHash } from '../../utils/aclHashMap';
// import { permissions, rawPermissions } from '../../fixtures/permission';
import { rootPermissions, internalUserPermissions } from '../../constants/AclPermission';
import { ZERO } from '../../constants/Common';

const defaultError = { message: 'Something went wrong. Please check the internet connection or use a valid link..' };
const { LOGIN, LOGOUT, CHANGE_PASSWORD } = actions;

const permissionKeyField = 'name';

export const loginAction = (email: string, password: string) => {
    return async dispatch => {
        dispatch({ payload: { ...loadingState }, type: LOGIN });
        try {
            const query = {
                password,
                type: 'email',
                value: email,
            };
            const result = await axiosInstance.post('/api/auth/local', query);
            const authenticated = true;
            const { data } = result.data;
            let {user} = data;
            if(!user){
                user = data;
            }
            let groupName: string = '';
            let { userType } = user;
            if(!userType){
                userType = UserType.admin;
            }
            if(userType === UserType.admin){
                const { group } = user;
                groupName = group;
            }

            const dmsTokenResult = await axiosInstance.post(`/api/document/token`);
            const { data: dmsToken } = dmsTokenResult.data;
            return dispatch({
                payload: {
                    ...successState,
                    data: {
                        authenticated,
                        dmsToken,
                        permissions:
                            userType === UserType.internal ? internalUserPermissions : (groupName === 'root'
                                ? rootPermissions
                                : aclPermissionsHash(data.permissions, permissionKeyField)),
                        user,
                        userType
                    },
                },
                type: LOGIN,
            });
        } catch (err) {
            console.log('err', err)
            let error = defaultError;
            if (err.response) {
                const { data } = err.response;
                error = { message: data.message };
            } else if (err.request) {
                error = { message: 'Network Unreachable' };
            }
            return dispatch({ payload: { ...failedState, errors: error.message }, type: LOGIN });
        }
    };
};

export const logoutAction = () => {
    return async dispatch => {
        dispatch({ payload: { ...loadingState }, type: LOGOUT });
        try {
            const result = await axiosInstance.post('/api/auth/logout');
            if (result.data.status === 'success') {
                const authenticated = false;
                return dispatch({
                    payload: {
                        ...successState,
                        data: { authenticated },
                    },
                    type: LOGOUT,
                });
            }
            const { message } = result.data.error;
            return dispatch({ payload: { ...failedState, errors: message }, type: LOGOUT });
        } catch (err) {
            let error = defaultError[ZERO];
            if (err.response) {
                const { data } = err.response;
                error = { message: data.message };
            } else if (err.request) {
                error = { message: 'Network Unreachable' };
            }
            return dispatch({ payload: { ...failedState, errors: error.message }, type: LOGOUT });
        }
    };
};


export const changePasswordAction = (newPassword: string) => {
    return async dispatch => {
        dispatch({payload: {...loadingState}, type: CHANGE_PASSWORD });
        try {
            const data ={
                newPassword: newPassword
            }
            console.log("changePasswordAction 1", newPassword);
            const result = await axiosInstance.post('/api/admin/changePassword', data);
            if(result.status){
             console.log(result);
             console.log("changePasswordAction 2", newPassword);
             const authenticated = false;
             return dispatch({
                 payload: {
                     ...successState,
                     data: { authenticated },
                 },
                 type: CHANGE_PASSWORD,
             });
           
            }
            console.log("changePasswordAction 3", newPassword);
        } catch (err){
            console.log('err', err);
            let error = defaultError;
            if (err.response) {
                const { data } = err.response;
                error = { message: data.message };
            } else if (err.request) {
                error = { message: 'Network Unreachable' };
            }
            return dispatch({ payload: { ...failedState, errors: error.message }, type: CHANGE_PASSWORD});
        }
    }
}
