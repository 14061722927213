
let literals: Object = {}

export function init(translations:Object): void {
    literals = translations
}

export function getString(stringId:string) :string {
    if(!literals) return stringId;
    if(stringId in literals)
        return literals[stringId];
    return stringId
};
