import axios from 'axios';

import { IWindow } from '../interfaces/IWindow';

const customWindow: IWindow = window;

if (!customWindow || !customWindow.config || !customWindow.config.baseUrl) {
    throw new Error('Configured base url required to create an axios connection');
}

export const axiosInstance = axios.create({
    baseURL: customWindow.config.baseUrl,
});
if (!customWindow || !customWindow.config || !customWindow.config.dmsUrl) {
    throw new Error('Configured DMS url required to create an axios connection');
}
export const axiosDMSInstance = axios.create({
    baseURL: customWindow.config.dmsUrl,
});

if (!customWindow || !customWindow.config || !customWindow.config.localizationUrl) {
    throw new Error('Configured localization url required to create an axios connection');
}
export const axiosLocalizationInstance = axios.create({
    baseURL: customWindow.config.localizationUrl,
});
