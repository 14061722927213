import React from 'react';
import { Button } from 'antd';
import back from '../../images/back.svg';

import IFullScreenIFrame from '../../interfaces/IFullScreenIFrame';
import { IFrame } from '../IFrame/IFrame';

import './FullScreenIFrame.scss';

const FullScreenIFrame = (props: IFullScreenIFrame) => {
    const { url, onClose } = props;

    return (
        <div className="full-screen-iframe-container">
            <div className="iframe-header">
                <div>
                    <Button onClick={onClose}>
                        <img src={back} alt="back button" />
                    </Button>
                </div>
            </div>
            <IFrame url={url} />;
        </div>
    );
};

export { FullScreenIFrame };
