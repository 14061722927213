import React from 'react';
import TextMessage from '../TextMessage/TextMessage';
import { IMessages, IMessage, MESSAGES_TYPES, ICustomComponent } from '../../../interfaces/IChat';
import './Messages.scss';
import { TEN, ZERO, ONE } from '../../../constants';

/**
 * Functional component to render the messages passes as prop from redux store 
 * @param props : IMessages
 */
const ChatHolder = (props: IMessages) => {
    const { data, linkShareHandler } = props;
    const chatRef = React.createRef<any>();
    const downRef = React.createRef<any>();
    let previousKind = '';
    let addAnchor = true;

    /**
     * Utility method to show or hide scroll button using vanilla javascript using React Refs 
     */
    const handleScroll = () => {
        const bottom = chatRef.current.scrollHeight - chatRef.current.scrollTop > chatRef.current.clientHeight + TEN;
        const downRefElement = downRef.current;
        if (bottom && downRefElement && !downRefElement.classList.contains('noScroll')) {
            downRefElement.style.display = 'block';
        } else if (downRefElement) {
                downRefElement.style.display = 'none';
            }
    };

    /**
     * Utility method to navigate user back to the bottom of the list of messages
     */
    const scrollMeDown = () => {
        const chatRefElement = chatRef.current.querySelector('.messages');
        if (chatRefElement) {
            chatRefElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
        const downRefElement = downRef.current;
        if(downRefElement){
            downRefElement.classList.add("noScroll");
            downRefElement.style.display = 'none';
        }
    };

    const removeNoScrollClass = () =>{
        const downRefElement = downRef.current;
        if(downRefElement){
            downRefElement.classList.remove("noScroll");
        }
    }

    /**
     * Util method to always scroll to the bottom of the list of messages whenever a new message arrives 
     */
    if (data && Array.isArray(data)) {
        setTimeout(() => {
            if (chatRef.current) {
                scrollMeDown();
            }
        }, 0);

        return (
            <div className="chat-msgs-holder" ref={chatRef} role="button" tabIndex={ZERO} onMouseDown={handleScroll} onTouchEnd={handleScroll} onScroll={handleScroll} onMouseOver={removeNoScrollClass} onTouchStart={removeNoScrollClass} onFocus={removeNoScrollClass}>
                <div className="chat-msgs-date">Today</div>
                <div className="messages">
                    {data.map((msg: IMessage | ICustomComponent, index) => {
                        const { type } = msg;
                        if (type === MESSAGES_TYPES.TEXT) {
                            const { timestamp, messageExchangeType } = msg as IMessage;
                            if (!previousKind) {
                                previousKind = messageExchangeType;
                                addAnchor = true;
                            } else {
                                addAnchor = previousKind !== messageExchangeType;
                                previousKind = messageExchangeType;
                            }
                            return (
                                <TextMessage
                                  key={`${timestamp}${index}`}
                                  data={msg as IMessage}
                                  linkShareHandler={linkShareHandler}
                                  addAnchor={addAnchor}
                                />
                            );
                        }
                        const { message } = msg as ICustomComponent;
                        if (type === MESSAGES_TYPES.CUSTOM_COMPONENT) return <>{message}</>;

                        return <div />;
                    })}
                </div>
                <div className="scrollMeDown" onClick={scrollMeDown} onKeyDown={scrollMeDown} role="button" ref={downRef} tabIndex={ONE}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 20" width="19" height="20">
                        <path fill="currentColor" d="M3.8 6.7l5.7 5.7 5.7-5.7 1.6 1.6-7.3 7.2-7.3-7.2 1.6-1.6z" />
                    </svg>
                </div>
            </div>
        );
    }
    return <div />;
};

export default ChatHolder;
