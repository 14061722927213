export enum enumConversationType {
    CHAT = 'chat',
    VIDEO = 'video',
    AUDIO = 'audio',
}
/**
 * Chat modes
 * FULL : chat view will be available in full screen with sidebar and other functionalities
 * BOT: chat will act as bot in a tiny window with only chat feature
 */
export enum ChatViewMode {
    FULL = 'full',
    BOT = 'bot'
}
export interface IConversationHeader {
    title: string;
    backPressHandler?: () => void;
    showCallConnected?: boolean;
    videoCallHandler: () => void;
    audioCallHandler: () => void;
    documentshareViewOpenHandler: () => void;
    type: enumConversationType;
    viewMode: ChatViewMode;
    hideChatHandler: (closeCoBrowsing?: boolean) => void;
    fullHeightChatToggleHandler:(state: boolean) => void;
    cobrowsingHandler:() => void;
    fullChatHeight: boolean;
    typingStatus?: boolean;
    coBrowsingActive?:boolean
}

export interface IVideo {
    name?: string;
}