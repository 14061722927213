import React from 'react';
import send from '../../../images/chat-send-icon.svg';
import gallery from '../../../images/gallery.svg';
import document from '../../../images/document.svg';
import attach from '../../../images/attachLight.svg';
import './Sender.scss';
import { ChatViewMode } from '../../../interfaces/IConversation';

type Props = {
    placeholder: string;
    sendMessage: (event: any) => void;
    onTextInputChange?: (event: any) => void;
    attachmentCallback1: () => void;
    attachmentCallback2: () => void;
    agentLogin: boolean;
    typingStatus?: boolean;
    viewMode: ChatViewMode;
};

// Prototype method to replace the last new line from the text area value
const trimMe = function(value) {
    return value.replace(/^\s+|\s+$/g, '');
};

class Sender extends React.Component<Props> {
    private textareaRef = React.createRef<any>();

    state = {
        hideAttachmentOption: false,
        showMe: true,
    };

    toggleAttachmentOption = () => {
        const { hideAttachmentOption } = this.state;
        this.setState({
            hideAttachmentOption: !hideAttachmentOption,
        });
    };

    onTextInputChangeHandler = e => {
        const { onTextInputChange } = this.props;
        if (onTextInputChange) onTextInputChange(e);

        if (!e.target.value) {
            this.setState({
                showMe: true,
            });
        } else {
            this.setState({
                showMe: false,
            });
        }
    };

    sendMessageHanlder = e => {
        const { sendMessage, viewMode } = this.props;
        if(viewMode !== ChatViewMode.BOT){
            const textareaRef = this.textareaRef.current;
            textareaRef.style.height = '50px';
        }
        sendMessage(e);
        this.setState({
            showMe: true
        });
    };

    onKeyUpHandler = e => {
        const textareaRef = this.textareaRef.current;
        if (textareaRef) {
            textareaRef.style.height = 0;
            textareaRef.style.height = `${textareaRef.scrollHeight}px`;
            textareaRef.style.paddingTop = '10px';
        }
        if (e.keyCode === 13 && !e.shiftKey && textareaRef.value) {
            const { sendMessage, viewMode } = this.props;
            e.preventDefault();
            sendMessage({ preventDefault() {}, target: { message: { value: trimMe(textareaRef.value) } } });
            this.setState({
                showMe: true,
            });
            textareaRef.value = '';
            if(viewMode !== ChatViewMode.BOT){
                textareaRef.style.height = '50px';
            }
        }
    };

    render() {
        const { placeholder, attachmentCallback1, attachmentCallback2, agentLogin, viewMode } = this.props;
        const { hideAttachmentOption, showMe } = this.state;
        return (
            <div className={`withAttachment ${viewMode === ChatViewMode.BOT ? 'chatBotView' : ''}`}>
                {hideAttachmentOption && (
                    <div className="attachment">
                        <div className="attachmentButtonBox">
                            {agentLogin && (
                                <button type="button" className="attachmentIcon" onClick={attachmentCallback2}>
                                    <img src={document} className="buttonImage" alt="" />
                                    <div className="buttonName">Eform</div>
                                </button>
                            )}
                            {agentLogin && (
                                <button type="button" className="attachmentIcon" onClick={attachmentCallback1}>
                                    <img src={document} className="buttonImage" alt="" />
                                    <div className="buttonName">Document</div>
                                </button>
                            )}
                            <button type="button" className="attachmentIcon">
                                <img src={gallery} className="buttonImage" alt="" />
                                <div className="buttonName">Gallery</div>
                            </button>
                        </div>
                    </div>
                )}
                <form
                  className={`msg-send-holder ${viewMode === ChatViewMode.BOT ? 'chatBotView' : ''}`}
                  onSubmit={this.sendMessageHanlder}
                >
                    <div className="msg-input-holder">
                        <textarea
                          id="senderTextarea"
                          className={`msg-input ${viewMode !== ChatViewMode.BOT ? 'noBotView' : ''}`}
                          name="message"
                          placeholder={placeholder}
                          autoComplete="off"
                          onChange={this.onTextInputChangeHandler}
                          onKeyUp={this.onKeyUpHandler}
                          ref={this.textareaRef}
                        />

                        <div
                          role="button"
                          className={`${viewMode !== ChatViewMode.BOT ? 'noBotViewAttachIcon' : ''}`}
                          onClick={this.toggleAttachmentOption}
                          onKeyDown={this.toggleAttachmentOption}
                          tabIndex={0}
                        >
                            <img alt="" src={attach} />
                        </div>
                    </div>
                    <button type="submit" className={`send-btn ${!showMe ? 'showMe' : ''}`}>
                        <img src={send} className="rcw-send-icon" alt="" />
                    </button>
                </form>
            </div>
        );
    }
}

export default Sender;