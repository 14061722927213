import React from 'react';
import { IMessageTime } from '../../../../interfaces/IChat';
import './MessageTime.scss';
import { formatAMPM } from '../../../../utils/time';

const MessageTime = (props: IMessageTime) => {
    const { timestamp} = props;
    return <span className="message-time">{formatAMPM(timestamp)}</span>;
};

export default MessageTime;
