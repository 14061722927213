import moment from 'moment';

/**
 * @param metadata : object
 * @returns dateFields: Array of keys which are of date type
 */
const getDateTypeFields = (metadata) => {
    const dateFields: Array<string> = [];
    metadata.forEach(data => {
        if (data.type === 'date') {
            dateFields.push(data.name);
        }
    });
    return dateFields;
};

/**
 * @param metadata : object
 * @returns uploadFields: Array of keys which are of upload type
 */
const getUploadTypeFields = (metadata) => {
    const uploadFields: Array<string> = [];
    metadata.forEach(data => {
        if (data.type === 'upload') {
            uploadFields.push(data.name);
        }
    });
    return uploadFields;
};

/**
 *
 * @param json : object
 * @param metadata : Array of keys
 * @returns temp :  object - transformed values for moment date to date format
 */
const changeMomentDateToDate = (json, metadata) => {
    const temp = Object.assign({}, json);

    Object.keys(temp).forEach(key => {
        if (metadata.includes(key)) {
            temp[key] = new Date(temp[key]);
        }
    });

    return temp;
};

const changeArrayDataToKeyValue = (json, tableKeys) => {
    const temp = {...json};
    Object.keys(temp).forEach(key => {
        if(tableKeys.includes(key) && Array.isArray(temp[key])) {
            temp[key].forEach((item) => {
                const {key:indexKey} = item;
                Object.keys(item).forEach(objKey => {
                    if(objKey !== "key" && indexKey) {
                        const tableCellLabel = `${key  }.${  indexKey  }.${  objKey}`;
                        temp[tableCellLabel] = item[objKey];
                    }
                })
            })
        }
    });
    return temp;
}

/**
 *
 * @param json : object
 * @param metadata : Array of keys
 * @returns temp :  object - transformed values for date to moment format for FormItem
 */

const changeDateToMomentDate = (json, metadata) => {
    const temp = Object.assign({}, json);

    Object.keys(temp).forEach(key => {
        if (metadata.includes(key)) {
            temp[key] = temp[key] ? moment(temp[key]) : null;
        }
    });
    return temp;
};

/**
 * function to map fileId from file object for upload types
 */
const changeToFileId = (json, metadata) => {
    const temp = Object.assign({}, json);

    Object.keys(temp).forEach(key => {
        if (metadata.includes(key)) {
            if (temp[key]) {
                temp[key] = temp[key].map(file => {
                    if (typeof file === 'object') {
                        return file.fileId;
                    }
                    return file;
                });
            }
        }
    });
    return temp;
};

/**
 * remove undefined and blank fields from submitted data
 */

const removeUnwantedFields = json => {
    const tempJson = Object.assign({}, json);
    Object.keys(json).forEach(key => {
        if (!json[key] || (json[key] instanceof Array && !json[key].length)) {
            delete tempJson[key];
        }
    });
    return tempJson;
};

const getProductLandingPageData = json => {
    const landingPageData: any = {};
    json.forEach((item) => {
        if(item.name === 'Product Name') {
            landingPageData.name = item.value;
        } else if(item.name === 'Product Description') {
            landingPageData.description = item.value;
        } else if(item.name === 'Product Price') {
            landingPageData.price = item.value;
        } 
    });
    return landingPageData;
}

export {
    getDateTypeFields,
    getUploadTypeFields,
    changeMomentDateToDate,
    changeDateToMomentDate,
    changeToFileId,
    removeUnwantedFields,
    changeArrayDataToKeyValue,
    getProductLandingPageData
};
