import { Alert, Divider, Input, Layout } from "antd";
import React, { useState } from "react";
import { HeaderComponent } from "../../components/Header/Header";
import { SCButton, SCPassword } from "@saleskey/frontend-shared";
import "./ChangePassword.scss";
import { connect, connectAdvanced } from "react-redux";
import { changePasswordAction } from "../../store/rootActions";


function ChangePasswordBase(params) {
    const [newPassword, setNewPassword] = useState<any>(undefined);
    const [confirmPassword, setConfirmPassword] = useState<any>(undefined);
    const { loginLoading, authErrorMessage, userType } = params;
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
    const [passwprdStrength, setPasswordStrength] = useState<string>("");


    function handlePasswordChange(e) {
        setNewPassword(e.currentTarget.value)
        if (!regex.test(newPassword)) {
            setPasswordStrength("Weak");
        }
        else if(regex.test(newPassword)){
            setPasswordStrength("Strong");
        }
    }
    function submitPasswordChange() {
        console.log("submit password",newPassword );
        const  {changePasswordDispatcher} = params;
        changePasswordDispatcher(newPassword);
    }

    return (
        <>
            <Layout>
                <HeaderComponent />
                { userType === "admin" ?    
                    <div className="pass-change-container">  
                    {!loginLoading && authErrorMessage && (
                        <Alert message={authErrorMessage} type="error" showIcon banner closable />
                    )}               
                    { passwprdStrength.length > 0 && <span className={passwprdStrength==="Strong"?"strong-password":"weak-password"}>Password is {passwprdStrength}</span>}
                    <SCPassword
                        value={newPassword}
                        placeholder="New Password"
                        onChange={handlePasswordChange}
                        size="large"
                    />
                    <Divider className="no-border" />
                    <SCPassword
                        value={confirmPassword}
                        placeholder="Confirm Password"
                        onChange={(e)=> setConfirmPassword(e.currentTarget.value)}
                        size="large"
                    />
                    <Divider className="no-border" />
                    <SCButton
                        text="Change Password"
                        type="primary"
                        className="primary loginButton"
                        fontWeight={900}
                        onClick={submitPasswordChange}
                        size="large"
                        loading={loginLoading}
                        disabled={newPassword !== confirmPassword }
                    />
                    </div>
                    : <h3>Only admin can change password</h3>
                }
              
            </Layout>
        </>
    )    
}

const stateToProps = (state) => {
    return {
        loginLoading: state.auth.loginLoading,
        authErrorMessage: state.auth.authErrorMessage,
        userType: state.auth.userType,
    }
}

const dispatchers = dispatch => {
    return {
        changePasswordDispatcher : (newPassword: string)=> dispatch(changePasswordAction(newPassword)),
    };
}

const ChangePassword = connect(stateToProps, dispatchers)(ChangePasswordBase);

export {ChangePassword, ChangePasswordBase}