import { contentType } from './IContentType';
import { __ChatTabTypes } from '../constants/chatTabTypes';
import { ChatViewMode, IVideo, IConversationHeader, enumConversationType } from './IConversation';
import { IEformVersionId } from './IEForm';

export interface IlinkShareHandler {
    linkShareHandler: (link: string, linkCode?: string) => void;
}
export interface IMessageBox extends IlinkShareHandler {
    message: string;
    type: string;
    addAnchor?: boolean;
    timestamp: string;
}

export interface IMessageTime {
    timestamp: string;
    moveToNewLine: boolean;
}

export interface IMessageStatus {
    status: string;
}

export interface IMessage {
    messageExchangeType: EMessageExchangeType;
    message: string;
    status: string;
    timestamp: string;
    type: MESSAGES_TYPES;
}

export interface ICustomComponent {
    message: React.ReactChildren;
    type: MESSAGES_TYPES;
}

export interface ITextMessage extends IlinkShareHandler {
    data: IMessage;
    addAnchor: boolean;
}

export interface IMessages extends IlinkShareHandler {
    data: IMessage[];
}

export interface IChat extends IlinkShareHandler {
    messages: IMessage[];
    historyMessages: IMessage[];
    sendMessage: (event: any) => void;
    placeholder: string;
    onTextInputChange?: (event: any) => void;
    title: string;
    showCallConnected?: boolean;
    attachmentCallback1: () => void;
    attachmentCallback2: () => void;
    agentLogin: boolean;
    typingStatus?: boolean;
    viewMode: ChatViewMode;
    noInputView?: boolean;
    fullChatHeight?: boolean;
}

export enum MESSAGES_TYPES {
    TEXT = 'text',
    CUSTOM_COMPONENT = 'component',
}

export enum EMessageExchangeType {
    SENT = 'sent',
    RECEIVED = 'received',
}
export enum messageDeliveryStatus {
    SENT = 'sent',
    DELIVERED = 'delivered',
    READ = 'read',
    ERROR = 'error',
}

export type BaseSharedContentThumbnail = {
    fileType: string;
    source: string;
    type: string;
};

export type BaseSharedContent = Partial<BaseSharedContentThumbnail> & {
    messageExchangeType: EMessageExchangeType;
    contentId: number;
    contentType: contentType;
    contentName: string;
    timestamp: Date;
    senderName: string;
    senderId: string;
    thumbnailUrl: string;
};

export interface IConversationComponent extends IChat, IVideo, IConversationHeader {
    title: string;
    type: enumConversationType;
    callingSourceUrl: string | undefined;
    viewMode: ChatViewMode;
    chatTabType?: __ChatTabTypes;
    chatTabChangeHandler?: (chatTabType: __ChatTabTypes) => void;
    linkCode?: string;
    eformVersionId?: IEformVersionId;
    reviewCommentsLoadingHandler?: (linkCode: string, eformVersionId: number) => void;
    reviewCommentsHistory?: [];
    fullChatHeight: boolean;
}

export interface IFloatingChatModal {
    currentUserName: string;
    botAnswerHandler: (answer: boolean) => void;
}
